/**
 * Copy Methods
 */
export const pusherCustomerChannelMixin = {
    data() {
        return {
            pusherCustomerChannelMixin: {
                isTypingEventThrottled: false,
                ignoreFirstSmsMessageUpdate: true,
                members: [],
                typingInactivityTimeouts: [],
            },
        };
    },

    computed: {
        pusherCustomerChannelMixinMembersTyping() {
            return this.pusherCustomerChannelMixin.members.filter((member) => member.typing);
        },

        pusherCustomerChannelMixinNotUserMembers() {
            return this.pusherCustomerChannelMixin.members.filter(
                (member) => member.id !== this.userSession.userInfo.id
            );
        },
    },

    created() {
        const customerChannel = Echo.join(`customer.id.${this.selectedCustomer.ID}`);

        customerChannel.here((members) => {
            // Get current members
            this.pusherCustomerChannelMixin.members = members.map((member) => {
                return { ...member, typing: false };
            });
        });

        customerChannel.joining((joiningMember) => {
            // Add member
            this.pusherCustomerChannelMixin.members.push({ ...joiningMember, typing: false });
        });

        customerChannel.leaving((leavingMember) => {
            // Remove member
            this.pusherCustomerChannelMixin.members = this.pusherCustomerChannelMixin.members.filter(
                (member) => member.id !== leavingMember.id
            );
        });

        Echo.private(`customer.id.${this.selectedCustomer.ID}`)
            .listenForWhisper('is-typing', (event) => {
                this.pusherCustomerChannelMixin.members = this.pusherCustomerChannelMixin.members.map((member) => {
                    if (member.id === event.userId) {
                        member.typing = true;
                    }

                    return member;
                });

                // Remove is typing indicator after inactivity
                if (this.pusherCustomerChannelMixin.typingInactivityTimeouts[event.userId]) {
                    clearTimeout(this.pusherCustomerChannelMixin.typingInactivityTimeouts[event.userId]);
                }

                this.pusherCustomerChannelMixin.typingInactivityTimeouts[event.userId] = setTimeout(() => {
                    this.pusherCustomerChannelMixin.members = this.pusherCustomerChannelMixin.members.map((member) => {
                        if (member.id === event.userId) {
                            member.typing = false;
                        }
                        return member;
                    });
                }, 8000);
            })
            .listenForWhisper('not-typing', (event) => {
                // Remove is typing indicator after inactivity
                if (this.pusherCustomerChannelMixin.typingInactivityTimeouts[event.userId]) {
                    clearTimeout(this.pusherCustomerChannelMixin.typingInactivityTimeouts[event.userId]);
                }

                this.pusherCustomerChannelMixin.members = this.pusherCustomerChannelMixin.members.map((member) => {
                    if (member.id === event.userId) {
                        member.typing = false;
                    }

                    return member;
                });
            });
    },

    beforeDestroy() {
        Echo.leave(`customer.id.${this.selectedCustomer.ID}`);
    },

    methods: {
        $_pusherCustomerChannelMixin_sendIsTypingEvent() {
            if (this.pusherCustomerChannelMixin.ignoreFirstSmsMessageUpdate) {
                this.pusherCustomerChannelMixin.ignoreFirstSmsMessageUpdate = false;
                return;
            }

            if (this.pusherCustomerChannelMixin.isTypingEventThrottled) {
                return;
            }

            Echo.private(`customer.id.${this.selectedCustomer.ID}`).whisper('is-typing', {
                userId: this.userSession.userInfo.id,
            });

            this.pusherCustomerChannelMixin.isTypingEventThrottled = setTimeout(() => {
                this.pusherCustomerChannelMixin.isTypingEventThrottled = false;
            }, 2500);
        },

        $_pusherCustomerChannelMixin_sendIsNotTypingEvent() {
            if (this.pusherCustomerChannelMixin.isTypingEventThrottled) {
                clearTimeout(this.pusherCustomerChannelMixin.isTypingEventThrottled);
                this.pusherCustomerChannelMixin.isTypingEventThrottled = null;
            }

            Echo.private(`customer.id.${this.selectedCustomer.ID}`).whisper('not-typing', {
                userId: this.userSession.userInfo.id,
            });
        },
    },
};
