<template>
    <div class="flex-grow-1">
        <form @submit.prevent="sendSMSToCustomer">
            <div class="bg-alpha_bg border rounded p-2">
                <div class="row justify-content-between mb-3">
                    <!-- Send To Select -->
                    <div class="col col-xl-4">
                        <div class="input-group input-group-sm">
                            <!-- Send To/Save For Select -->
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    {{ isSendToInputOptionTypePending ? 'Save for' : 'Send to' }}
                                </span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="sendToInput"
                                size="mini"
                                value-key="uniqueIdentifier"
                            >
                                <el-option-group
                                    v-for="(sendToOption, index) in sendToOptions"
                                    :key="index"
                                    :label="sendToOption.label"
                                >
                                    <template v-for="(option, index) in sendToOption.options">
                                        <el-option
                                            v-if="sendToOption.label === 'Send to Customer'"
                                            :key="index"
                                            :label="`${option.label}: ${ (option.type=='asm') ? option.number : formatPhoneNumber(option.number)}` + (option.isValid == 0 ? ' (Invalid)' : '')"
                                            :value="option"
                                            :title="isOptOutTexts ? 'Customer is opted out of receiving texts' : ''"
                                            :disabled="isOptOutTexts"
                                        >
                                            {{ option.label }}: {{ (option.type=='asm') ? option.number : formatPhoneNumber(option.number) }}
                                            <i v-if="option.isValid == 1" title="Valid" class="fas fa-check-circle text-success"></i>
                                            <i v-else-if="option.isValid == 0" title="Invalid" class="fas fa-exclamation-circle text-danger"></i>
                                        </el-option>
                                        <el-option
                                            v-else
                                            :key="index"
                                            :label="`${option.name} (${option.number})`"
                                            :value="option"
                                        ></el-option>
                                    </template>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <!-- Predefined Messages Button -->
                    <div class="col-2 col-sm-4 text-right">
                        <!-- For Small Screens -->
                        <button
                            type="button"
                            class="d-lg-none btn btn-lg btn-alpha_secondary"
                            data-toggle="modal"
                            data-target="#predefined_messages_modal"
                            :disabled="!predefinedSMSMessages.length"
                        >
                            <span class="d-none d-sm-inline">Predefined Messages</span>
                            <i class="d-sm-none far fa-comment-alt-lines"></i>
                        </button>
                        <!-- For Large Screens -->
                        <button
                            type="button"
                            class="d-none d-lg-inline-block btn btn-xs btn-alpha_secondary"
                            data-toggle="modal"
                            data-target="#predefined_messages_modal"
                            :disabled="!predefinedSMSMessages.length"
                        >Predefined Messages</button>
                    </div>
                </div>

                <!-- Message Input -->
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Type message"
                    v-model="smsMessageInput"
                ></el-input>

                <!-- Attached Files -->
                <div v-if="uppyUploadedFileUrls && uppyUploadedFileUrls.length" class="row pt-2">
                    <div class="col d-flex flex-wrap mb-2 mb-sm-0">
                        <div v-for="(file, index) in uppyUploadedFileUrls" :key="index" class="attached-file">
                            <a :href="file.url" target="_blank">{{ file.name }}</a>
                            <button @click="removeUppyFileUrl(file)" type="button" class="text-danger ml-1">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between pt-2">
                    <div class="col-sm-8 col-xl-6 d-flex flex-wrap align-items-center mb-2 mb-sm-0">
                        <!-- Attach Image -->
                        <template v-if="!file">
                            <label
                                class="add-attachment-button badge badge-alpha_neutral--shift mr-2 mb-0 p-1"
                                :class="{ disabled: !isSendToInputOptionTypePending && isOptOutTexts }"
                            >
                                <input
                                    @change="onFileChange"
                                    ref="file"
                                    type="file"
                                    name="file"
                                    accept="image/*, application/pdf"
                                    capture="environment"
                                    style="display: none"
                                    :disabled="!isSendToInputOptionTypePending && isOptOutTexts"
                                >
                                <i class="fas fa-image"></i> Attach Image/PDF
                            </label>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-danger mr-2 p-1" @click="removeFile">Remove File</button>
                        </template>

                        <!-- Upload Video Via Uppy File Uploader -->
                        <button
                            @click="isUppyOpen = !isUppyOpen"
                            id="uppy-trigger"
                            class="add-attachment-button badge badge-alpha_neutral--shift mr-2 mb-0 border-0 p-1"
                            type="button"
                            :disabled="(!isSendToInputOptionTypePending && isOptOutTexts) || (uppyUploadedFileUrls && uppyUploadedFileUrls.length >= 1)"
                        ><i class="fas fa-video"></i> Upload Video</button>

                        <dashboard-modal
                            :uppy="uppy"
                            :open="isUppyOpen"
                            :props="{
                                proudlyDisplayPoweredByUppy: false,
                                trigger: '#uppy-trigger',
                            }"
                        />

                        <!-- Message Characters Used Indicator -->
                        <small>{{ smsMessageInput.length }} Characters used</small>

                        <!-- Vehicle Select -->
                        <div class="col-12 col-sm px-0 ml-sm-2 mt-2 mt-sm-0">
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="selectedVehicleIdInput"
                                size="mini"
                                placeholder="Select"
                            >
                                <el-option
                                    v-for="(vehicle, index) in vehicles"
                                    :key="index"
                                    :label="`${vehicle.year} ${vehicle.v_make} ${vehicle.v_model}`"
                                    :value="vehicle.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <div class="col-sm-4 d-flex justify-content-end">
                        <div class="mr-1 d-flex align-items-center" v-for="member in pusherCustomerChannelMembers" :key="member.id">
                            <img v-tooltip:top :title="member.name" :class="{ 'flash': member.typing }" :src="member.avatar" :alt="member.name" class="rounded-circle" width="25" height="25">
                        </div>
                        <!-- For Small Screens -->
                        <button
                            type="submit"
                            class="d-lg-none btn btn-lg btn-alpha_primary ml-1"
                            :disabled="!isSmsMessageSendable && !isPendingMessageSavable"
                            :title="isOptOutTexts && !isSendToInputOptionTypePending ? 'Customer is opted out of receiving texts' : ''"
                        >{{ isSendToInputOptionTypePending ? 'Save as Pending' : 'Send to Customer' }}</button>
                        <!-- For Large Screens -->
                        <button
                            type="submit"
                            class="d-none d-lg-inline-block btn btn-xs btn-alpha_primary ml-1"
                            :disabled="!isSmsMessageSendable && !isPendingMessageSavable"
                            :title="isOptOutTexts && !isSendToInputOptionTypePending ? 'Customer is opted out of receiving texts' : ''"
                        >{{ isSendToInputOptionTypePending ? 'Save as Pending' : 'Send to Customer' }}</button>
                    </div>

                </div>
            </div>
        </form>

        <!-- Predefined Messaged Modal -->
        <div class="modal fade" id="predefined_messages_modal" tabindex="-1" role="dialog" aria-labelledby="predefined_message_title" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="predefined_message_title">Message Templates</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <template v-for="(category, categoryIndex) in predefinedSMSMessages">
                        <div v-if="category.SMS.length" class="list-group mb-3" :key="categoryIndex">
                            <h2 class="font-20">{{ category.NAME }}</h2>
                            <template v-for="(sms, smsIndex) in category.SMS">
                                <button
                                    :key="smsIndex"
                                    @click="smsMessageInput = renderPredefinedMessageTags(sms.PREDEFINED_SMS_MESSAGE)"
                                    type="button"
                                    class="list-group-item list-group-item-action flex-column align-items-start"
                                    data-dismiss="modal"
                                    :disabled="isLoading.createRewardsTinyUrl"
                                >
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1">{{ sms.NAME }}</h5>
                                    </div>
                                    <p class="mb-1">{{ renderPredefinedMessageTags(sms.PREDEFINED_SMS_MESSAGE) }}</p>
                                    <button v-if="hasRewardsLinkTagInPredefinedMessage(sms.PREDEFINED_SMS_MESSAGE) && !rewardsLinkTinyUrl"
                                        @click.stop="createRewardsTinyUrl"
                                        type="button"
                                        class="btn btn-alpha_secondary"
                                        :disabled="isLoading.createRewardsTinyUrl"
                                    >
                                        <template v-if="isLoading.createRewardsTinyUrl">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Convert Reward Link to Tiny URL</template>
                                    </button>
                                </button>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DashboardModal } from '@uppy/vue';
import { smsCompositionMixin } from '@/mixins/smsCompositionMixin';

export default {
    components: {
        'dashboard-modal': DashboardModal,
    },

    mixins: [smsCompositionMixin],

    props: {
        fileUrls: {
            type: Array,
            default: () => [],
        },
        parentId: {
            type: String
        },
        pusherCustomerChannelMembers: {
            type: Array,
            default: () => [],
        },
        rewardsLinkTinyUrl: {
            type: String,
            default: null,
        },
        vehicleId: {
            type: String,
        },
        vehicles: {
            type: Array,
        },
    },

    data() {
        return {
            isUppyOpen: false,
        }
    },

    computed: {
        // Uppy Instance
        uppy: function() {
            return new Uppy({
                restrictions: {
                    allowedFileTypes: ['video/*'],
                    maxFileSize: 1000000 * 1000, // Megabytes
                    maxNumberOfFiles: 1,
                    maxTotalFileSize: 1000000 * 1000, // Megabytes
                }
            }).use(AwsS3Multipart, {
                limit: 4,
                companionUrl: '/',
            }).on('complete', (result) => {
                this.addUppyFileUrls(result.successful);
            })
        },

        // Options containing phone numbers and ASMs for the Send To drop-down.
        sendToOptions() {
            const sendToOptions = [];

            // Create a copy of the asms for sorting
            const asms = this.asms.map(asm => Object.assign({}, asm));

            // Add customer phone numbers to options
            if (this.isCustomerMessagingEnabled && this.phoneNumbers.length) {
                sendToOptions.push({ label: 'Send to Customer', options: this.phoneNumbers });
            }

            // Add ASMs to options
            if (asms.length) {
                const formattedAsms = [];

                // Format ASMs
                asms.forEach(asm => {
                    formattedAsms.push(
                        {
                            id: asm.asmId,
                            name: asm.asmName,
                            number:
                            asm.asmNumber,
                            optionType: 'pending',
                            uniqueIdentifier: asm.asmId,
                        }
                    );
                });

                // Move logged in ASM to beginning of array
                formattedAsms.sort((a, b) => {
                    const userId = this.userSession.externalUserId ?? '';
                    return a.id == userId ? -1 : b.id == userId ? 1 : 0;
                });

                sendToOptions.push({ label: 'Save as Pending', options: formattedAsms });
            }

            return sendToOptions;
        },

        isSendToInputOptionTypePending() {
            return Boolean(this.sendToInput.optionType && this.sendToInput.optionType === 'pending');
        },

        isPendingMessageSavable() {
            if (!this.isSendToInputOptionTypePending) {
                return false;
            }

            if (!this.smsMessageInput) {
                return false;
            }

            if (!this.customer.ID) {
                return false;
            }

            if (!this.selectedVehicleIdInput) {
                return false;
            }

            return true;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        selectedVehicleIdInput: {
            get() {
                return this.vehicleId;
            },
            set(message) {
                this.$emit('updateSelectedVehicleId', message);
            }
        },

        uppyUploadedFileUrls: {
            get() {
                return this.fileUrls;
            },
            set(fileUrls) {
                this.$emit('updateFileUrls', fileUrls);
            }
        },
    },

    created() {
        //
    },

    beforeDestroy () {
        // Delete unsent files and close uppy
        try {
            // this.fileUrls.forEach(fileUrl => {
            //     this.deleteFileFromS3(fileUrl.key);
            // });
            this.uppy.close();
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        // Add file URLs to uppyUploadedFileUrls array
        addUppyFileUrls(files) {
            const fileUrls = [...this.uppyUploadedFileUrls];

            files.forEach(file => {
                fileUrls.push({
                    bucket: file.response.body.bucket,
                    fileGuid: file.response.body.metadata['file-guid'] ?? null,
                    id: file.id,
                    key: file.s3Multipart.key,
                    name: file.name,
                    url: file.uploadURL,
                });
            });

            this.uppyUploadedFileUrls = fileUrls;
        },

        // Remove file URLs from uppyUploadedFileUrls array
        removeUppyFileUrl(fileToRemove) {
            const fileUrls = this.uppyUploadedFileUrls.filter(file => {
                if(file.url === fileToRemove.url) {
                    return false;
                }

                return true;
            });

            this.uppyUploadedFileUrls = fileUrls;

            try {
                this.uppy.removeFile(fileToRemove.id);
            } catch (error) {
                console.error(error);
            }

            this.deleteFileFromS3(fileToRemove.key);
        },

        // Delete file from S3 bucket
        deleteFileFromS3(key) {
            axios
                .post(route('s3.multipart.deleteUploaded'), { key: key })
                .then(response => {
                    console.info(`Deleted the file ${key} successfully from S3.`);
                })
                .catch(error => {
                    console.error(`The file ${key} was unable to be deleted from S3.`);
                });
        },

        // Send SMS message to customer
        async sendSMSToCustomer() {
            if (!this.phoneNumbers.length) {
                return this.$message({
                    type: 'error',
                    message: 'Unable to send or save a pending message if a customer has no phone numbers listed.'
                });
            }

            this.isLoading.SendSMSToCustomer = true;

            let smsMessage = this.smsMessageInput;

            const formData = new FormData();

            formData.append('parent_id', this.parentId);
            formData.append('customerId', this.customer.ID);
            formData.append('customerNumber', this.customer.number);
            formData.append('smsText', smsMessage);
            formData.append('vehicleId', this.selectedVehicleIdInput);
            formData.append('componentName', this.componentName);

            if (this.uppyUploadedFileUrls?.length) {
                this.uppyUploadedFileUrls.forEach(fileUrl => {
                    formData.append('fileGuid', fileUrl.fileGuid);
                    formData.append('fileName', fileUrl.key);
                    formData.append('bucket', fileUrl.bucket);
                });
            }

            // If sendToInput is ASM, set pendingUser and set phoneNumber to first phone number
            if (this.isSendToInputOptionTypePending) {
                formData.append('pendingUser', this.sendToInput.id);
                formData.append('phoneNumber', this.phoneNumbers[0].number);
            }
            // Else, just set the phone number
            else {
                formData.append('phoneNumber', this.sendToInput.number);
            }

            // Add file to form data
            if (this.fileInput) {
                formData.append('file', this.fileInput);
            }

            // If files are attached and isTechnicianMode enabled
            if (this.enableTechnicianMode && (this.fileInput || this.uppyUploadedFileUrls?.length)) {
                let technicianNumber = '';

                await this.promptForTechnicianNumber()
                    .then(value => {
                        technicianNumber = value.trim();
                    })
                    .catch(error => {
                        //
                    });

                if (!technicianNumber) {
                    this.isLoading.SendSMSToCustomer = false;
                    return;
                }

                formData.append('technicianNumber', technicianNumber);
            }

            axios
                .post(
                    route(
                        'api.unotifi.SendSMSToCustomer',
                        { selectedDealer: this.selectedDealerId }
                    ),
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(response => {
                    if (response.data.success) {
                        this.$emit('success');

                        try {
                            this.uppy.reset();
                        } catch (error) {
                            console.error(error);
                        }

                        this.$message({
                            type: 'success',
                            message: 'Message sent successfully'
                        });
                    }
                })
                .catch(error => {
                    const errorMessage = error.response.data.message
                        ?? 'There was an error sending the message. Please try again.';

                    this.$message({
                        type: 'error',
                        message: errorMessage
                    });
                })
                .finally(() => {
                    $(`#notification_${this.customer.ID}`).remove();
                    this.isLoading.SendSMSToCustomer = false;
                });
        },

        async promptForTechnicianNumber() {
            return new Promise((resolve, reject) => {
                this.$prompt('Please enter the technician number.', 'Technician Number', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    inputPattern: /^[a-zA-Z0-9]+$/,
                    inputErrorMessage: 'A valid technician number is required.',
                }).then(({ value }) => {
                    return resolve(value);
                }).catch(() => {
                    return reject('Cancelled');
                });
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    .add-attachment-button {
        cursor: pointer;

        &.disabled, &:disabled {
            cursor: not-allowed;
        }
    }

    .attached-file {
        background: var(--alpha_bg--shift);
        border-radius: 5px;
        margin: 0 2px 2px 0;
        padding: 2px 7px;

        button {
            background: none;
            border: none;
            padding: 0;
        }
    }
</style>
