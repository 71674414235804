<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a
                            href="#filters-results"
                            class="font-20"
                            data-toggle="collapse"
                            aria-expanded="true"
                            @click="servicesResultSize"
                        >Pending Messages</a>

                        <ServicesFavoriteButton
                            servicePageName="services-pending-messages"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="
                            filterResultsFormCurrentPage = 1;
                            $_generalMixin_scrollSimpleBarResultsList();
                            submitFilterResultsForm({clearResults: true})
                        ">
                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Assigned To ASMs -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Assigned To</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormAssignedToAsmNumbers"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [
                                                {asmName: 'Select Options', asmId: 'select-options'},
                                                ...asms
                                            ]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmNumber"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div
                                                v-if="asm.asmId === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormAssignedToAsmNumbers = asms.map(
                                                        item => item.asmNumber
                                                    )"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormAssignedToAsmNumbers = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>
                                                {{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFirstName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input v-model="filterResultsFormLastName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- VIN -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input v-model="filterResultsFormVINNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button
                                        type="submit"
                                        :disabled="isLoadingResults.getResults"
                                        class="btn btn-alpha_primary btn-block btn-lg"
                                    >
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button
                                        @click="
                                            resetFilterResultsForm();
                                            $_generalMixin_scrollSimpleBarResultsList();
                                            submitFilterResultsForm({clearResults: true})
                                        "
                                        type="button"
                                        class="btn btn-alpha_secondary btn-block btn-lg"
                                    >
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar
                    ref="resultsList"
                    id="results_list"
                    class="overflow-auto clients-list-wrapper flex-grow-1 pt-3"
                    :style="servicesResultCss"
                >
                    <ul
                        v-if="results.pendingMessages && results.pendingMessages.length"
                        class="list-unstyled clients-list"
                    >
                        <li
                            v-for="(result, index) in results.pendingMessages"
                            @click="loadResultInfo(result, selectedDealerId)"
                            :class="{
                                'active': isResultSelected(result),
                                'unread': $_generalMixin_getMessageStatusByCustomerId(
                                    result.custID, notifications.notifications
                                ) === 'unread'
                            }"
                            :key="index"
                            :id="result.custID"
                        >
                            <!-- Customer/Date -->
                            <div class="clients-list__name flex-grow-1">
                                <p class="font-14 mb-0">{{ result.cust }}</p>
                                <p class="font-12"><em>{{ truncate(result.description, 25, '…') }}</em></p>
                                {{ $moment(result.date).format('MM/DD/YY @h:mm A') }}
                            </div>

                            <!-- Customer Info -->
                            <div class="clients-list__info">
                                <!-- Sentiment -->
                                <div
                                    v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                    class="mood mood-sm mx-1"
                                    :class="`mood-${$_generalMixin_getCustomerMoodSettings(
                                        result.custID, customersSentiments
                                    ).color}`"
                                >
                                    <i
                                        :class="$_generalMixin_getCustomerMoodSettings(
                                            result.custID, customersSentiments
                                        ).icon"
                                        title="Customer's mood guessed from their recent correspondence"
                                    ></i>
                                </div>
                                <div v-else class="mood mood-sm mx-1">
                                    <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                </div>

                                <!-- Notifications -->
                                <div class="clients-list__notification">
                                    <div
                                        v-if="$_generalMixin_getMessageStatusByCustomerId(
                                            result.custID, notifications.notifications
                                        ) === 'unread'"
                                        class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1 badge-notify"
                                    >
                                        <i class="icon">textsms</i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {
                        filterResultsFormCurrentPage = currentPage;
                        $_generalMixin_scrollSimpleBarResultsList();
                        submitFilterResultsForm({clearResults: true})
                    }"
                />
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <PendingMessageInfo />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import { truncate } from '@/filters/stringFormats';
import { mapPreferences } from 'vue-preferences';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import PendingMessageInfo from '@/components/services/PendingMessageInfo.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        PendingMessageInfo,
        ResultsPagination,
        ServicesFavoriteButton,
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filter selection options
            filterResultsFormOptions: {},

            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 70vh',
        }
    },

    computed: {
        ...mapState('services/pendingMessages', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapState('services/pendingMessageInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
            selectedPendingMessageId: 'selectedPendingMessageId',
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapGetters('services/pendingMessageInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapPreferences({
            pendingMessages_searchForm_assignedTo: {
                defaultValue: null
            },
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormAssignedToAsmNumbers: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.assignedToAsmNumbers;
            },
            set(value) {
                this.pendingMessages_searchForm_assignedTo = Array.isArray(value) && value.length === 1
                    ? value[0]
                    : null;

                this.$store.commit('services/pendingMessages/SET_FILTERS', {
                    attribute: 'assignedToAsmNumbers',
                    value: value
                });
            }
        },

        filterResultsFormFirstName: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.firstName;
            },
            set(value) {
                this.$store.commit('services/pendingMessages/SET_FILTERS', { attribute: 'firstName', value: value });
            }
        },

        filterResultsFormLastName: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.lastName;
            },
            set(value) {
                this.$store.commit('services/pendingMessages/SET_FILTERS', { attribute: 'lastName', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('services/pendingMessages/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/pendingMessages/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return this.$store.state.services.pendingMessages.filterResultsForm.dateFromTo;
            },
            set(value) {
                this.$store.commit('services/pendingMessages/SET_FILTERS', { attribute: 'dateFromTo', value: value });
            }
        },
    },

    watch: {
        $route(to) {
            if (to.query.customer_id && to.query.vehicle_id) {
                this.loadResultInfo(
                    {
                        custID: to.query.customer_id,
                        vehicleId: to.query.vehicle_id,
                        type: 'Approval Pending' ?? null
                    },
                    this.selectedDealerId
                );
            } else if (to.query.customer_id) {
                this.loadResultInfo(
                    {
                        custID: to.query.customer_id,
                        type: 'Approval Pending' ?? null
                    },
                    this.selectedDealerId
                );
            }

            this.scrollToClient();
        },

        'isLoadingResults.getResults'(isLoading){
            if (this.$route.query.customer_id && !isLoading) {
                // give time to render the html to scrollIntoView
                setTimeout(() => {
                    this.scrollToClient();
                } , 1000);

            }
        }
    },

    created() {
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        if (this.$route.query.customer_id && this.$route.query.vehicle_id) {
            this.loadResultInfo(
                {
                    custID: this.$route.query.customer_id,
                    vehicle_id: this.$route.query?.vehicle_id,
                    type: 'Approval Pending'
                },
                this.selectedDealerId
            );
        } else if(this.$route.query.customer_id){
            this.loadResultInfo(
                {
                    custID: this.$route.query.customer_id,
                    type: 'Approval Pending'
                },
                this.selectedDealerId
            );
        }

        if (! this.results.pendingMessages) {
            this.submitFilterResultsForm(this.filterResultsParams);
        }
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex pendingMessages actions
        ...mapActions('services/pendingMessages', {
            getResults: 'getResults',
        }),

        // Vuex pendingMessageInfo actions
        ...mapActions('services/pendingMessageInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex pendingMessages mutations
        ...mapMutations('services/pendingMessages', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Imported methods
         */
        truncate,

        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.notifications.notifications.forEach(result => {
                        if (result.custID && ! foundCustomerIds.includes(result.custID)) {
                            foundCustomerIds.push(result.custID);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({
                        selectedDealerId: this.selectedDealerId,
                        customerIds: foundCustomerIds
                    });

                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        loadResultInfo(result, selectedDealerId) {
            this.getResultInfo({
                result: result,
                selectedDealerId: selectedDealerId
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();
            const autoRefreshInterval = this.userSession
                ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000
                : 60000

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, autoRefreshInterval);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/pendingMessages/RESET_FILTERS');
        },

        /**
         * Delete pending message
         */
        deletePendingMessage(eventId, description) {
            this.$confirm('Are you sure you want to delete this pending message?', 'Delete Pending Message', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
            }).then(() => {
                const params = {
                    type: 'Deleted',
                };

                axios.post(route('api.unotifi.UpdateEvent', { eventId: eventId, description: description }), params)
                    .catch(error => {
                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }
                    });
            }).catch(error => {
                console.error(error);
            });
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(event) {
            if (this.$route.query.customer_id && !this.selectedPendingMessageId) {
                return event.custID === this.$route.query.customer_id;
            }
            return this.selectedPendingMessageId && event.event_id == this.selectedPendingMessageId;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive?'max-height: 80vh':'max-height: 70vh';
            this.filerResultActive=!this.filerResultActive;
        },

        // Scroll to the client message is activated
        scrollToClient(){
            if (this.$route.query.customer_id){
                let scrollTocli = document.getElementById(this.$route.query.customer_id);
                if (scrollTocli) {
                    scrollTocli.scrollIntoView({behavior: "smooth"});
                }
            }
        },
    },
}
</script>
