<template>
<div>
    <!-- Create New Chat Modal -->
    <div class="modal fade" ref="create_new_chat_modal" tabindex="-1" role="dialog" aria-labelledby="create_new_chat_title" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="create_new_chat_title">
                        Start a New Chat
                        <template v-if="selectedVehicleId || selectedCustomerId">
                            About the Selected Vehicle and/or Customer
                        </template>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Add Chat Form -->
                    <form @submit.prevent="addConversationMessage">
                        <div class="row justify-content-between mb-3">
                            <div class="col col-xl-6">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            Participants
                                        </span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="newChatForm.selectedParticipants"
                                        size="mini"
                                        collapse-tags
                                        :multiple="true"
                                        :multiple-limit="40"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [{asmName: 'Select Options', asmId: 'select-options'}, ...selectableParticipants]"
                                            :key="index"
                                            :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                            :value="asm.asmId"
                                            :disabled="asm.asmId === 'select-options'"
                                        >
                                            <!-- Select Options Buttons -->
                                            <div v-if="asm.asmId === 'select-options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="newChatForm.selectedParticipants = selectableParticipants.map(item => item.asmId)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="newChatForm.selectedParticipants = []"
                                                >Select None</button>
                                            </div>
                                            <!-- Label -->
                                            <span v-else>{{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4}"
                            placeholder="Type message"
                            v-model="newChatForm.chatMessageInput"
                        ></el-input>
                        <div class="row justify-content-between pt-2">
                            <div class="col-sm-8 col-md-6 col-lg-8 col-xl-4 d-flex flex-wrap mb-2 mb-sm-0">
                                <small>{{ newChatForm.chatMessageInput.length }} Characters used</small>
                            </div>
                            <div class="col text-right">
                                <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-alpha_primary"
                                    :disabled="!newChatForm.selectedParticipants.length || !newChatForm.chatMessageInput"
                                >Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <modal-create-new-chat ref="modalCreateNewChat"></modal-create-new-chat>
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalCreateNewChat.showModal()">New Chat</button>
 */

import { mapState } from 'vuex';

export default {
    props: {
        selectedCustomerId: {
            type: String,
            default: '',
        },
        selectedVehicleId: {
            type: String,
            default: '',
        },
    },

    components: {
        //
    },

    data() {
        return {
            errors: [],

            isLoading: {
                addConversationMessage: false,
            },

            newChatForm: {
                chatMessageInput: '',
                selectedParticipants: [],
            },
        }
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        selectableParticipants() {
            return this.asms.filter(asm => !this.userSession || asm.asmId !== this.userSession.externalUserId);
        },
    },

    watch: {
        //
    },

    created() {
        //
    },

    mounted() {
        //
    },

    methods : {
        showModal() {
            $(this.$refs.create_new_chat_modal).modal('show');
        },

        hideModal() {
            $(this.$refs.create_new_chat_modal).modal('hide');
        },

        // Reset Form
        resetNewChatForm() {
            this.newChatForm.chatMessageInput = '';
            this.newChatForm.selectedParticipants = [];
        },

        // Create New Chat
        addConversationMessage() {
            this.isLoading.addConversationMessage = true;

            const params = {
                conversationId: this.conversationId,
                customerId: this.selectedCustomerId,
                message: this.newChatForm.chatMessageInput,
                participantASMIds: this.newChatForm.selectedParticipants,
                vehicleId: this.selectedVehicleId,
            };

            axios
                .post(route('api.unotifi.AddConversationMessage', { selectedDealer: this.selectedDealerId }), params)
                .then(response => {
                    if (response.data.success) {
                        this.$emit('success');

                        this.$message({
                            type: 'success',
                            message: 'Message sent successfully.'
                        });

                        this.hideModal();
                        this.resetNewChatForm();
                    }
                })
                .catch(error => {
                    if(error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error sending the message'
                    });
                })
                .finally(() => {
                    this.isLoading.addConversationMessage = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
//
</style>