<template>
    <div class="container-fluid p-sm-2">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-7">
                <span class="font-20 font-weight-bolder">Users Activity</span>
            </div>

            <!-- Filter -->
            <div class="col-12 col-md-6 col-lg-5">
                <form @submit.prevent="getCharts()">
                    <div class="row mb-4 mb-lg-0">
                        <!-- Date -->
                        <div class="col-12 col-sm-6 col-md-6 mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Date Range
                                    </span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsForm.dateFromTo"
                                    :max-date='new Date()'
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template
                                        v-slot="{
                                            inputValue,
                                            inputEvents,
                                            isDragging
                                        }"
                                    >
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="
                                                isDragging
                                                    ? 'text-gray-600'
                                                    : 'text-gray-900'
                                            "
                                            :value="
                                                `${inputValue.start} - ${inputValue.end}`
                                            "
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <button
                                :disabled="isLoading.getCharts"
                                type="submit"
                                class="btn btn-alpha_primary btn-xs"
                            >
                                <template v-if="isLoading.getCharts">
                                    <i
                                        style="height: 11px"
                                        class="fas fa-cog fa-spin"
                                    ></i>
                                    <span class="sr-only">Loading...</span>
                                </template>
                                <template v-else>Filter</template>
                            </button>
                            <button
                                @click="printPage()"
                                :disabled="! chartData"
                                type="button"
                                class="btn btn-alpha_neutral--shift btn-xs"
                            >
                                <i class="icon">print</i> Print
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Charts -->
        <transition name="fade">
            <div
                v-if="chartData"
                v-loading="isLoading.getCharts"
                id="charts_to_print"
                class="row bg-alpha_bg"
            >
                <!-- User's Last Activity Table -->
                <div v-if="usersLastActivityTable.data" class="col-12 col-md-6">
                    <div class="row p-2">
                        <div class="col-12 py-2">
                            <p class="font-weight-bold text-center mb-2">
                                Activity History
                            </p>
                            <div class="table-responsive">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">
                                                Last Activity Date
                                            </th>
                                            <th class="text-right" scope="col">
                                                How Long Ago
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(data, index) in usersLastActivityTable.data"
                                            :key="index"
                                            :class="{
                                                'text-alpha_text--muted': !data.value
                                            }"
                                        >
                                            <th scope="row">{{ data.name }}</th>
                                            <td>
                                                {{
                                                    data.value
                                                    ? $moment(data.value)
                                                        .format(
                                                            'MM/DD/YY @h:mm A'
                                                        )
                                                    : 'No Activity'
                                                }}
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    data.value
                                                        ? $moment(data.value)
                                                            .fromNow()
                                                        : 'No Activity'
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SMSes Manually Sent By Users Chart -->
                <div
                    v-if="smsManuallySentByUserChart.data"
                    class="col-12 col-md-6"
                >
                    <div class="row p-2">
                        <div class="col-12">
                            <HorizontalBarChart
                                class="chart-wrapper"
                                label="SMS Manually Sent By User"
                                :chart-data="smsManuallySentByUserChart.data"
                                :options="smsManuallySentByUserChart.options"
                                :style="`height: ${calculatedHeight}px`"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import HorizontalBarChart from '@/components/analytics/charts/HorizontalBarChart.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        HorizontalBarChart,
    },

    data() {
        return {
            chartColors: {
                color1: 'rgb(255, 174, 201)',
                color1Border: 'rgb(255, 118, 149)',
                color2: 'rgb(154, 208, 245)',
                color2Border: 'rgb(88, 178, 239)',
            },

            chartData: null,

            filterResultsForm: {
                dateFromTo: {
                    start: new Date(),
                    end: new Date(),
                },
            },

            isLoading: {
                getCharts: false,
            },
        }
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        calculatedHeight() {
            const length = this.smsManuallySentByUserChart.data?.datasets[0]
                ?.data?.length;

            if (!length) {
                return 0;
            }

            if (length === 1) {
                return 90;
            }

            return (
                length * 50
            )
        },

        // User's last activity table
        usersLastActivityTable() {
            const chart = {
                data: null,
            };

            if (this.chartData) {
                const { items: asmLastActivity } = this.chartData.find(
                    data => data.name === 'ASM Last Activity'
                );

                if (asmLastActivity.length) {
                    chart.data = asmLastActivity;
                }
            }

            return chart;
        },

        smsManuallySentByUserChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: smsManuallySentByUserData } = this.chartData
                    .find(o => o.name == "SMS Manually Sent By User");

                if (smsManuallySentByUserData.length) {
                    chart.data = {
                        labels: smsManuallySentByUserData.map(o => o.name),
                        datasets: [{
                            backgroundColor: this.chartColors.color1,
                            borderColor: this.chartColors.color1Border,
                            borderWidth: 1,
                            minBarLength: 2,
                            data: smsManuallySentByUserData.map(o => o.value),
                        }]
                    };

                    chart.options = {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var value = data
                                        .datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index];

                                    if (value < 1) {
                                        value = 0;
                                    }

                                    return value;
                                }
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        precision: 0,
                                    }
                                },
                            ],
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { display: false },
                        title: {
                            display: true,
                            text: 'SMS Manually Sent By User'
                        }
                    };
                }
            }

            return chart;
        },
    },

    created() {
        this.setMixPanel();
        // Get ASM list if empty
        if (! this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }
    },

    methods: {
        setMixPanel(){
            this.$mixpanel.identify(this.userSession?.externalUserId);
            this.$mixpanel.track('users-activity',{
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
                asm_id: this.selectedASM?.asmId,
                asm_name: this.selectedASM?.asmName,
                asm_dealername: this.selectedASM?.dealerName,
                asm_location_code: this.selectedASM?.locationCode,
                asm_location_id: this.selectedASM?.locationId,
                asm_location_name: this.selectedASM?.locationName,
            });
        },
        // Vuex services actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),

        async getCharts() {
            this.isLoading.getCharts = true;

            const tab = 'ASM Activity Report';
            const params = this.filterResultsForm;

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.GetCharts',
                        {
                            selectedDealer: this.selectedDealerId,
                            tab: tab
                        }
                    ),
                    { params: params }
                );

                if (response.data.success) {
                    this.chartData = response.data.results;
                }
            } catch (error) {
                if (error.response?.data?.errors) {
                    console.error(error.response.data.errors);
                } else {
                    console.error(error);
                }
            } finally {
                this.isLoading.getCharts = false;
            }
        },

        printPage() {
            window.print();
        },
    },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
    max-height: 90vh;
    position: relative;
}

// Vue transition classes
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
