import { mapState } from 'vuex';

export const generalMixin = {
    data() {
        return {
            scrollSimpleBarTabContentTimeout: null,
            scrollSimpleBarResultsListTimeout: null,
        };
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),
    },

    methods: {
        /**
         * Get customer's latest message status
         */
        $_generalMixin_getMessageStatusByCustomerId(customerId, notifications) {
            // Incoming message types: Reply || Hello
            let status = 'pending';

            const foundSMSs = notifications.filter((smsMessage) => {
                return !smsMessage.read_at && smsMessage.customer_id === customerId;
            });

            if (foundSMSs.length) {
                const foundReplyHellos = foundSMSs.filter((smsMessage) => ['reply', 'hello'].includes(smsMessage.type));

                if (foundReplyHellos.length) {
                    status = 'unread';
                }
            }

            return status;
        },

        /**
         * Get customer mood settings
         */
        $_generalMixin_getCustomerMoodSettings(customerId, customersSentiments) {
            let mood = 'none';

            if (customersSentiments.some((sentiment) => sentiment.customerId === customerId)) {
                mood = customersSentiments.find((sentiment) => sentiment.customerId === customerId).icon;
            }

            // Mood: none
            const moodSettings = {
                color: 'default',
                icon: '',
            };

            // Mood: angry
            if (mood === 'angry') {
                moodSettings.color = 'red';
                moodSettings.icon = 'far fa-frown';
            }

            // Mood: indifferent
            if (mood === 'indifferent') {
                moodSettings.color = 'blue';
                moodSettings.icon = 'far fa-meh';
            }

            // Mood: happy
            if (mood === 'happy') {
                moodSettings.color = 'green';
                moodSettings.icon = 'far fa-smile';
            }

            return moodSettings;
        },

        /**
         * Scroll results list within SimpleBar
         */
        $_generalMixin_scrollSimpleBarResultsList({ behavior = 'instant', position = 'top', timeout = 0 } = {}) {
            const containerToSearchFor = '#results_list .simplebar-content-wrapper';
            const container = document.querySelector(containerToSearchFor);

            if (this.scrollSimpleBarResultsListTimeout) {
                window.clearTimeout(this.scrollSimpleBarResultsListTimeout);
            }

            if (container) {
                this.scrollSimpleBarResultsListTimeout = setTimeout(() => {
                    const height = position === 'bottom' ? container.scrollHeight : 0;

                    try {
                        container.scrollTo({ top: height, behavior: behavior });
                    } catch (error) {
                        console.warn(error);
                    }
                }, timeout);
            } else {
                console.warn(`${containerToSearchFor} not found.`);
            }
        },

        /**
         * Scroll tab content within SimpleBar
         */
        $_generalMixin_scrollSimpleBarTabContent({ behavior = 'instant', position = 'bottom', timeout = 0 } = {}) {
            const containerToSearchFor = '#simplebar_tab_content .simplebar-content-wrapper';
            const container = document.querySelector(containerToSearchFor);

            if (this.scrollSimpleBarTabContentTimeout) {
                window.clearTimeout(this.scrollSimpleBarTabContentTimeout);
            }

            if (container) {
                this.scrollSimpleBarTabContentTimeout = setTimeout(() => {
                    const height = position === 'bottom' ? container.scrollHeight : 0;

                    try {
                        container.scrollTo({ top: height, behavior: behavior });
                    } catch (error) {
                        console.warn(error);
                    }
                }, timeout);
            } else {
                console.warn(`${containerToSearchFor} not found.`);
            }
        },

        /**
         * Get the scroll postition of the Simple Bar tab content
         */
        $_generalMixin_getSimpleBarTabContentScrollPosition() {
            const simpleBarContentWrapper = document.querySelector('#simplebar_tab_content .simplebar-content-wrapper');

            if (!simpleBarContentWrapper) {
                return { clientHeight: null, position: null, scrollHeight: null, scrollTop: null };
            }

            const { clientHeight, scrollHeight, scrollTop } = simpleBarContentWrapper;

            return {
                clientHeight: clientHeight,
                position: scrollTop + clientHeight >= scrollHeight ? 'bottom' : scrollTop === 0 ? 'top' : 'middle',
                scrollHeight: scrollHeight,
                scrollTop: scrollTop,
            };
        },

        // Get ASM by ID or number
        $_generalMixin_getASMByIdOrNumber(asmIdOrNumber, asms = this.asms) {
            const foundAsm = asms.find((asm) => {
                return [asm.asmId, asm.asmNumber].includes(asmIdOrNumber);
            });

            return foundAsm;
        },
    },
};
