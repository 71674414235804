/**
 * Copy Methods
 */
export const copyMethods = {
    methods: {
        /**
         * Copy text to clipboard
         * @param {string} textToCopy The text to copy to the clipboard
         * @param {object} options Optional settings.
         * @param {boolean} options.showConfirmation Whether or not to show the confirmation message.
         * @param {string} options.successMessage Overwrite the default success message.
         * @param {string} options.errorMessage Overwrite the default error message.
         */
        $_copyMethods_copyText(
            textToCopy,
            { showConfirmation = true, successMessage = 'Text Copied!', errorMessage = 'Unable to copy text' } = {}
        ) {
            this.$copyText(textToCopy).then(
                () => {
                    if (showConfirmation) {
                        this.$message({
                            type: 'success',
                            message: successMessage,
                        });
                    }
                },
                (error) => {
                    this.$message({
                        type: 'error',
                        message: errorMessage,
                    });
                    console.error(error);
                }
            );
        },
    },
};
