<template>
<div class="modal fade" id="customer_contact_info_modal" tabindex="-1" role="dialog" aria-labelledby="customer_contact_info_title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-2" id="customer_contact_info_title">Update Contact Info</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <template v-if="customer">
                <div class="modal-body">
                    <!-- Preferred Contact Method -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Pref. Contact Method</span>
                            </div>
                            <select
                                @change="updatePreferredContactAndOptIns()"
                                v-model="contactPreferenceAndFeatureOptIns.methodOfCommunication"
                                class="form-control"
                                id="preferred_contact_method"
                                :disabled="!customer || !customer.ID"
                            >
                                <option
                                    v-for="(method, index) in contactMethods"
                                    :disabled="isCommunicationOptout(method.value)"
                                    :key="index"
                                    :value="method.value"
                                >{{ method.label }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- Opt In Contact Methods -->
                    <div class="form-group">
                        <label class="d-block">Opt In Contact Methods</label>
                        <!-- Text -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.text"
                                @change="setCommunicationPreference($event, 'SMS')"
                                :disabled="!customer || !customer.ID || optInContactMethods.text && contactPreferenceAndFeatureOptIns.methodOfCommunication === 'Text'"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_text"
                            ><label class="form-check-label" for="contact_method_text">Text</label>
                        </div>
                        <!-- Email -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.email"
                                @change="setCommunicationPreference($event, 'Email')"
                                :disabled="!customer || !customer.ID || optInContactMethods.email && contactPreferenceAndFeatureOptIns.methodOfCommunication === 'Email'"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_email"
                            ><label class="form-check-label" for="contact_method_email">Email</label>
                        </div>
                        <!-- Call -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.call"
                                @change="setCommunicationPreference($event, 'Call')"
                                :disabled="!customer || !customer.ID || optInContactMethods.call && contactPreferenceAndFeatureOptIns.methodOfCommunication === 'Call'"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_call"
                            ><label class="form-check-label" for="contact_method_call">Call</label>
                        </div>
                        <!-- Mail/Print -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.print"
                                @change="setCommunicationPreference($event, 'Print')"
                                :disabled="!customer || !customer.ID || optInContactMethods.print && contactPreferenceAndFeatureOptIns.methodOfCommunication === 'Print'"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_mail"
                            ><label class="form-check-label" for="contact_method_mail">Print/Mail</label>
                        </div>
                    </div>
                    <!-- Opt In Features -->
                    <div class="form-group">
                        <label class="d-block">Opt In Features</label>
                        <div class="form-check form-check-inline">
                            <input
                                @change="updatePreferredContactAndOptIns()"
                                v-model="contactPreferenceAndFeatureOptIns.optInAppointment"
                                class="form-check-input"
                                type="checkbox"
                                id="features_appointments"
                                :disabled="!customer || !customer.ID"
                            >
                            <label class="form-check-label" for="features_appointments">Appointments</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                @change="updatePreferredContactAndOptIns()"
                                v-model="contactPreferenceAndFeatureOptIns.optInPointsSystem"
                                class="form-check-input"
                                type="checkbox"
                                id="features_points_system"
                                :disabled="!customer || !customer.ID"
                            >
                            <label class="form-check-label" for="features_points_system">Points System</label>
                        </div>
                    </div>
                    <!-- Mark Vehicle as Sold -->
                    <div v-if="cars.length" class="form-group">
                        <label class="d-block">Mark Vehicle as Sold</label>
                        <div v-for="(vehicle, index) in cars" :key="index" class="form-check form-check-inline">
                            <input @change="setVehicleAsSold($event, vehicle.id)" class="form-check-input" type="checkbox" :id="`vehicle_id_${vehicle.id}`" :checked="vehicle.sold == 1">
                            <label class="form-check-label" :for="`vehicle_id_${vehicle.id}`">{{ vehicle.year }} {{ vehicle.v_make }} {{ vehicle.v_model }}</label>
                        </div>
                    </div>
                    <!-- Email Address -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Email Address</span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.email"
                                type="email"
                                name="email"
                                class="form-control"
                                :disabled="!customer || !customer.ID"
                            >
                        </div>
                    </div>
                    <!-- Cell Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Cell Phone
                                    <span v-if="customer.isValidCellPhone == 1">&nbsp;<i title="Valid" class="fas fa-check-circle text-success"></i></span>
                                    <span v-else-if="customer.isValidCellPhone == 0">&nbsp;<i title="Invalid" class="fas fa-exclamation-circle text-danger"></i></span>
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.cellPhoneNumber"
                                type="tel"
                                name="cellPhone"
                                class="form-control"
                                :disabled="!customer || !customer.ID"
                            >
                            <div v-if="arePhoneLabelsAvailable" class="input-group-append">
                                <button
                                    @click="toggles.showCellPhoneLabel = !toggles.showCellPhoneLabel"
                                    class="btn btn-alpha_neutral"
                                    type="button"
                                    :disabled="!customer || !customer.ID"
                                ><i class="fa-solid fa-tag"></i></button>
                            </div>
                        </div>
                        <!-- Label -->
                        <div v-if="arePhoneLabelsAvailable && (toggles.showCellPhoneLabel || customerPhoneNumbersAndEmail.cellPhoneLabel)" class="input-group input-group-sm mt-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa-solid fa-arrow-turn-down-right mr-1"></i> Label
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.cellPhoneLabel"
                                type="text"
                                name="cellPhoneLabel"
                                class="form-control"
                                maxlength="25"
                                :disabled="!customer || !customer.ID"
                            >
                        </div>
                    </div>
                    <!-- Home Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Home Phone
                                    <span v-if="customer.isValidHomePhone == 1">&nbsp;<i title="Valid" class="fas fa-check-circle text-success"></i></span>
                                    <span v-else-if="customer.isValidHomePhone == 0">&nbsp;<i title="Invalid" class="fas fa-exclamation-circle text-danger"></i></span>
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.homePhoneNumber"
                                type="tel"
                                name="homePhone"
                                class="form-control"
                                :disabled="!customer || !customer.ID"
                            >
                            <div v-if="arePhoneLabelsAvailable" class="input-group-append">
                                <button
                                    @click="toggles.showHomePhoneLabel = !toggles.showHomePhoneLabel"
                                    class="btn btn-alpha_neutral"
                                    type="button"
                                    :disabled="!customer || !customer.ID"
                                ><i class="fa-solid fa-tag"></i></button>
                            </div>
                        </div>
                        <!-- Label -->
                        <div v-if="arePhoneLabelsAvailable && (toggles.showHomePhoneLabel || customerPhoneNumbersAndEmail.homePhoneLabel)" class="input-group input-group-sm mt-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa-solid fa-arrow-turn-down-right mr-1"></i> Label
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.homePhoneLabel"
                                type="text"
                                name="homePhoneLabel"
                                class="form-control"
                                maxlength="25"
                                :disabled="!customer || !customer.ID"
                            >
                        </div>
                    </div>
                    <!-- Work Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Work Phone
                                    <span v-if="customer.isValidWorkPhone == 1">&nbsp;<i title="Valid" class="fas fa-check-circle text-success"></i></span>
                                    <span v-else-if="customer.isValidWorkPhone == 0">&nbsp;<i title="Invalid" class="fas fa-exclamation-circle text-danger"></i></span>
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.workPhoneNumber"
                                type="tel"
                                name="workPhone"
                                class="form-control"
                                :disabled="!customer || !customer.ID"
                            >
                            <div v-if="arePhoneLabelsAvailable" class="input-group-append">
                                <button
                                    @click="toggles.showWorkPhoneLabel = !toggles.showWorkPhoneLabel"
                                    class="btn btn-alpha_neutral"
                                    type="button"
                                    :disabled="!customer || !customer.ID"
                                ><i class="fa-solid fa-tag"></i></button>
                            </div>
                        </div>
                        <!-- Label -->
                        <div v-if="arePhoneLabelsAvailable && (toggles.showWorkPhoneLabel || customerPhoneNumbersAndEmail.workPhoneLabel)" class="input-group input-group-sm mt-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa-solid fa-arrow-turn-down-right mr-1"></i> Label
                                </span>
                            </div>
                            <input
                                @change="updateCustomer()"
                                v-model="customerPhoneNumbersAndEmail.workPhoneLabel"
                                type="text"
                                name="workPhoneLabel"
                                class="form-control"
                                maxlength="25"
                                :disabled="!customer || !customer.ID"
                            >
                        </div>
                    </div>
                    <!-- Custom Phones -->
                    <div v-for="(customPhoneNumber, index) in customPhoneNumbersForForm" :key="index" class="mb-2">
                        <div class="input-group input-group-sm">
                            <input
                                @change="customPhoneNumbersForForm[index].phone_number ? updateCustomPhones() : $event.preventDefault()"
                                type="text"
                                class="form-control"
                                v-model="customPhoneNumbersForForm[index].label"
                                placeholder="Label"
                                :disabled="!customer || !customer.ID"
                            >
                            <input
                                @change="updateCustomPhones()"
                                type="text"
                                class="form-control"
                                v-model="customPhoneNumbersForForm[index].phone_number"
                                placeholder="Phone Number"
                                :disabled="!customer || !customer.ID"
                            >
                            <div class="input-group-append">
                                <button
                                    @click="removeCustomPhoneNumber(index); updateCustomPhones()"
                                    class="btn btn-alpha_danger"
                                    type="button"
                                    :disabled="!customer || !customer.ID"
                                >X</button>
                            </div>
                        </div>
                    </div>
                    <!-- Add New Custom Number -->
                    <button
                        @click="addNewCustomPhoneNumber"
                        type="button"
                        class="btn badge badge-pill badge-alpha_neutral"
                        :disabled="!customer || !customer.ID || anyCustomPhoneNumbersForFormNull"
                    ><i class="fa-solid fa-plus"></i> Custom Number</button>
                    <hr>
                    <!-- The input names are obscured on purpose. Otherwise, Chrome keeps asking to save the address -->
                    <!-- Address -->
                    <div class="mb-1">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Address</span>
                            </div>
                            <input
                                type="text"
                                name="customer_ad1"
                                class="form-control"
                                :value="customer.address1"
                                placeholder="123 Main St"
                                disabled
                            >
                        </div>
                    </div>
                    <!-- Address Line 2 -->
                    <div class="mb-1">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Address Line 2</span>
                            </div>
                            <input
                                type="text"
                                name="customer_ad2"
                                class="form-control"
                                :value="customer.address2"
                                placeholder="Optional: Suite, Apt, etc."
                                disabled
                            >
                        </div>
                    </div>
                    <div class="row mb-1">
                        <!-- City -->
                        <div class="col-12 col-md-4 pr-md-0 mb-1 mb-md-0">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text--elastic">City</span>
                                </div>
                                <input
                                    type="text"
                                    name="customer_c"
                                    class="form-control"
                                    :value="customer.city"
                                    placeholder="City"
                                    disabled
                                >
                            </div>
                        </div>
                        <!-- State -->
                        <div class="col-6 col-md-4 pr-0">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text--elastic">State</span>
                                </div>
                                <input
                                    type="text"
                                    name="customer_s"
                                    class="form-control"
                                    :value="customer.state"
                                    placeholder="State"
                                    disabled
                                >
                            </div>
                        </div>
                        <!-- Zip -->
                        <div class="col-6 col-md-4">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text--elastic">Zip</span>
                                </div>
                                <input
                                    type="text"
                                    name="customer_z"
                                    class="form-control"
                                    :value="customer.zip"
                                    placeholder="Zip Code"
                                    disabled
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_primary" data-dismiss="modal">Close</button>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        arePhoneLabelsAvailable: {
            type: Boolean,
            default: false,
        },

        cars: {
            type: Array,
            required: true,
        },

        customer: {
            type: Object,
            required: true,
        },

        customPhoneNumbers: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },

    data() {
        return {
            customPhoneNumbersForForm: [],

            customerPhoneNumbersAndEmail: {
                cellPhoneNumber: '',
                cellPhoneLabel: '',
                homePhoneNumber: '',
                workPhoneNumber: '',
                email: '',
            },

            contactMethods: [
                { value: 'Email', label: 'Email' },
                { value: 'Print', label: 'Print/Mail' },
                { value: 'Text', label: 'Text' },
                { value: '', label: 'None' },
            ],

            contactPreferenceAndFeatureOptIns: {
                methodOfCommunication: '',
                optInAppointment: null,
                optInPointsSystem: null,
            },

            optInContactMethods: {
                text: false,
                email: false,
                call: false,
                print: false,
            },

            toggles: {
                showCellPhoneLabel: false,
                showHomePhoneLabel: false,
                showWorkPhoneLabel: false,
            },

            isLoading: {
                setCommunicationPreference: false,
                setVehicleAsSold: false,
                updateCustomer: false,
                updateCustomPhones: false,
                updatePreferredContactAndOptIns: false,
            },
        }
    },

    computed: {
        // Used for disallowing additional blank phone numbers
        anyCustomPhoneNumbersForFormNull() {
            const emptyPhoneNumbers = this.customPhoneNumbersForForm.filter(phoneNumber => {
                return ! (phoneNumber.label && phoneNumber.phone_number);
            });

            return emptyPhoneNumbers.length > 0;
        },

        /**
         * Check to see if anything is loading
         */
        anyLoading() {
            return Object.values(this.isLoading).includes(true);
        },
    },

    watch: {
        //
    },

    created() {
        // Create a copy of the custom phone numbers
        this.customPhoneNumbersForForm = this.customPhoneNumbers.map(phoneNumber => Object.assign({}, phoneNumber));

        // Create copy of preferred contact method and opt-out feature settings.
        this.contactPreferenceAndFeatureOptIns.methodOfCommunication = this.customer.method_of_communication;
        this.contactPreferenceAndFeatureOptIns.optInAppointment = this.customer.opt_out_appointments == '1' ? false : true;
        this.contactPreferenceAndFeatureOptIns.optInPointsSystem = this.customer.opt_out_the_points_system == '1' ? false : true;

        // Create copy of contact method opt-ins
        this.optInContactMethods.text = this.customer.optOutTextMessage == '1' ? false : true;
        this.optInContactMethods.email = this.customer.do_not_email_flag == '1' ? false : true;
        this.optInContactMethods.call = this.customer.do_not_call_flag == '1' ? false : true;
        this.optInContactMethods.print = this.customer.do_not_mail_flag == '1' ? false : true;

        // Create copy of customer phone numbers and email
        this.customerPhoneNumbersAndEmail.cellPhoneNumber = this.customer.cellPhone;
        this.customerPhoneNumbersAndEmail.cellPhoneLabel = this.customer.cell_phone_label;
        this.customerPhoneNumbersAndEmail.homePhoneNumber = this.customer.homePhone;
        this.customerPhoneNumbersAndEmail.homePhoneLabel = this.customer.home_phone_label;
        this.customerPhoneNumbersAndEmail.workPhoneNumber = this.customer.workPhone;
        this.customerPhoneNumbersAndEmail.workPhoneLabel = this.customer.work_phone_label;
        this.customerPhoneNumbersAndEmail.email = this.customer.email;
    },

    methods : {
        /*
        |----------------------------------------------------------------------
        | Update customer methods
        |----------------------------------------------------------------------
        |
        | These are the methods for updating the customer's contact info.
        |
        */

        /**
         * Update preferred contact method and set feature opt-ins
         */
        updatePreferredContactAndOptIns() {
            const parameters = {
                methodOfCommunication: this.contactPreferenceAndFeatureOptIns.methodOfCommunication,
                optOutAppointment: ! this.contactPreferenceAndFeatureOptIns.optInAppointment,
                optOutPointsSystem: ! this.contactPreferenceAndFeatureOptIns.optInPointsSystem,
            };

            this.isLoading.updatePreferredContactAndOptIns = true;

            axios
                .post(route('api.unotifi.SetOptOut', { selectedDealer: this.selectedDealerId, customerId: this.customer.ID }), parameters)
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Customer info updated'
                        });
                    }
                    this.$emit('update-result-info');
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.updatePreferredContactAndOptIns = false;
                });
        },

        /**
         * Update the customer's custom phone numbers
         */
        updateCustomPhones() {
            const parameters = {
                customPhones: this.customPhoneNumbersForForm,
            };

            this.isLoading.updateCustomPhones = true;

            axios
                .post(route('api.unotifi.UpdateCustomPhones', { selectedDealer: this.selectedDealerId, customerId: this.customer.ID }), parameters)
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Customer info updated'
                        });
                    }
                    this.$emit('update-result-info');
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.updateCustomPhones = false;
                });
        },

        /**
         * Set vehicle as sold
         */
        setVehicleAsSold(event, vehicleId) {
            const isSold = event.target.checked;

            const parameters = {
                sold: isSold,
            };

            this.isLoading.setVehicleAsSold = true;

            axios
                .post(route('api.unotifi.SetCarAsSold', { selectedDealer: this.selectedDealerId, vehicleId: vehicleId }), parameters)
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Customer info updated'
                        });
                        this.$emit('update-result-info');
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.setVehicleAsSold = false;
                });
        },

        /**
         * Set communication opt-ins
         */
        setCommunicationPreference(event, type) {
            const optIn = event.target.checked;

            const parameters = {
                type: type,
                optIn: optIn,
            };

            this.isLoading.setCommunicationPreference = true;

            axios
                .post(route('api.unotifi.SetCommunicationPreference', { selectedDealer: this.selectedDealerId, customerId: this.customer.ID }), parameters)
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Customer info updated'
                        });
                    }
                    this.$emit('update-result-info');
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.setCommunicationPreference = false;
                });
        },

        /**
         * Update customer phone numbers and email
         */
        updateCustomer() {
            const cellPhoneNumber = this.customerPhoneNumbersAndEmail.cellPhoneNumber;
            const homePhoneNumber = this.customerPhoneNumbersAndEmail.homePhoneNumber;
            const workPhoneNumber = this.customerPhoneNumbersAndEmail.workPhoneNumber;

            const parameters = {
                cellPhoneNumber: cellPhoneNumber,
                homePhoneNumber: homePhoneNumber,
                workPhoneNumber: workPhoneNumber,
                email: this.customerPhoneNumbersAndEmail.email,
            };

            if (this.arePhoneLabelsAvailable) {
                parameters['cellPhoneLabel'] = cellPhoneNumber ? this.customerPhoneNumbersAndEmail.cellPhoneLabel : '';
                parameters['homePhoneLabel'] = homePhoneNumber ? this.customerPhoneNumbersAndEmail.homePhoneLabel : '';
                parameters['workPhoneLabel'] = workPhoneNumber ? this.customerPhoneNumbersAndEmail.workPhoneLabel : '';
            }

            this.isLoading.updateCustomer = true;

            axios
                .post(route('api.unotifi.UpdateCustomer', { selectedDealer: this.selectedDealerId, customerId: this.customer.ID }), parameters)
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Customer info updated'
                        });
                        this.$emit('update-result-info');
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors?.email) {
                        this.$message({
                            type: 'error',
                            message: error.response.data.errors.email[0]
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: 'There was an error updating the customer info'
                        });
                    }

                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.updateCustomer = false;
                });
        },

        /*
        |----------------------------------------------------------------------
        | Other methods
        |----------------------------------------------------------------------
        |
        | These are the rest of the methods for the component
        |
        */

        /**
         * Add a new custom phone number to the custom phone numbers input
         */
        addNewCustomPhoneNumber() {
            this.customPhoneNumbersForForm.push(
                {
                    label: '',
                    phone_number: '',
                }
            );
        },

        /**
         * Remove a custom phone number from the custom phone numbers input
         */
        removeCustomPhoneNumber(index) {
            this.customPhoneNumbersForForm.splice(index, 1);
        },

        /**
         * Check if communication opt-out
         */
        isCommunicationOptout(method) {
            if (method === 'Text') return ! this.optInContactMethods.text;
            if (method === 'Email') return ! this.optInContactMethods.email;
            if (method === 'Call') return ! this.optInContactMethods.call;
            if (method === 'Print') return ! this.optInContactMethods.print;
        },
    },
}
</script>
