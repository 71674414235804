export default {
    namespaced: true,

    state: {
        asms: [],

        caseNotifications: {
            notifications: [],
            total: 0,
        },

        chatNotifications: {
            notifications: [],
            total: 0,
        },

        smsNotifications: {
            notifications: [],
            total: 0,
        },

        isLoading: {
            dismissCaseNotifications: false,
            dismissChatNotifications: false,
            dismissSMSNotifications: false,
            getASMList: false,
            getCaseNotifications: false,
            getChatNotifications: false,
            getSMSNotifications: false,
            markCaseNotificationsRead: false,
            markChatNotificationsRead: false,
            markSMSNotificationsRead: false,
        },
    },

    getters: {
        allNotifications: state => {
            const smsNotifications = state.smsNotifications.notifications.map(notification => ({ ...notification, notificationType: 'sms' }));
            const caseNotifications = state.caseNotifications.notifications.map(notification => ({ ...notification, notificationType: 'case' }));
            const chatNotifications = state.chatNotifications.notifications.map(notification => ({ ...notification, notificationType: 'chat' }));
            const allNotifications = {
                notifications: smsNotifications.concat(caseNotifications, chatNotifications),
                total: state.smsNotifications.total + state.caseNotifications.total + state.chatNotifications.total,
            };

            allNotifications.notifications.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);

                return bDate - aDate;
            });

            return allNotifications;
        },

        hasUnreadNotifications: (state, getters) => {
            return getters.allNotifications.notifications.some(
                notification => ! notification.read_at
            );
        }
    },

    actions: {
        /**
         * Get list of ASMs
         */
        async getASMList({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getASMList', value: true});

            await axios
                .get(route('api.unotifi.getASMList', { selectedDealer: selectedDealerId }))
                .then(response => {
                    commit('SET_ASM_LIST', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getASMList', value: false});
                });
        },

        /**
         * Get SMS notifications
         */
        async getSMSNotifications({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getSMSNotifications', value: true});

            await axios
                .get(route('api.sms-notifications', { selectedDealer: selectedDealerId }))
                .then(response => {
                    if (response.data.notifications) {
                        commit('SET_SMS_NOTIFICATIONS', { smsNotifications: response.data.notifications, total: response.data.total });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getSMSNotifications', value: false});
                });
        },

        pushChatNotification({ commit }, notification) {
            commit('PUSH_CHAT_NOTIFICATION', notification);
        },

        pushSmsNotification({ commit }, notification) {
            commit('PUSH_SMS_NOTIFICATION', notification);
        },

        /**
         * Mark SMS notifications by Id as read
         */
        async markSMSNotificationsRead({ commit }, {ids, selectedDealerId, undo = false}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markSMSNotificationsRead', value: true});

            await axios
                .post(route('api.sms-notifications.markRead', {selectedDealer: selectedDealerId}), {smsNotificationIds: ids, undo})
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_SMS_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markSMSNotificationsRead', value: false});
                });
        },

        /**
         * Mark all SMS notifications as read
         */
        async markAllSMSNotificationsRead({ commit }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markSMSNotificationsRead', value: true});
            await axios
                .post(route('api.sms-notifications.markAllRead', {selectedDealer: selectedDealerId}))
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_SMS_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markSMSNotificationsRead', value: false});
                });
        },

        /**
         * Dismiss SMS notifications by Id
         */
        async dismissSMSNotifications({ commit }, {ids, selectedDealerId}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissSMSNotifications', value: true});

            await axios
                .post(route('api.sms-notifications.dismiss', {selectedDealer: selectedDealerId}), {smsNotificationIds: ids})
                .then(response => {
                    commit('REMOVE_SMS_NOTIFICATIONS', ids);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissSMSNotifications', value: false});
                });
        },

        /**
         * Get case notifications
         */
        async getCaseNotifications({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCaseNotifications', value: true});

            await axios
                .get(route('api.case-notifications', { selectedDealer: selectedDealerId }))
                .then(response => {
                    if (response.data.notifications) {
                        commit('SET_CASE_NOTIFICATIONS', { caseNotifications: response.data.notifications, total: response.data.total });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCaseNotifications', value: false});
                });
        },

        /**
         * Mark case notifications by Id as read
         */
        async markCaseNotificationsRead({ commit }, {ids, selectedDealerId, undo = false}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markCaseNotificationsRead', value: true});
            await axios
                .post(route('api.case-notifications.markRead',  {selectedDealer: selectedDealerId}), {caseNotificationIds: ids, undo})
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_CASE_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markCaseNotificationsRead', value: false});
                });
        },

        /**
         * Mark all case notifications as read
         */
        async markAllCaseNotificationsRead({ commit }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markCaseNotificationsRead', value: true});
            await axios
                .post(route('api.case-notifications.markAllRead',  {selectedDealer: selectedDealerId}))
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_CASE_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markCaseNotificationsRead', value: false});
                });
        },

        /**
         * Dismiss case notifications by Id
         */
        async dismissCaseNotifications({ commit }, {ids, selectedDealerId}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissCaseNotifications', value: true});

            await axios
                .post(route('api.case-notifications.dismiss', {selectedDealer: selectedDealerId}), {caseNotificationIds: ids})
                .then(response => {
                    commit('REMOVE_CASE_NOTIFICATIONS', ids);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissCaseNotifications', value: false});
                });
        },

        /**
         * Get chat notifications
         */
        async getChatNotifications({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getChatNotifications', value: true});

            await axios
                .get(route('api.chat-notifications', { selectedDealer: selectedDealerId }))
                .then(response => {
                    if (response.data.notifications) {
                        commit('SET_CHAT_NOTIFICATIONS', { chatNotifications: response.data.notifications, total: response.data.total });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getChatNotifications', value: false});
                });
        },

        /**
         * Mark chat notifications by Id as read
         */
        async markChatNotificationsRead({ commit }, {ids, selectedDealerId, undo = false}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markChatNotificationsRead', value: true});
            await axios
                .post(route('api.chat-notifications.markRead',  {selectedDealer: selectedDealerId}), {chatNotificationIds: ids, undo})
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_CHAT_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markChatNotificationsRead', value: false});
                });
        },

        /**
         * Mark all chat notifications as read
         */
        async markAllChatNotificationsRead({ commit }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'markChatNotificationsRead', value: true});
            await axios
                .post(route('api.chat-notifications.markAllRead',  {selectedDealer: selectedDealerId}))
                .then(response => {
                    if (response.data.notifications) {
                        commit('UPDATE_CHAT_NOTIFICATIONS', response.data.notifications);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'markChatNotificationsRead', value: false});
                });
        },

        /**
         * Dismiss chat notifications by Id
         */
        async dismissChatNotifications({ commit }, {ids, selectedDealerId}) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissChatNotifications', value: true});

            await axios
                .post(route('api.chat-notifications.dismiss', {selectedDealer: selectedDealerId}), {chatNotificationIds: ids})
                .then(response => {
                    commit('REMOVE_CHAT_NOTIFICATIONS', ids);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'dismissChatNotifications', value: false});
                });
        },
    },

    mutations: {
        SET_ASM_LIST(state, asms) {
            state.asms = asms.map(asm => Object.freeze(asm));
        },

        SET_CASE_NOTIFICATIONS(state, { caseNotifications, total }) {
            state.caseNotifications.notifications = caseNotifications;
            state.caseNotifications.total = total;
        },

        REMOVE_CHAT_NOTIFICATIONS(state, chatNotificationIds) {
            state.chatNotifications.notifications = state.chatNotifications.notifications.filter(notification => {
                return ! chatNotificationIds.includes(notification.id);
            });
        },

        SET_CHAT_NOTIFICATIONS(state, { chatNotifications, total }) {
            state.chatNotifications.notifications = chatNotifications;
            state.chatNotifications.total = total;
        },

        REMOVE_CASE_NOTIFICATIONS(state, caseNotificationIds) {
            state.caseNotifications.notifications = state.caseNotifications.notifications.filter(notification => {
                return ! caseNotificationIds.includes(notification.id);
            });
        },

        UPDATE_CASE_NOTIFICATIONS(state, updatedNotifications) {
            // Set read_at for each notification with id in updatedNotificationsIds
            state.caseNotifications.notifications.forEach(notification => {
                const foundNotification = updatedNotifications.find(
                    ({id}) => id === notification.id
                );

                if (foundNotification) {
                    notification.read_at = foundNotification.read_at;
                }
            });
        },

        UPDATE_CHAT_NOTIFICATIONS(state, updatedNotifications) {
            // Set read_at for each notification with id in updatedNotificationsIds
            state.chatNotifications.notifications.forEach(notification => {
                const foundNotification = updatedNotifications.find(
                    ({id}) => id === notification.id
                );

                if (foundNotification) {
                    notification.read_at = foundNotification.read_at;
                }
            });
        },

        SET_SMS_NOTIFICATIONS(state, { smsNotifications, total }) {
            state.smsNotifications.notifications = smsNotifications;
            state.smsNotifications.total = total;
        },

        PUSH_CHAT_NOTIFICATION(state, chatNotification) {
            state.chatNotifications.notifications.push(chatNotification);
            state.chatNotifications.total++;
        },

        PUSH_SMS_NOTIFICATION(state, smsNotification) {
            state.smsNotifications.notifications.push(smsNotification);
            state.smsNotifications.total++;
        },

        REMOVE_SMS_NOTIFICATIONS(state, smsNotificationIds) {
            state.smsNotifications.notifications = state.smsNotifications.notifications.filter(notification => {
                return ! smsNotificationIds.includes(notification.id);
            });
        },

        UPDATE_SMS_NOTIFICATIONS(state, updatedNotifications) {
            // Set read_at for each notification with id in updatedNotificationsIds
            state.smsNotifications.notifications.forEach(notification => {
                const foundNotification = updatedNotifications.find(
                    ({id}) => id === notification.id
                );

                if (foundNotification) {
                    notification.read_at = foundNotification.read_at;
                }
            });
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
