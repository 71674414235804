import { mapPreferences } from 'vue-preferences';

export default {
    namespaced: true,

    state: {
        ...mapPreferences({
            cases_searchForm_openedBy: {
                defaultValue: null
            },
        }),

        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        //
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResults', value: true});

            try {
                const response = await axios.post(
                    route(
                        'api.unotifi.GetCases',
                        { selectedDealer: selectedDealerId }
                    ),
                    parameters
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results.data) {
                state.results = Object.freeze(results.data);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const openedByAsmIds = [];

            if (state.cases_searchForm_openedBy.get()) {
                openedByAsmIds.push(state.cases_searchForm_openedBy.get());
            }

            state.filterResultsForm = {
                caseNumber: '',
                currentPage: 1,
                dateFromTo: {
                    start: yesterday,
                    end: new Date(),
                },
                firstName: '',
                lastName: '',
                openedByASMIds: openedByAsmIds,
                status: ['Open', 'Closed'],
                subject: '',
            }
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
