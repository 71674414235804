<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div class="services__results">
                <div class="px-2 px-md-4">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a href="#filters-results" class="font-20" data-toggle="collapse" aria-expanded="true" @click="servicesResultSize">
                            Opportunities
                        </a>

                        <ServicesFavoriteButton
                            servicePageName="services-opportunities"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="filterResultsFormCurrentPage = 1; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})">
                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFilterByFirstName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFilterByLastName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Customer Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Customer No.</span>
                                    </div>
                                    <input v-model="filterResultsFormFilterByCustomerNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input v-model="filterResultsFormFilterByVin" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Phone Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Phone</span>
                                    </div>
                                    <input v-model="filterResultsFormFilterByPhoneNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Status</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByStatuses"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! statusListOpportunities.statusList.length"
                                    >
                                        <el-option
                                            v-for="(status, index) in ['Select Options', ...statusListOpportunities.statusList]"
                                            :key="index"
                                            :label="status"
                                            :value="status"
                                            :disabled="status === 'Select Options'"
                                            :hidden="status === 'Select Options' && statusListOpportunities.statusList.length < 10"
                                        >
                                            <div v-if="status === 'Select Options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByStatuses = statusListOpportunities.statusList.map(item => item)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByStatuses = []"
                                                >Select None</button>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Assigned -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Assigned</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByAssigned"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [{asmName: 'Select Options', asmId: 'select-options'},{asmName: 'Unassigned', asmId: 'Unassigned'}, ...asms]"
                                            :key="index"
                                            :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                            :value="asm.asmId"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div v-if="asm.asmId === 'select-options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByAssigned = asms.map(item => item.asmId)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByAssigned = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>{{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Campaign -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Campaign</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByCampaignIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! campaignListFiltered.length"
                                    >
                                        <el-option
                                            v-for="(campaign, index) in [{name:'Select Options', id:'select-options'}, ...campaignListFiltered]"
                                            :key="index"
                                            :label="campaign.name"
                                            :value="campaign.id"
                                            :disabled="campaign.id === 'select-options'"
                                            :hidden="campaign.id === 'select-options' && campaignListFiltered.length < 10"
                                        >
                                            <div v-if="campaign.id === 'select-options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByCampaignIds = campaignListFiltered.map(item => item.id)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByCampaignIds = []"
                                                >Select None</button>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Sort By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Sort By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormSortBy"
                                        size="mini"
                                        placeholder="Sort"
                                    >
                                        <el-option
                                            v-for="(sortMethod, index) in filterResultsOptions.sortMethods"
                                            :key="index"
                                            :label="sortMethod.label"
                                            :value="sortMethod.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Opened By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Opened By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByOpenedByAsmIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [{asmName: 'Select Options', asmId: 'select-options'}, ...asms]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmNumber"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div v-if="asm.asmId === 'select-options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByOpenedByAsmIds = asms.map(item => item.asmNumber)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormFilterByOpenedByAsmIds = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>{{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Stage -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Stage</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByStages"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                    >
                                        <el-option
                                            v-for="(stage, index) in filterResultsOptions.stages"
                                            :key="index"
                                            :label="stage.label"
                                            :value="stage.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Activity -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Activity</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterByActivities"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                    >
                                        <el-option
                                            v-for="(activity, index) in filterResultsOptions.activities"
                                            :key="index"
                                            :label="activity"
                                            :value="activity">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button type="submit" :disabled="isLoadingResults.getResults" class="btn btn-alpha_primary btn-block btn-lg">
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button @click="resetFilterResultsForm(); $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="btn btn-alpha_secondary btn-block btn-lg">
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div v-if="results.extra_count" class="stats">
                        <div class="stats-item text-alpha_state-primary"><small>New</small><span>{{ results.extra_count.new }}</span><div class="dot"></div></div>
                        <div class="stats-item text-alpha_state-secondary"><small>In progress</small><span>{{ results.extra_count.in_progress }}</span><div class="dot"></div></div>
                        <div class="stats-item text-alpha_state-tertiary"><small>Won</small><span>{{ results.extra_count.won }}</span><div class="dot"></div></div>
                        <div class="stats-item text-state-quatenary"><small>Closed</small><span>{{ results.extra_count.closed }}</span><div class="dot"></div></div>
                    </div>
                </div>

                <!-- Results -->
                <simplebar ref="resultsList" id="results_list" class="overflow-auto clients-list-wrapper flex-grow-1" :style="servicesResultCss">
                    <!-- Bulk Assign Opportunities Button -->
                    <div
                        v-if="hasOppsThatAreBulkAssignable"
                        class="text-center"
                    >
                        <button
                            @click="$refs.modalBulkAssignOpportunities.showModal()"
                            type="button"
                            class="btn btn-link font-weight-bold text-alpha_primary"
                        >Bulk Assign Opportunities</button>
                    </div>

                    <!-- Results -->
                    <ul v-if="results.data" class="list-unstyled clients-list">
                        <li
                            v-for="(result, index) in results.data"
                            @click="loadResultInfo(result, selectedDealerId)"
                            :class="[
                                {
                                    'active': isResultSelected(result.id),
                                    'unread': $_generalMixin_getMessageStatusByCustomerId(
                                        result.customer_id, notifications.notifications
                                    ) === 'unread'
                                },
                                computeStageClass(result.sales_stage)
                            ]"
                            :key="index"
                        >
                            <!-- Customer/Opportunity Name -->
                            <div class="clients-list__name flex-grow-1">
                                <p class="font-14">{{ result.customer_name }}</p>
                                {{ result.name }}
                                <div v-if="result.assigned_user_id">
                                    Assigned To: {{ $_generalMixin_getASMByIdOrNumber(result.assigned_user_id) ? $_generalMixin_getASMByIdOrNumber(result.assigned_user_id).asmName : 'Unknown' }}
                                </div>
                                <div v-if="filterResultsParams.sortBy === 'last_service_date'">
                                    Last Serviced: {{ result.last_service_date ? $moment(result.last_service_date).format('MM/DD/YY') : 'N/A'}}
                                </div>
                                <div v-else-if="filterResultsParams.sortBy === 'sales_stage'">
                                    {{ result.sales_stage ? result.sales_stage : 'N/A'}}
                                </div>
                                <div v-else-if="filterResultsParams.sortBy === 'customer_score'">
                                    Customer Score: {{ result.customer_score ? result.customer_score : 'N/A'}}
                                </div>
                                <div v-else-if="filterResultsParams.sortBy === 'last_reply_date'">
                                    Last Reply: {{ result.last_reply_date ? $moment(result.last_reply_date, ['YYYY-MM-DD HH:mm:ss']).fromNow() : 'N/A'}}
                                </div>
                                <div v-else-if="['vehicle_model', 'vehicle_year'].includes(filterResultsParams.sortBy)">
                                    {{ result.vehicle_make }} {{ result.vehicle_model }} {{ result.vehicle_year }}
                                </div>
                                <div v-else>
                                    Date Entered: {{ result.date_entered ? $moment(result.date_entered, ['YYYY/MM/DD']).format('MM/DD/YY') : 'N/A'}}
                                </div>
                            </div>

                            <!-- Customer Info -->
                            <div class="clients-list__info">
                                <!-- Sentiment -->
                                <div
                                    v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                    class="mood mood-sm mx-1"
                                    :class="`mood-${$_generalMixin_getCustomerMoodSettings(result.customer_id, customersSentiments).color}`"
                                >
                                    <i
                                        :class="$_generalMixin_getCustomerMoodSettings(result.customer_id, customersSentiments).icon"
                                        title="Customer's mood guessed from their recent correspondence"
                                    ></i>
                                </div>
                                <div v-else class="mood mood-sm mx-1">
                                    <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                </div>
                                <!-- Notifications -->
                                <div class="clients-list__notification">
                                    <div
                                        v-if="$_generalMixin_getMessageStatusByCustomerId(
                                            result.customer_id, notifications.notifications
                                        ) === 'unread'"
                                        class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1 badge-notify"
                                    >
                                        <i class="icon">textsms</i>
                                    </div>
                                </div>
                                <!-- Activity -->
                                <div class="clients-list__activity mx-1 text-center">
                                    <div v-for="(activity, index) in result.activities" :key="index">
                                        <abbr :title="`${activity} logged`">
                                            {{ activity.charAt(0) }}
                                        </abbr>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {filterResultsFormCurrentPage = currentPage; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})}"
                />
            </div>

        </div>

        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Repair Order Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <OpportunityInfo />
            </template>
        </div>

        <!-- Bulk Assign Opportunities Modal -->
        <ModalBulkAssignOpportunities
            v-if="hasOppsThatAreBulkAssignable"
            ref="modalBulkAssignOpportunities"
            :asms="asms"
            :opportunities="results.data"
            @opportunities-updated="submitFilterResultsForm({clearResults: true})"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import ModalBulkAssignOpportunities from '@/components/services/child-components/ModalBulkAssignOpportunities.vue';
import OpportunityInfo from '@/components/services/OpportunityInfo.vue';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        ModalBulkAssignOpportunities,
        OpportunityInfo,
        ResultsPagination,
        ServicesFavoriteButton,
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filter selection options
            filterResultsOptions: {
                activities: ['Call', 'Email', 'Text', 'Other', 'No Activity'],
                sortMethods: [
                    {'label': 'Date Entered',       'value': 'date_entered'},
                    {'label': 'Sales Stage',        'value': 'sales_stage'},
                    {'label': 'Customer Score',     'value': 'customer_score'},
                    {'label': 'Customer Name',      'value': 'customer_name'},
                    {'label': 'Latest Replies',     'value': 'last_reply_date'},
                    {'label': 'Vehicle Year',       'value': 'vehicle_year'},
                    {'label': 'Vehicle Model',      'value': 'vehicle_model'},
                    {'label': 'Last Service Date',  'value': 'last_service_date'},
                ],
                stages: [
                    {'label': 'New',            'value': 'new',},
                    {'label': 'In Progress',    'value': 'in_progress',},
                    {'label': 'Won',            'value': 'won',},
                    {'label': 'Close',          'value': 'closed'},
                ],
            },

            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 25vh',
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapState('services', {
            campaignList: 'campaignList',
            statusListOpportunities: 'statusListOpportunities',
        }),

        ...mapState('services/opportunities', {
            filterResultsForm: 'filterResultsForm',
            results: 'results',
            isLoadingResults: 'isLoading',
        }),

        ...mapState('services/opportunityInfo', {
            resultInfo: 'resultInfo',
            selectedOpportunityId: 'selectedOpportunityId',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('services/opportunityInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        campaignListFiltered() {
            const opportunityCampaignList = [];
            const validCampaignTypes = [
                'Appointment Instant Opportunity',
                'Instant Opportunity',
                'Missed Appointment Opportunity',
                'Opportunity',
                'Vehicle Health Opportunity',
            ];

            if (this.campaignList && this.campaignList.length) {
                const filteredCampaigns = this.campaignList.filter(campaign => {
                    return (
                        campaign.type
                        && validCampaignTypes.includes(campaign.type)
                    )
                    || (
                        campaign.type == "Text"
                        && campaign.generate_opportunity == "1"
                    );
                });

                opportunityCampaignList.push(...filteredCampaigns);
            }

            return opportunityCampaignList;
        },

        hasOppsThatAreBulkAssignable() {
            return this.results.data?.length && this.results.data.some(opportunity => {
                return opportunity.sales_stage === 'Needs Analysis' && !opportunity.activities.length;
            });
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return {
                    start: this.$store.state.services.opportunities.filterResultsForm.fromDate,
                    end: this.$store.state.services.opportunities.filterResultsForm.toDate,
                };
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'fromDate', value: value.start });
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'toDate', value: value.end });
            }
        },

        filterResultsFormSortBy: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.sortBy;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'sortBy', value: value });
            }
        },

        filterResultsFormFilterByActivities: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByActivities;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByActivities', value: value });
            }
        },

        filterResultsFormFilterByAssigned: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByAssigned;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByAssigned', value: value });
            }
        },

        filterResultsFormFilterByCampaignIds: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByCampaignIds;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByCampaignIds', value: value });
            }
        },

        filterResultsFormFilterByCustomerNumber: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByCustomerNumber;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByCustomerNumber', value: value });
            }
        },

        filterResultsFormFilterByFirstName: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByFirstName;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByFirstName', value: value });
            }
        },

        filterResultsFormFilterByLastName: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByLastName;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByLastName', value: value });
            }
        },

        filterResultsFormFilterByOpenedByAsmIds: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByOpenedByAsmIds;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByOpenedByAsmIds', value: value });
            }
        },

        filterResultsFormFilterByStatuses: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByStatuses;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByStatuses', value: value });
            }
        },

        filterResultsFormFilterByPhoneNumber: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByPhoneNumber;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByPhoneNumber', value: value });
            }
        },

        filterResultsFormFilterByStages: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByStages;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByStages', value: value });
            }
        },

        filterResultsFormFilterByVin: {
            get() {
                return this.$store.state.services.opportunities.filterResultsForm.filterByVin;
            },
            set(value) {
                this.$store.commit('services/opportunities/SET_FILTERS', { attribute: 'filterByVin', value: value });
            }
        },
    },

    created() {
        // Set opportunity filter options to default
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            // Give the userSession enough time to load before resetting and submiting.
            const maxAttempts = 5;
            let attempts = 0;

            const resetFilterInterval = setInterval(() => {
                if (this.userSession?.externalUserId || attempts >= maxAttempts) {
                    this.resetFilterResultsForm();
                    this.submitFilterResultsForm(this.filterResultsParams);
                    clearInterval(resetFilterInterval);
                } else {
                    attempts++;
                }
            }, 500);
        }

        // Populate the status list array if it is empty
        if (!this.statusListOpportunities.statusList.length) {
            this.getStatusListOpportunities(this.selectedDealerId);
        }

        // Populate the campaigns list array if it is empty
        if (!this.campaignList.length) {
            this.getListCampaigns(this.selectedDealerId);
        }
    },

    mounted() {
        // Console log the timezone for testing
        console.log('Timezone from Moment', this.$moment.tz.guess());
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        /**
         * Vuex services actions
         */
        ...mapActions('services', {
            getListCampaigns: 'getListCampaigns',
            getStatusListOpportunities: 'getStatusListOpportunities',
        }),

        // Vuex opportunities actions
        ...mapActions('services/opportunities', {
            getResults: 'getResults',
        }),

        // Vuex opportunityInfo actions
        ...mapActions('services/opportunityInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex opportunities mutations
        ...mapMutations('services/opportunities', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Submit opportunities filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            // Remove empty values
            Object.keys(this.filterResultsParams).forEach((key) => {
                if (this.filterResultsParams[key] && this.filterResultsParams[key] instanceof Date) {
                    return;
                }

                if (typeof(this.filterResultsParams[key]) === 'number') {
                    return;
                }

                if (this.filterResultsParams[key].length) {
                    return;
                }

                delete this.filterResultsParams[key];
            });

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.data?.forEach(result => {
                        if (result.customer_id && ! foundCustomerIds.includes(result.customer_id)) {
                            foundCustomerIds.push(result.customer_id);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({ selectedDealerId: this.selectedDealerId, customerIds: foundCustomerIds });

                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        loadResultInfo(result, selectedDealerId) {
            this.getResultInfo({
                result: result,
                selectedDealerId: selectedDealerId
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, this.userSession ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000 : 60000);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/opportunities/RESET_FILTERS', this.userSession?.externalUserId);
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(opportunityId) {
            return this.selectedOpportunityId && opportunityId == this.selectedOpportunityId;
        },

        /**
         * Compute the stage and return the corrisponding class name
         */
        computeStageClass(salesStage) {
            let computedClass = '';

            if (! this.results.stage_lookup) {
                return computedClass;
            }

            const stageLookup = this.results.stage_lookup;

            for(const stage in stageLookup) {
                if(stageLookup[stage].includes(salesStage)) {
                    computedClass = stage === 'new' ? 'danger'
                        : stage === 'won' ? 'success'
                        : stage === 'in_progress' ? 'warning'
                        : stage === 'closed' ? 'closed'
                        : computedClass;

                    break;
                }
            };

            return computedClass;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive ? 'max-height: 70vh' : 'max-height: 25vh';
            this.filerResultActive = !this.filerResultActive;
        },
    },

    watch: {
        resultInfo(newData, oldData) {
            let reloadResults = false;
            const sameCustomer = (oldData?.Customer?.ID && newData?.Customer?.ID) && oldData.Customer.ID === newData.Customer.ID;

            // Reload if activity is changed
            if (sameCustomer && oldData.ActivityLog.length != newData.ActivityLog.length) {
                reloadResults = true;
            }

            // Reload if opportunity Status changes
            if (sameCustomer && oldData.OpportunitiesStatusLog.length != newData.OpportunitiesStatusLog.length) {
                reloadResults = true;
            }

            // Reload result
            if (reloadResults) this.submitFilterResultsForm();
        },
    },
}
</script>

<style lang="scss" scoped>
//
</style>
