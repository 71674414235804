<template>
<div class="services-bookmark">
    <button
        @click="toggleDefaultStartingPage"
        type="button"
        title="Set as your default starting page"
    >
        <i
            class="fa-bookmark"
            :class="
                defaultStartingPage === servicePageName
                    ? 'fa-solid'
                    : 'fa-regular'"
        ></i>
    </button>
</div>
</template>

<script>
import { preference } from 'vue-preferences';

export default {
    name: 'ServicesFavoriteButton',

    props: {
        servicePageName: {
            type: String,
            required: true,
        },
    },

    computed: {
        defaultStartingPage: preference('defaultStartingPage'),
    },

    methods: {
        toggleDefaultStartingPage() {
            this.defaultStartingPage =
                this.defaultStartingPage !== this.servicePageName
                    ? this.servicePageName
                    : null;
        },
    },
};
</script>

<style lang="scss">
.services-bookmark {
    display: flex;
    margin-left: auto;

    button {
        background: none;
        border: none;
        outline-offset: 4px;
        padding: 0;
    }

    i {
        color: var(--alpha_text);
        font-size: 1.5em;
    }
}
</style>
