export const reportViewMixin = {
    data() {
        return {
            chartData: null,

            isLoading: {
                getReportData: false,
            },
        };
    },

    methods: {
        /**
         * Get report data
         */
        getReportData() {
            this.isLoading.getReportData = true;

            axios
                .get(route('api.reports.getData', { selectedDealer: this.selectedDealerId, report: this.report.id }))
                .then((response) => {
                    this.chartData = response.data;
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'Unable to retreive the report data.',
                    });
                })
                .finally(() => {
                    this.isLoading.getReportData = false;
                });
        },
    },
};
