<template>
<div>
    <!-- Bulk Message Composition Modal -->
    <div
        ref="modal_bulk_assign_opportunities"
        class="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="bulk_assign_opportunities_title"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-xl modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title mr-2"
                        id="bulk_assign_opportunities_title"
                    >Bulk Assign Opportunities</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Selections -->
                    <div class="bulk-opportunity-buttons mb-2">
                        <div>
                            <el-checkbox
                                v-model="checkAll"
                                :indeterminate="isIndeterminate"
                                class="mb-0"
                            >Select All</el-checkbox>
                        </div>

                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Assign To</span>
                            </div>
                            <el-select
                                class="select-blue"
                                v-model="formOptions.selectedAsm"
                                size="mini"
                            >
                                <el-option
                                    v-for="(asm, index) in asms"
                                    :key="index"
                                    :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                    :value="asm.asmId"
                                />
                            </el-select>
                        </div>
                    </div>

                    <!-- Opportunity List -->
                    <ul class="bulk-opportunity-list">
                        <li
                            v-for="opportunity in opportunities"
                            :key="opportunity.id"
                        >
                            <button
                                @click="toggleSelectedOpportunity(opportunity.id)"
                                :disabled="!checkIfOpportunitySelectable(opportunity)"
                                :class="{ selected: checkIfOpportunitySelected(opportunity.id) }"
                                :title="
                                    !checkIfOpportunitySelectable(opportunity)
                                        ? opportunityNotSelectableReason
                                        : ''
                                "
                            >
                                {{ opportunity.name }}<br>
                                {{ $moment(opportunity.date_entered).format('MM/DD/YY') }}<br>
                                {{ opportunity.customer_name }}<br>
                                {{ opportunity.vehicle_make }}
                                {{ opportunity.vehicle_model }}
                                {{ opportunity.vehicle_year }}<br>
                                {{ getAssignedAsm(opportunity.assigned_user_id) }}
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-danger ml-0"
                        data-dismiss="modal"
                    >Cancel</button>
                    <button
                        @click="assignOpportunities"
                        type="button"
                        class="btn btn-alpha_primary"
                        :disabled="!canSubmitAssignOpportunities"
                    >Assign Opportunities</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <ModalBulkAssignOpportunities ref="modalBulkAssignOpportunities" />
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalBulkAssignOpportunities.showModal()">Bulk Assign Opportunities</button>
 */

import { getAsmNameById } from '@/filters/asmHelpers';
import { truncate } from '@/filters/stringFormats';

export default {
    props: {
        asms: {
            type: Array,
            required: true,
        },

        opportunities: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            formOptions: {
                selectedAsm: '',
                selectedOpportunitiesIds: [],
            },

            checkAll: true,

            isLoading: {
                assignOpportunities: false,
            },

            opportunityNotSelectableReason: 'Only opportunities with the ' +
                'status of "Needs Analysis" and that have no activity are ' +
                'selectable',
        }
    },

    computed: {
        selectableOpportunities() {
            return this.opportunities.filter(opportunity => {
                return this.checkIfOpportunitySelectable(opportunity);
            });
        },

        isIndeterminate() {
            if (!this.formOptions.selectedOpportunitiesIds.length) {
                return false;
            }

            if (this.formOptions.selectedOpportunitiesIds.length === this.selectableOpportunities.length) {
                return false;
            }

            return true;
        },

        canSubmitAssignOpportunities() {
            if (!this.formOptions.selectedOpportunitiesIds.length) {
                return false;
            }

            if (this.formOptions.selectedAsm === '') {
                return false;
            }

            if (this.isLoading.assignOpportunities) {
                return false;
            }

            return true;
        },
    },

    watch: {
        formOptions: {
            deep: true,
            handler() {
                if (!this.formOptions.selectedOpportunitiesIds.length) {
                    this.checkAll = false;
                }

                if (this.formOptions.selectedOpportunitiesIds.length === this.selectableOpportunities.length) {
                    return this.checkAll = true;
                }
            },
        },

        checkAll(value) {
            this.formOptions.selectedOpportunitiesIds = value
                ? this.selectableOpportunities.map(opportunity => {
                    return opportunity.id;
                })
                : [];
        },
    },

    created() {
        this.formOptions.selectedOpportunitiesIds =
            this.selectableOpportunities.map(opportunity => {
                return opportunity.id;
            });
    },

    methods : {
        // Imported methods
        getAsmNameById,
        truncate,

        showModal() {
            $(this.$refs.modal_bulk_assign_opportunities).modal('show');
        },

        hideModal() {
            $(this.$refs.modal_bulk_assign_opportunities).modal('hide');
        },

        checkIfOpportunitySelectable(opportunity) {
            if (opportunity.sales_stage !== 'Needs Analysis') {
                return false;
            }

            if (opportunity.activities.length) {
                return false;
            }

            return true;
        },

        checkIfOpportunitySelected(opportunityId) {
            return this.formOptions.selectedOpportunitiesIds.includes(opportunityId);
        },

        toggleSelectedOpportunity(opportunityId) {
            const index = this.formOptions.selectedOpportunitiesIds.indexOf(opportunityId);

            if (index === -1) {
                this.formOptions.selectedOpportunitiesIds.push(opportunityId);
            } else {
                this.formOptions.selectedOpportunitiesIds.splice(index, 1);
            }
        },

        assignOpportunities() {
            this.isLoading.assignOpportunities = true;

            axios
                .post(
                    route('api.unotifi.AssignOpportunities', {
                        selectedDealer: this.selectedDealerId,
                    }),
                    {
                        assignToAsmId: this.formOptions.selectedAsm,
                        opportunityIds: this.formOptions.selectedOpportunitiesIds,
                    }
                )
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: 'Applicable opportunities have been assigned to the selected ASM',
                    });

                    this.$emit('opportunities-updated');
                    this.hideModal();
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                    this.$message({
                        type: 'error',
                        message: 'There was an error assigning the opportunities to the selected ASM',
                    });
                })
                .finally(() => {
                    this.isLoading.assignOpportunities = false;
                });
        },

        getAssignedAsm(asmId) {
            if (!asmId) {
                return 'Unassigned';
            }

            if (!this.asms.length) {
                return this.truncate(asmId, 8, '…');
            }

            const asmName = this.getAsmNameById(asmId, this.asms);

            return asmName ? asmName : this.truncate(asmId, 8, '…');
        },
    },
}
</script>

<style lang="scss" scoped>
ul.bulk-opportunity-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-right: -10px;
    padding: 0;

    li {
        flex-basis: calc(50% - 10px);
        margin-bottom: 10px;
        margin-right: 10px;

        @media screen and (min-width: 800px) {
            flex-basis: calc(33.333% - 10px);
        }

        @media screen and (min-width: 1425px) {
            flex-basis: calc(25% - 10px);
        }

        @media screen and (min-width: 1920px) {
            flex-basis: calc(20% - 10px);
        }

        button {
            background: none;
            border: 1px solid var(--alpha_border);
            border-radius: 5px;
            color: inherit;
            height: 100%;
            margin: 0;
            padding: 5px;
            position: relative;
            text-align: initial;
            vertical-align: top;
            width: 100%;

            &.selected:after, &:disabled:after {
                font-family: 'FontAwesome', sans-serif;
                font-size: 1.5em;
                position: absolute;
                right: 5px;
                top: 5px;
            }

            &.selected {
                border-color: var(--alpha_success);
                box-shadow: 3px 3px 0 var(--alpha_success);

                &:after {
                    color: var(--alpha_success);
                    content: '\f058';
                }
            }

            &:disabled {
                &:after {
                    color: var(--alpha_neutral);
                    content: '\f05e';
                }
            }
        }
    }
}

.bulk-opportunity-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.input-group {
    width: initial;
}
</style>
