import { mapPreferences } from 'vue-preferences';

export default {
    namespaced: true,

    state: {
        ...mapPreferences({
            pendingMessages_searchForm_assignedTo: {
                defaultValue: null
            },
        }),

        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        //
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResults', value: true});

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.GetEvents',
                        {
                            selectedDealer: selectedDealerId,
                            type: 'Approval Pending'
                        }
                    ),
                    { params: parameters }
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results) {
                state.results = Object.freeze(results);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 5);
            const assignedToAsmNumbers = [];

            if (state.pendingMessages_searchForm_assignedTo.get()) {
                assignedToAsmNumbers.push(state.pendingMessages_searchForm_assignedTo.get());
            }

            state.filterResultsForm = {
                assignedToAsmNumbers: assignedToAsmNumbers,
                currentPage: 1,
                dateFromTo: {
                    start: startDate,
                    end: new Date(),
                },
                firstName: '',
                lastName: '',
                VINNumber: '',
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
