/**
 * Convert text to Title Case
 */
export const titleCase = (string) => {
    return string
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
};

/**
 * Replace links in string with HTML links
 */
export const convertUrlsToHtml = ({ text, style = '', target = '' }) => {
    const URLMatcher =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;
    return text.replace(URLMatcher, (match) => `<a style="${style}" target="${target}" href="${match}">${match}</a>`);
};

/**
 * Truncate long text
 * @param {string} text - The text to be truncated.
 * @param {number} length - The maximum length of the text to be truncated.
 * @param {string=} suffix - Optional suffix to replace truncated text with.
 */
export const truncate = (text, length, suffix = '') => {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
};

/**
 * Strip slashes from a string such as those added when escaping characters
 * @param {string} str - The string to strip slashes from.
 */
export const stripSlashes = (str) => {
    return (str + '').replace(/\\(.?)/g, function (s, n1) {
        switch (n1) {
            case '\\':
                return '\\';
            case '0':
                return '\u0000';
            case '':
                return '';
            default:
                return n1;
        }
    });
};

/**
 * Display length of time in a more friendly format ie: 1000ms to "1 second", or 129600s to "1.5 days"
 */
export const friendlyTime = (valueOfTime, { unitOfTime, round = true } = {}) => {
    // Check allowed unitOfTime values
    if (!unitOfTime || !['hour', 'millisecond', 'minute', 'second'].includes(unitOfTime)) {
        console.error('The unitOfTime parameter for the friendlyTime method is either missing or invalid.');
        return 'Invalid unit of time';
    }

    // Check if valueOfTime is a number
    if (isNaN(valueOfTime)) {
        console.error('Invalid valueOfTime value provided to the friendlyTime method.');
        return 'Invalid value of time';
    }

    let calculatedValueOfTime = Number(valueOfTime);
    let calculatedUnitOfTime = unitOfTime;

    // Handle millisecond calculations
    if (calculatedUnitOfTime === 'millisecond' && calculatedValueOfTime >= 60) {
        calculatedValueOfTime = calculatedValueOfTime / 60;
        calculatedUnitOfTime = 'second';
    }

    // Handle seconds calculations
    if (calculatedUnitOfTime === 'second' && calculatedValueOfTime >= 60) {
        calculatedValueOfTime = calculatedValueOfTime / 60;
        calculatedUnitOfTime = 'minute';
    }

    // Handle minutes calculations
    if (calculatedUnitOfTime === 'minute' && calculatedValueOfTime >= 60) {
        calculatedValueOfTime = calculatedValueOfTime / 60;
        calculatedUnitOfTime = 'hour';
    }

    // Handle days calculations
    if (calculatedUnitOfTime === 'hour' && calculatedValueOfTime >= 24) {
        calculatedValueOfTime = calculatedValueOfTime / 24;
        calculatedUnitOfTime = 'day';
    }

    // Handle rounding
    if (round) {
        calculatedValueOfTime = Math.round(calculatedValueOfTime);
    }

    // Handle pluralization
    calculatedUnitOfTime = calculatedValueOfTime === 1 ? calculatedUnitOfTime : `${calculatedUnitOfTime}s`;

    return `${calculatedValueOfTime} ${calculatedUnitOfTime}`;
};

/**
 * Append ordinal suffix to numbers ie: 1st, 2nd, 3rd, 4th, etc.
 */
export const appendOrdinalSuffix = (number) => {
    if (isNaN(number) || number == 0) {
        console.warn(`Unhandled value, "${number}", passed to the friendlyNumber method.`);
        return number;
    }

    let lastDigit = number % 10;
    let lastTwoDigits = number % 100;

    if (lastDigit == 1 && lastTwoDigits != 11) {
        return number + 'st';
    }

    if (lastDigit == 2 && lastTwoDigits != 12) {
        return number + 'nd';
    }

    if (lastDigit == 3 && lastTwoDigits != 13) {
        return number + 'rd';
    }

    return number + 'th';
};

/**
 * Decode a string containing HTML tags and entities into displayable text.
 *
 * Example: &quote;Title Of Work&quot; to "Title Of Work", or when needing to
 * display tags such as <strong>Bold Text</strong>.
 */
export const decodeHtml = (string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = string;
    return txt.value;
};
