export const formReportMixin = {
    data() {
        return {
            isLoading: {
                getListOfDealerships: false,
            },
        };
    },

    methods: {
        /**
         * Get list of dealerships with dealer_numbers
         */
        async $_formReportMixin_getListOfDealerships() {
            this.isLoading.getListOfDealerships = true;
            let dealerships = [];

            await axios
                .get(route('api.user-info.getDealerships', { selectedDealer: this.selectedDealerId }))
                .then((response) => {
                    dealerships = response.data.filter((dealership) => {
                        return dealership.dealer_number;
                    });
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'Unable to get list of dealerships.',
                    });
                })
                .finally(() => {
                    this.isLoading.getListOfDealerships = false;
                });

            return dealerships;
        },
    },
};
