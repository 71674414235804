<template>
<div>
    <div class="font-23 text-center mb-4">
        Two-Factor Authentication Management
    </div>

    <!-- If two-factor recently disabled -->
    <template v-if="recentlyDisabled">
        <div class="alert alert-success" role="alert">
            Two-factor authentication has been disabled.
        </div>
    </template>

    <!-- If two-factor recently confirmed -->
    <template v-if="recentlyConfirmed">
        <div class="card mb-3 border-alpha_success">
            <div class="card-header">
                Two-factor authentication setup complete.
            </div>

            <div class="card-body">
                <p>
                    <strong>
                        Please take a moment to generate and save your
                        recovery codes.
                    </strong>
                </p>
            </div>
        </div>
    </template>

    <!-- If two-factor enabled -->
    <template v-if="twoFactorAuthenticationStatus === 'enabled'">
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-check-circle text-alpha_success"></i>
                Two-factor authentication is enabled.
            </div>
        </div>

        <!-- Backup Code Information -->
        <div class="card mb-3">
            <div class="card-header">
                Recovery Codes
            </div>

            <div class="card-body">
                <p>
                    Two-factor recovery codes may be used to recover your
                    account in the event you lose access to your two-factor
                    authentication device.
                </p>

                <p>Save your recovery codes in a secure location.</p>

                <p class="mb-4">
                    <em>
                        Generating new recovery codes will invalidate any
                        existing recovery codes.
                    </em>
                </p>

                <RecoveryCodes />
            </div>
        </div>

        <!-- Disable two-factor authentication -->
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-exclamation-triangle text-alpha_danger"></i>
                Disable Two-Factor Authentication
            </div>

            <div class="card-body">
                <p class="card-text mb-2">
                    Disabling two-factor authentication will remove an extra
                    layer of security from your account.
                </p>

                <form
                    method="POST"
                    :action="route('two-factor.enable')"
                    onsubmit="return confirm(
                        'You are about to disable two-factor authentication. '
                            + '\n\nClick OK to continue.'
                    )"
                >
                    <CSRFTokenInput />
                    <input type="hidden" name="_method" value="DELETE">

                    <button
                        type="submit"
                        class="btn btn-alpha_danger spin-on-click"
                    >
                        Disable Two-Factor Authentication
                        <i class="fas fa-spinner-third fa-spin d-none"></i>
                    </button>
                </form>
            </div>
        </div>
    </template>

    <!-- Elseif two-factor pending confirmation -->
    <template
        v-else-if="twoFactorAuthenticationStatus === 'pending-confirmation'"
    >
        <div class="card mb-3">
            <div class="card-header">
                Two-factor authentication has been enabled.
            </div>

            <div class="card-body">
                <p>
                    To complete setup, please scan the following QR code with
                    your phone's authenticator application.
                </p>

                <p class="mb-3">
                    Once scanned, enter the code generated by your authenticator
                    application to confirm two-factor authentication setup.
                </p>

                <div
                    class="d-flex justify-content-center"
                >
                    <div
                        class="bg-white p-2"
                        v-html="twoFactorQrCodeSvg"
                    ></div>
                </div>

                <!-- Confirm two-factor authentication -->
                <form
                    method="POST"
                    :action="route('two-factor.confirm')"
                >
                    <CSRFTokenInput />

                    <div class="form-group mt-4">
                        <label for="recovery_code">Recovery Code</label>

                        <input
                            id="recovery_code"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': twoFactorConfirmationError
                            }"
                            name="code"
                            required
                        >

                        <div
                            v-if="twoFactorConfirmationError"
                            class="invalid-feedback"
                            role="alert"
                        >
                            {{ twoFactorConfirmationError }}
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-alpha_primary btn-lg btn-block
                            spin-on-click"
                    >
                        Confirm
                        <i
                            class="fas fa-spinner-third fa-spin d-none"
                        ></i>
                    </button>
                </form>

                <!-- Cancel -->
                <form
                    method="POST"
                    :action="route('two-factor.enable')"
                >
                    <CSRFTokenInput />
                    <input type="hidden" name="_method" value="DELETE">

                    <button
                        type="submit"
                        class="btn btn-block btn-link text-alpha_danger
                            spin-on-click"
                    >
                        Cancel Setup
                        <i class="fas fa-spinner-third fa-spin d-none"></i>
                    </button>
                </form>
            </div>
        </div>
    </template>

    <!-- Else two-factor not enabled -->
    <template v-else>
        <div class="card mb-3">
            <div class="card-header">Enable Two-Factor Authentication</div>

            <div class="card-body">
                <p>
                    Two-factor authentication adds an extra layer of security
                    to your account.
                </p>

                <p class="mb-3">
                    Enabling two-factor authentication will allow you to
                    securely login to your account using your phone's
                    authenticator application, such as Google Authenticator or
                    Authy.
                </p>

                <form
                    method="POST"
                    :action="route('two-factor.enable')"
                >
                    <CSRFTokenInput />

                    <button
                        type="submit"
                        class="btn btn-alpha_primary btn-lg btn-block
                            spin-on-click"
                    >
                        Enable Two-Factor Authentication
                        <i class="fas fa-spinner-third fa-spin d-none"></i>
                    </button>
                </form>
            </div>
        </div>
    </template>

    <!-- Return Home -->
    <div>
        <a href="/" class="btn btn-link btn-block">Return Home</a>
    </div>
</div>
</template>

<script>
import CSRFTokenInput from '@/components/CSRFTokenInput.vue';
import RecoveryCodes from '@/components/auth/TwoFactorManagement/RecoveryCodes.vue';

export default {
    components: {
        CSRFTokenInput,
        RecoveryCodes,
    },

    props: {
        recentlyConfirmed: {
            type: Boolean,
            default: false
        },

        recentlyDisabled: {
            type: Boolean,
            default: false
        },

        twoFactorConfirmationError: {
            type: String,
            default: ''
        },

        twoFactorQrCodeSvg: {
            type: String,
            default: ''
        },

        twoFactorAuthenticationStatus: {
            type: String,
            required: true
        },
    },
}
</script>
