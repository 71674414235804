<template>
    <div class="flex-grow-1">
        <form @submit.prevent="updateEvent({isApproved: true})">
            <div>
                <div class="row justify-content-between mb-2">
                    <div class="col col-xl-4">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Send to</span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="formData.phoneNumber"
                                size="mini"
                                value-key="uniqueIdentifier"
                                :disabled="isOptOutTexts"
                            >
                                <el-option
                                    v-for="(phoneNumber, index) in formOptions.phoneNumbers"
                                    :key="index"
                                    :label="`${phoneNumber.label}: ${formatPhoneNumber(phoneNumber.number)}` + (phoneNumber.isValid == 0 ? ' (Invalid)' : '')"
                                    :value="phoneNumber"
                                >
                                    {{ phoneNumber.label }}: {{ formatPhoneNumber(phoneNumber.number) }}
                                    <i v-if="phoneNumber.isValid == 1" title="Valid" class="fas fa-check-circle text-success"></i>
                                    <i v-else-if="phoneNumber.isValid == 0" title="Invalid" class="fas fa-exclamation-circle text-danger"></i>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <!-- Pending Message: Display -->
                <div
                    v-if="!isEditingPendingMessage"
                    @dblclick="editPendingMessage"
                    class="pending-message-text"
                    v-html="convertUrlsToHtml({text: formData.smsMessage, target: '_blank', style: 'color: inherit'})"
                ></div>
                <!-- Pending Message: Edit -->
                <el-input
                    v-else
                    ref="pendingMessage"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Type message"
                    v-model="formData.smsMessage"
                ></el-input>
            </div>
            <div class="row justify-content-between py-2">
                <div class="col-sm-8 col-md-6 col-lg-8 col-xl-4 d-flex flex-wrap align-items-center mb-2 mb-sm-0">
                    <template v-if="! formData.fileInput">
                        <label class="add-attachment-button badge badge-alpha_neutral--shift mr-2 mb-0">
                            <input @change="onFileChange" ref="file" type="file" name="file" accept="image/*, video/*" capture="environment" style="display: none" :disabled="isOptOutTexts">
                            <i class="icon">attach_file</i> Attach File
                        </label>
                    </template>
                    <template v-else>
                        <button type="button" class="btn btn-danger mr-2" @click="removeFile">Remove Image</button>
                    </template>
                    <small>{{ formData.smsMessage.length }} Characters used</small>
                </div>

                <div class="col-sm-4 text-right">
                    <template v-if="!isEditingPendingMessage">
                        <!-- Edit Message Button -->
                        <button
                            @click="editPendingMessage"
                            type="button"
                            class="btn btn-alpha_secondary mr-1"
                        >
                            <i class="fas fa-edit"></i>
                        </button>

                        <!-- Delete Message Button -->
                        <button
                            @click="deletePendingMessage(pendingMessage.ID)"
                            type="button"
                            class="btn btn-alpha_danger mr-1"
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>

                        <!-- Approve/Send Message Button -->
                        <button
                            type="submit"
                            class="btn btn-alpha_primary"
                            :disabled="isOptOutTexts
                                || isLoading.updateEvent"
                            :title="isOptOutTexts
                                ? 'Customer is opted out of receiving texts'
                                : ''"
                        >Approve/Send</button>
                    </template>
                    <template v-else>
                        <!-- Cancel Button -->
                        <button
                            @click="cancelPendingMessageEdit"
                            type="button"
                            class="btn btn-alpha_danger mr-1"
                        >Cancel</button>

                        <!-- Save Button -->
                        <button
                            @click="updateEvent()"
                            type="button"
                            class="btn btn-alpha_primary"
                            :disabled="!hasMessageBeenEdited
                                || isLoading.updateEvent"
                        >Save</button>
                    </template>
                </div>
            </div>
            <div>{{ pendingMessageEngenderedInfo }}</div>
        </form>
    </div>
</template>

<script>
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { convertUrlsToHtml } from '@/filters/stringFormats';

export default {
    props: {
        customer: {
            type: Object,
        },
        pendingMessage: {
            type: Object,
        },
        phoneNumbers: {
            type: Array,
        },
    },

    data() {
        return {
            isEditingPendingMessage: false,

            isLoading: {
                updateEvent: false,
            },

            formData: {
                fileInput: '',
                phoneNumber: '',
                smsMessage: '',
            },

            formOptions: {
                phoneNumbers: [],
            },
        }
    },

    computed: {
        hasMessageBeenEdited() {
            return this.formData.smsMessage !== this.pendingMessage.text;
        },

        isOptOutTexts() {
            return this.customer.optOutTextMessage === '1';
        },

        pendingMessageEngenderedInfo() {
            const engendered = this.pendingMessage.generated_from === 'System' ? 'Generated' : 'Created';
            const dateTime = this.$moment(this.pendingMessage.DateTime).format('MM/DD/YY @h:mm A');
            const asmName = this.pendingMessage.first_name_ASM || this.pendingMessage.last_name_ASM
                ? `${this.pendingMessage.first_name_ASM} ${this.pendingMessage.last_name_ASM}`
                : 'Unknown';
            const initiator = this.pendingMessage.generated_from === 'System' ? '' : `by ${asmName}`;

            return `${engendered} on ${dateTime} ${initiator}`;
        },
    },

    created() {
        this.formData.smsMessage = this.pendingMessage.text;

        this.phoneNumbers.forEach(phoneNumber => {
            this.formOptions.phoneNumbers.push(phoneNumber);
        });

        this.setSelectedPhoneNumber();
    },

    methods: {
        /**
         * Imported methods
         */
        convertUrlsToHtml,
        formatPhoneNumber,

        // Handle file change
        onFileChange() {
            this.formData.fileInput = this.$refs.file.files[0];
        },
        removeFile() {
            this.formData.fileInput = '';
        },

        // Make the pending message input editable
        editPendingMessage() {
            this.isEditingPendingMessage = true;

            // Wait until next Vue render then focus the input
            this.$nextTick(() => {
                this.$refs.pendingMessage.focus();
            });
        },

        // Cancel editing the pending message
        cancelPendingMessageEdit() {
            this.formData.smsMessage = this.pendingMessage.text;
            this.isEditingPendingMessage = false;
        },

        // Send SMS message to customer
        updateEvent({isApproved = false} = {}) {
            const formData = new FormData();
            formData.append('phoneNumber', this.formData.phoneNumber.number);
            formData.append(
                'type',
                isApproved ? 'Pending' : 'Approval Pending'
            );
            formData.append('description', this.formData.smsMessage);

            if (this.formData.fileInput) {
                formData.append('file', this.formData.fileInput);
            }

            this.isLoading.updateEvent = true;

            axios
                .post(
                    route(
                        'api.unotifi.UpdateEvent',
                        {
                            selectedDealer: this.selectedDealerId,
                            eventId: this.pendingMessage.ID
                        }
                    ),
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                )
                .then(response => {
                    if (response.data.success) {
                        this.$emit('update-result-info');

                        this.$message({
                            type: 'success',
                            message: `Message ${
                                isApproved ? 'sent' : 'updated'
                            } successfully`
                        });
                    }

                    this.isEditingPendingMessage = false;
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: `There was an error ${
                            isApproved ? 'sending' : 'updating'
                        } the message`
                    });
                })
                .finally(() => {
                    this.isLoading.updateEvent = false;
                });
        },

        setSelectedPhoneNumber() {
            const foundPhoneNumber = this.phoneNumbers.find(phoneNumber => {
                if (phoneNumber.number.includes(this.pendingMessage.send_to_phone) || this.pendingMessage.send_to_phone.includes(phoneNumber.number)) {
                    return true;
                }
            });

            if (foundPhoneNumber) {
                this.formData.phoneNumber = foundPhoneNumber;
            } else {
                const unknownPhoneNumber = {
                    isValid: 'unknown',
                    label: 'Unknown',
                    number: this.pendingMessage.send_to_phone,
                    type: 'unknown',
                    uniqueIdentifier: `Unknown-${this.pendingMessage.send_to_phone}`,
                };

                this.formOptions.phoneNumbers.push(unknownPhoneNumber);

                this.formData.phoneNumber = unknownPhoneNumber;
            }
        },

        /**
         * Delete pending message
         */
        deletePendingMessage(eventId) {
            this.$confirm('Are you sure you want to delete this pending message?', 'Delete Pending Message', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
            }).then(() => {
                const params = {
                    description: this.pendingMessage.text,
                    type: 'Deleted',
                };

                axios.post(route('api.unotifi.UpdateEvent', { selectedDealer: this.selectedDealerId, eventId: eventId }), params)
                    .then(response => {
                        this.$emit('update-result-info');

                        this.$message({
                            type: 'success',
                            message: 'Pending message deleted successfully'
                        });
                    })
                    .catch(error => {
                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }

                        this.$message({
                            type: 'error',
                            message: 'There was an error deleting the pending message'
                        });
                    })
                    .finally(() => {
                        //
                    });
            }).catch(error => {
                console.error(error);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    .add-attachment-button {
        cursor: pointer;
    }

    .pending-message-text {
        border: 1px solid var(--alpha_border);
        border-radius: 4px;
        color: var(--alpha_text);
        font-size: 14px;
        line-height: 1.5;
        min-height: 54px;
        overflow: auto;
        padding: 5px 15px;
        resize: vertical;
    }
</style>