/**
 * Get ASM name by Id
 * @param {string} asmId
 * @param {array<string>} asms
 * @param {Object} options - Additional options to pass.
 * @param {string} options.defaultValue - The default value to return if the ASM does not have a name.
 * @returns {string|null}
 */
export const getAsmNameById = (asmId, asms, { defaultValue = '(ASM Name Not Provided)' } = {}) => {
    const foundASM = asms.find((asm) => asm.asmId === asmId);

    if (!foundASM) {
        return null;
    }

    let asmName = foundASM.first_name ?? '';
    asmName += foundASM.last_name ? ` ${foundASM.last_name}` : '';

    if (!asmName) {
        asmName = foundASM.asmName ?? defaultValue;
    }

    return asmName;
};
