<template>
  <div>
    <!-- Report View -->
    <component v-if="selectedReportView.component"
      :is="selectedReportView.component"
      v-bind="selectedReportView.props"
      :key="selectedReportView.reportId"
      @close-report-view="deselectReportView"
    />

    <div v-else class="table-responsive">
      <!-- If there are reports -->
      <table v-if="reports.length" class="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th class="text-right" scope="col">Report</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.id">
            <th class="align-middle" scope="row">{{ report.name }}</th>
            <td class="align-middle">{{ $moment(report.created_at).format('MM/DD/YY @h:mm A') }}</td>
            <td class="align-middle text-right">
              <!-- Pending -->
              <template v-if="report.status === 'pending'">
                <span class="badge badge-alpha_neutral rounded-pill">Pending</span>
              </template>

              <!-- Processing -->
              <template v-else-if="report.status === 'processing'">
                <span class="badge badge-alpha_success rounded-pill">Processing</span>
              </template>

              <!-- Failed -->
              <template v-else-if="report.status === 'failed'">
                <span class="badge badge-alpha_danger rounded-pill">Failed</span>
              </template>

              <!-- Completed -->
              <template v-else>
                <!-- Download Button -->
                <button @click="downloadReport(report.id)" type="button" class="btn btn-sm btn-alpha_primary">
                  <i class="fa-solid fa-file-arrow-down"></i>
                </button>
                <!-- View Button -->
                <button v-if="checkIfReportHasView(report)"
                  @click="selectReportView(report)"
                  type="button"
                  class="btn btn-sm btn-alpha_primary"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </template>

              <!-- Refresh Reports -->
              <button v-if="['pending', 'processing'].includes(report.status)"
                @click="getReports"
                type="button"
                class="btn btn-sm btn-alpha_primary"
                title="Check the repot status"
                :disabled="isLoading.getReports"
              >
                <i class="fa-solid fa-sync-alt" :class="{ 'fa-spin': isLoading.getReports }"></i>
              </button>

              <!-- Delete Report -->
              <button v-if="checkIfReportCanBeDeleted(report)" type="button" class="btn btn-sm btn-alpha_danger" @click="deleteReport(report.id)">
                <i class="fa-solid fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Else if getting reports -->
      <div v-else-if="isLoading.getReports">
        <p class="font-weight-bolder">Looking up your reports now.</p>
      </div>

      <!-- Else -->
      <div v-else>
        <p class="font-weight-bolder">Looks like you don't have any reports yet. Why don't you try creating one now?</p>
      </div>
    </div>
  </div>
</template>

<script>
import ViewRecallBdcReport from './views/ViewRecallBdcReport.vue';
import ViewRecallRoiReport from './views/ViewRecallRoiReport.vue';

export default {
  name: 'ReportsList',

  components: {
    ViewRecallBdcReport,
    ViewRecallRoiReport,
  },

  data() {
    return {
      isLoading: {
        deleteReport: false,
        getReports: false,
        getReportUrl: false,
      },

      selectedReportView: {
        component: '',
        props: {},
        reportId: '',
      },

      reports: [],

      reportViews: [
        {
          name: 'Recall BDC Report',
          component: ViewRecallBdcReport,
        },
        {
          name: 'Recall ROI Report',
          component: ViewRecallRoiReport,
        },
      ],
    }
  },

  created() {
    // Get reports list
    this.getReports();
  },

  methods: {
    getReports() {
      this.isLoading.getReports = true;

      axios
        .get(route('api.reports.index', { selectedDealer: this.selectedDealerId }))
        .then(response => {
          this.reports = response.data;
        })
        .catch(error => {
          if (error.response?.data?.errors) {
            console.error(error.response.data.errors);
          } else {
            console.error(error);
          }

          this.$message({
            type: 'error',
            message: 'Unable to get list of reports.',
          });
        })
        .finally(() => {
          this.isLoading.getReports = false;
        });
    },

    checkIfReportCanBeDeleted(report) {
      // Check if report was created less than 5 minutes ago
      if (new Date() - new Date(report.created_at) < 300000) {
        return false;
      }

      return true;
    },

    checkIfReportHasView(report) {
      // Check if report has a view
      return this.reportViews.some(view => view.name === report.name);
    },

    selectReportView(report) {
      this.selectedReportView = {
        component: this.reportViews.find(view => view.name === report.name).component,
        props: {
          report: report,
        },
        reportId: report.id,
      };
    },

    deselectReportView() {
      this.selectedReportView = {
        component: '',
        props: {},
        reportId: '',
      };
    },

    deleteReport(reportId) {
      this.$confirm('Are you sure you want to delete this report?' , 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          this.isLoading.deleteReport = true;

          axios
            .delete(route('api.reports.destroy', { selectedDealer: this.selectedDealerId, report: reportId }))
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Report deleted successfully.',
              });
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                console.error(error.response.data.errors);
              } else {
                console.error(error);
              }

              this.$message({
                type: 'error',
                message: 'Unable to delete report.',
              });
            })
            .finally(() => {
              this.isLoading.deleteReport = false;
              this.getReports();
            });
        });
    },

    async downloadReport(reportId) {
      const url = await this.getReportUrl(reportId);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.setAttribute('download', 'download');
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },

    async getReportUrl(reportId) {
      this.isLoading.getReportUrl = true;

      return axios
        .get(route('api.reports.getUrl', { selectedDealer: this.selectedDealerId, report: reportId }))
        .then(response => {
          return response.data;
        })
        .catch(error => {
          if (error.response?.data?.errors) {
            console.error(error.response.data.errors);
          } else {
            console.error(error);
          }

          this.$message({
            type: 'error',
            message: 'Unable to retreive the report URL.',
          });

          throw new Error('Unable to retreive the report URL.');
        })
        .finally(() => {
          this.isLoading.getReportUrl = false;
        });
    },
  },
}
</script>
