<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a href="#filters-results" class="font-20" data-toggle="collapse" aria-expanded="true" @click="servicesResultSize">
                            Leads
                        </a>
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="filterResultsFormCurrentPage = 1; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})">
                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFirstName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input v-model="filterResultsFormLastName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Phone Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Phone No.</span>
                                    </div>
                                    <input v-model="filterResultsFormPhoneNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Email -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Email</span>
                                    </div>
                                    <input v-model="filterResultsFormEmail" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input v-model="filterResultsFormVINNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Vehicle Make -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Vehicle Make</span>
                                    </div>
                                    <input v-model="filterResultsFormVehicleMake" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Vehicle Model -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Vehicle Model</span>
                                    </div>
                                    <input v-model="filterResultsFormVehicleModel" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Sort By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Sort By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormSortBy"
                                        size="mini"
                                        placeholder="Select"
                                    >
                                        <el-option
                                            v-for="(filterName, index) in filterResultsFormOptions.sortBy"
                                            :key="index"
                                            :label="filterName"
                                            :value="filterName">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button type="submit" :disabled="isLoadingResults.getResults" class="btn btn-alpha_primary btn-block btn-lg">
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button @click="resetFilterResultsForm(); $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="btn btn-alpha_secondary btn-block btn-lg">
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar ref="resultsList" id="results_list" class="overflow-auto clients-list-wrapper flex-grow-1 pt-3" :style="servicesResultCss">
                    <ul v-if="results.leads && results.leads.length" class="list-unstyled clients-list">
                        <li
                            v-for="(result, index) in results.leads"
                            @click="routeReplace(result.id)"
                            :class="{
                                'active': isResultSelected(result.id),
                                'unread': $_generalMixin_getMessageStatusByCustomerId(
                                    result.id, notifications.notifications
                                ) === 'unread'
                            }"
                            :key="index"
                        >
                            <!-- Title/Customer/Vehicle -->
                            <div class="clients-list__name flex-grow-1">
                                <p class="font-14 mb-0">{{ result.title }}</p>
                                <p class="mb-0" v-if="result.first_name || result.last_name">{{ result.first_name }} {{ result.last_name }}</p>
                                <p class="mb-0" v-if="result.vehicle_make || result.vehicle_model">
                                    {{ result.vehicle_make }} {{ result.vehicle_model }}
                                </p>
                                <small>{{ $moment.utc(result.date_modified).local().format('MM/DD/YY @h:mm A') }}</small>
                            </div>

                            <!-- Customer Info -->
                            <div class="clients-list__info">
                                <!-- Activity -->
                                <div v-if="result.activity && result.activity.length" class="clients-list__activity mx-1 text-center">
                                    <div v-for="(activity, index) in result.activity" :key="index">
                                        <abbr :title="`${activity} logged`">
                                            {{ activity.charAt(0) }}
                                        </abbr>
                                    </div>
                                </div>

                                <!-- Delete Lead -->
                                <div class="clients-list__arrival-status mx-1 text-right">
                                    <i v-if="result.deleted != 0 || false" class="far fa-2x fa-calendar-check text-success"></i>
                                    <button v-else @click.prevent.stop="deleteLead(result.id)" type="button" class="btn p-0">
                                        <i class="far fa-2x fa-trash-alt text-danger"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <div
                    v-if="results.leads?.length && !(
                        results.current_page === 1 && results.isLastPage
                    )"
                    class="column-module-button"
                >
                    <nav aria-label="Leads pagination">
                        <ul class="pagination pagination-sm justify-content-center">
                            <li class="page-item" :class="{'disabled': filterResultsFormCurrentPage == 1}">
                                <button @click="filterResultsFormCurrentPage = 1; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="page-link" href="#" aria-label="First" :disabled="filterResultsFormCurrentPage == 1">
                                    <i aria-hidden="true" class="fas fa-angle-double-left"></i>
                                    <span class="sr-only">First</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{'disabled': filterResultsFormCurrentPage == 1}">
                                <button @click="filterResultsFormCurrentPage--; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="page-link" aria-label="Previous" :disabled="filterResultsFormCurrentPage == 1">
                                    <i aria-hidden="true" class="fas fa-angle-left"></i>
                                    <span class="sr-only">Previous</span>
                                </button>
                            </li>
                            <li class="page-item">
                                <span class="page-link">
                                    {{ results.current_page }}
                                </span>
                            </li>
                            <li class="page-item" :class="{'disabled': results.isLastPage}">
                                <button @click="filterResultsFormCurrentPage++; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="page-link" aria-label="Next" :disabled="results.isLastPage">
                                    <i aria-hidden="true" class="fas fa-angle-right"></i>
                                    <span class="sr-only">Next</span>
                                </button>
                            </li>
                            <li>
                                <button @click="setCurrentPageManually()" type="button" class="page-link">Go to page</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <lead-info />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import LeadInfo from '@/components/services/LeadInfo.vue';

export default {
    components: {
        'lead-info': LeadInfo,
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 60vh',
        }
    },

    computed: {
        ...mapState('services/leads', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapState('services/leadInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('services/leads', {
            filterResultsFormOptions: 'filterResultsFormOptions',
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.dateFromTo;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'dateFromTo', value: value });
            }
        },

        filterResultsFormEmail: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.email;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'email', value: value });
            }
        },

        filterResultsFormFirstName: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.firstName;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'firstName', value: value });
            }
        },

        filterResultsFormLastName: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.lastName;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'lastName', value: value });
            }
        },

        filterResultsFormPhoneNumber: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.phoneNumber;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'phoneNumber', value: value });
            }
        },

        filterResultsFormSortBy: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.sortBy;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'sortBy', value: value });
            }
        },

        filterResultsFormVehicleMake: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.vehicleMake;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'vehicleMake', value: value });
            }
        },

        filterResultsFormVehicleModel: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.vehicleModel;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'vehicleModel', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.services.leads.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('services/leads/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },
    },

    watch: {
        /**
         * Get result info when URL query parameter changes
         */
        $route(to, from) {
            if (to.query.lead_id) {
                this.getResultInfo({ selectedDealerId: this.selectedDealerId, leadId: to.query.lead_id, isNotification: to.query.notification });
            }
        },
    },

    created() {
        // Reset the filter results form
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        // Submit the filter results form
        if (! this.results.leads) {
            this.submitFilterResultsForm();
        }

        // Get result info if query param exists
        if (this.$route.query.lead_id) {
            this.getResultInfo({ selectedDealerId: this.selectedDealerId, leadId: this.$route.query.lead_id, isNotification:  this.$route.query.notification });
        }
    },

    methods: {
        // Vuex leads actions
        ...mapActions('services/leads', {
            getResults: 'getResults',
            updateLeadInfoById: 'updateLeadInfoById',
        }),

        // Vuex leadInfo actions
        ...mapActions('services/leadInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex leads mutations
        ...mapMutations('services/leads', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        // Vuex leadInfo mutations
        ...mapMutations('services/leadInfo', {
            CLEAR_RESULT_INFO: 'CLEAR_RESULT_INFO',
        }),

        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, this.userSession ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000 : 60000);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the search result filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/leads/RESET_FILTERS');
        },

        /**
         * Check if the search result info is selected
         */
        isResultSelected(leadId) {
            return this.resultInfo && leadId == this.resultInfo.info.id;
        },

        /**
         * Get the customer's name
         */
        getResultCustomerName(result) {
            let customerName = '';

            customerName += result.first_name ?? '';
            customerName += result.last_name ? ` ${result.last_name}` : '';

            if (! customerName) {
                customerName = result.cust ?? '(No Name Entered)';
            }

            return customerName;
        },

        /**
         * Delete Lead
         */
        deleteLead(leadId) {
            const deleteConfirmed = confirm('Are you sure you want to delete the lead?');

            if (deleteConfirmed) {
                this.updateLeadInfoById({selectedDealerId: this.selectedDealerId, leadId: leadId, keyValueInfoToUpdate: {deleted: true}})
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: 'Lead deleted successfully.'
                        });

                        if (this.resultInfo?.info?.id && this.resultInfo.info.id === leadId) {
                            this.CLEAR_RESULT_INFO();
                        }

                        this.submitFilterResultsForm();
                    })
                    .catch(error => {
                        this.$message({
                            type: 'error',
                            message: error.message
                        });
                    });
            }
        },

        /**
         * Set the pagination current page manually
         */
        setCurrentPageManually() {
            this.$prompt('Enter the page number to go to.', 'Go to page', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^[1-9][0-9]{0,1}$/,
                inputErrorMessage: 'Number must be between 1-99'
            }).then(({ value }) => {
                this.filterResultsFormCurrentPage = value;
                this.$_generalMixin_scrollSimpleBarResultsList();
                this.submitFilterResultsForm({clearResults: true});
            }).catch(() => {
                //
            });
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive?'max-height: 80vh':'max-height: 60vh';
            this.filerResultActive = !this.filerResultActive;
        },

        /**
         * Replace route parameters
         */
        routeReplace(leadId){
            return this.$router
                .replace({'query': {'lead_id': leadId}})
                .catch((error)=>{
                    if (error.from?.fullPath && error.to?.fullPath && error.from.fullPath === error.to.fullPath) {
                        this.getResultInfo({ selectedDealerId: this.selectedDealerId, leadId: leadId });
                    }
                });
        },
    },
}
</script>

<style lang="scss" scoped>
//
</style>
