<template>
<TransitionGroup tag="div" name="list" class="toasts-container">
    <div v-for="(toast) in toasts" :key="toast.id" class="toast-message">
        <!-- SMS Toast -->
        <template v-if="toast.type === 'sms'">
            <div class="toast-message__icon">
                <i class="fa-solid fa-message fa-xl"></i>
            </div>
            <div @click="goToMessage(toast)" class="toast-message__content">
                <div class="toast-message__title text-capitalize">
                    <template v-if="toast.content.type === 'approval pending'">
                        Pending Message
                    </template>
                    <template v-else-if="toast.content.generated_from === 'payment'">
                        Payment
                        {{ toast.title }}
                    </template>
                    <template v-else-if="['lead', 'leadwidget'].includes(toast.content.generated_from)">
                        Lead
                        {{ toast.title }}
                    </template>
                    <template v-else>
                        {{ toast.title }}
                    </template>
                </div>
                <div class="toast-message__body">
                    <span v-if="toast.content.customer_name" class="font-weight-bold">
                        {{ toast.content.customer_name}}
                    </span>
                    <br>
                    <span v-if="toast.content.text">{{ toast.content.text }}</span>
                    <span v-if="toast.content.description">{{ toast.content.description }}</span>
                </div>
                <div
                    v-if="toast.content.dealership
                        && toast.content.dealership.name
                        && !isCurrentDealership(toast.content.dealership.dealer_number)"
                    class="toast-message__dealership"
                >
                    {{ toast.content.dealership.name }}
                    ({{ toast.content.dealership.dealer_number }})
                </div>
            </div>
        </template>
        <!-- General Toast -->
        <template v-else>
            <div class="toast-message__icon">
                <i class="fa-solid fa-bell fa-xl"></i>
            </div>
            <div @click="goToMessage(toast)" class="toast-message__content">
                <div class="toast-message__title text-capitalize">
                    {{ toast.title }}
                </div>
                <div class="toast-message__body">
                    <span v-if="toast.content" class="font-weight-bold">{{ toast.content}}</span>
                </div>
            </div>
        </template>
        <button
            @click.stop="closeToast(toast.id)"
            type="button"
            class="toast-message__close"
        ><i class="fa-solid fa-xmark fa-lg"></i></button>
    </div>
</TransitionGroup>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "ToastCustom",

    data() {
        return {
            toasts: [],
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        showNotificationsTray: {
            get() {
                return this.$store.state.navigation.showNotificationsTray;
            },
            set(value) {
                this.$store.commit('navigation/SET_NOTIFICATIONS_TRAY', value);
            }
        },
    },

    methods: {
        ...mapActions('global', {
            markCaseNotificationsRead: 'markCaseNotificationsRead',
            markSMSNotificationsRead: 'markSMSNotificationsRead',
        }),

        addToast(title, content, type = 'general') {
            const formattedNotification = {
                id: this.toasts.length,
                title: title,
                type: type,
                content: content,
            };

            formattedNotification.autoCloseTimeout = setTimeout(() => {
                this.toasts = this.toasts.filter(
                    toast => toast.id !== formattedNotification.id
                );
            }, 10000);

            this.toasts.push(formattedNotification);
        },

        isCurrentDealership(dealerNumber) {
            return Boolean(
                this.userSession.dealership
                && this.userSession.dealership.dealer_number === dealerNumber
            );
        },

        goToMessage(toast) {
            if (true) {
                if (toast.type === 'sms') {
                    if (this.isCurrentDealership(toast.content.dealership.dealer_number)) {
                        if (toast.content.type === 'approval pending') {
                            this.$router.push({
                                name: 'services-pending-messages',
                                query: {
                                    customer_id: toast.content.customer_id,
                                    vehicle_id: toast.content.vehicle_id
                                        ? toast.content.vehicle_id
                                        : null,
                                }
                            });
                        } else if (['lead', 'leadwidget'].includes(toast.content.generated_from)) {
                            this.$router.push({
                                name: 'services-leads',
                                query: {
                                    lead_id: toast.content.customer_id,
                                    notification: true,
                                }
                            });
                        } else {
                            this.$router.push({
                                name: 'services-recent',
                                query: {
                                    customer_id: toast.content.customer_id,
                                    auto_campaign_id: toast.content.auto_campaign_id,
                                }
                            });
                        }
                    } else {
                        if (toast.content.type === 'approval pending') {
                            this.$router.push({
                                name: 'dealerships.show',
                                params: {
                                    route_name: 'services/recent',
                                    dealership: toast.content.dealership.id,
                                    customer_id: toast.content.customer_id,
                                    vehicle_id: toast.content.vehicle_id
                                        ? toast.content.vehicle_id
                                        : null,
                                }
                            });
                        } else if (['lead', 'leadwidget'].includes(toast.content.generated_from)) {
                            this.$router.push({
                                name: 'dealerships.show',
                                params: {
                                    route_name: 'services/leads',
                                    dealership: toast.content.dealership.id,
                                    lead_id: toast.content.customer_id,
                                }
                            });
                        } else {
                            this.$router.push({
                                name: 'dealerships.show',
                                params: {
                                    route_name: 'services/recent',
                                    dealership: toast.content.dealership.id,
                                    customer_id: toast.content.customer_id,
                                    auto_campaign_id: toast.content.auto_campaign_id,
                                }
                            });
                        }
                    }
                } else if (toast.type === 'case') {
                    if (this.isCurrentDealership(toast.content.dealership.dealer_number)) {
                        this.$router.push({
                            name: 'services-cases',
                            query: {
                                customer_id: toast.content.customer_id,
                                vehicle_id: toast.content.vehicle_id,
                                case_id: toast.content.case_id,
                            }
                        });
                    } else {
                        this.$router.push({
                            name: 'dealerships.show',
                            params: {
                                route_name: 'services/cases',
                                dealership: toast.content.dealership.id,
                                customer_id: toast.content.customer_id,
                                vehicle_id: toast.content.vehicle_id,
                                case_id: toast.content.case_id,
                            }
                        });
                    }
                } else {
                    this.showNotificationsTray = true;
                }
            }

            this.closeAllToasts();
        },

        closeToast(id) {
            // Find the index of the toast with the given id
            const index = this.toasts.findIndex(toast => toast.id === id);

            // Return if the toast does not exist
            if (index === -1) {
                return;
            }

            // Clear the autoCloseTimeout for the found toast
            clearTimeout(this.toasts[index].autoCloseTimeout);

            // Remove the toast from the array using splice for efficiency
            this.toasts.splice(index, 1);
        },

        closeAllToasts() {
            this.toasts.forEach(toast => {
                clearTimeout(toast.autoCloseTimeout);
            });

            this.toasts = [];
        },
    }
}
</script>

<style lang="scss" scoped>
.toasts-container {
    display: flex;
    flex-flow: column-reverse;
    gap: 15px;
    position: fixed;
    right: 16px;
    top: 60px;

    .toast-message {
        background: var(--alpha_bg);
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        color: var(--alpha_text);
        display: flex;
        flex-flow: row;
        font-size: 1.2em;
        gap: 15px;
        padding: 15px;
        width: 330px;

        .toast-message__title {
            line-height: .8em;
        }

        .toast-message__content {
            cursor: pointer;
            display: flex;
            flex-basis: 245px;
            flex-flow: column;
            padding: 0 15px 0 0;
            gap: 15px;
        }

        .toast-message__title {
            font-weight: bold;
        }

        .toast-message__dealership {
            background: var(--charlie_bg);
            color: var(--charlie_text);
            flex-basis: 100%;
            padding: 3px;
            text-align: center;
        }

        .toast-message__close {
            background: none;
            border: none;
            color: var(--alpha_text--muted);
            cursor: pointer;
            height: 15px;
            line-height: 0;
            padding: 0;
            position: relative;
            right: 0;
            top: 0;
            width: 15px;
        }
    }
}

.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
