<script>
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    extends: HorizontalBar,

    mixins: [
        reactiveProp,
    ],

    props: {
        chartData: {
            type: Object,
            required: true,
        },

        label: {
            type: String,
        },

        options: {
            type: Object,
            default: () => {},
        },
    },

    mounted() {
        this.renderChart(this.chartData, this.options);
    },

    watch: {
        chartData () {
            this.renderChart(this.chartData, this.options);
        },
    }
}
</script>
