<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="chats__results">
                <div class="px-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a href="#filters-results" class="font-20" data-toggle="collapse" aria-expanded="true" @click="chatsResultSize">
                            Chats
                        </a>
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="$_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})">
                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFirstName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input v-model="filterResultsFormLastName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Customer Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Customer No.</span>
                                    </div>
                                    <input v-model="filterResultsFormCustomerNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input v-model="filterResultsFormVINNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Phone Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Phone No.</span>
                                    </div>
                                    <input v-model="filterResultsFormPhoneNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Participating ASMs -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Participants</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormAsmIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [{asmName: 'Select Options', asmId: 'select-options'}, ...asms]"
                                            :key="index"
                                            :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                            :value="asm.asmId"
                                            :disabled="asm.asmId === 'select-options'"
                                        >
                                            <!-- Select Options Buttons -->
                                            <div v-if="asm.asmId === 'select-options'" class="el-select-dropdown__buttons">
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormAsmIds = asms.map(asm => asm.asmId)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormAsmIds = []"
                                                >Select None</button>
                                            </div>
                                            <!-- Label -->
                                            <span v-else>{{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}</span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button type="submit" :disabled="isLoadingResults.getResults || isFilterResultsFormEmpty" class="btn btn-alpha_primary btn-block btn-lg">
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button @click="resetFilterResultsForm(); $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})" type="button" class="btn btn-alpha_secondary btn-block btn-lg">
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <!-- Create New Chat Conversation -->
                        <div class="text-center">
                            <button type="button" class="btn btn-link text-alpha_primary" @click="$refs.modalCreateNewChat.showModal()">New Chat</button>
                        </div>
                    </div>
                </div>

                <!-- Results -->
                <simplebar ref="resultsList" id="results_list" class="overflow-auto clients-list-wrapper flex-grow-1" :style="chatsResultCss">
                    <ul v-if="results" class="list-unstyled clients-list">
                        <li
                            v-for="(result, index) in results"
                            @click="getResultInfoUpdateUrl(result.conversation_id, result.participants)"
                            :class="{'active': isResultSelected(result.conversation_id)}"
                            :key="index"
                            class="d-block"
                        >
                            <!-- Customer Info -->
                            <div v-if="result.first_name || result.last_name || result.v_model" class="clients-list__name mb-1">
                                <p v-if="result.first_name || result.last_name" class="font-14">
                                    {{ result.first_name }} {{ result.last_name }}
                                    <small v-if="result.v_model" class="text-nowrap">{{ result.veh_year }} {{ result.v_model }}</small>
                                </p>
                            </div>
                            <!-- Participants -->
                            <div>
                                <div class="participants-list">
                                    <div v-for="(participant, index) in result.participants.slice(0, 4)" :key="index" class="participants-list__item">{{ $_generalMixin_getASMByIdOrNumber(participant) ? $_generalMixin_getASMByIdOrNumber(participant).asmName.trim() : participant }}</div>
                                    <div v-if="result.participants.length > 4" class="participants-list__item participants-list__item--sans-icon">+ {{ result.participants.length - 4 }}</div>
                                </div>
                                {{ $moment.utc(result.maxDate).local().calendar() }}
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a conversation
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <chat-info
                    @updateResults="submitFilterResultsForm"
                />
            </template>
        </div>

        <!-- New Chat -->
        <modal-create-new-chat
            ref="modalCreateNewChat"
            @success="submitFilterResultsForm()"
        ></modal-create-new-chat>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import ChatInfo from '@/components/chats/ChatInfo.vue';
import ModalCreateNewChat from '@/components/modals/ModalCreateNewChat.vue';

export default {
    components: {
        'chat-info': ChatInfo,
        'modal-create-new-chat': ModalCreateNewChat,
    },

    mixins: [generalMixin],

    data() {
        return {
            // CSS class for chats result
            chatsResultCss:'max-height: 60vh',

            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,
        }
    },

    computed: {
        ...mapState('chats', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapGetters('chats', {
            isFilterResultsFormEmpty: 'isFilterResultsFormEmpty',
        }),

        ...mapState('chatInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('chatInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormAsmIds: {
            get() {
                return this.$store.state.chats.filterResultsForm.asmIds;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'asmIds', value: value });
            }
        },

        filterResultsFormCustomerNumber: {
            get() {
                return this.$store.state.chats.filterResultsForm.customerNumber;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'customerNumber', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return this.$store.state.chats.filterResultsForm.dateFromTo;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'dateFromTo', value: value });
            }
        },

        filterResultsFormFirstName: {
            get() {
                return this.$store.state.chats.filterResultsForm.firstName;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'firstName', value: value });
            }
        },

        filterResultsFormLastName: {
            get() {
                return this.$store.state.chats.filterResultsForm.lastName;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'lastName', value: value });
            }
        },

        filterResultsFormPhoneNumber: {
            get() {
                return this.$store.state.chats.filterResultsForm.phoneNumber;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'phoneNumber', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.chats.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('chats/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },
    },

    watch: {
        /**
         * Get result info when URL query parameter changes
         */
        $route(to, from) {
            if (to.query.conversation_id) {
                const participants = to.query.participants ? to.query.participants.split(',') : [];
                this.getResultInfo({ conversationId: to.query.conversation_id, participants: participants, selectedDealerId: this.selectedDealerId });
            }
        },
    },

    created() {
        // Get ASMs list
        if (!this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }

        // Set opportunity filter options to default
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            // Give the userSession enough time to load before resetting and submiting.
            const maxAttempts = 5;
            let attempts = 0;

            const resetFilterInterval = setInterval(() => {
                if (this.userSession?.externalUserId || attempts >= maxAttempts) {
                    this.resetFilterResultsForm();
                    this.submitFilterResultsForm();
                    clearInterval(resetFilterInterval);
                } else {
                    attempts++;
                }
            }, 500);
        }
    },

    mounted() {
        // Get result info if query param exists
        if (this.$route.query.conversation_id) {
            const participants = this.$route.query.participants ? this.$route.query.participants.split(',') : [];
            this.getResultInfo({ conversationId: this.$route.query.conversation_id, participants: participants, selectedDealerId: this.selectedDealerId });
        }
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex global actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),

        // Vuex chats actions
        ...mapActions('chats', {
            getResults: 'getResults',
        }),

        // Vuex chatInfo actions
        ...mapActions('chatInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex chats mutations
        ...mapMutations('chats', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Get result info and update URL
         */
        getResultInfoUpdateUrl(conversationId, participants) {
            this.$router
                .replace({
                    query: {
                        conversation_id: conversationId,
                        participants: participants.join(','),
                    }
                })
                .catch(error => {
                    // Reload result info
                    if (error.from?.fullPath && error.to?.fullPath && error.from.fullPath === error.to.fullPath) {
                        this.getResultInfo({ conversationId: conversationId, participants: participants, selectedDealerId: this.selectedDealerId });
                    }
                });
        },

        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    // Set timeout for results auto refresh
                    this.setGetResultsInterval();
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, this.userSession ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000 : 60000);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the search result filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('chats/RESET_FILTERS', this.userSession?.externalUserId);
        },

        /**
         * Check if the search result info is selected
         */
        isResultSelected(conversationId) {
            return this.resultInfo && conversationId == this.resultInfo.conversation.id;
        },

        /**
         * Get the customer's name
         */
        getResultCustomerName(result) {
            let customerName = '';

            customerName += result.first_name ?? '';
            customerName += result.last_name ? ` ${result.last_name}` : '';

            if (! customerName) {
                customerName = result.cust ?? '(No Name Entered)';
            }

            return customerName;
        },

        /**
         * Set CSS class for chats result
         */
        chatsResultSize() {
        this.chatsResultCss = this.filerResultActive ? 'max-height: 80vh' : 'max-height: 60vh';
        this.filerResultActive=!this.filerResultActive;
        },
    },
}
</script>

<style lang="scss" scoped>
.participants-list {
    display: flex;
    flex-wrap: wrap;

    .participants-list__item {
        background: var(--alpha_bg--shift);
        border-radius: 6px;
        margin: 0 5px 5px 0;
        padding: 3px 7px;

        &:not(.participants-list__item--sans-icon)::before {
            content: "\f2bd";
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            margin-right: 3px;
        }
    }
}
</style>
