<template>
    <div class="modal fade" id="opportunity_modal" ref="modal_opportunity" tabindex="-1" role="dialog" aria-labelledby="opportunity_modal_info_title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Create Opportunity</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <template v-if="selectableCampaignList.length">
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Select Campaign</span>
                                </div>
                                <select
                                    class="form-control"
                                    v-model="selectedCampaign"
                                >
                                    <option
                                        v-for="(campaign, index) in selectableCampaignList"
                                        :key="index"
                                        :label="campaign.name"
                                        :value="campaign">
                                    >{{ campaign.name }}</option>
                                </select>
                            </div>
                        </div>
                        <button :disabled="!selectedCampaign || isLoading.createOpportunity" @click="createOpportunity()" type="button" class="btn btn-alpha_primary float-right">Create Opportunity</button>
                    </template>
                    <template v-else>
                        <p>Please create a campaign and then select it from the list before creating a new opportunity.</p>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "ModalOpportunity",

    props: {
        customer: {
            type: Object,
            required: true,
        },
        vehicle: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            isLoading: {
                createOpportunity: false,
            },

            selectedCampaign: '',
        };
    },

    computed: {
        ...mapState('services', {
            campaignList: 'campaignList',
        }),

        selectableCampaignList() {
            const selectableCampaigns = this.campaignList.filter(campaign => campaign.manual == 1 || campaign.name === 'Dealer Campaign');

            // Add Dealer Campaign if doesn't exist
            if (!selectableCampaigns.find(campaign => campaign.name === 'Dealer Campaign')) {
                selectableCampaigns.push(
                    {
                        id: null,
                        manual: 1,
                        name: 'Dealer Campaign',
                    }
                );
            }

            return selectableCampaigns;
        },
    },

    methods: {
        ...mapActions('services', {
            getListCampaigns: 'getListCampaigns',
        }),

        showModal(){
            $(this.$refs.modal_opportunity).modal('show');
        },

        hideModal() {
            $(this.$refs.modal_opportunity).modal('hide');
        },

        /**
         * Create Opportunity
         *
         * If the campaign cannot be found, we pass along the campaign name and
         * set the createMissingCampaign flag to true.
         */
        async createOpportunity(){
            const customerId = this.customer.ID;
            const vehicleId = this.vehicle.id;

            const params = {
                campaignName: this.selectedCampaign.name,
            };

            if (this.selectedCampaign.id) {
                params['campaignId'] = this.selectedCampaign.id;
            } else {
                params['createMissingCampaign'] = true;
            }

            this.isLoading.createOpportunity = true;

            await axios
                .post(
                    route('api.unotifi.CreateOpportunity', {
                        customerId: customerId,
                        selectedDealer: this.selectedDealerId,
                        vehicleId: vehicleId,
                    }),
                    params
                )
                .then((response) => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Opportunity created successfully',
                        });

                        this.$emit('success');
                        this.hideModal();
                    }
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                    this.$message({
                        type: 'error',
                        message: 'Unable to create opportunity.',
                    });
                })
                .finally(() => {
                    this.isLoading.createOpportunity = false;
                });
        },
    },
}
</script>
