<template>
<div
    class="modal fade" id="view_service_history_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="view_service_history_title"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-2" id="view_service_history_title">
                    {{vehicle.year }}
                    {{ vehicle.v_make }}
                    {{ vehicle.v_model }}
                    Service History
                </h5>

                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                ><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <!-- Service History Selector -->
                <nav>
                    <div
                        id="service_history_tab"
                        class="nav nav-tabs border-bottom mb-3"
                        role="tablist"
                    >
                        <button
                            class="nav-link active"
                            id="nav_service_history_tab"
                            data-toggle="tab"
                            data-target="#nav_service_history"
                            type="button"
                            role="tab"
                            aria-controls="nav_service_history"
                            aria-selected="true"
                        >Dealership History</button>

                        <button
                            class="nav-link"
                            id="nav_carfax_history_tab"
                            data-toggle="tab"
                            data-target="#nav_carfax_history"
                            type="button"
                            role="tab"
                            aria-controls="nav_carfax_history"
                            aria-selected="false"
                        >CARFAX History</button>
                    </div>
                </nav>

                <!-- Service History -->
                <div class="tab-content" id="service_history_tab_content">
                    <!-- Dealership Service History -->
                    <div
                        class="tab-pane fade show active"
                        id="nav_service_history"
                        role="tabpanel"
                        aria-labelledby="nav_service_history_tab"
                    >
                        <button
                            v-if="!dealershipServiceHistory.length && !isLoading.getPerformedServicesByVehicle"
                            @click="getPerformedServicesByVehicle"
                            type="button"
                            class="btn btn-alpha_primary"
                        >Load Dealership Service History</button>
                        <div v-else-if="isLoading.getPerformedServicesByVehicle">
                            Loading...
                        </div>
                        <template v-else>
                            <div
                                v-if="toyotaCareIntervalCodes.length"
                                class="toyota-care-intervals mb-3"
                            >
                                <ToyotaCareIntervalGraphic
                                    :codes="toyotaCareIntervalCodes"
                                    :mileage="Number(vehicle.estimated_mileage) || 0"
                                />
                            </div>
                            <div
                                v-for="(groupedServices, groupedServicesIndex) in dealershipServiceHistoryGroupedByMonth"
                                :key="groupedServicesIndex"
                                class="performed-services"
                            >
                                <h2 class="performed-services__group-date font-20 sticky-top">
                                    {{ groupedServices.monthYear }}
                                </h2>

                                <div
                                    v-for="(service, serviceIndex) in groupedServices.services"
                                    class="performed-services__group"
                                    :key="serviceIndex"
                                >
                                    <table class="performed-services__service table table-sm table-bordered mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Date
                                                </th>
                                                <th scope="col">
                                                    Mileage
                                                </th>
                                                <th scope="col">
                                                    Code
                                                </th>
                                                <th scope="col">
                                                    RO #
                                                </th>
                                                <th scope="col">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row">
                                                    {{ $moment(service.date).format('MM/DD/YYYY') }}
                                                </td>
                                                <td>{{ service.mileage }}</td>
                                                <td>{{ service.dealerLaborCode }}</td>
                                                <td>{{ service.repair_order_number }}</td>
                                                <td>{{ service.status }}</td>
                                            </tr>
                                            <tr v-if="service.SOCCode">
                                                <td scope="row" colspan="5">
                                                    <strong>Service:</strong> {{ service.SOCCode }}
                                                </td>
                                            </tr>
                                            <tr v-if="service.op_level_comments_tech_comme">
                                                <td scope="row" colspan="5">
                                                    <strong>Comments:</strong>
                                                    {{ service.op_level_comments_tech_comme }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                    </div>

                    <!-- CARFAX History -->
                    <div
                        id="nav_carfax_history"
                        class="tab-pane fade"
                        role="tabpanel"
                        aria-labelledby="nav_carfax_history_tab"
                    >
                        <div v-if="!vehicle.VIN">
                            Unable to retrieve service history for vehicles without a VIN.
                        </div>
                        <button
                            v-else-if="!carfaxServiceHistory.length && !isLoading.getCarfaxServiceHistory"
                            @click="getCarfaxServiceHistory"
                            type="button"
                            class="btn btn-alpha_primary"
                        >Load CARFAX Service History</button>
                        <div v-else-if="isLoading.getCarfaxServiceHistory">
                            Loading...
                        </div>
                        <div
                            v-else
                            v-for="(groupedServices, groupedServicesIndex) in carfaxServiceHistoryGroupedByMonth"
                            :key="groupedServicesIndex"
                            class="performed-services"
                        >
                            <h2 class="performed-services__group-date font-20 sticky-top">
                                {{ groupedServices.monthYear }}
                            </h2>

                            <div
                                v-for="(carfaxServiceResult, index) in groupedServices.services"
                                :key="index"
                                class="performed-services__group"
                            >
                                <table class="performed-services__service table table-sm table-bordered mb-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th v-if="carfaxServiceResult.odometer" scope="col">Mileage</th>
                                            <th scope="col">Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">
                                                {{
                                                    $moment(carfaxServiceResult.displayDate, 'MM/DD/YYYY')
                                                    .format('MM/DD/YYYY')
                                                }}
                                            </td>
                                            <td v-if="carfaxServiceResult.odometer">
                                                {{ carfaxServiceResult.odometer }}
                                            </td>
                                            <td>
                                                <i
                                                    v-if="carfaxServiceResult.type === 'recall'"
                                                    class="fa-solid fa-triangle-exclamation
                                                        text-alpha_danger"
                                                ></i>
                                                {{ titleCase(carfaxServiceResult.type) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" colspan="3">
                                                <strong v-if="carfaxServiceResult.type === 'recall'">
                                                    Recalls:
                                                </strong>
                                                <strong v-else>
                                                    Services Performed:
                                                </strong>
                                                <ul class="mb-0">
                                                    <li
                                                        v-for="(service, serviceIndex)
                                                            in carfaxServiceResult.text"
                                                        :key="serviceIndex"
                                                    >{{ service }}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-alpha_primary"
                    data-dismiss="modal"
                >Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <modal-view-service-history ref="modalViewServiceHistory"></modal-view-service-history>
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalViewServiceHistory.showModal()">Service History</button>
 */

import { titleCase } from '@/filters/stringFormats';
import ToyotaCareIntervalGraphic from "@/components/ui/ToyotaCareIntervalGraphic.vue";

export default {
    components: {
        ToyotaCareIntervalGraphic,
    },

    props: {
        vehicle: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            carfaxServiceHistory: [],

            dealershipServiceHistory: [],

            isLoading: {
                getCarfaxServiceHistory: false,
                getPerformedServicesByVehicle: false,
            },
        }
    },

    computed: {
        carfaxServiceHistoryGroupedByMonth() {
            const groupedServices = [];

            this.carfaxServiceHistory.forEach(service => {
                const currentMonthYear = this.$moment(
                    service.displayDate,
                    'MM/DD/YYYY'
                ).format('MMMM YYYY');

                // Find the index of the current month/year in the groupedServices array
                const index = groupedServices.findIndex(groupedService => {
                    return groupedService.monthYear === currentMonthYear
                });

                // If the current month/year is not in the groupedServices array, add it
                if (index === -1) {
                    groupedServices.push({
                        monthYear: currentMonthYear,
                        services: [service],
                    });
                } else {
                    // If the current month/year is in the groupedServices array, add the service to it
                    groupedServices[index].services.push(service);
                }
            });

            return groupedServices;
        },

        dealershipServiceHistoryGroupedByMonth() {
            const groupedServices = [];

            // Group each service by date
            this.dealershipServiceHistory.forEach(service => {
                const currentMonthYear = this.$moment(
                    service.date,
                    ['YYYY-MM-DD', 'YYYY-MM-DD hh:mm:ss']
                ).format('MMMM YYYY');

                // Find the index of the current month/year in the groupedServices array
                const index = groupedServices.findIndex(
                    groupedService => {
                        return groupedService.monthYear === currentMonthYear
                    }
                );

                if (index === -1) {
                    groupedServices.push({
                        monthYear: currentMonthYear,
                        services: [service],
                    });
                } else {
                    groupedServices[index]
                        .services.push(service);
                }
            });

            // Sort each group of services by date
            groupedServices.sort((a, b) => {
                return new Date(b.monthYear) - new Date(a.monthYear);
            });

            return groupedServices;
        },

        toyotaCareIntervalCodes() {
            const toyotaCareIntervalCodes = [];

            this.dealershipServiceHistory.forEach(service => {
                // Skip if already added
                if (toyotaCareIntervalCodes.includes(service.dealerLaborCode)) {
                    return;
                }

                const toyotaCareCodes = [
                    'KSYN', 'KCON', 'KSUP', 'KFCV', 'KT86', 'KGRC',
                ];

                // Skip if code is not a ToyotaCare interval
                if (!toyotaCareCodes.some(code => service.dealerLaborCode.endsWith(code))) {
                    return;
                }

                toyotaCareIntervalCodes.push(service.dealerLaborCode);
            });

            // Sort results
            toyotaCareIntervalCodes.sort();

            return toyotaCareIntervalCodes;
        },
    },

    watch: {
        vehicle(newVehicle, oldVehicle) {
            if (newVehicle.id !== oldVehicle.id) {
                this.resetServiceHistoryModal();
            }
        },
    },

    methods : {
        /**
         * Imported methods
         */
        titleCase,

        showModal() {
            $('#view_service_history_modal').modal('show');
        },

        hideModal() {
            $('#view_service_history_modal').modal('hide');
        },

        async getPerformedServicesByVehicle() {
            this.isLoading.getPerformedServicesByVehicle = true;

            await axios
                .post(
                    route(
                        'api.unotifi.GetPerformedServicesByVehicleID',
                        {
                            selectedDealer: this.selectedDealerId,
                            vehicleId: this.vehicle.id
                        }
                    )
                )
                .then(response => {
                    if (response.data.success) {
                        this.dealershipServiceHistory = response.data.performedServices;
                    }
                })
                .catch((error) => {
                    const message = error.response?.data?.message
                        || `Unknown error. If this problem persists,
                        please contact support.`;

                    this.$message({
                        type: 'error',
                        message: message,
                    });
                })
                .finally(() => {
                    this.isLoading.getPerformedServicesByVehicle = false;
                });
        },

        async getCarfaxServiceHistory() {
            if (this.carfaxServiceHistory.length) {
                return;
            }

            this.isLoading.getCarfaxServiceHistory = true;

            await axios
                .get(
                    route(
                        'api.ingestionEngine.getCarfaxServiceHistory'
                    ),
                    {
                        params: { vin: this.vehicle.VIN }
                    }
                )
                .then(response => {
                    this.carfaxServiceHistory = response.data;
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `There was an error retrieving the CARFAX history. If this problem persists, please
                            contact support.`,
                    });
                })
                .finally(() => {
                    this.isLoading.getCarfaxServiceHistory = false;
                });
        },

        resetServiceHistoryModal() {
            this.carfaxServiceHistory = [];
            this.dealershipServiceHistory = [];
        },
    },
}
</script>

<style lang="scss" scoped>
.performed-services {
    .performed-services__group-date {
        background-color: var(--alpha_bg);
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--alpha_border);
        margin-bottom: 1em;
        padding-bottom: .5em;
    }
}
</style>
