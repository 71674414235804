<template>
<div>
    <button
        @click="generateRecoveryCodes"
        type="button"
        class="btn btn-alpha_primary"
        :disabled="(
            isLoading.getRecoveryCodes || isLoading.generateRecoveryCodes
        )"
    >
        Generate New Recovery Codes
    </button>

    <div v-if="recoveryCodes.length" class="mt-3">
        <strong>Recovery Codes</strong>

        <ul>
            <li v-for="code in recoveryCodes" :key="code">
                {{ code }}
            </li>
        </ul>
    </div>

    <!-- Password Confirm Modal -->
    <ModalPasswordConfirmation ref="modalPasswordConfirm" />
</div>
</template>

<script>
import ModalPasswordConfirmation from '@/components/modals/ModalPasswordConfirmation.vue';

export default {
    components: { ModalPasswordConfirmation },

    data() {
        return {
            isLoading: {
                generateRecoveryCodes: false,
                getRecoveryCodes: false,
            },

            recoveryCodes: [],
        };
    },

    methods: {
        generateRecoveryCodes() {
            this.isLoading.generateRecoveryCodes = true;

            axios
                .post('/user/two-factor-recovery-codes')
                .then(() => {
                    this.getRecoveryCodes();
                })
                .catch(error => {
                    if (error.response.status === 423) {
                        this.$refs.modalPasswordConfirm.showModal()
                    } else {
                        this.$message.error(
                            'Failed to generate recovery codes'
                        );

                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.generateRecoveryCodes = false;
                });
        },

        getRecoveryCodes() {
            this.isLoading.getRecoveryCodes = true;

            axios
                .get('/user/two-factor-recovery-codes')
                .then(response => {
                    this.recoveryCodes = response.data;
                })
                .catch(error => {
                    this.$message.error(
                        'Failed to retrieve recovery codes'
                    );

                    console.error(error);
                })
                .finally(() => {
                    this.isLoading.getRecoveryCodes = false;
                });
        },
    },
}
</script>
