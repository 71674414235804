export default {
    namespaced: true,

    state: {
        file: '',

        isLoading: {
            getLeadNotesById: false,
            getPhoneCallLogs: false,
            getResultInfo: false,
            updateLeadInfo: false,
            updateResultInfo: false,
        },

        leadNotes: [],

        phoneCallLogFilters: {},

        phoneCallLogs: [],

        resultInfo: null,

        selectedCustomerSendToInput: null,

        smsMessage: '',
    },

    getters: {
        customerASM: (state, getters, rootState) => {
            if (!rootState.global.asms) {
                return null;
            }

            if (!state.resultInfo?.info?.last_contacted_by) {
                return null;
            }

            const foundASM = rootState.global.asms.find(asm => asm.asmId === state.resultInfo.info.last_contacted_by);

            return foundASM ?? null;
        },

        customerInfo: (state) => {
            const customerInfo = {
                address1: state.resultInfo?.info?.primary_address_street ?? '',
                city: state.resultInfo?.info?.primary_address_city ?? '',
                do_not_call: state.resultInfo?.info?.do_not_call ?? 1,
                do_not_email: state.resultInfo?.info?.do_not_email ?? 1,
                do_not_text: state.resultInfo?.info?.do_not_text ?? 1,
                email: state.resultInfo?.info?.email1 ?? '',
                first_name: state.resultInfo?.info?.first_name ?? '',
                last_name: state.resultInfo?.info?.last_name ?? '',
                state: state.resultInfo?.info?.primary_address_state ?? '',
                zip: state.resultInfo?.info?.primary_address_postalcode ?? '',
            };

            return customerInfo;
        },

        customerVehicle: (state) => {
            const customerVehicle = {
                estimated_miles: state.resultInfo?.info?.vehicle_estimated_miles ?? '',
                make: state.resultInfo?.info?.vehicle_make ?? '',
                model: state.resultInfo?.info?.vehicle_model ?? '',
                vin: state.resultInfo?.info?.vehicle_vin ?? '',
                year: state.resultInfo?.info?.year ?? '',
            };

            return customerVehicle;
        },

        customerPhoneNumbers: (state) => {
            const phoneNumbers = [];

            if (state.resultInfo?.info?.phone_mobile) {
                phoneNumbers.push({
                    label: 'Cell Phone',
                    number: state.resultInfo.info.phone_mobile,
                    type: 'cellPhone',
                    uniqueIdentifier: `cellPhone-${state.resultInfo.info.phone_mobile}`,
                });
            }

            if (state.resultInfo?.info?.phone_home) {
                phoneNumbers.push({
                    label: 'Home Phone',
                    number: state.resultInfo.info.phone_home,
                    type: 'homePhone',
                    uniqueIdentifier: `homePhone-${state.resultInfo.info.phone_home}`,
                });
            }

            if (state.resultInfo?.info?.phone_work) {
                phoneNumbers.push({
                    label: 'Work Phone',
                    number: state.resultInfo.info.phone_work,
                    type: 'workPhone',
                    uniqueIdentifier: `workPhone-${state.resultInfo.info.phone_work}`,
                });
            }

            return phoneNumbers;
        },
    },

    actions: {
        /**
         * Get result info
         */
        async getResultInfo({ commit, dispatch, getters, rootState }, { selectedDealerId, leadId }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResultInfo', value: true});
            commit('CLEAR_RESULT_INFO');

            const params = {
                leadId: leadId,
                selectedDealer: selectedDealerId,
            };

            await axios
                .get(route('api.unotifi.getLeadInfo', params))
                .then(response => {
                    if (response.data.success) {
                        commit('SET_RESULT_INFO', response.data.leadInfo);
                        dispatch('setInitialSelectedCustomerSendToInput', response.data.leadInfo.info);

                        // Get phone call logs if dealer has call tracking
                        if (rootState.userSession?.userSession?.hasCallTrackingIntegration) {
                            commit('RESET_PHONE_CALL_LOG_FILTERS');
                            commit('SET_PHONE_CALL_LOG_FILTERS', { attribute: 'phoneNumbers', value: getters.customerPhoneNumbers });
                            dispatch('getPhoneCallLogs');
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResultInfo', value: false});
                });
        },

        /**
         * Update result info
         */
        async updateResultInfo({ commit, dispatch, getters, state }, { selectedDealerId, leadId }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateResultInfo', value: true});

            const params = {
                leadId: leadId,
                selectedDealer: selectedDealerId,
            };

            await axios
                .get(route('api.unotifi.getLeadInfo', params))
                .then(response => {
                    if (response.data.success) {
                        commit('SET_RESULT_INFO', response.data.leadInfo);

                        const selectedPhoneNumberInCustomPhoneNumbers = state.selectedCustomerSendToInput?.optionType === 'pending' ||
                            getters.customerPhoneNumbers.length &&
                            state.selectedCustomerSendToInput?.uniqueIdentifier &&
                            getters.customerPhoneNumbers.some(phoneNumber =>  phoneNumber.uniqueIdentifier === state.selectedCustomerSendToInput.uniqueIdentifier);

                        if (!selectedPhoneNumberInCustomPhoneNumbers) {
                            dispatch('setInitialSelectedCustomerSendToInput', response.data.leadInfo.info);
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateResultInfo', value: false});
                });
        },

        /**
         * Clear the result info
        */
        clearResultInfo({ commit }) {
            commit('CLEAR_RESULT_INFO');
        },

        /**
         * Get lead notes by ID
         */
        async getLeadNotesById({ commit }, { selectedDealerId, leadId }) {
            commit('SET_LEAD_NOTES', []);

            if (!leadId) {
                return;
            }

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getLeadNotesById', value: true});

            await axios
                .get(route('api.unotifi.GetCustomerNotes', { selectedDealer: selectedDealerId, customerId: leadId }))
                .then(response => {
                    commit('SET_LEAD_NOTES', response.data.notes);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getLeadNotesById', value: false});
                });
        },

        /**
         * Get phone call logs
         */
         async getPhoneCallLogs({ commit, state }) {
            commit('SET_PHONE_CALL_LOGS', []);

            // Return if no phone numbers selected or date not set
            if ((!state.phoneCallLogFilters.phoneNumbers && !state.phoneCallLogFilters.phoneNumbers.length) || !state.phoneCallLogFilters.dateFrom) {
                return;
            }

            const phoneNumbers = [];

            state.phoneCallLogFilters.phoneNumbers.forEach(phoneNumber => {
                if (phoneNumber.number.length === 10 && !phoneNumbers.includes(phoneNumber.number)) {
                    phoneNumbers.push(phoneNumber.number);
                }
            });

            // Return if none of the selected phone numbers are valid
            if (!phoneNumbers.length) {
                return;
            }

            const params = {
                phoneNumbers: phoneNumbers,
                dateFrom: state.phoneCallLogFilters.dateFrom,
            };

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPhoneCallLogs', value: true});

            axios
                .get(route('api.unotifi-rest.get-call-logs-by-phone-numbers', { selectedDealer: selectedDealerId }), {params: params})
                .then(response => {
                    commit('SET_PHONE_CALL_LOGS', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPhoneCallLogs', value: false});
                });
        },

        /**
         * Set the initial selected send to input
         */
        async setInitialSelectedCustomerSendToInput({ commit }, leadInfo) {
            let initialSelectedSendToInput = {
                label: 'None',
                number: '',
                type: 'none',
                uniqueIdentifier: '',
            };

            if (leadInfo.phone_mobile) {
                initialSelectedSendToInput = {
                    label: 'Cell Phone',
                    number: leadInfo.phone_mobile,
                    type: 'cellPhone',
                    uniqueIdentifier: `cellPhone-${leadInfo.phone_mobile}`,
                };
            } else if (leadInfo.phone_home) {
                initialSelectedSendToInput = {
                    label: 'Home Phone',
                    number: leadInfo.home_phone,
                    type: 'homePhone',
                    uniqueIdentifier: `homePhone-${leadInfo.home_phone}`,
                };
            } else if (leadInfo.phone_work) {
                initialSelectedSendToInput = {
                    label: 'Work Phone',
                    number: leadInfo.phone_work,
                    type: 'workPhone',
                    uniqueIdentifier: `workPhone-${leadInfo.phone_work}`,
                };
            }

            commit('SET_SELECTED_CUSTOMER_SEND_TO_INPUT', initialSelectedSendToInput);
        },

        /**
         * Update Lead Info
         */
        async updateLeadInfo({ commit, state, dispatch }, { selectedDealerId, keyValueInfoToUpdate }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateLeadInfo', value: true});

            return await axios
                .post(route('api.unotifi.updateLead', { selectedDealer: selectedDealerId, leadId: state.resultInfo.info.id }), keyValueInfoToUpdate)
                .then(response => {
                    dispatch('updateResultInfo', { selectedDealerId: selectedDealerId, leadId: state.resultInfo.info.id });
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = 'There was an error updating the lead info.';

                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;

                        console.error(error.response.data.errors);

                        if (errors.email1) {
                            errorMessage = 'An invalid email address was provided.';
                        }

                        if (errors.phone_home || errors.phone_mobile || errors.phone_work) {
                            errorMessage = 'An invalid phone number was provided.';
                        }

                        if (errors.vehicle_estimated_miles) {
                            errorMessage = 'An invalid number was provided for the vehicle estimated mileage.'
                        }

                        if (errors.primary_address_postalcode) {
                            errorMessage = 'An invalid zip code was provided.'
                        }

                        throw new Error(errorMessage);
                    } else {
                        console.error(error);

                        throw new Error(errorMessage);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateLeadInfo', value: false});
                });
        },
    },

    mutations: {
        SET_RESULT_INFO(state, resultInfo) {
            state.resultInfo = resultInfo;
        },

        CLEAR_RESULT_INFO(state) {
            state.file = '';
            state.leadNotes = [];
            state.resultInfo = null;
            state.selectedCustomerSendToInput = null;
            state.smsMessage = '';
        },

        SET_LEAD_NOTES(state, notes) {
            state.leadNotes = notes;
        },

        SET_CUSTOMER_NOTES(state, notes) {
            state.customerNotes = notes;
        },

        SET_PHONE_CALL_LOGS(state, phoneCallLogs) {
            state.phoneCallLogs = phoneCallLogs;
        },

        SET_SELECTED_CUSTOMER_SEND_TO_INPUT(state, sendToInput) {
            state.selectedCustomerSendToInput = sendToInput;
        },

        SET_SMS_MESSAGE(state, smsMessage) {
            state.smsMessage = smsMessage;
        },

        SET_FILE(state, file) {
            state.file = file;
        },

        SET_PHONE_CALL_LOG_FILTERS(state, { attribute, value }) {
            state.phoneCallLogFilters[attribute] = value;
        },

        RESET_PHONE_CALL_LOG_FILTERS(state) {
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setHours(0,0,0,0);
            ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

            const ninetyDaysAgoString = [
                ninetyDaysAgo.getFullYear(),
                ('0' + (ninetyDaysAgo.getMonth() + 1)).slice(-2),
                ('0' + ninetyDaysAgo.getDate()).slice(-2)
            ].join('-');

            state.phoneCallLogFilters = {
                dateFrom: ninetyDaysAgoString,
                phoneNumbers: [],
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
