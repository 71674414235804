<template>
    <div
        class="navigation-wrapper col-lg-2 col-xl-1 col-left"
        :class="{ expanded: showExpandedNav, narrow: isNavCollapsed }"
    >
        <div
            class="d-md-none py-3 px-3 mx-1 d-flex align-items-center justify-content-around"
        >
            <router-link
                :to="{ name: 'services' }"
                active-class="active"
                class="font-20 font-weight-bolder"
            >
                SERVICE
            </router-link>
            <router-link
                :to="{ name: 'general-bdc' }"
                active-class="active"
                class="font-20 font-weight-bolder ml-4"
            >
                ANALYTICS
            </router-link>
            <button
                v-if="hasAutoMerchantAccount"
                @click="$refs.modalAddManualPayment.showModal()"
                type="button"
                class="btn btn-link font-14 font-weight-bolder px-3 align-self-stretch d-flex align-items-center text-decoration-none"
            >
                <i class="fas fa-sm fa-plus mr-1"></i>
                PAYMENT REQUEST
            </button>
        </div>

        <!-- Services Navigation -->
        <nav
            v-if="$store.state.navigation.service_nav"
            role="navigation"
            class="navigation"
            :class="{ 'nav-edit-mode': isNavEditModeEnabled }"
        >
            <template v-for="(servicesNavLink, index) in servicesNavLinks">
                <router-link
                    v-if="isNavEditModeEnabled || servicesNavLink.enabled"
                    :to="{ name: servicesNavLink.routeName }"
                    custom
                    v-slot="{ href, isActive }"
                    :key="index"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i
                            v-if="servicesNavLink.icon.type === 'material-icon'"
                            class="icon"
                            >{{ servicesNavLink.icon.value }}</i
                        >
                        <i v-else :class="servicesNavLink.icon.value"></i>
                        <strong>{{ servicesNavLink.title }}</strong>
                        <el-switch
                            @click.stop="true"
                            @change="
                                updateServicesNavLinkSetting(servicesNavLink)
                            "
                            v-model="servicesNavLink.enabled"
                            class="nav-enable-input"
                        ></el-switch>
                    </a>
                </router-link>
            </template>
        </nav>

        <!-- Analytics Navigation -->
        <nav
            v-if="$store.state.navigation.analytics_nav"
            role="navigation"
            class="navigation"
        >
            
            <router-link
                :to="{ name: 'analytics-users-activity' }"
                custom
                v-slot="{ href, isActive }"
            >
                <a :href="href" :class="{ active: isActive }">
                    <i class="icon">account_circle</i>
                    <strong>User Activity</strong>
                </a>
            </router-link>
             
            <!-- Metabase: Dealer IQ -->
            <template
                v-if="userSession.metabaseIntegrations?.includes('DEALER-IQ')"
            >
                <router-link
                    :to="{ name: 'general-bdc' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>General BDC</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'bdc-campaign-performance' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>BDC Campaign Perf.</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'bdc-user-performance' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>BDC User Perf.</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'bdc-contacted-opps' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>BDC Contacted Opps</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'bdc-ro-detail' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>BDC RO Detail</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'campaign-performance' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>Campaign Perf.</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'csi' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>CSI</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'advisor-usage' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>Advisor Usage</strong>
                    </a>
                </router-link>
                <router-link
                    :to="{ name: 'case-summary' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>Case Summary</strong>
                    </a>
                </router-link>
            </template>

            <!-- Metabase: Rewards IQ -->
            <template
                v-if="userSession.metabaseIntegrations?.includes('REWARDS-IQ')"
            >
                <router-link
                    :to="{ name: 'rewards-activity' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>Rewards Activity</strong>
                    </a>
                </router-link>
            </template>

            <!-- Metabase: Koons Auto Group -->
            <template
                v-if="
                    userSession.metabaseIntegrations?.includes(
                        'KOONS-AUTO-GROUP'
                    )
                "
            >
                <router-link
                    :to="{ name: 'print-campaign-performance' }"
                    custom
                    v-slot="{ href, isActive }"
                >
                    <a :href="href" :class="{ active: isActive }">
                        <i class="icon">assessment</i>
                        <strong>Print Campaign Perf.</strong>
                    </a>
                </router-link>
            </template>
        </nav>

        <!-- Settings Navigation -->
        <nav
            v-if="$store.state.navigation.settings_nav"
            role="navigation"
            class="navigation"
        >
            <router-link
                :to="{ name: 'settings-account-settings' }"
                custom
                v-slot="{ href, isActive }"
            >
                <a :href="href" :class="{ active: isActive }">
                    <i class="icon">personal</i>
                    <strong>User Account</strong>
                </a>
            </router-link>
            <router-link
                :to="{ name: 'settings-dealership-settings' }"
                custom
                v-slot="{ href, isActive }"
            >
                <a :href="href" :class="{ active: isActive }">
                    <i class="icon">settings</i>
                    <strong>Dealership</strong>
                </a>
            </router-link>
        </nav>

        <!-- Settings Navigation Edit Mode Button -->
        <button
            v-if="$store.state.navigation.service_nav"
            @click="setNavEditMode(!isNavEditModeEnabled)"
            v-click-outside="disableNavEditMode"
            type="button"
            class="navigation-toggle btn"
        >
            <i class="fa-regular fa-sliders"></i>
        </button>

        <br />

        <!-- Navigation Collapse Button -->
        <button
            @click="isNavCollapsed = !isNavCollapsed"
            type="button"
            class="navigation-toggle d-none d-lg-inline btn"
        >
            <i v-if="isNavCollapsed" class="icon">chevron_right</i>
            <i v-else class="icon">chevron_left</i>
        </button>

        <!-- Add Payment Modal -->
        <ModalAddManualPayment
            v-if="hasAutoMerchantAccount"
            ref="modalAddManualPayment"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { preference } from 'vue-preferences';
import ModalAddManualPayment from '@/components/modals/ModalAddManualPayment.vue';

export default {
    data() {
        return {
            isNavEditModeEnabled: false,

            servicesNavLinks: [
                {
                    routeName: 'services-repairs',
                    title: 'Repairs',
                    icon: {
                        type: 'material-icon',
                        value: 'build',
                    },
                    enabled: true,
                },{
                    routeName: 'services-appointments',
                    title: 'Appointments',
                    icon: {
                        type: 'material-icon',
                        value: 'access_alarm',
                    },
                    enabled: true,
                },{
                    routeName: 'services-opportunities',
                    title: 'Opportunities',
                    icon: {
                        type: 'material-icon',
                        value: 'campaign',
                    },
                    enabled: true,
                },{
                    routeName: 'services-search',
                    title: 'Search',
                    icon: {
                        type: 'material-icon',
                        value: 'search',
                    },
                    enabled: true,
                },{
                    routeName: 'services-recent',
                    title: 'Recent',
                    icon: {
                        type: 'material-icon',
                        value: 'chat_bubble',
                    },
                    enabled: true,
                },{
                    routeName: 'services-pending-messages',
                    title: 'Pending',
                    icon: {
                        type: 'material-icon',
                        value: 'event',
                    },
                    enabled: true,
                },{
                    routeName: 'services-cases',
                    title: 'Cases',
                    icon: {
                        type: 'material-icon',
                        value: 'business_center',
                    },
                    enabled: true,
                },{
                    routeName: 'services-leads',
                    title: 'Leads',
                    icon: {
                        type: 'font-awesome',
                        value: 'fas fa-fire-alt',
                    },
                    enabled: true,
                },{
                    routeName: 'chats',
                    title: 'Chats',
                    icon: {
                        type: 'material-icon',
                        value: 'chat_bubble_outline',
                    },
                    enabled: true,
                },
            ],
        };
    },

    components: {
        ModalAddManualPayment,
    },

    props: {
        version: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        isNavCollapsed: preference('isNavCollapsed'),

        hasAutoMerchantAccount() {
            return Boolean(this.userSession?.hasAutoMerchantAccount);
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        showExpandedNav: {
            get() {
                return this.$store.state.navigation.showExpandedNav;
            },
            set(value) {
                this.$store.commit('navigation/SET_EXPANDED_NAV', value);
            }
        },
    },

    watch: {
        $route(to, from) {
            this.showExpandedNav = false;
        },
    },

    created() {
        // Update services navigation links
        this.servicesNavLinks.forEach(service => {
            service.enabled = this.userSession.settings.dealership[`show-page-${service.routeName}`] ?? true;
        });
    },

    methods: {
        setNavEditMode(value) {
            if (value) {
                this.isNavCollapsed = false;
            }

            this.isNavEditModeEnabled = value;
        },

        disableNavEditMode() {
            this.setNavEditMode(false);
        },

        updateServicesNavLinkSetting(navLink) {
            // Renable nav edit mode after v-click-outside has disabled it
            setTimeout(() => {
                this.setNavEditMode(true);
            }, 0);

            const dealershipSettings = {};

            dealershipSettings[`show-page-${navLink.routeName}`] = navLink.enabled;

            axios
                .post(route('api.dealership-settings.updateConfiguredSettings', { selectedDealer: this.selectedDealerId }), dealershipSettings)
                .catch(error => {
                    this.$message({
                        type: 'error',
                        message: 'Unable to save navigation preference.',
                    });
                });
        },
    },
}
</script>
