<template>
    <div class="modal fade" id="click-to-call_modal" tabindex="-1" role="dialog" aria-labelledby="click-to-call_modal_title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="click-to-call_modal_title">Click to Call {{ formattedDestinationPhoneNumber }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="clickToCall">
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Source Phone Number</span>
                            </div>
                            <input v-model="sourcePhoneNumber" v-mask="'(###) ###-####'" class="form-control" placeholder="(555) 555-5555" pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}" required>
                        </div>

                        <button
                            class="btn btn-sm btn-alpha_primary mr-2"
                            type="submit"
                            :disabled="isClickToCallSubmitDisabled"
                        >
                            <template v-if="!isLoading.clickToCall">
                                Click To Call
                            </template>
                            <template v-else>
                                Calling...
                            </template>
                        </button>

                        <el-switch
                            v-model="confirmationPromptEnabled"
                            active-text="Enable Confirmation Prompt"
                        ></el-switch>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { preference } from 'vue-preferences';

export default {
    props: {
        canUseConfirmationPrompt: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            destinationPhoneNumber: '',

            isLoading: {
                clickToCall: false,
            },

            sourcePhoneNumber: '',
        }
    },

    computed: {
        confirmationPromptEnabled: preference('clickToCallConfirmationPromptEnabled', { default: false }),

        isClickToCallSubmitDisabled() {
            if (this.isLoading.clickToCall) return true;
            if (this.strippedSourcePhoneNumber.length !== 10) return true;
            if (this.destinationPhoneNumber.length !== 10) return true;
        },

        // Format found contact info phone number
        formattedDestinationPhoneNumber() {
            const cleaned = ('' + this.destinationPhoneNumber).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }

            return this.destinationPhoneNumber;
        },

        // Strip non-digits from lookupPhoneNumber
        strippedSourcePhoneNumber() {
            return this.sourcePhoneNumber.replace(/\D/g,'');
        },
    },

    methods: {
        showModal(phoneNumber) {
            this.destinationPhoneNumber = phoneNumber;

            $('#click-to-call_modal').modal('show');
        },

        hideModal() {
            $('#click-to-call_modal').modal('hide');
        },

        clickToCall() {
            this.isLoading.clickToCall = true;

            let parameters = {
                confirmationPrompt: (
                    this.confirmationPromptEnabled
                    && this.canUseConfirmationPrompt
                ),
                destinationPhoneNumber: this.destinationPhoneNumber,
                sourcePhoneNumber: this.strippedSourcePhoneNumber,
            };

            axios
                .post(
                    route(
                        'api.unotifi-rest.click-to-call',
                        { selectedDealer: this.selectedDealerId }
                    ),
                    parameters
                )
                .then(response => {
                    this.$message({
                        type: 'success',
                        message: response.data.message
                            ? response.data.message
                            : 'Calling...'
                    });
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: error.response.data.message
                            ? error.response.data.message
                            : 'There was a problem connecting the call.'
                    });
                })
                .finally(() => {
                    this.isLoading.clickToCall = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
//
</style>
