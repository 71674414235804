/**
 * Directive used to add bootstrap tooltips to elements.
 *
 * Usage: <span v-tooltip title="Tooltip text">Hover/click me</span>
 * Alternative Usage: <span v-tooltip="'Tooltip text'">Hover/click me</span>
 * Specify placement: <span v-tooltip:top="'Tooltip text'">Hover/click me</span>
 *
 * The title attribute will take precedence over the value passed to the
 * directive.
 */
export default {
    bind(el, binding) {
        $(el).tooltip({
            title: binding.value ?? el.getAttribute('title') ?? '',
            placement: binding.arg ?? 'top',
            trigger: 'hover',
        });
    },

    unbind(el, binding) {
        $(el).tooltip('dispose');
    },
};
