export default {
    namespaced: true,

    state: {
        userSession: null,

        isLoading: {
            getUserSession: false,
        },
    },

    getters: {
        //
    },

    actions: {
        /**
         * Get user session
         */
        async getUserSession({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getUserSession', value: true});

            await axios
                .get(route('api.getUserSession', { selectedDealer: selectedDealerId }))
                .then(response => {
                    if (response.data.success) {
                        commit('SET_USER_SESSION', response.data.session);
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getUserSession', value: false});
                });
        },
    },

    mutations: {
        SET_USER_SESSION(state, userSession) {
            state.userSession = userSession;
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
