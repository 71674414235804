try {
    // Listen for changes in prefers-color-scheme and update the theme accordingly
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
        const applicationTheme = localStorage.getItem('applicationTheme')?.replace(/['"]+/g, '');

        if (applicationTheme === 'auto') {
            const newColorScheme = event.matches ? 'dark' : 'light';
            document.body.classList.toggle('theme_dark', newColorScheme === 'dark');
        }
    });

    // Check if the prefers-color-scheme feature is supported
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        const applicationTheme = localStorage.getItem('applicationTheme')?.replace(/['"]+/g, '');

        // Return early if theme is not set to auto
        if (applicationTheme === 'auto') {
            document.body.classList.add('theme_dark');
        }
    }

    // Get the user's theme preference from local storage, if available
    const applicationTheme = localStorage.getItem('applicationTheme')?.replace(/['"]+/g, '');

    if (applicationTheme && applicationTheme !== 'auto') {
        switch (applicationTheme) {
            case 'dark':
                document.body.classList.add('theme_dark');
                break;
            // Add more cases for other themes if needed
            default:
                break;
        }
    }
} catch (error) {
    console.error(error);
}
