export default {
    namespaced: true,

    state: {
        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        isFilterResultsFormEmpty: state => {
            if (
                ! state.filterResultsForm.customerNumber &&
                ! state.filterResultsForm.firstName &&
                ! state.filterResultsForm.lastName &&
                ! state.filterResultsForm.phoneNumber &&
                ! state.filterResultsForm.VINNumber
            ) {
                return true;
            }

            return false;
        },
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {
                key:'getResults',
                value: true
            });

            try {
                const response = await axios.post(
                    route(
                        'api.unotifi.GetSearch',
                        { selectedDealer: selectedDealerId }
                    ),
                    parameters
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results.data) {
                state.results = Object.freeze(results.data);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            state.filterResultsForm = {
                currentPage: 1,
                customerNumber: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                VINNumber: '',
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
