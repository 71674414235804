<template>
    <div class="col d-flex flex-column">
        <div class="container-fluid">

            <router-view></router-view>

        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    components: {
        //
    },

    data() {
        return {
            //
        }
    },

    computed: {
        //
    },

    created() {
        //
    },

    mounted() {
        //
    },

    methods : {
        //
    },
}

</script>
