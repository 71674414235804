import './tracker';
import './bootstrap';

import './theme-detection';

import.meta.glob(['../images/**', '../fonts/**', '../media/**']);

/**
 * Import packages
 */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import moment from 'moment-timezone';
import route from 'ziggy';
import routes from './routes/routes';
import simplebar from 'simplebar-vue';
import store from './store';
import VCalendar from 'v-calendar';
import VClickOutside from 'v-click-outside';
import VueClipboard from 'vue-clipboard2';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueMixpanel from 'vue-mixpanel';
import VuePlyr from 'vue-plyr';
import VuePreferences from 'vue-preferences';
import VueRouter from 'vue-router';
import VueSafeHTML from 'vue-safe-html';
import VueTheMask from 'vue-the-mask';
import { asset } from '@codinglabs/laravel-asset';

/**
 * Import plugins
 */
import TitleManager from './plugins/title-manager';

/**
 * Import global components
 * Import components that should be made available globally here,
 * then register them in the Global Components section further down.
 */
import AppLeftNav from '@/components/layout/AppLeftNav.vue';
import AppNotifications from '@/components/ui/AppNotifications.vue';
import AppTopNav from '@/components/layout/AppTopNav.vue';
import DealershipLoginForm from '@/components/login/DealershipLoginForm.vue';
import MessageFlasher from '@/components/ui/MessageFlasher.vue';
import TwoFactorChallenge from '@/components/auth/TwoFactorChallenge.vue';
import TwoFactorManagement from '@/components/auth/TwoFactorManagement/index.vue';

/**
 * Import global directives
 * Import directives that should be made available globally here,
 * then register them in the Global Directives section further down.
 */
import BootstrapTooltipDirective from './directives/bootstrap-tooltip-directive';

/**
 * Create the Vue application
 */
import Vue from 'vue';
window.Vue = Vue;

/**
 * Set the components/plugins/etc to use with Vue
 */
Vue.use(VCalendar, { componentPrefix: 'v' });
Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(VueHtmlToPaper, {
    styles: ['../../print.css'],
});
Vue.use(VueTheMask);
Vue.use(VueMixpanel, {
    token: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
});
Vue.use(VuePlyr, {
    plyr: {},
});
Vue.use(VuePreferences);
Vue.use(ElementUI, { locale });
Vue.use(VueSafeHTML);
Vue.use(TitleManager, {
    faviconNotification: '/icon-notification.svg',
});

/**
 * Add prototypes to Vue
 */
Vue.prototype.$moment = moment;

// Selected Dealership ID required for API calls
Vue.prototype.$selectedDealerId = document.querySelector('meta[name="selected-dealer-id"]')?.content;

// App Name
Vue.prototype.$appName = import.meta.env.VITE_APP_NAME ?? 'Unotifi';

/**
 * Add directives to Vue
 */
Vue.use(VClickOutside);
Vue.directive('tooltip', BootstrapTooltipDirective);

/**
 * Mixins for VueJS
 */
Vue.mixin({
    computed: {
        selectedDealerId() {
            return this.$selectedDealerId;
        },
    },
    methods: {
        asset: asset,
        route: route,
    },
});

// Global Components
Vue.component('app-left-nav', AppLeftNav);
Vue.component('app-notifications', AppNotifications);
Vue.component('app-top-nav', AppTopNav);
Vue.component('dealership-login', DealershipLoginForm);
Vue.component('message-flasher', MessageFlasher);
Vue.component('simplebar', simplebar);
Vue.component('two-factor-challenge', TwoFactorChallenge);
Vue.component('two-factor-management', TwoFactorManagement);

/**
 * Start Bugsnag
 */
Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: import.meta.env.VITE_APP_ENV,
});

// The Bugsnag docs say to add this but then Bugsnag throws the warning:
// installVueErrorHandler() was called unnecessarily
// const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)
// Instead, we'll just add the Bugsnag instance to the window object
window.Bugsnag = Bugsnag;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page.
 */
new Vue({
    el: '#app',
    router: new VueRouter(routes),
    store,
    created() {
        this.checkNavigation();

        const userSession = document.querySelector('meta[name="user-session"]')?.content;

        if (userSession) {
            store.commit('userSession/SET_USER_SESSION', JSON.parse(userSession));
        }
    },
    methods: {
        checkNavigation() {
            if (this.$route.path.indexOf('services') !== -1) {
                this.$store.dispatch('navigation/changeServiceNav');
            } else if (this.$route.path.indexOf('analytics') !== -1) {
                this.$store.dispatch('navigation/changeAnalyticsNav');
            } else if (this.$route.path.indexOf('chats') !== -1) {
                this.$store.dispatch('navigation/changeServiceNav');
            } else {
                this.$store.dispatch('navigation/changeSettingsNav');
            }
        },
    },
    watch: {
        $route: function () {
            this.checkNavigation();
        },
    },
});
