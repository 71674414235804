<template>
    <div class="chat-message chat-message--outgoing chat-message--typing">
        <div v-tooltip:left :title="userName" class="chat-message__user-icon">{{ memberInitials }}</div>
        <div class="chat-message__bubble-wrapper">
            <div class="chat-message__bubble">
                <span><i class="fa-solid fa-2x fa-ellipsis fa-fade"></i></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userName: {
            type: String,
            required: true,
        },
    },

    computed: {
        memberInitials() {
            return this.userName.split(' ').map(n => n[0]).join('');
        },
    },
}
</script>
