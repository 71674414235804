export default {
    namespaced: true,

    state: {
        customerNotes: [],

        customersLastMessageSentiment: 'none',

        errors: {
            getVehicleNotesById: null,
        },

        file: '',
        fileUrls: [],

        isLoading: {
            getCustomerNotesById: false,
            getCustomersLastMessageSentimentById: false,
            getPhoneCallLogs: false,
            getResultInfo: false,
            getVehicleNotesById: false,
            getWarningsByVin: false,
            updateResultInfo: false,
        },

        phoneCallLogFilters: {},

        phoneCallLogs: [],

        resultInfo: null,

        rewardsLinkTinyUrl: null,

        selectedCustomerSendToInput: null,
        selectedResultAppointmentId: null,
        selectedResultASM: null,
        selectedStatusListOpportunity: null,
        selectedVehicleId: null,

        smsMessage: '',

        vehicleNotes: [],
        vehicleWarnings: [],
    },

    getters: {
        customerInitials: state => {
            const firstInitial = state.resultInfo?.Customer?.firstName ? state.resultInfo.Customer.firstName.charAt(0) : '';
            const lastInitial = state.resultInfo?.Customer?.lastName ? state.resultInfo.Customer.lastName.charAt(0) : '';
            const initials = firstInitial || lastInitial ? firstInitial + lastInitial : 'N/A';

            return initials;
        },

        customerPhoneNumbers: (state, getters) => {
            const phoneNumbers = [];

            if (getters.selectedCustomer?.cellPhone) {
                phoneNumbers.push({
                    isValid: getters.selectedCustomer.isValidCellPhone,
                    label: getters.selectedCustomer.cell_phone_label ? getters.selectedCustomer.cell_phone_label
                        : 'Cell Phone',
                    number: getters.selectedCustomer.cellPhone,
                    type: 'cellPhone',
                    uniqueIdentifier: `cellPhone-${getters.selectedCustomer.cellPhone}`,
                });
            }

            if (getters.selectedCustomer?.homePhone) {
                phoneNumbers.push({
                    isValid: getters.selectedCustomer.isValidHomePhone,
                    label: getters.selectedCustomer.home_phone_label ? getters.selectedCustomer.home_phone_label
                        : 'Home Phone',
                    number: getters.selectedCustomer.homePhone,
                    type: 'homePhone',
                    uniqueIdentifier: `homePhone-${getters.selectedCustomer.homePhone}`,
                });
            }

            if (getters.selectedCustomer?.workPhone) {
                phoneNumbers.push({
                    isValid: getters.selectedCustomer.isValidWorkPhone,
                    label: getters.selectedCustomer.work_phone_label ? getters.selectedCustomer.work_phone_label
                        : 'Work Phone',
                    number: getters.selectedCustomer.workPhone,
                    type: 'workPhone',
                    uniqueIdentifier: `workPhone-${getters.selectedCustomer.workPhone}`,
                });
            }

            if (state.resultInfo?.CustomPhoneNumbers) {
                state.resultInfo.CustomPhoneNumbers.forEach(phoneNumber => {
                    phoneNumbers.push({
                        isValid: phoneNumber.isValid,
                        label: phoneNumber.label,
                        number: phoneNumber.phone_number,
                        type: phoneNumber.label,
                        uniqueIdentifier: `${phoneNumber.label}-${phoneNumber.phone_number}`,
                    });
                });
            }

            return phoneNumbers;
        },

        filteredCars: state => {
            const filteredCars = state.resultInfo.Cars.filter(car => {
                return car.sold == 0 || car.id === state.resultInfo.Car.ID;
            });

            return filteredCars;
        },

        selectedCustomer: state => {
            return state.resultInfo?.Customer;
        },

        selectedVehicle: (state, getters) => {
            const selectedVehicle = getters.filteredCars.find(car => car.id === state.selectedVehicleId);
            return selectedVehicle ?? null;
        },

        selectedVehicleOpportunitiesSorted: (state, getters) => {
            const opportunities = [];
            const condensedOpportunities = [];

            if (getters.selectedVehicle) {
                opportunities.push(...getters.selectedVehicle.opportunities);
            } else {
                getters.filteredCars.forEach(filteredCar => {
                    filteredCar.opportunities.forEach(opportunity => {
                        opportunities.push(opportunity);
                    });
                });
            }

            // Condense duplicate opportunities and consolidate activities
            opportunities.forEach(selectedVehicleOpportunity => {
                // See if opportunity exists
                const foundCondensedOpportunityIndex = condensedOpportunities.findIndex(condensedOpportutnity => {
                    return condensedOpportutnity.opp_id === selectedVehicleOpportunity.opp_id;
                });

                // If opportunity doesn't exist, add it
                if (foundCondensedOpportunityIndex === -1) {
                    const copiedOpportunity = Object.assign({}, selectedVehicleOpportunity);

                    // Convert the activity attribute to array of activities
                    copiedOpportunity.activity = copiedOpportunity.activity ? [copiedOpportunity.activity] : [];

                    condensedOpportunities.push(copiedOpportunity);
                }

                // If opportunity already exists, append activity to it
                if (foundCondensedOpportunityIndex > -1 && selectedVehicleOpportunity.activity) {
                    if (! condensedOpportunities[foundCondensedOpportunityIndex].activity.includes(selectedVehicleOpportunity.activity)) {
                        condensedOpportunities[foundCondensedOpportunityIndex].activity.push(selectedVehicleOpportunity.activity);
                    }
                }
            });

            // Sort
            condensedOpportunities.sort((a, b) => {
                const aDate = new Date(a.date_entered);
                const bDate = new Date(b.date_entered);
                return aDate - bDate;
            });

            // Trim opportunities
            const numberToTrim = 5;
            const trimmedOpportunities = condensedOpportunities.slice(-numberToTrim);

            return trimmedOpportunities;
        },
    },

    actions: {
        /**
         * Get result info
         */
        async getResultInfo({ commit, dispatch, getters, rootState }, { selectedDealerId, result }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResultInfo', value: true});
            commit('RESET_ERRORS');
            commit('SET_SELECTED_RESULT_ASM', {asmNumber: result.asm, asms: rootState.global.asms});
            commit('SET_SELECTED_RESULT_APPOINTMENT_ID', result.appointment_id);

            const params = {
                selectedDealer: selectedDealerId,
                appointmentId: result.appointment_id
            };

            if (result.customer_id) params.customerId = result.customer_id;
            if (result.vehicle_id) params.vehicleId = result.vehicle_id;

            await axios
                .get(route('api.unotifi.GetInfo4Appointments', params))
                .then(response => {
                    if (response.data.success) {
                        const cars = response.data.appData.Cars;
                        const carWithAppointment = cars.find(car => car.nextAppointment.app_id === result.appointment_id);

                        commit('SET_RESULT_INFO', response.data.appData);
                        commit('SET_SELECTED_VEHICLE_ID', carWithAppointment ? carWithAppointment.id : null);
                        commit('SET_VEHICLE_NOTES', []);
                        commit('SET_SELECTED_STATUS_LIST_OPPORTUNITY', null);
                        commit('SET_SMS_MESSAGE', '');
                        commit('SET_REWARDS_LINK_TINY_URL', null);
                        commit('SET_FILE', '');
                        commit('SET_FILE_URLS', []);
                        commit('SET_VEHICLE_WARNINGS', []);

                        dispatch('setInitialSelectedCustomerSendToInput', response.data.appData.Customer);
                        dispatch('getCustomersLastMessageSentimentById', { selectedDealerId: selectedDealerId, customerId: response.data.appData.Customer.ID });

                        // Get phone call logs if dealer has call tracking
                        if (rootState.userSession?.userSession?.hasCallTrackingIntegration) {
                            commit('RESET_PHONE_CALL_LOG_FILTERS');
                            commit('SET_PHONE_CALL_LOG_FILTERS', { attribute: 'phoneNumbers', value: getters.customerPhoneNumbers });
                            dispatch('getPhoneCallLogs');
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResultInfo', value: false});
                });
        },

        /**
         * Update result info
         */
        async updateResultInfo({ commit, dispatch, getters, state }, { selectedDealerId, appointmentId, customerId }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateResultInfo', value: true});

            const params = {
                appointmentId: appointmentId,
                customerId: customerId,
                selectedDealer: selectedDealerId
            };

            await axios
                .get(route('api.unotifi.GetInfo4Appointments', params))
                .then(response => {
                    if (response.data.success) {
                        commit('SET_RESULT_INFO', response.data.appData);

                        const selectedPhoneNumberInCustomPhoneNumbers = state.selectedCustomerSendToInput?.optionType === 'pending' ||
                            getters.customerPhoneNumbers.length &&
                            state.selectedCustomerSendToInput?.uniqueIdentifier &&
                            getters.customerPhoneNumbers.some(phoneNumber =>  phoneNumber.uniqueIdentifier === state.selectedCustomerSendToInput.uniqueIdentifier);

                        if (!selectedPhoneNumberInCustomPhoneNumbers) {
                            dispatch('setInitialSelectedCustomerSendToInput', response.data.appData.Customer);
                        }
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateResultInfo', value: false});
                });
        },

        /**
         * Get customer's latest message sentiment
         */
        async getCustomersLastMessageSentimentById({ commit }, { selectedDealerId, customerId }) {
            commit('SET_CUSTOMERS_LAST_MESSAGE_SENTIMENT', 'default');

            if (!customerId) {
                return;
            }

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomersLastMessageSentimentById', value: true});

            await axios
                .post(route('api.unotifi.getCustomersLastRelevantMessageSentiment', { selectedDealer: selectedDealerId }), {customerIds: [customerId]})
                .then(response => {
                    commit('SET_CUSTOMERS_LAST_MESSAGE_SENTIMENT', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomersLastMessageSentimentById', value: false});
                });
        },

        /**
         * Get vehicle warnings by VIN
         */
        async getWarningsByVin({ commit }, { selectedDealerId, vin }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getWarningsByVin', value: true});

            commit('SET_VEHICLE_WARNINGS', []);

            await axios
                .get(route('api.unotifi.GetWarningsByVin', { selectedDealer: selectedDealerId, vin: vin }))
                .then(response => {
                    commit('SET_VEHICLE_WARNINGS', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getWarningsByVin', value: false});
                }
            );
        },

        /**
         * Get vehicle notes by ID
         */
        async getVehicleNotesById({ commit }, { selectedDealerId, vehicleId }) {
            commit('SET_VEHICLE_NOTES', []);
            commit('CLEAR_ERROR', 'getVehicleNotesById');

            if (!vehicleId) {
                return;
            }

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getVehicleNotesById', value: true});

            await axios
                .get(route('api.unotifi.GetVehicleNotes', { selectedDealer: selectedDealerId, vehicleId: vehicleId }))
                .then(response => {
                    commit('SET_VEHICLE_NOTES', response.data.notes);
                })
                .catch(() => {
                    commit('SET_ERROR', {
                        key: 'getVehicleNotesById',
                        value: 'Unable to get vehicle notes. If this issue persists, please contact support.'
                    });
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getVehicleNotesById', value: false});
                });
        },

        /**
         * Get customer notes by ID
         */
        async getCustomerNotesById({ commit }, { selectedDealerId, customerId }) {
            commit('SET_CUSTOMER_NOTES', []);

            if (!customerId) {
                return;
            }

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomerNotesById', value: true});

            await axios
                .get(route('api.unotifi.GetVehicleNotes', { selectedDealer: selectedDealerId, vehicleId: customerId }))
                .then(response => {
                    commit('SET_CUSTOMER_NOTES', response.data.notes);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomerNotesById', value: false});
                });
        },

        /**
         * Get phone call logs
         */
         async getPhoneCallLogs({ commit, state }) {
            commit('SET_PHONE_CALL_LOGS', []);

            // Return if no phone numbers selected or date not set
            if ((!state.phoneCallLogFilters.phoneNumbers && !state.phoneCallLogFilters.phoneNumbers.length) || !state.phoneCallLogFilters.dateFrom) {
                return;
            }

            const phoneNumbers = [];

            state.phoneCallLogFilters.phoneNumbers.forEach(phoneNumber => {
                if (phoneNumber.number.length === 10 && !phoneNumbers.includes(phoneNumber.number)) {
                    phoneNumbers.push(phoneNumber.number);
                }
            });

            // Return if none of the selected phone numbers are valid
            if (!phoneNumbers.length) {
                return;
            }

            const params = {
                phoneNumbers: phoneNumbers,
                dateFrom: state.phoneCallLogFilters.dateFrom,
            };

            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPhoneCallLogs', value: true});

            axios
                .get(route('api.unotifi-rest.get-call-logs-by-phone-numbers', { selectedDealer: selectedDealerId }), {params: params})
                .then(response => {
                    commit('SET_PHONE_CALL_LOGS', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPhoneCallLogs', value: false});
                });
        },

        /**
         * Set the initial selected send to input
         */
        async setInitialSelectedCustomerSendToInput({ commit }, customer) {
            let initialSelectedSendToInput = {
                isValid: false,
                label: 'None',
                number: '',
                type: 'none',
                uniqueIdentifier: '',
            };

            if (customer.cellPhone) {
                initialSelectedSendToInput = {
                    isValid: customer.isValidCellPhone,
                    label: 'Cell Phone',
                    number: customer.cellPhone,
                    type: 'cellPhone',
                    uniqueIdentifier: `cellPhone-${customer.cellPhone}`,
                };
            } else if (customer.homePhone) {
                initialSelectedSendToInput = {
                    isValid: customer.isValidHomePhone,
                    label: 'Home Phone',
                    number: customer.homePhone,
                    type: 'homePhone',
                    uniqueIdentifier: `homePhone-${customer.homePhone}`,
                };
            } else if (customer.workPhone) {
                initialSelectedSendToInput = {
                    isValid: customer.isValidWorkPhone,
                    label: 'Work Phone',
                    number: customer.workPhone,
                    type: 'workPhone',
                    uniqueIdentifier: `workPhone-${customer.workPhone}`,
                };
            }

            commit('SET_SELECTED_CUSTOMER_SEND_TO_INPUT', initialSelectedSendToInput);
        },
    },

    mutations: {
        SET_ERROR(state, {key, value}) {
            state.errors[key] = value;
        },

        CLEAR_ERROR(state, key) {
            state.errors[key] = null;
        },

        RESET_ERRORS(state) {
            Object.keys(state.errors).forEach(key => {
                state.errors[key] = null;
            });
        },

        SET_RESULT_INFO(state, resultInfo) {
            state.resultInfo = resultInfo;
        },

        SET_CUSTOMERS_LAST_MESSAGE_SENTIMENT(state, lastMessageSentiments = 'default') {
            if (lastMessageSentiments === 'default') {
                return state.customersLastMessageSentiment = 'none';
            }

            if (lastMessageSentiments.some(sentiment => sentiment.customerId === state.resultInfo.Customer.ID)) {
                const icon = lastMessageSentiments.find(sentiment => sentiment.customerId === state.resultInfo.Customer.ID).icon;
                return state.customersLastMessageSentiment = icon;
            }
        },

        SET_VEHICLE_NOTES(state, notes) {
            state.vehicleNotes = notes;
        },

        SET_CUSTOMER_NOTES(state, notes) {
            state.customerNotes = notes;
        },

        SET_SELECTED_VEHICLE_ID(state, vehicleId) {
            state.selectedVehicleId = vehicleId;
        },

        SET_PHONE_CALL_LOGS(state, phoneCallLogs) {
            state.phoneCallLogs = phoneCallLogs;
        },

        SET_SELECTED_CUSTOMER_SEND_TO_INPUT(state, sendToInput) {
            state.selectedCustomerSendToInput = sendToInput;
        },

        SET_SELECTED_STATUS_LIST_OPPORTUNITY(state, statusListOpportunity) {
            state.selectedStatusListOpportunity = statusListOpportunity;
        },

        SET_SELECTED_RESULT_APPOINTMENT_ID(state, appointmentId) {
            state.selectedResultAppointmentId = appointmentId;
        },

        SET_SELECTED_RESULT_ASM(state, {asmNumber, asms}) {
            const foundASM = asms.find(asm => asm.asmNumber === asmNumber);
            state.selectedResultASM = foundASM ?? null;
        },

        SET_SMS_MESSAGE(state, smsMessage) {
            state.smsMessage = smsMessage;
        },

        SET_REWARDS_LINK_TINY_URL(state, tinyUrl) {
            state.rewardsLinkTinyUrl = tinyUrl;
        },

        SET_FILE(state, file) {
            state.file = file;
        },

        SET_FILE_URLS(state, fileUrls) {
            state.fileUrls = fileUrls;
        },

        SET_PHONE_CALL_LOG_FILTERS(state, { attribute, value }) {
            state.phoneCallLogFilters[attribute] = value;
        },

        SET_VEHICLE_WARNINGS(state, vehicleWarnings) {
            state.vehicleWarnings = vehicleWarnings;
        },

        RESET_PHONE_CALL_LOG_FILTERS(state) {
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setHours(0,0,0,0);
            ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

            const ninetyDaysAgoString = [
                ninetyDaysAgo.getFullYear(),
                ('0' + (ninetyDaysAgo.getMonth() + 1)).slice(-2),
                ('0' + ninetyDaysAgo.getDate()).slice(-2)
            ].join('-');

            state.phoneCallLogFilters = {
                dateFrom: ninetyDaysAgoString,
                phoneNumbers: [],
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
