<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a
                            href="#filters-results"
                            class="font-20"
                            data-toggle="collapse"
                            aria-expanded="true"
                            @click="servicesResultSize"
                        >Cases</a>

                        <ServicesFavoriteButton
                            servicePageName="services-cases"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="
                            filterResultsFormCurrentPage = 1;
                            $_generalMixin_scrollSimpleBarResultsList();
                            submitFilterResultsForm({clearResults: true})
                        ">
                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Opened By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Opened By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormOpenedByASMIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [
                                                { asmName: 'Select Options', asmId: 'select-options' },
                                                ...asms
                                            ]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmId"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div
                                                v-if="asm.asmId === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByASMIds = asms.map(
                                                        item => item.asmId
                                                    )"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByASMIds = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>
                                                {{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Case Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Case Number</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormCaseNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Subject -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Subject</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormSubject"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormFirstName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormLastName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Opened/Closed -->
                            <div class="text-center">
                                <el-checkbox-group
                                    v-model="filterResultsFormStatus"
                                    :min="1"
                                    :max="2"
                                >
                                    <el-checkbox
                                        v-for="(status, index) in ['Open', 'Closed']"
                                        :label="status"
                                        :key="index"
                                    >{{ status }}</el-checkbox>
                                </el-checkbox-group>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button
                                        type="submit"
                                        class="btn btn-alpha_primary btn-block btn-lg"
                                        :disabled="isLoadingResults.getResults"
                                    >
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button
                                        @click="
                                            resetFilterResultsForm();
                                            $_generalMixin_scrollSimpleBarResultsList();
                                            submitFilterResultsForm({clearResults: true})
                                        "
                                        type="button"
                                        class="btn btn-alpha_secondary btn-block btn-lg"
                                    >
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar
                    ref="resultsList"
                    id="results_list"
                    class="overflow-auto clients-list-wrapper flex-grow-1 pt-3"
                    :style="servicesResultCss"
                >
                    <ul v-if="results.cases && results.cases.length" class="list-unstyled clients-list">
                        <li
                            v-for="(result, index) in results.cases"
                            @click="routeReplace(result);"
                            :class="{
                                'active': isResultSelected(result.case_id),
                                'unread': $_generalMixin_getMessageStatusByCustomerId(
                                    result.custID, notifications.notifications
                                ) === 'unread',
                                'closed': result.status === 'Closed',
                                'warning': result.status !== 'Closed'
                            }"
                            :key="index"
                            :id="result.custID"
                        >
                            <!-- Customer/Date -->
                            <div class="clients-list__name flex-grow-1">
                                <p class="font-14">{{ result.cust }}</p>
                                <template v-if="result.v_model || result.v_make">
                                    <strong>{{ result.v_make }} {{ result.v_model }}</strong>
                                    <br>
                                </template>
                                <template v-if="result.subject">
                                    <strong>Subject: </strong>{{ result.subject }}
                                    <br>
                                </template>
                                <strong>Priority: </strong>{{ result.priority }}<br>
                                <strong>Case #: </strong>{{ result.case_number }}<br>
                                <strong>Status: </strong>{{ result.status }}<br>
                                <strong>Assigned To: </strong>{{ result.assignedTo }}<br>
                                {{ $moment(result.date).format('MM/DD/YY @h:mm A') }}
                            </div>

                            <!-- Customer Info -->
                            <div class="clients-list__info">
                                <!-- Sentiment -->
                                <div
                                    v-if="!isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                    class="mood mood-sm mx-1"
                                    :class="`mood-${$_generalMixin_getCustomerMoodSettings(
                                        result.custID, customersSentiments
                                    ).color}`"
                                >
                                    <i
                                        :class="$_generalMixin_getCustomerMoodSettings(
                                            result.custID, customersSentiments
                                        ).icon"
                                        title="Customer's mood guessed from their recent correspondence"
                                    ></i>
                                </div>
                                <div v-else class="mood mood-sm mx-1">
                                    <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                </div>

                                <!-- Notifications -->
                                <div class="clients-list__notification">
                                    <div
                                        v-if="$_generalMixin_getMessageStatusByCustomerId(
                                            result.custID, notifications.notifications
                                        ) === 'unread'"
                                        class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1 badge-notify"
                                    >
                                        <i class="icon">textsms</i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {
                        filterResultsFormCurrentPage = currentPage;
                        $_generalMixin_scrollSimpleBarResultsList();
                        submitFilterResultsForm({clearResults: true})
                    }"
                />
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <CaseInfo />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import { mapPreferences } from 'vue-preferences';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import CaseInfo from '@/components/services/CaseInfo.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        CaseInfo,
        ResultsPagination,
        ServicesFavoriteButton
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filter selection options
            filterResultsFormOptions: {},

            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 55vh',
        }
    },

    computed: {
        ...mapState('services/cases', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapState('services/caseInfo', {
            caseInfo: 'caseInfo',
            isLoadingResultInfo: 'isLoading',
            resultInfo: 'resultInfo',
        }),

        ...mapGetters('services/caseInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapPreferences({
            cases_searchForm_openedBy: {
                defaultValue: null
            },
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCaseNumber: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.caseNumber;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'caseNumber', value: value });
            }
        },

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.dateFromTo;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'dateFromTo', value: value });
            }
        },

        filterResultsFormOpenedByASMIds: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.openedByASMIds;
            },
            set(value) {
                this.cases_searchForm_openedBy = Array.isArray(value) && value.length === 1 ? value[0] : null;

                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'openedByASMIds', value: value });
            }
        },

        filterResultsFormSubject: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.subject;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'subject', value: value });
            }
        },

        filterResultsFormFirstName: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.firstName;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'firstName', value: value });
            }
        },

        filterResultsFormLastName: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.lastName;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'lastName', value: value });
            }
        },

        filterResultsFormStatus: {
            get() {
                return this.$store.state.services.cases.filterResultsForm.status;
            },
            set(value) {
                this.$store.commit('services/cases/SET_FILTERS', { attribute: 'status', value: value });
            }
        },
    },

    watch: {
        /**
         * Get result info when URL query parameter changes
         */
        $route(to, from) {
            if (to.query.customer_id && to.query.case_id) {
                this.loadResultInfo(
                    this.selectedDealerId,
                    to.query.customer_id,
                    to.query.case_id,
                    to.query.vehicle_id ?? null
                );
            }
        },
    },

    created() {
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        // Get result info if query param exists
        if (this.$route.query.customer_id && this.$route.query.case_id) {
            this.loadResultInfo(
                this.selectedDealerId,
                this.$route.query.customer_id,
                this.$route.query.case_id,
                this.$route.query.vehicle_id ?? null
            );
        }

        if (! this.results.cases) {
            this.submitFilterResultsForm(this.filterResultsParams);
        }
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex services actions
        ...mapActions('services', {
            getListCampaigns: 'getListCampaigns',
        }),

        // Vuex cases actions
        ...mapActions('services/cases', {
            getResults: 'getResults',
        }),

        // Vuex caseInfo actions
        ...mapActions('services/caseInfo', {
            getCaseInfo: 'getCaseInfo',
            getResultInfo: 'getResultInfo',
        }),

        // Vuex cases mutations
        ...mapMutations('services/cases', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.cases.forEach(result => {
                        if (result.custID && ! foundCustomerIds.includes(result.custID)) {
                            foundCustomerIds.push(result.custID);
                        }

                        this.asms.forEach(assigned => {
                           if(assigned.asmId === result.assigned_user_id){
                               result.assignedTo = assigned.asmName
                           }
                       });
                    });

                    this.getCustomersLastMessageSentimentsByIds({
                        selectedDealerId: this.selectedDealerId,
                        customerIds: foundCustomerIds
                    });

                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        loadResultInfo(selectedDealerId, customerId, caseId, vehicleId = null) {
            this.getResultInfo({
                selectedDealerId: selectedDealerId,
                customerId: customerId,
                caseId: caseId,
                vehicleId: vehicleId
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();
            const autoRefreshInterval = this.userSession
                ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000
                : 60000;

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, autoRefreshInterval);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/cases/RESET_FILTERS');
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(caseId) {
            return this.caseInfo && caseId == this.caseInfo.case_id;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive ? 'max-height: 80vh' : 'max-height: 55vh';
            this.filerResultActive = !this.filerResultActive;
        },

        routeReplace(result){
            return this.$router
                .replace({'query': {
                    'customer_id': result.custID,
                    'case_id': result.case_id,
                    'vehicle_id': result.vehicle_id
                }})
                .catch((error)=>{
                    if (error.from?.fullPath && error.to?.fullPath && error.from.fullPath === error.to.fullPath) {
                        this.loadResultInfo(
                            this.selectedDealerId,
                            result.custID,
                            result.case_id,
                            result.vehicle_id
                        );
                    }
                });
        }
    },
}
</script>
