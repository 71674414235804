export const formatPhoneNumber = (phoneNumber) => {
    if (!(typeof phoneNumber === 'string' || phoneNumber instanceof String)) {
        phoneNumber = phoneNumber.toString();
    }

    return phoneNumber
        .replace(/[^0-9]/g, '')
        .slice(-10)
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
