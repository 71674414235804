import Vue from 'vue';
import Vuex from 'vuex';

// Import Stores
import chats from './modules/chats/chats';
import chatInfo from './modules/chats/chatInfo';
import global from './modules/global';
import navigation from './modules/navigation';
import services from './modules/services/services';
import userSession from './modules/userSession';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: import.meta.env.APP_ENV !== 'production',
    modules: {
        chats,
        chatInfo,
        global,
        navigation,
        services,
        userSession,
    },
});
