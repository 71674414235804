<template>
    <div class="row">
        <div class="result-info-wrapper py-3 scrolling-column order-2 order-md-1">

            <div class="col h-100 d-flex flex-column flex-grow-1 flex-shrink-1">
                <div class="row mb-2 flex-shrink-0">
                    <div class="col-12">
                        <div class="font-weight-bolder border rounded py-2 px-3 user-details">
                            <div class="row header-info">
                                <div class="col-12 col-sm-6">
                                    <!-- Title -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">Title</span>
                                        <p>
                                            {{ resultInfo.info.title ? resultInfo.info.title : 'N/A' }}
                                        </p>
                                    </div>

                                    <!-- Status -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center border-bottom py-1">
                                      <span class="text-uppercase">Status</span>
                                      <p>{{ resultInfo.info.status ? resultInfo.info.status : 'N/A' }}</p>
                                    </div>

                                    <!-- Source -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center py-1">
                                        <span class="text-uppercase">Source</span>
                                        <p>
                                            <template v-if="resultInfo.info.detailsurl">
                                                <a :href="resultInfo.info.detailsurl" target="_blank">
                                                    {{ resultInfo.info.source ? resultInfo.info.source : 'Details Website' }}
                                                </a>
                                            </template>
                                            <template v-else>
                                                {{ resultInfo.info.source ? resultInfo.info.source : 'N/A' }}
                                            </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6">
                                    <!-- Vehicle -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">Vehicle</span>
                                        <p>
                                            {{ resultInfo.info.year }}
                                            {{ resultInfo.info.vehicle_make }}
                                            {{ resultInfo.info.vehicle_model }}
                                            {{ !resultInfo.info.year && !resultInfo.info.vehicle_make && !resultInfo.info.vehicle_model ? 'N/A' : ''}}
                                        </p>
                                    </div>

                                    <!-- VIN Number -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">VIN</span>
                                        <p>
                                            {{ resultInfo.info.vehicle_vin ? resultInfo.info.vehicle_vin : 'N/A' }}
                                            <button
                                                v-if="resultInfo.info.vehicle_vin"
                                                @click="$_copyMethods_copyText(resultInfo.info.vehicle_vin)"
                                                class="btn badge badge-alpha_neutral--shift"
                                                type="button"
                                                title="Copy customer name"
                                            ><i class="icon">file_copy</i></button>
                                        </p>
                                    </div>

                                    <!-- Estimated Miles -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between align-items-center align-items-sm-start align-items-md-center align-items-lg-start align-items-xl-center py-1">
                                        <span class="text-uppercase">Estimated Miles</span>
                                        <input
                                            v-model="vehicleEstimatedMileage"
                                            @change="updatevehicleEstimatedMileage($event.target.value)"
                                            class="input-editable hide-spin-buttons"
                                            type="number"
                                            min="0"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Result Info Navigation -->
                <div class="d-flex justify-content-between align-items-center border-bottom flex-shrink-0">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <!-- All -->
                        <li class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'all'"
                                class="nav-link active"
                                id="all-tab"
                                data-toggle="tab"
                                href="#all"
                                role="tab"
                                aria-controls="all"
                                aria-selected="false"
                            >All</a>
                        </li>

                        <!-- Messages -->
                        <li class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'messages'"
                                class="nav-link"
                                id="messages-tab"
                                data-toggle="tab"
                                href="#messages"
                                role="tab"
                                aria-controls="messages"
                                aria-selected="true"
                            >Messages</a>
                        </li>

                        <!-- Notes -->
                        <li class="nav-item">
                            <a
                                @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), selectedTabId = 'notes'"
                                class="nav-link"
                                id="notes-tab"
                                data-toggle="tab"
                                href="#notes"
                                role="tab"
                                aria-controls="notes"
                                aria-selected="false"
                            >Notes</a>
                        </li>
                    </ul>

                    <button @click="$htmlToPaper(selectedTabId)" type="button" class="btn badge badge-alpha_neutral--shift"><i class="icon">print</i> Print</button>
                </div>

                <!-- Tab Box -->
                <simplebar id="simplebar_tab_content" class="scrolling-wrapper">
                    <div class="tab-content" id="myTabContent">
                        <!-- All -->
                        <div class="tab-pane fade py-2 px-0 show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="container-fluid">
                                <template v-if="allCommunications.length">
                                    <template v-for="(communication, index) in allCommunications">
                                        <!-- SMS -->
                                        <lead-message
                                            v-if="communication.communicationType === 'sms'"
                                            :key="index"
                                            :customer-info="customerInfo"
                                            :sms='communication'
                                        />

                                        <!-- Activity Log -->
                                        <activity-log
                                            v-if="communication.communicationType === 'activity'"
                                            :key="index"
                                            :activity-name="communication.name"
                                            :asm="{firstName: communication.modified_by_name, lastName: ''}"
                                            :date-time="communication.date_modified"
                                        />

                                        <!-- Lead Notes -->
                                        <div v-if="communication.communicationType === 'leadNote'" :key="index" class="communication communication--vehicle-note">
                                            <div class="communication__icon">
                                                <div class="icon">note</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Lead Note</strong>
                                                    <p>{{ communication.description }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.full_name }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Phone Call Logs -->
                                        <phone-call-log
                                            v-if="communication.communicationType === 'phoneCallLog'"
                                            :key="index"
                                            :phone-call="communication"
                                        />

                                        <!-- Customer Notes -->
                                        <div v-if="communication.communicationType === 'customerNote'" :key="index" class="communication communication--customer-note">
                                            <div class="communication__icon">
                                                <div class="icon">note</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Customer Note</strong>
                                                    <p>{{ communication.description }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.full_name }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <!-- No Communications -->
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No communications found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <!-- SMS Chat Log -->
                        <div class="tab-pane fade py-2 px-0" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <div class="container-fluid">
                                <template v-if="leadSMSMessages.length">
                                    <lead-message
                                        v-for="(sms, index) in leadSMSMessages"
                                        :key="index"
                                        :customer-info="customerInfo"
                                        :sms='sms'
                                    />
                                </template>
                                <div v-else>No messages to display</div>
                            </div>
                        </div>

                        <!-- Phone Calls Log -->
                        <result-info-tab-phone-call-logs
                            v-if="userSession && userSession.hasCallTrackingIntegration"
                            :customer-phone-numbers="customerPhoneNumbers"
                            :date-from="phoneCallLogFiltersDateFrom"
                            :is-loading-get-phone-call-logs="!!isLoading.getPhoneCallLogs"
                            :phone-call-logs="phoneCallLogs"
                            :phone-numbers="phoneCallLogFiltersPhoneNumbers"
                            @updateDateFrom="(dateFrom) => phoneCallLogFiltersDateFrom = dateFrom"
                            @updatePhoneNumbers="(phoneNumbers) => phoneCallLogFiltersPhoneNumbers = phoneNumbers"
                            @getPhoneCallLogs="getPhoneCallLogs"
                        />

                        <!-- Notes -->
                        <div class="tab-pane fade py-2 px-0" id="notes" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="container-fluid">
                                <template v-if="(! isLoadingLeadInfo.getCustomerNotesById && ! isLoadingLeadInfo.getLeadNotesById) && selectedVehicleAndCustomerNotes.length">
                                    <div v-for="(note, index) in selectedVehicleAndCustomerNotes" :key="index" class="communication communication--activity">
                                        <div class="communication__icon">
                                            <div class="icon">note</div>
                                        </div>
                                        <div class="communication__content">
                                            <div class="communication__header">
                                                <strong v-if="note.communicationType === 'leadNote'">Lead Note</strong>
                                                <strong v-else-if="note.communicationType === 'customerNote'">Customer Note</strong>
                                                <p>{{ note.description }}</p>
                                            </div>
                                            <div class="communication__item">
                                                {{ note.full_name }}
                                            </div>
                                            <div class="communication__item">
                                                <small class="white-space-nowrap">
                                                    {{ $moment(note.date_modified).format('MM/DD/YY @h:mm A') }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoadingLeadInfo.getCustomerNotesById || isLoadingLeadInfo.getLeadNotesById">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No notes found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </simplebar>

                <!-- Lead SMS Composition -->
                <lead-sms-composition
                    :asm="customerASM"
                    :customer="customerInfo"
                    :file="file"
                    :lead-id="resultInfo.info.id"
                    :phone-number="selectedCustomerSendToInput"
                    :phone-numbers="customerPhoneNumbers"
                    :sms-message="smsMessage"
                    :vehicle="customerVehicle"
                    component-name="Leads"
                    @updateSendToInput="updateSendToInput"
                    @updateSMSMessage="updateSMSMessage"
                    @updateFile="updateFile"
                    @success="updateSMSMessages"
                />
            </div>
        </div>

        <div class="customer-info-wrapper order-1 order-md-2 bg-alpha_bg">
            <lead-customer-info
                @update-customer-notes="getLeadNotesById({ selectedDealerId: selectedDealerId, leadId: resultInfo.info.id })"
                @update-result-info="callUpdateResultInfoMethod()"
            />
        </div>
    </div>
</template>

<script>
import LeadCustomerInfo from '@/components/services/Customer/LeadCustomerInfo.vue';
import LeadMessage from '@/components/services/child-components/LeadMessage.vue';
import LeadSMSComposition from '@/components/services/child-components/LeadSMSComposition.vue';
import PhoneCallLog from '@/components/services/child-components/PhoneCallLog.vue';
import ResultInfoTabPhoneCallLogs from '@/components/services/child-components/ResultInfoTabPhoneCallLogs.vue';
import { generalMixin } from '@/mixins/generalMixin';
import { copyMethods } from '@/mixins/helperMixins';
import { servicesInfoMixin } from '@/mixins/servicesInfoMixin';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    components: {
        'lead-sms-composition': LeadSMSComposition,
        'lead-message': LeadMessage,
        'lead-customer-info': LeadCustomerInfo,
        'phone-call-log': PhoneCallLog,
        'result-info-tab-phone-call-logs': ResultInfoTabPhoneCallLogs,
    },

    mixins: [copyMethods, generalMixin, servicesInfoMixin],

    data() {
        return {
            isLoading: {
                //
            },

            selectedTabId: 'all',

            vehicleEstimatedMileage: null,
        }
    },

    computed: {
        ...mapState('services/leadInfo', {
            file: 'file',
            isLoadingLeadInfo: 'isLoading',
            leadNotes: 'leadNotes',
            phoneCallLogs: 'phoneCallLogs',
            resultInfo: 'resultInfo',
            selectedCustomerSendToInput: 'selectedCustomerSendToInput',
            smsMessage: 'smsMessage',
        }),

        ...mapGetters('services/leadInfo', {
            customerASM: 'customerASM',
            customerInfo: 'customerInfo',
            customerPhoneNumbers: 'customerPhoneNumbers',
            customerVehicle: 'customerVehicle',
        }),

        leadActivityLog() {
            if (!this.resultInfo.activity) {
                return [];
            }
            return this.resultInfo.activity;
        },

        leadSMSMessages() {
            if (!this.resultInfo.messages) {
                return [];
            }
            return this.resultInfo.messages;
        },

        selectedVehicleAndCustomerNotes() {
            const notes = [];

            // Push vehicle notes to array
            this.leadNotes.forEach(leadNote => {
                leadNote.communicationType = 'leadNote';
                notes.push(leadNote);
            });

            // Sort notes
            notes.sort((a, b) => {
                const aDate = a.date_modified ? a.date_modified : '';
                const bDate = b.date_modified ? b.date_modified : '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });

            return notes;
        },

        allCommunications() {
            const allCommunications = [];

            // Push activities to array
            this.leadActivityLog.forEach(activity => {
                activity.communicationType = 'activity';
                allCommunications.push(activity);
            });

            // Push SMS messages to array
            this.leadSMSMessages.forEach(message => {
                message.communicationType = 'sms';
                allCommunications.push(message);
            });

            // Push lead notes to array
            this.leadNotes.forEach(leadNote => {
                leadNote.communicationType = 'leadNote';
                allCommunications.push(leadNote);
            });

            // Push phone call logs to array
            this.phoneCallLogs.forEach(phoneCallLog => {
                phoneCallLog.communicationType = 'phoneCallLog';
                allCommunications.push(phoneCallLog);
            });

            // Sort all communications array by date
            allCommunications.sort((a, b) => {
                const aDate = a.DateTime ? a.DateTime
                    : a.date_modified_timezone ? a.date_modified_timezone
                    : a.date_modified ? a.date_modified
                    : a.start_date_time ? a.start_date_time
                    : '';
                const bDate = b.DateTime ? b.DateTime
                    : b.date_modified_timezone ? b.date_modified_timezone
                    : b.date_modified ? b.date_modified
                    : b.start_date_time ? b.start_date_time
                    : '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });


            return allCommunications;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        phoneCallLogFiltersDateFrom: {
            get() {
                return this.$store.state.services.leadInfo.phoneCallLogFilters.dateFrom;
            },
            set(value) {
                this.$store.commit('services/leadInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'dateFrom', value: value });
            }
        },

        phoneCallLogFiltersPhoneNumbers: {
            get() {
                return this.$store.state.services.leadInfo.phoneCallLogFilters.phoneNumbers;
            },
            set(value) {
                this.$store.commit('services/leadInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'phoneNumbers', value: value });
            }
        },
    },

    watch: {
        // Perform actions when selected vehicle SMS messages change
        leadSMSMessages() {
            this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
            if (! this.isLoadingGlobal.markSMSNotificationsRead) {
                this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.leadSMSMessages, this.resultInfo.info.id);
            }
        },

        // Perform actions when vehicle notes change
        leadNotes() {
            this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
        },

        // Perform actions when SMS notifications change
        smsNotifications: {
            handler(newValue){
                this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.leadSMSMessages, this.resultInfo.info.id);
            }, deep: true,
        },
    },

    created() {
        // Set the vehicle estimated mileage input field
        this.vehicleEstimatedMileage = this.resultInfo.info.vehicle_estimated_miles;

        // Find and mark sms notifications as read
        this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.leadSMSMessages, this.resultInfo.info.id);
    },

    mounted() {
        // Scroll message box to bottom
        this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250});

        // Get lead notes
        this.getLeadNotesById({ selectedDealerId: this.selectedDealerId, leadId: this.resultInfo.info.id });
    },

    methods: {
        /**
         * Vuex leadInfo actions
         */
        ...mapActions('services/leadInfo', {
            getLeadNotesById: 'getLeadNotesById',
            getPhoneCallLogs: 'getPhoneCallLogs',
            updateLeadInfo: 'updateLeadInfo',
            updateResultInfo: 'updateResultInfo',
        }),

        /**
         * Call updateResultInfo method with required parameters
         */
        callUpdateResultInfoMethod() {
            this.updateResultInfo({ selectedDealerId: this.selectedDealerId, leadId: this.resultInfo.info.id });
        },

        /**
         * Update send to input
         */
        updateSendToInput(sendToInput) {
            this.$store.commit('services/leadInfo/SET_SELECTED_CUSTOMER_SEND_TO_INPUT', sendToInput);
        },

        /**
         * Update SMS message in store
         */
        updateSMSMessage(message) {
            this.$store.commit('services/leadInfo/SET_SMS_MESSAGE', message);
        },

        /**
         * Update file in store
         */
        updateFile(file) {
            this.$store.commit('services/leadInfo/SET_FILE', file);
        },

        /**
         * Update SMS message log
         */
        updateSMSMessages() {
            this.callUpdateResultInfoMethod();
            this.$store.commit('services/leadInfo/SET_SMS_MESSAGE', '');
            this.$store.commit('services/leadInfo/SET_FILE', '');
        },

        /**
         * Update vehicle estimated mileage
         */
        updatevehicleEstimatedMileage(value) {
            this.updateLeadInfo({selectedDealerId: this.selectedDealerId, keyValueInfoToUpdate: {vehicle_estimated_miles: value}})
                .then(response => {
                    //
                })
                .catch(error => {
                    this.vehicleEstimatedMileage = this.resultInfo.info.vehicle_estimated_miles;

                    this.$message({
                        type: 'error',
                        message: error.message
                    });
                });
        },
    },
}

</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';

.input-editable {
    background: var(--alpha_bg);
    border: 0;
    border-bottom: 1px solid var(--alpha_border);
    border-radius: 0;
    text-align: right;

    &:focus {
        background: var(--alpha_bg--shift);
        outline: 0;
    }
}
</style>
