import appointmentInfo from './appointmentInfo';
import appointments from './appointments';
import caseInfo from './caseInfo';
import cases from './cases';
import leadInfo from './leadInfo';
import leads from './leads';
import opportunities from './opportunities';
import opportunityInfo from './opportunityInfo';
import pendingMessageInfo from './pendingMessageInfo';
import pendingMessages from './pendingMessages';
import recentResultInfo from './recentResultInfo';
import recentResults from './recentResults';
import repairOrderInfo from './repairOrderInfo';
import repairOrders from './repairOrders';
import searchResultInfo from './searchResultInfo';
import searchResults from './searchResults';

export default {
    namespaced: true,

    modules: {
        appointmentInfo,
        appointments,
        caseInfo,
        cases,
        leadInfo,
        leads,
        opportunities,
        opportunityInfo,
        pendingMessageInfo,
        pendingMessages,
        recentResultInfo,
        recentResults,
        repairOrderInfo,
        repairOrders,
        searchResultInfo,
        searchResults,
    },

    state: {
        campaignList: [],

        customersSentiments: [],

        isLoading: {
            getCustomersLastMessageSentimentsByIds: false,
            getListCampaigns: false,
            getPredefinedSMSMessages: false,
            getStatusListOpportunities: false,
        },

        predefinedSMSMessages: [],

        statusListOpportunities: {
            leadsStatusList: [],
            statusList: [],
        },
    },

    actions: {
        /**
         * Get customers' latest message sentiments
         */
        async getCustomersLastMessageSentimentsByIds({ commit, state }, { selectedDealerId, customerIds }) {
            const missingCustomerSentimentsIds = [];

            customerIds.forEach(customerId => {
                const isFound = state.customersSentiments.some(customerSentiment => {
                    return customerSentiment.customerId === customerId;
                });

                if (! isFound) {
                    missingCustomerSentimentsIds.push(customerId);
                }
            });

            if (missingCustomerSentimentsIds.length) {
                commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomersLastMessageSentimentsByIds', value: true});

                await axios
                    .post(route('api.unotifi.getCustomersLastRelevantMessageSentiment', { selectedDealer: selectedDealerId }), {customerIds: missingCustomerSentimentsIds})
                    .then(response => {
                        commit('SET_CUSTOMERS_SENTIMENTS', response.data);
                    })
                    .catch(error => {
                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }
                    })
                    .finally(() => {
                        commit('CHANGE_IS_LOADING_SETTINGS', {key:'getCustomersLastMessageSentimentsByIds', value: false});
                    });
            }
        },

        /**
         * Get predefined SMS messages
         */
        async getPredefinedSMSMessages({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPredefinedSMSMessages', value: true});

            await axios
                .post(route('api.unotifi.GetPredefinedSMS', { selectedDealer: selectedDealerId }))
                .then(response => {
                    commit('SET_PREDEFINED_SMS_MESSAGES', response.data.categoriesWithSms);
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getPredefinedSMSMessages', value: false});
                });
        },

        /**
         * Get status list opportunities
         */
        async getStatusListOpportunities({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getStatusListOpportunities', value: true});

            await axios
                .post(route('api.unotifi.GetStatusListOpportunities', { selectedDealer: selectedDealerId }))
                .then(response => {
                    commit('SET_STATUS_LIST_OPPORTUNITIES', response.data);
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getStatusListOpportunities', value: false});
                });
        },

        /**
         * Get list of campaigns
         */
        async getListCampaigns({ commit }, selectedDealerId) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getListCampaigns', value: true});

            await axios
                .get(route('api.unotifi-rest.list-campaigns', { selectedDealer: selectedDealerId }))
                .then(response => {
                    commit('SET_CAMPAIGNS_LIST', response.data);
                })
                .catch(error => {
                    if (error.response?.data?.message) {
                        console.error(error.response.data.message);
                    } else {
                        console.error('There was an error retrieving the list of campaigns.');
                    }
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'getListCampaigns', value: false});
                });
        },
    },

    mutations: {
        SET_CUSTOMERS_SENTIMENTS(state, sentiments) {
            sentiments.forEach(sentiment => {
                const isFound = state.customersSentiments.some(customerSentiment => {
                    return customerSentiment.customerId === sentiment.customerId
                });

                if (! isFound) {
                    state.customersSentiments.push(sentiment);
                }
            });
        },

        SET_PREDEFINED_SMS_MESSAGES(state, predefinedSMSMessages) {
            state.predefinedSMSMessages = predefinedSMSMessages;
        },

        SET_STATUS_LIST_OPPORTUNITIES(state, statusListOpportunities) {
            state.statusListOpportunities.statusList = statusListOpportunities.statusList;
            state.statusListOpportunities.leadsStatusList = statusListOpportunities.leadsStatusList;
        },

        SET_CAMPAIGNS_LIST(state, campaignList) {
            state.campaignList = campaignList.map(campaign => Object.freeze(campaign));
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
