export default {
    namespaced: true,

    state: {
        analytics_nav: false,
        service_nav: true,
        setting_nav: false,
        showExpandedNav: false,
        showNotificationsTray: false,
    },

    actions: {
        changeAnalyticsNav(context) {
            context.commit('analytics_nav');
        },

        changeServiceNav(context) {
            context.commit('service_nav');
        },

        changeSettingsNav(context) {
            context.commit('settings_nav');
        },
    },

    mutations: {
        SET_EXPANDED_NAV(state, value) {
            state.showExpandedNav = Boolean(value);
        },

        SET_NOTIFICATIONS_TRAY(state, value) {
            state.showNotificationsTray = Boolean(value);
        },

        analytics_nav(state) {
            state.analytics_nav = true;
            state.settings_nav = false;
            state.service_nav = false;
        },

        service_nav(state) {
            state.service_nav = true;
            state.settings_nav = false;
            state.analytics_nav = false;
        },

        settings_nav(state) {
            state.settings_nav = true;
            state.analytics_nav = false;
            state.service_nav = false;
        },
    },
};
