<template>

    <div class="col bg-alpha_bg--shift bg-gradient-overlay-alpha_bg p-3 d-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto">
        <router-view></router-view>
    </div>

</template>

<script>

export default {
    components: {
        //
    },

    data() {
        return {
            //
        }
    },

    computed: {
        //
    },

    created() {
        //
    },

    mounted() {
        //
    },

    methods : {
        //
    },
}

</script>

<style scoped>
    .height-100{
        height: 100vh
    }

</style>
