import { mapPreferences } from 'vue-preferences';

export default {
    namespaced: true,

    state: {
        ...mapPreferences({
            repairs_searchForm_openedBy: {
                defaultValue: null
            },
        }),

        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        // Filter selection options
        filterResultsOptions: state => {
            const defaultStatuses = [
                {'label': 'Invoiced',   'value': 'INVOICED'},
                {'label': 'Opened',     'value': 'OPENED'},
                {'label': 'Update',     'value': 'UPDATE'}
            ];

            return {
                roSta: state.results.filters ? state.results.filters : defaultStatuses,
            }
        },
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {
                key:'getResults',
                value: true
            });

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.GetListOfCustomersInService',
                        { selectedDealer: selectedDealerId }
                    ),
                    { params: parameters }
                );
                commit('SET_RESULTS', response.data);
            } catch (error) {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results) {
                state.results = Object.freeze(results);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            const fiveDaysAgo = new Date();
            fiveDaysAgo.setHours(0,0,0,0);
            fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
            const thirtyDaysFromNow = new Date();
            thirtyDaysFromNow.setHours(0,0,0,0);
            thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
            const openedByAsmNumbers = [];

            if (state.repairs_searchForm_openedBy.get()) {
                openedByAsmNumbers.push(state.repairs_searchForm_openedBy.get());
            }

            state.filterResultsForm = {
                currentPage: 1,
                customerNumber: '',
                fromToDate: {
                    start: fiveDaysAgo,
                    end: thirtyDaysFromNow,
                },
                name: '',
                openedByAsmNumbers: openedByAsmNumbers,
                payTypes: [],
                repair_order_number: '',
                roSta: [],
                sort: 'promised_at',
                VINNumber: '',
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
