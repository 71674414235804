<template>
    <MetabaseDashboard :idDashboard="66" />
</template>

<script>
import MetabaseDashboard from '@/components/analytics/MetabaseDashboard.vue';

export default {
    components: {
        MetabaseDashboard,
    },
}
</script>
