<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a
                            href="#filters-results"
                            class="font-20"
                            data-toggle="collapse"
                            aria-expanded="true"
                            @click="servicesResultSize"
                        >Repairs</a>

                        <ServicesFavoriteButton
                            servicePageName="service-repairs"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="
                            filterResultsFormCurrentPage = 1;
                            $_generalMixin_scrollSimpleBarResultsList();
                            submitFilterResultsForm({clearResults: true})
                        ">
                             <!-- Date Range -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormFromToDate"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Name</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Customer Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Customer No.</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormCustomerNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormVINNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Repair Order Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Rep. Order No.</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormRepair_order_number"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Status</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormRoSta"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! results.filters || ! results.filters.statuses.length"
                                    >
                                        <el-option
                                            v-for="(status, index) in filterResultsOptions.roSta.statuses"
                                            :key="index"
                                            :label="titleCase(status)"
                                            :value="status">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Pay Types -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Pay Type</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormPayTypes"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                    >
                                        <el-option
                                            v-for="(payType, index) in filterResultsSortFilterOptions.payTypes"
                                            :key="index"
                                            :label="payType.label"
                                            :value="payType.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Opened By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Opened By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormOpenedByAsmNumbers"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [
                                                {asmName: 'Select Options', asmId: 'select-options'},
                                                ...asms
                                            ]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmNumber"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >

                                            <div
                                                v-if="asm.asmId === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByAsmNumbers = asms.map(
                                                        item => item.asmNumber
                                                    )"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByAsmNumbers = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>
                                                {{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Sort By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Sort By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormSort"
                                        size="mini"
                                        placeholder="Sort"
                                    >
                                        <el-option
                                            v-for="(sortMethod, index) in filterResultsSortFilterOptions.sortMethods"
                                            :key="index"
                                            :label="sortMethod.label"
                                            :value="sortMethod.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button
                                        type="submit"
                                        :disabled="isLoadingResults.getResults"
                                        class="btn btn-alpha_primary btn-block btn-lg"
                                    >
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button
                                        @click="
                                            resetFilterResultsForm();
                                            $_generalMixin_scrollSimpleBarResultsList();
                                            submitFilterResultsForm({clearResults: true})
                                        "
                                        type="button"
                                        class="btn btn-alpha_secondary btn-block btn-lg"
                                    >
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar
                    ref="resultsList"
                    id="results_list"
                    class="overflow-auto clients-list-wrapper flex-grow-1"
                    :style="servicesResultCss"
                >
                    <template v-if="results.resultsList && results.resultsList.length">
                        <!-- Compose Group Message -->
                        <div
                            v-if="
                                results.allResultsCustomerPhoneNumbers
                                && results.allResultsCustomerPhoneNumbers.length
                            "
                            class="text-center"
                        >
                            <button
                                type="button"
                                class="btn btn-link font-weight-bold mt-1 text-alpha_primary"
                                @click="$refs.modalComposeGroupMessage.showModal()"
                            >Compose Group Message</button>
                        </div>
                        <ul class="list-unstyled clients-list">
                            <li
                                v-for="(result, index) in results.resultsList"
                                @click="loadResultInfo(result, selectedDealerId)"
                                :class="{
                                    'active': isResultSelected(result.roNr),
                                    'unread': $_generalMixin_getMessageStatusByCustomerId(
                                        result.customer_id, notifications.notifications
                                    ) === 'unread'}"
                                :key="index"
                            >
                                <!-- Customer/Vehicle -->
                                <div class="clients-list__name flex-grow-1">
                                    <div class="font-14">{{ getResultCustomerName(result) }}</div>
                                    <div class="font-12">#: {{ result.repair_order_number }}</div>
                                    <div>{{ result.vehicle }}</div>
                                    <div>
                                        <small class="font-weight-bold">{{ result.roSta }}</small>
                                    </div>
                                </div>

                                <!-- Customer Info -->
                                <div class="clients-list__info">
                                    <!-- Sentiment -->
                                    <div
                                        v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                        class="mood mood-sm mx-1"
                                        :class="`mood-${$_generalMixin_getCustomerMoodSettings(
                                            result.customer_id, customersSentiments
                                        ).color}`"
                                    >
                                        <i
                                            :class="$_generalMixin_getCustomerMoodSettings(
                                                result.customer_id, customersSentiments
                                            ).icon"
                                            title="Customer's mood guessed from their recent correspondence"
                                        ></i>
                                    </div>
                                    <div v-else class="mood mood-sm mx-1">
                                        <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                    </div>

                                    <!-- Notifications -->
                                    <div class="clients-list__notification">
                                        <div
                                            v-if="result.last_message_direction === 'incoming'"
                                            class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1"
                                            :class="{'badge-notify': $_generalMixin_getMessageStatusByCustomerId(
                                                result.customer_id, notifications.notifications
                                            ) === 'unread'}"
                                        >
                                            <i class="icon">textsms</i>
                                        </div>
                                    </div>

                                    <!-- C W I -->
                                    <div class="clients-list__cwi mx-1 text-center">
                                        <div v-if="result.c  == 1">C</div>
                                        <div v-if="result.w  == 1">W</div>
                                        <div v-if="result.i == 1">I</div>
                                    </div>

                                    <!-- ASM -->
                                    <div class="clients-list__asm mx-1 text-right">{{ result.asm }}</div>
                                </div>
                            </li>
                        </ul>
                    </template>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {
                        filterResultsFormCurrentPage = currentPage;
                        $_generalMixin_scrollSimpleBarResultsList();
                        submitFilterResultsForm({clearResults: true})
                    }"
                />
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <RepairOrderInfo />
            </template>
        </div>

        <!-- Compose Group Message Modal -->
        <template v-if="results.allResultsCustomerPhoneNumbers && results.allResultsCustomerPhoneNumbers.length">
            <ModalComposeGroupMessage
                ref="modalComposeGroupMessage"
                :customer-phone-numbers="results.allResultsCustomerPhoneNumbers"
            />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import { titleCase } from '@/filters/stringFormats';
import { mapPreferences } from 'vue-preferences';
import ModalComposeGroupMessage from '@/components/services/child-components/ModalComposeGroupMessage.vue';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import RepairOrderInfo from '@/components/services/RepairOrderInfo.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        ModalComposeGroupMessage,
        RepairOrderInfo,
        ResultsPagination,
        ServicesFavoriteButton,
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filters to pass along with getResults function
            filterResultsParams: {},

            // Results sort and filter options
            filterResultsSortFilterOptions: {
                payTypes: [
                    {'label': 'Customer Paid (C)',  'value': 'c'},
                    {'label': 'Warranty (W)',       'value': 'w'},
                    {'label': 'Internal (I)',       'value': 'i'}
                ],
                sortMethods: [
                    {'label': 'Due Date',           'value': 'promised_at'},
                    {'label': 'RO Number',          'value': 'repair_order_number'},
                    {'label': 'Status',             'value': 'roSta'},
                    {'label': 'Customer Last Name', 'value': 'last_name'},
                    {'label': 'Customer Full Name', 'value': 'cust'},
                    {'label': 'Vehicle Year',       'value': 'vehicle_year'},
                    {'label': 'Vehicle Model',      'value': 'vehicle_model'},
                ],
            },

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 50vh',
        }
    },

    computed: {
        ...mapState('services/repairOrders', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapGetters('services/repairOrders', {
            filterResultsOptions: 'filterResultsOptions',
        }),

        ...mapState('services/repairOrderInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('services/repairOrderInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapPreferences({
            repairs_searchForm_openedBy: {
                defaultValue: null
            },
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormFromToDate: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.fromToDate;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'fromToDate', value: value });
            }
        },

        filterResultsFormName: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.name;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'name', value: value });
            }
        },

        filterResultsFormCustomerNumber: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.customerNumber;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'customerNumber', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },

        filterResultsFormRepair_order_number: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.repair_order_number;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', {
                    attribute: 'repair_order_number',
                    value: value
                });
            }
        },

        filterResultsFormRoSta: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.roSta;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'roSta', value: value });
            }
        },

        filterResultsFormPayTypes: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.payTypes;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'payTypes', value: value });
            }
        },

        filterResultsFormOpenedByAsmNumbers: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.openedByAsmNumbers;
            },
            set(value) {
                this.repairs_searchForm_openedBy = Array.isArray(value) && value.length === 1 ? value[0] : null;

                this.$store.commit('services/repairOrders/SET_FILTERS', {
                    attribute: 'openedByAsmNumbers',
                    value: value
                });
            }
        },

        filterResultsFormSort: {
            get() {
                return this.$store.state.services.repairOrders.filterResultsForm.sort;
            },
            set(value) {
                this.$store.commit('services/repairOrders/SET_FILTERS', { attribute: 'sort', value: value });
            }
        },

    },

    created() {
        // Set results filter options to default
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        if (! this.results.resultsList) {
            this.submitFilterResultsForm(this.filterResultsParams);
        }
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex repairOrders actions
        ...mapActions('services/repairOrders', {
            getResults: 'getResults',
        }),

        // Vuex repairOrderInfo actions
        ...mapActions('services/repairOrderInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex repairOrders mutations
        ...mapMutations('services/repairOrders', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Imported methods
         */
        titleCase,

        /**
         * Submit results filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get results
            this.getResults({
                    selectedDealerId: this.selectedDealerId,
                    parameters: this.filterResultsParams
                })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.resultsList.forEach(result => {
                        if (
                            result.customer_id
                            && ! foundCustomerIds.includes(result.customer_id)
                        ) {
                            foundCustomerIds.push(result.customer_id);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({
                        selectedDealerId: this.selectedDealerId,
                        customerIds: foundCustomerIds
                    });

                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        loadResultInfo(result, selectedDealerId) {
            this.getResultInfo({
                result: result,
                selectedDealerId: selectedDealerId
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, this.userSession ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000 : 60000);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/repairOrders/RESET_FILTERS');
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(roNr) {
            return this.resultInfo && roNr == this.resultInfo.RepairOrder.ID;
        },

        /**
         * Get the customer's name
         */
        getResultCustomerName(result) {
            let customerName = '';

            customerName += result.first_name ?? '';
            customerName += result.last_name ? ` ${result.last_name}` : '';

            if (! customerName) {
                customerName = result.cust ?? '(No Name Entered)';
            }

            return customerName;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
          this.servicesResultCss = this.filerResultActive ? 'max-height: 80vh' : 'max-height: 50vh';
          this.filerResultActive = !this.filerResultActive;
        },
    },
}
</script>
