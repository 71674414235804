<template>
    <div class="modal fade" id="create_report_modal" tabindex="-1" role="dialog" aria-labelledby="create_report_modal_title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="create_report_modal_title">New Report</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row mb-2">
                        <!-- Report Select -->
                        <div class="col">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Report</span>
                                </div>
                                <el-select
                                    class="select-blue hide-overflow-tags w-100"
                                    v-model="selectedReport"
                                    size="mini"
                                    placeholder="Select Report"
                                    value-key="name"
                                >
                                    <el-option
                                        v-for="(report, index) in availableReports"
                                        :key="index"
                                        :label="report.name"
                                        :value="report">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <transition name="expand">
                        <!-- Recall BDC Report Form -->
                        <form-recall-bdc-report v-if="selectedReport && selectedReport.identifier === 'recall_bdc_report'"
                            @reportRequestCreated="$emit('reload-reports'); hideModal();"
                        />

                        <!-- Recall ROI Report Form -->
                        <form-recall-roi-report v-else-if="selectedReport && selectedReport.identifier === 'recall_roi_report'"
                            @reportRequestCreated="$emit('reload-reports'); hideModal();"
                        />
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormRecallBdcReport from "@/components/reports/FormRecallBdcReport.vue";
import FormRecallRoiReport from "@/components/reports/FormRecallRoiReport.vue";

export default {
    name: "ModalCreateNewReport",

    components: {
        'form-recall-bdc-report': FormRecallBdcReport,
        'form-recall-roi-report': FormRecallRoiReport,
    },

    data() {
        return {
            isLoading: {
                getAvailableReports: false,
            },

            availableReports: [],

            selectedReport: null,
        }
    },

    created() {
        if (!this.availableReports.length) {
            this.getAvailableReports();
        }
    },

    methods: {
        showModal() {
            $('#create_report_modal').modal('show');
        },

        hideModal() {
            $('#create_report_modal').modal('hide');
        },

        getAvailableReports() {
            this.availableReports = [
                {
                    identifier: 'recall_bdc_report',
                    name: 'Recall BDC Report'
                },
                {
                    identifier: 'recall_roi_report',
                    name: 'Recall ROI Report'
                },
            ];
        },
    },
}
</script>
