<template>

    <div class="router-view-container col bg-alpha_bg--shift bg-gradient-overlay-alpha_bg p-3 d-flex flex-column flex-grow-1 flex-shrink-1 h-100 overflow-auto">
        <router-view></router-view>
    </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
    components: {
        //
    },

    data() {
        return {
            //
        }
    },

    computed: {
        //
    },

    created() {
        this.setMixPanel();
    },

    mounted() {
        //
    },

    methods: {
        setMixPanel(){
            this.$mixpanel.identify(this.userSession?.externalUserId);
            this.$mixpanel.track('analytics',{
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
                asm_id: this.selectedASM?.asmId,
                asm_name: this.selectedASM?.asmName,
                asm_dealername: this.selectedASM?.dealerName,
                asm_location_code: this.selectedASM?.locationCode,
                asm_location_id: this.selectedASM?.locationId,
                asm_location_name: this.selectedASM?.locationName,
            });
        }
    },
}

</script>
