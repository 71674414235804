<template>
<div class="communication communication--recall">
    <div class="communication__icon">
        <div class="icon">local_hospital</div>
    </div>
    <div class="communication__content">
        <div class="communication__header communication__header--full-width">
            <strong>Vehicle Health Warning</strong>

            <div class="communication__date">
                {{ $moment(warning.time_triggered).format('ll') }}
            </div>
        </div>
        <div class="communication__item w-100">
            <h5>Description</h5>
            <p class="card-text mb-3">
                {{ warning.description }}
            </p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Type</th>
                    <th scope="col">Mileage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ warning.name }}</td>
                        <td>{{ warning.type }}</td>
                        <td>{{ warning.mileage }} {{ warning.mileage_unit }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        warning: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
