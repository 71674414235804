<template>
    <div class="row">
        <!-- Close Report View -->
        <div class="col-12 text-right">
            <button class="btn btn-alpha_danger" @click="$emit('close-report-view')">
                Close
            </button>
        </div>

        <template v-if="recallChartData && recallChartData.length && appointmentArrivalRate.data">
            <div class="col-12 col-sm-6 col-md-3">
                <h2>Recall BDC Report</h2>
                <p>
                    <strong>Date Range:</strong>
                    {{ $moment(report.payload.startDate).format('MM/DD/YY') }}
                    - {{ $moment(report.payload.endDate).format('MM/DD/YY') }}
                </p>
                <p><strong>Dealerships:</strong></p>
                <ul>
                    <li v-for="(dealershipIntegralinkCode, index) in report.payload.dealershipIntegralinkCodes"
                        :key="index"
                    >{{ dealershipIntegralinkCode }}</li>
                </ul>
            </div>

            <!-- Appointment Arrival Rate -->
            <div class="col-12 col-sm-6 col-md-4">
                <doughnut-chart
                    class="chart-wrapper"
                    label="Appointment Arrival Rate"
                    :chart-data="appointmentArrivalRate.data"
                    :options="appointmentArrivalRate.options"
                    :plugins="appointmentArrivalRate.plugins"
                />
            </div>
        </template>
        <template v-else-if="isLoading.getReportData">
            <div class="col-12">
                <p>Loading...</p>
            </div>
        </template>
        <template v-else>
            <div class="col-12">
                <p>The selected report does not contain any recall campaigns.</p>
            </div>
        </template>
    </div>
</template>

<script>
import DoughnutChart from '@/components/analytics/charts/DoughnutChart.vue';
import { centerTextPlugin } from '@/components/analytics/charts/ChartJsPlugins';
import { reportViewMixin } from '@/components/reports/mixins/reportViewMixin';

export default {
    mixins: [
        reportViewMixin,
    ],

    components: {
        DoughnutChart,
    },

    props: {
        report: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            centerTextPlugin: centerTextPlugin,
        }
    },

    computed: {
        /**
         * Filter chart data by recall campaigns
         */
        recallChartData() {
            // Filter data by campaigns that contain 'recall'
            return !this.chartData ? null
                : this.chartData.filter((dataPoint) => {
                    return dataPoint['Campaign Name'].toLowerCase().includes('recall');
                });
        },

        appointmentArrivalRate() {
            const chart = {
                data: null,
                options: {},
                plugins: this.centerTextPlugin,
            };

            if (!this.recallChartData) {
                return chart;
            }

            // Get sum of total appointments
            const totalAppointments = this.recallChartData.reduce((acc, curr) => {
                return acc + Number(curr['Total Appointments']);
            }, 0);

            // Get sum of total appointments that were attended
            const totalAppointmentsAttended = this.recallChartData.reduce((acc, curr) => {
                return acc + Number(curr['Total Appointments Arrived']);
            }, 0);

            // Calculate number of appointments that were not attended
            const totalAppointmentsNotAttended = totalAppointments - totalAppointmentsAttended;

            // Calculate appointment arrival rate
            const arrivalPercent = !totalAppointmentsAttended ? 0
                : Math.round(totalAppointmentsAttended / totalAppointments * 100);

            chart.data = {
                labels: [
                    'Arrived',
                    'Not Arrived'
                ],
                datasets: [{
                    data: [totalAppointmentsAttended, totalAppointmentsNotAttended],
                    backgroundColor: [
                        'rgb(54, 162, 235)',
                        'rgb(255,99,131)',
                    ],
                    hoverBackgroundColor: [
                        'rgb(54, 162, 235)',
                        'rgb(255,99,131)',
                    ]
                }]
            };

            chart.options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'bottom',
                },
                elements: {
                    center: {
                        text: `${arrivalPercent}%`,
                        color: 'var(--alpha_text)', // Default is #000000
                        fontStyle: 'Arial', // Default is Arial
                        sidePadding: 20, // Default is 20 (as a percentage)
                        minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    }
                },
                title: {
                    display: true,
                    text: 'Appointment Arrival Rate',
                },
            };

            return chart;
        },
    },

    created() {
        // Get report data
        this.getReportData();
    },
}
</script>
