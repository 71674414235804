<template>
<div>
    <!-- Authentication Code Instructions -->
    <template v-if="!showRecoveryCodeForm">
        <p v-if="!showRecoveryCodeForm" class="mb-3">
            Please confirm access to your account by entering the
            authentication code provided by your authenticator
            application.
        </p>
    </template>

    <!-- Recovery Code Instructions -->
    <template v-else>
        <p>
            Please confirm access to your account by entering one of
            your emergency recovery codes.
        </p>

        <p class="mb-3">
            <em>
                Please note that each recovery code can only be used once. Once a
                code has been used to log in, you should generate a new set of
                recovery codes.
            </em>
        </p>
    </template>

    <!-- Two-Factor Challenge Form -->
    <form
        method="POST"
        action="/two-factor-challenge"
    >
        <CSRFTokenInput />

        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text">
                    {{ !showRecoveryCodeForm ? 'Code' : 'Recovery Code' }}
                </span>
            </div>

            <input
                :id="!showRecoveryCodeForm ? 'code' : 'recovery_code'"
                type="text"
                class="form-control bg-alpha_bg--shift"
                :name="!showRecoveryCodeForm ? 'code' : 'recovery_code'"
                required
            >
        </div>

        <button
            type="submit"
            class="btn btn-alpha_primary btn-lg btn-block
                spin-on-click mb-2"
        >
            Submit
            <i
                class="fas fa-spinner-third fa-spin d-none"
            ></i>
        </button>
    </form>

    <!-- Recovery Mode Selector -->
    <button
        @click="showRecoveryCodeForm = !showRecoveryCodeForm"
        type="button"
        class="btn btn-link btn-block"
    >
        {{
            !showRecoveryCodeForm
                ? 'Use a recovery code'
                : 'Use an authentication code'
        }}
    </button>
</div>
</template>

<script>
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";

export default {
    components: {
        CSRFTokenInput,
    },

    data() {
        return {
            showRecoveryCodeForm: false,
        };
    },
}
</script>
