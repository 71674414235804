// Top Level
import AnalyticsComponent from '@/pages/Analytics/index.vue';
import ChatsComponent from '@/pages/Chats/index.vue';
import ServicesComponent from '@/pages/Services/index.vue';
import SettingsComponent from '@/pages/Settings/index.vue';

// Analytics Children
import AdvisorUsageComponent from '@/pages/Analytics/AdvisorUsage/index.vue';
import BdcCampaignPerformanceComponent from '@/pages/Analytics/BdcCampaignPerformance/index.vue';
import BdcCampaignUserComponent from '@/pages/Analytics/BdcUserPerformance/index.vue';
import BdcContactedOpps from '@/pages/Analytics/BdcContactedOpps/index.vue';
import BdcRoDetailComponent from '@/pages/Analytics/BdcRoDetail/index.vue';
import BusinessDevelopmentAnalyticsComponent from '@/pages/Analytics/BusinessDevelopment/index.vue';
import CampaignPerformanceComponent from '@/pages/Analytics/CampaignPerformance/index.vue';
import CampaignReportsAnalyticsComponent from '@/pages/Analytics/CampaignReports/index.vue';
import CaseSummaryComponent from '@/pages/Analytics/CaseSummary/index.vue';
import CsiComponent from '@/pages/Analytics/Csi/index.vue';
import GeneralBdcComponent from '@/pages/Analytics/GeneralBdc/index.vue';
import OpportunityStatisticsComponent from '@/pages/Analytics/OpportunityStatistics/index.vue';
import PrintCampaignPerformance from '@/pages/Analytics/PrintCampaignPerformance/index.vue';
import RewardsActivityComponent from '@/pages/Analytics/RewardsActivity/index.vue';
import UsersActivityAnalyticsComponent from '@/pages/Analytics/UsersActivity/index.vue';

// Chats Children
import ChatsUIComponent from '@/pages/Chats/UI/index.vue';

// Services Children
import AppointmentsComponent from '@/pages/Services/Appointments/index.vue';
import CasesComponent from '@/pages/Services/Cases/index.vue';
import LeadsComponent from '@/pages/Services/Leads/index.vue';
import OpportunitiesComponent from '@/pages/Services/Opportunities/index.vue';
import PendingMessagesComponent from '@/pages/Services/PendingMessages/index.vue';
import RecentResultsComponent from '@/pages/Services/Recent/index.vue';
import RepairOrdersComponent from '@/pages/Services/RepairOrders/index.vue';
import SearchResultsComponent from '@/pages/Services/Search/index.vue';

// Settings Children
import AccountSettingsComponent from '@/pages/Settings/AccountSettings/index.vue';
import DealershipSettingsComponent from '@/pages/Settings/DealershipSettings/index.vue';

// Errors
import Error404Component from '@/pages/404/index.vue';

export default {
    routes: [
        // Root
        {
            path: '/',
            redirect: { name: 'services' },
        },

        // Analytics
        {
            path: '/analytics',
            name: 'analytics',
            redirect: { name: 'analytics-business-development' },
            component: AnalyticsComponent,
            children: [
                // Busines Development
                {
                    path: 'business-development',
                    name: 'analytics-business-development',
                    component: BusinessDevelopmentAnalyticsComponent,
                },
                // Campaign Reports
                {
                    path: 'campaign-reports',
                    name: 'analytics-campaign-reports',
                    component: CampaignReportsAnalyticsComponent,
                },
                // General BDC
                {
                    path: 'general-bdc',
                    name: 'general-bdc',
                    component: GeneralBdcComponent,
                },
                // BDC Campaign Performance
                {
                    path: 'bdc-campaign-performance',
                    name: 'bdc-campaign-performance',
                    component: BdcCampaignPerformanceComponent,
                },
                // BDC User Performance
                {
                    path: 'bdc-user-performance',
                    name: 'bdc-user-performance',
                    component: BdcCampaignUserComponent,
                },
                // BDC Contacted Opps
                {
                    path: 'bdc-contacted-opps',
                    name: 'bdc-contacted-opps',
                    component: BdcContactedOpps,
                },
                // Campaign Performance
                {
                    path: 'campaign-performance',
                    name: 'campaign-performance',
                    component: CampaignPerformanceComponent,
                },
                // CSI
                {
                    path: 'csi',
                    name: 'csi',
                    component: CsiComponent,
                },
                // Advisor Usage
                {
                    path: 'advisor-usage',
                    name: 'advisor-usage',
                    component: AdvisorUsageComponent,
                },
                // BDC RO Detail
                {
                    path: 'bdc-ro-detail',
                    name: 'bdc-ro-detail',
                    component: BdcRoDetailComponent,
                },
                // Case Summary
                {
                    path: 'case-summary',
                    name: 'case-summary',
                    component: CaseSummaryComponent,
                },
                // Opportunity Statistics
                {
                    path: 'opportunity-statistics',
                    name: 'analytics-opportunity-statistics',
                    component: OpportunityStatisticsComponent,
                },
                // Print Campaign Performance
                {
                    path: 'print-campaign-performance',
                    name: 'print-campaign-performance',
                    component: PrintCampaignPerformance,
                },
                // Rewards Activity
                {
                    path: 'rewards-activity',
                    name: 'rewards-activity',
                    component: RewardsActivityComponent,
                },
                // User Activity
                {
                    path: 'users-activity',
                    name: 'analytics-users-activity',
                    component: UsersActivityAnalyticsComponent,
                },
            ],
        },

        // Chats
        {
            path: '/chats',
            name: 'chats',
            redirect: { name: 'chats-ui' },
            component: ChatsComponent,
            children: [
                // Chats UI
                {
                    path: 'ui',
                    name: 'chats-ui',
                    component: ChatsUIComponent,
                },
            ],
        },

        // Services
        {
            path: '/services',
            name: 'services',
            redirect: () => {
                const defaultStartingPageCookie = localStorage.getItem('defaultStartingPage');

                const defaultStartingPage = defaultStartingPageCookie
                    ? defaultStartingPageCookie.replace(/['"]+/g, '')
                    : null;

                if (defaultStartingPage) {
                    switch (defaultStartingPage) {
                        case 'services-appointments':
                            return { name: defaultStartingPage };
                        case 'services-opportunities':
                            return { name: defaultStartingPage };
                        case 'services-search':
                            return { name: defaultStartingPage };
                        case 'services-recent':
                            return { name: defaultStartingPage };
                        case 'services-pending-messages':
                            return { name: defaultStartingPage };
                        case 'services-cases':
                            return { name: defaultStartingPage };
                    }
                }

                return { name: 'services-repairs' };
            },
            component: ServicesComponent,
            children: [
                // Appointments
                {
                    path: 'appointments',
                    name: 'services-appointments',
                    component: AppointmentsComponent,
                },
                // Cases
                {
                    path: 'cases',
                    name: 'services-cases',
                    component: CasesComponent,
                },
                // Leads
                {
                    path: 'leads',
                    name: 'services-leads',
                    component: LeadsComponent,
                },
                // Opportunities
                {
                    path: 'opportunities',
                    name: 'services-opportunities',
                    component: OpportunitiesComponent,
                },
                // Pending Messages
                {
                    path: 'pending-messages',
                    name: 'services-pending-messages',
                    component: PendingMessagesComponent,
                },
                // Recent
                {
                    path: 'recent',
                    name: 'services-recent',
                    component: RecentResultsComponent,
                },
                // Repairs
                {
                    path: 'repairs',
                    name: 'services-repairs',
                    component: RepairOrdersComponent,
                },
                // Search
                {
                    path: 'search',
                    name: 'services-search',
                    component: SearchResultsComponent,
                },
            ],
        },

        // Settings
        {
            path: '/settings',
            name: 'settings',
            redirect: { name: 'settings-account-settings' },
            component: SettingsComponent,
            children: [
                // Account Settings
                {
                    path: 'account-settings',
                    name: 'settings-account-settings',
                    component: AccountSettingsComponent,
                },
                // Dealership Settings
                {
                    path: 'dealership-settings',
                    name: 'settings-dealership-settings',
                    component: DealershipSettingsComponent,
                },
            ],
        },

        // 404
        {
            path: '/404',
            alias: '*',
            name: 'not-found',
            component: Error404Component,
        },
    ],
};
