<template>
<div class="select-confirm">
    <select v-if="! showConfirmationDialog" v-model="statusUpdate.status" @change="handleInputChange" class="form-control form-control-sm mr-2 mb-2">
        <option
            v-for="(status, index) in statuses"
            :key="index"
            :value="status"
        >{{ status }}</option>
    </select>
    <div v-else class="confirmation-dialog">
        <form @submit.prevent="updateCaseStatus" id="handle_assigned">
            <select v-model="selectedASMId" class="form-control form-control-sm mr-2 mb-2" name="asm-id" required>
                <option value="" disabled>Select ASM</option>
                <option v-for="(asm, index) in asms" :key="index" :value="asm.asmId">
                    {{ asm.asmName }} ({{ asm.asmNumber }})
                </option>
            </select>
        </form>
        <button @click="cancelChange" class="btn btn-danger"><i class="fas fa-times"></i></button>
        <button form="handle_assigned" class="btn btn-alpha_primary"><i class="fas fa-check"></i></button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        asms: {
            type: Array,
            required: true,
        },

        caseId: {
            type: String,
            required: true,
        },

        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: {
                updateCaseStatus: false,
            },

            selectedASMId: '',

            showConfirmationDialog: false,

            statuses: ['Assigned', 'Pending', 'Closed', 'Rejected'],

            statusUpdate: {
                confirmedStatus: '',
                status: '',
                unconfirmedStatus: '',
            },
        }
    },

    created() {
        this.statusUpdate.confirmedStatus = this.value;
        this.statusUpdate.status = this.value;
    },

    methods: {
        handleInputChange(event) {
            this.statusUpdate.unconfirmedStatus = event.currentTarget.value;

            if (this.statusUpdate.unconfirmedStatus !== 'Assigned') {
                this.selectedASMId = '';
                return this.updateCaseStatus();
            }

            this.showConfirmationDialog = true;
        },

        confirmChange() {
            this.statusUpdate.status = this.statusUpdate.unconfirmedStatus;
            this.statusUpdate.confirmedStatus = this.statusUpdate.status;
            this.statusUpdate.unconfirmedStatus = '',
            this.showConfirmationDialog = false;
        },

        cancelChange() {
            this.selectedASMId = '';
            this.statusUpdate.status = this.statusUpdate.confirmedStatus;
            this.statusUpdate.unconfirmedStatus = '',
            this.showConfirmationDialog = false;
        },

        /**
         * Update the case status
         */
        updateCaseStatus() {
            const parameters = {
                'status': this.statusUpdate.unconfirmedStatus,
            };

            if (this.selectedASMId) {
                parameters.assignedUserId = this.selectedASMId;
            }

            this.isLoading.updateCaseStatus = true;

            axios
                .post(route('api.unotifi.updateCase', { selectedDealer: this.selectedDealerId, caseId: this.caseId }), parameters)
                .then(response => {
                    if (response.data.success) {
                        // Inform of success
                        this.$message({
                            type: 'success',
                            message: 'Case updated successfully'
                        });

                        this.confirmChange();
                    }
                })
                .catch(error => {
                    this.cancelChange();

                    this.$message({
                        type: 'error',
                        message: 'Unable to update case status',
                    });

                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.updateCaseStatus = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
    .form-control-sm {
        max-width: 165px;
        padding: 0.0rem 0.5rem;
    }
</style>
