<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a
                            href="#filters-results"
                            class="font-20"
                            data-toggle="collapse"
                            aria-expanded="true"
                            @click="servicesResultSize"
                        >Recent</a>

                        <ServicesFavoriteButton
                            servicePageName="services-recent"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="
                            filterResultsFormCurrentPage = 1;
                            $_generalMixin_scrollSimpleBarResultsList();
                            submitFilterResultsForm({clearResults: true})
                        ">
                            <!-- Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormDateFromTo"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Last Contact By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Contact By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormLastContactedByAsmIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [
                                                {asmName: 'Select Options', asmId: 'select-options'},
                                                ...asms
                                            ]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmId"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div
                                                v-if="asm.asmId === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormLastContactedByAsmIds = asms.map(item => item.asmId)"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormLastContactedByAsmIds = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>
                                                {{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Campaign -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Campaign</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormCampaignListIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! campaignList.length"
                                    >
                                        <el-option
                                            v-for="(campaign, index) in [
                                                {name:'Select Options', id:'select-options'},
                                                ...campaignList
                                            ]"
                                            :key="index"
                                            :label="campaign.name"
                                            :value="campaign.id"
                                            :disabled="campaign.id === 'select-options'"
                                            :hidden="campaign.id === 'select-options' && campaignList.length < 10"
                                        >
                                            <div
                                                v-if="campaign.id === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormCampaignListIds = campaignList.map(
                                                        item => item.id
                                                    )"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormCampaignListIds = []"
                                                >Select None</button>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Filter by -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Filter By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormFilterBy"
                                        size="mini"
                                        clearable
                                        placeholder="Select"
                                    >
                                        <el-option
                                            v-for="(filterName, index) in filterResultsFormOptions.filterBy"
                                            :key="index"
                                            :label="filterName"
                                            :value="filterName">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button
                                        type="submit"
                                        :disabled="isLoadingResults.getResults"
                                        class="btn btn-alpha_primary btn-block btn-lg"
                                    >
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button
                                        @click="
                                            resetFilterResultsForm();
                                            $_generalMixin_scrollSimpleBarResultsList();
                                            submitFilterResultsForm({clearResults: true})
                                        "
                                        type="button"
                                        class="btn btn-alpha_secondary btn-block btn-lg"
                                    >
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar
                    ref="resultsList"
                    id="results_list"
                    class="overflow-auto clients-list-wrapper flex-grow-1 pt-3"
                    :style="servicesResultCss"
                >
                    <ul v-if="results.latestSMSList" class="list-unstyled clients-list">
                        <template v-for="(result, index) in results.latestSMSList">
                            <!-- If Lead Result -->
                            <li v-if="result.generated_from === 'Lead'"
                                @click="goToLead(result.custID)"
                                :class="{'unread': $_generalMixin_getMessageStatusByCustomerId(
                                    result.custID, notifications.notifications
                                ) === 'unread'}"
                                :key="index"
                                :id="result.custID"
                            >
                                <!-- Customer/Date -->
                                <div class="clients-list__name flex-grow-1">
                                    <p class="font-14">{{ result.cust }}</p>
                                    {{ $moment(result.date).format('MM/DD/YY @h:mm A') }}
                                </div>

                                <!-- Customer Info -->
                                <div class="clients-list__info">
                                    <div class="mx-1">
                                        <span class="badge rounded-pill bg-warning">Lead</span>
                                    </div>
                                    <!-- Notifications -->
                                    <div :id="`notification_${result.custID}`" class="clients-list__notification">
                                        <div
                                            v-if="result.type === 'Reply' || result.type === 'Hello'"
                                            class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1"
                                            :class="{'badge-notify': $_generalMixin_getMessageStatusByCustomerId(
                                                result.custID, notifications.notifications
                                            ) === 'unread'}"
                                        >
                                            <i class="icon">textsms</i>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- Else Any Other Result -->
                            <li v-else
                                @click="routeReplace(result)"
                                :class="{
                                    'active': isResultSelected(result.custID),
                                    'unread': $_generalMixin_getMessageStatusByCustomerId(
                                        result.custID, notifications.notifications
                                    ) === 'unread'
                                }"
                                :key="index"
                                :id="result.custID"
                            >
                                <!-- Customer/Date -->
                                <div class="clients-list__name flex-grow-1">
                                    <p class="font-14">{{ result.cust }}</p>
                                    {{ $moment(result.date).format('MM/DD/YY @h:mm A') }}
                                    <div v-if="result.generated_from === 'Payment'">
                                        Payment {{ result.type}}
                                    </div>
                                </div>

                                <!-- Customer Info -->
                                <div class="clients-list__info">
                                    <!-- Sentiment -->
                                    <div
                                        v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                        class="mood mood-sm mx-1"
                                        :class="`mood-${$_generalMixin_getCustomerMoodSettings(
                                            result.custID, customersSentiments
                                        ).color}`"
                                    >
                                        <i
                                            :class="$_generalMixin_getCustomerMoodSettings(
                                                result.custID, customersSentiments
                                            ).icon"
                                            title="Customer's mood guessed from their recent correspondence"
                                        ></i>
                                    </div>
                                    <div v-else class="mood mood-sm mx-1">
                                        <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                    </div>

                                    <!-- Notifications -->
                                    <div :id="`notification_${result.custID}`" class="clients-list__notification">
                                        <div
                                            v-if="result.type === 'Reply' || result.type === 'Hello'"
                                            class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1"
                                            :class="{'badge-notify': $_generalMixin_getMessageStatusByCustomerId(
                                                result.custID, notifications.notifications
                                            ) === 'unread'}"
                                        >
                                            <i class="icon">textsms</i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {
                        filterResultsFormCurrentPage = currentPage;
                        $_generalMixin_scrollSimpleBarResultsList();
                        submitFilterResultsForm({clearResults: true})
                    }"
                />
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <RecentResultInfo />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import { preference } from 'vue-preferences';
import { mapPreferences } from 'vue-preferences';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import RecentResultInfo from '@/components/services/RecentResultInfo.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        RecentResultInfo,
        ResultsPagination,
        ServicesFavoriteButton
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 58vh',
        }
    },

    computed: {
        ...mapState('services', {
            campaignList: 'campaignList',
        }),

        ...mapState('services/recentResults', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapGetters('services/recentResults', {
            filterResultsFormOptions: 'filterResultsFormOptions',
        }),

        ...mapState('services/recentResultInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapGetters('services/recentResultInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapPreferences({
            recentResults_searchForm_lastContactedBy: {
                defaultValue: null
            },
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.recentResults.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/recentResults/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormDateFromTo: {
            get() {
                return this.$store.state.services.recentResults.filterResultsForm.dateFromTo;
            },
            set(value) {
                this.$store.commit('services/recentResults/SET_FILTERS', { attribute: 'dateFromTo', value: value });
            }
        },

        filterResultsFormFilterBy: {
            get() {
                return this.$store.state.services.recentResults.filterResultsForm.filterBy;
            },
            set(value) {
                this.$store.commit('services/recentResults/SET_FILTERS', { attribute: 'filterBy', value: value });
            }
        },

        filterResultsFormLastContactedByAsmIds: {
            get() {
                return this.$store.state.services.recentResults.filterResultsForm.lastContactedByAsmIds;
            },
            set(value) {
                this.recentResults_searchForm_lastContactedBy = Array.isArray(value) && value.length === 1
                    ? value[0]
                    : null;

                this.$store.commit('services/recentResults/SET_FILTERS', {
                    attribute: 'lastContactedByAsmIds',
                    value: value
                });
            }
        },

        filterResultsFormCampaignListIds: {
            get() {
                return this.$store.state.services.recentResults.filterResultsForm.campaignListIds;
            },
            set(value) {
                this.$store.commit('services/recentResults/SET_FILTERS', {
                    attribute: 'campaignListIds',
                    value: value
                });
            }
        },
    },

    watch: {
        /**
         * Get result info when URL query parameter changes
         */
        $route(to, from) {
            if (to.query.customer_id) {
                this.loadResultInfo(
                    this.selectedDealerId,
                    to.query.customer_id,
                    to.query.auto_campaign_id,
                    to.query.sms_date_time
                );
            }
        },
    },

    created() {
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }

        // Populate the campaigns list array if it is empty
        if (!this.campaignList.length) {
            this.getListCampaigns(this.selectedDealerId);
        }
    },

    mounted() {
        // Get result info if query param exists
        if (this.$route.query.customer_id) {
            this.loadResultInfo(
                this.selectedDealerId,
                this.$route.query.customer_id,
                this.$route.query.auto_campaign_id,
                this.$route.query.sms_date_time
            );
        }

        // Submit the filter results form
        if (! this.results.latestSMSList) {
            this.submitFilterResultsForm();
        }
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex services actions
        ...mapActions('services', {
            getListCampaigns: 'getListCampaigns',
        }),

        // Vuex recentResults actions
        ...mapActions('services/recentResults', {
            getResults: 'getResults',
        }),

        // Vuex recentResultInfo actions
        ...mapActions('services/recentResultInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex recentResults mutations
        ...mapMutations('services/recentResults', {
            SET_RESULTS: 'SET_RESULTS',
        }),


        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.latestSMSList?.forEach(result => {
                        if (result.custID && ! foundCustomerIds.includes(result.custID)) {
                            foundCustomerIds.push(result.custID);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({
                        selectedDealerId: this.selectedDealerId,
                        customerIds: foundCustomerIds
                    });

                    // Set timeout for results auto refresh if on first page
                    if (this.filterResultsFormCurrentPage === 1) {
                        this.setGetResultsInterval();
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        loadResultInfo(
            selectedDealerId,
            customerId,
            campaignId = null,
            smsDateTime = null
        ) {
            this.getResultInfo({
                selectedDealerId: selectedDealerId,
                customerId: customerId,
                autoCampaignId: campaignId,
                smsDateTime: smsDateTime
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();
            const autoRefreshInterval = this.userSession
                ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000
                : 60000;

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, autoRefreshInterval);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/recentResults/RESET_FILTERS');
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(customerId) {
            return this.resultInfo && customerId == this.resultInfo.Customer.ID;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive ? 'max-height: 78vh' : 'max-height: 58vh';
            this.filerResultActive = !this.filerResultActive;
        },

        /**
         * Update route parameters
         * The $route object is being watched for updates and fires the getResultInfo method.
         */
        routeReplace(result) {
            return this.$router
                .replace({'query': {
                    'customer_id': result.custID,
                    'auto_campaign_id': result.auto_campaign_id,
                    'sms_date_time': result.date
                }})
                .catch(error => {
                    if (error.from?.fullPath && error.to?.fullPath && error.from.fullPath === error.to.fullPath) {
                        this.loadResultInfo(
                            this.selectedDealerId,
                            result.custID,
                            result.auto_campaign_id
                        );
                    }
                });
        },

        /**
         * Go to lead on leads page
         */
        goToLead(leadId) {
            const shouldConfirmRecentToLeadNavigation = preference(
                'shouldConfirmRecentToLeadNavigation',
                { defaultValue: true }
            );

            if (!shouldConfirmRecentToLeadNavigation.get()) {
                return this.$router.push({ name: 'services-leads', query: { lead_id: leadId } });
            }

            this.$confirm(
                'Click "OK" or "Don\'t Ask Again" to navigate to the Leads page and load the selected info.',
                'Navigating to the Leads page',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Don\'t Ask Again',
                    distinguishCancelAndClose: true,
                    type: 'info',
                    callback: action => {
                        if (action === 'close') {
                            return;
                        }

                        if (action === 'cancel') {
                            shouldConfirmRecentToLeadNavigation.set(false);
                        }

                        this.$router.push({ name: 'services-leads', query: { lead_id: leadId } });
                    }
                }
            );
        },
    },
}
</script>
