<template>
    <MetabaseDashboard :idDashboard="62" :additionalFilters="additionalFilters">
        <!-- Campaign type Select -->
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
                <span class="input-group-text">Type</span>
            </div>
            <el-select
                class="select-blue hide-overflow-tags w-100"
                v-model="campaignTypes"
                size="mini"
                multiple
                collapse-tags
                placeholder="Select"
            >
                <el-option
                    v-for="(type, index) in allCampaignTypes"
                    :key="index"
                    :label="type"
                    :value="type"
                >
                </el-option>
            </el-select>
        </div>
    </MetabaseDashboard>
</template>

<script>
import MetabaseDashboard from "@/components/analytics/MetabaseDashboard.vue";

const CAMPAIGN_TYPES = ["Email", "Text", "Print"];

export default {
    components: {
        MetabaseDashboard,
    },
    data() {
        return {
            allCampaignTypes: CAMPAIGN_TYPES,
            campaignTypes: CAMPAIGN_TYPES,
        };
    },
    computed: {
        additionalFilters() {
            return {
                campaign_type: this.campaignTypes,
            };
        },
    },
};
</script>
