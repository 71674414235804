<template>
<div class="modal fade" id="customer_contact_info_modal" tabindex="-1" role="dialog" aria-labelledby="customer_contact_info_title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-2" id="customer_contact_info_title">Update Contact Info</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <template v-if="resultInfo && resultInfo.info">
                <div class="modal-body">
                    <!-- Opt In Contact Methods -->
                    <div class="form-group">
                        <label class="d-block">Opt In Contact Methods</label>

                        <!-- Text -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.text"
                                @change="updateOptInSettings($event.target.checked, 'do_not_text')"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_text"
                            ><label class="form-check-label" for="contact_method_text">Text</label>
                        </div>

                        <!-- Email -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.email"
                                @change="updateOptInSettings($event.target.checked, 'do_not_email')"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_email"
                            ><label class="form-check-label" for="contact_method_email">Email</label>
                        </div>

                        <!-- Call -->
                        <div class="form-check form-check-inline">
                            <input
                                v-model="optInContactMethods.call"
                                @change="updateOptInSettings($event.target.checked, 'do_not_call')"
                                class="form-check-input"
                                type="checkbox"
                                id="contact_method_call"
                            ><label class="form-check-label" for="contact_method_call">Call</label>
                        </div>
                    </div>

                    <div>Contact Methods</div>

                    <!-- Email Address -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Email Address</span>
                            </div>

                            <input
                                v-model="customerPhoneNumbersAndEmail.email"
                                @change="updateContactInfo($event.target.value, 'email1')"
                                class="form-control"
                                type="email"
                                name="email"
                                placeholder="name@domain.com"
                                disabled
                            >
                        </div>
                    </div>

                    <!-- Cell Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Cell Phone
                                </span>
                            </div>

                            <input
                                v-model="customerPhoneNumbersAndEmail.mobilePhoneNumber"
                                @change="updateContactInfo(stripPhoneNumber($event.target.value), 'phone_mobile')"
                                v-mask="'(###) ###-####'"
                                class="form-control"
                                type="tel"
                                name="mobilePhone"
                                placeholder="(555) 555-5555"
                                pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                            >
                        </div>
                    </div>

                    <!-- Home Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Home Phone
                                </span>
                            </div>

                            <input
                                v-model="customerPhoneNumbersAndEmail.homePhoneNumber"
                                @change="updateContactInfo(stripPhoneNumber($event.target.value), 'phone_home')"
                                v-mask="'(###) ###-####'"
                                class="form-control"
                                type="tel"
                                name="homePhone"
                                placeholder="(555) 555-5555"
                                pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                            >
                        </div>
                    </div>

                    <!-- Work Phone -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Work Phone
                                </span>
                            </div>

                            <input
                                v-model="customerPhoneNumbersAndEmail.workPhoneNumber"
                                @change="updateContactInfo(stripPhoneNumber($event.target.value), 'phone_work')"
                                v-mask="'(###) ###-####'"
                                class="form-control"
                                type="tel"
                                name="workPhone"
                                placeholder="(555) 555-5555"
                                pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                            >
                        </div>
                    </div>

                    <div>Address</div>

                    <!-- Street Address -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Street Address</span>
                            </div>

                            <input
                                v-model="customerAddress.streetAddress"
                                @change="updateAddressInfo($event.target.value, 'primary_address_street')"
                                class="form-control"
                                type="text"
                                name="streetAddress"
                                placeholder="123 Street"
                                disabled
                            >
                        </div>
                    </div>

                    <!-- City -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">City</span>
                            </div>

                            <input
                                v-model="customerAddress.city"
                                @change="updateAddressInfo($event.target.value, 'primary_address_city')"
                                class="form-control"
                                type="text"
                                name="city"
                                placeholder="City"
                                disabled
                            >
                        </div>
                    </div>

                    <!-- State -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">State</span>
                            </div>

                            <input
                                v-model="customerAddress.state"
                                @change="updateAddressInfo($event.target.value, 'primary_address_state')"
                                class="form-control"
                                type="text"
                                name="state"
                                placeholder="State"
                                disabled
                            >
                        </div>
                    </div>

                    <!-- Zip -->
                    <div class="mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Zip Code</span>
                            </div>

                            <input
                                v-model="customerAddress.zip"
                                v-mask="'#####-####'"
                                @change="customerAddress.zip.slice(-1) === '-' ? customerAddress.zip = customerAddress.zip.slice(0, -1) : ''; updateAddressInfo($event.target.value, 'primary_address_postalcode')"
                                type="text"
                                name="zip"
                                class="form-control"
                                minlength="5"
                                maxlength="10"
                                placeholder="#####-####"
                                disabled
                            >
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_primary" data-dismiss="modal">Close</button>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    props: {
        //
    },

    data() {
        return {
            customerAddress: {
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
            },

            customerPhoneNumbersAndEmail: {
                mobilePhoneNumber: '',
                homePhoneNumber: '',
                workPhoneNumber: '',
                email: '',
            },

            isLoading: {
                //
            },

            optInContactMethods: {
                call: false,
                email: false,
                text: false,
            },
        }
    },

    computed: {
        ...mapState('services/leadInfo', {
            isLoadingLeadInfo: 'isLoading',
            resultInfo: 'resultInfo',
        }),

        /**
         * Check to see if anything is loading
         */
        anyLoading() {
            return Object.values(this.isLoading).includes(true);
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////
    },

    watch: {
        //
    },

    created() {
        this.customerAddress.streetAddress = this.resultInfo.info.primary_address_street;
        this.customerAddress.city = this.resultInfo.info.primary_address_city;
        this.customerAddress.state = this.resultInfo.info.primary_address_state;
        this.customerAddress.zip = this.resultInfo.info.primary_address_postalcode;

        this.customerPhoneNumbersAndEmail.mobilePhoneNumber = this.resultInfo.info.phone_mobile;
        this.customerPhoneNumbersAndEmail.homePhoneNumber = this.resultInfo.info.phone_home;
        this.customerPhoneNumbersAndEmail.workPhoneNumber = this.resultInfo.info.phone_work;
        this.customerPhoneNumbersAndEmail.email = this.resultInfo.info.email1;

        this.optInContactMethods.call = this.resultInfo.info.do_not_call != 1;
        this.optInContactMethods.email = this.resultInfo.info.do_not_email != 1;
        this.optInContactMethods.text = this.resultInfo.info.do_not_text != 1;
    },

    methods : {
        /**
         * Vuex leadInfo actions
         */
        ...mapActions('services/leadInfo', {
            updateLeadInfo: 'updateLeadInfo',
        }),

        // Strip non-digits from phone number
        stripPhoneNumber(phoneNumber) {
            return phoneNumber.replace(/\D/g,'');
        },


        /*
        |----------------------------------------------------------------------
        | Update customer methods
        |----------------------------------------------------------------------
        |
        | These are the methods for updating the customer's contact info.
        |
        */

        /**
         * Update opt-in settings
         */
        updateOptInSettings(value, key) {
            const optInOption = key === 'do_not_call' ? 'call'
                : key === 'do_not_email' ? 'email'
                : key === 'do_not_text' ? 'text'
                : null;

            this.updateLeadInfo({selectedDealerId: this.selectedDealerId, keyValueInfoToUpdate: {[key]: !value}})
                .then(response => {
                    //
                })
                .catch(error => {
                    if (optInOption) {
                        this.optInContactMethods[optInOption] = this.resultInfo.info[key] != 1;
                    }

                    this.$message({
                        type: 'error',
                        message: error.message
                    });
                });
        },

        /**
         * Update Contact Info
         */
        updateContactInfo(value, key) {
            const method = key === 'phone_mobile' ? 'mobilePhoneNumber'
                : key === 'phone_home' ? 'homePhoneNumber'
                : key === 'phone_work' ? 'workPhoneNumber'
                : key === 'email1' ? 'email'
                : null;

            this.updateLeadInfo({selectedDealerId: this.selectedDealerId, keyValueInfoToUpdate: {[key]: value}})
                .then(response => {
                    //
                })
                .catch(error => {
                    if (method) {
                        this.customerPhoneNumbersAndEmail[method] = this.resultInfo.info[key];
                    }

                    this.$message({
                        type: 'error',
                        message: error.message
                    });
                });
        },

        /**
         * Update Address Info
         */
        updateAddressInfo(value, key) {
            const method = key === 'primary_address_street' ? 'streetAddress'
                : key === 'primary_address_city' ? 'city'
                : key === 'primary_address_state' ? 'state'
                : key === 'primary_address_postalcode' ? 'zip'
                : null;

            this.updateLeadInfo({selectedDealerId: this.selectedDealerId, keyValueInfoToUpdate: {[key]: value}})
                .then(response => {
                    //
                })
                .catch(error => {
                    if (method) {
                        this.customerAddress[method] = this.resultInfo.info[key];
                    }

                    this.$message({
                        type: 'error',
                        message: error.message
                    });
                });
        },
    },
}
</script>

<style lang="scss" scoped>
//
</style>