import { mapPreferences } from 'vue-preferences';

export default {
    namespaced: true,

    state: {
        ...mapPreferences({
            recentResults_searchForm_lastContactedBy: {
                defaultValue: null
            },
        }),

        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        // Filter selection options
        filterResultsFormOptions: state => {
            return {
                filterBy: [
                    'Payment Messages',
                    'Replies and Manually Sent Messages',
                    'Reply Messages',
                    'Sent Messages',
                    'SMS Messages',
                    'System Generated Messages',
                    'Web Appointment Messages',
                ],
            }
        },
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {
                key:'getResults',
                value: true
            });

            try {
                const response = await axios.post(
                    route(
                        'api.unotifi.LatestSMSListRecent',
                        { selectedDealer: selectedDealerId }
                    ),
                    parameters
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results.data) {
                state.results = Object.freeze(results.data);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const lastContactedByAsmIds = [];

            if (state.recentResults_searchForm_lastContactedBy.get()) {
                lastContactedByAsmIds.push(state.recentResults_searchForm_lastContactedBy.get());
            }

            state.filterResultsForm = {
                campaignListIds: [],
                currentPage: 1,
                dateFromTo: {
                    start: yesterday,
                    end: new Date(),
                },
                filterBy: '',
                lastContactedByAsmIds: lastContactedByAsmIds,
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
