import ActivityLog from '@/components/services/child-components/ActivityLog.vue';
import CustomerInfo from '@/components/services/Customer/CustomerInfo.vue';
import ModalAddACase from '@/components/services/Customer/ModalAddACase.vue';
import VueContext from 'vue-context';
import { truncate } from '@/filters/stringFormats';
import { mapActions, mapState } from 'vuex';

export const servicesInfoMixin = {
    components: {
        'activity-log': ActivityLog,
        'customer-info': CustomerInfo,
        'modal-add-a-case': ModalAddACase,
        'vue-context': VueContext,
    },

    data() {
        return {
            autoRefreshResultInfoInterval: null,

            selectedTabId: null,

            servicesInfoMixin_isLoading: {
                addActivity: false,
                updatePayment: false,
            },
        };
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
            isLoadingGlobal: 'isLoading',
            smsNotifications: 'smsNotifications',
        }),

        ...mapState('services', {
            campaignList: 'campaignList',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),
    },

    created() {
        // Populate the campaigns list array if it is empty
        if (!this.campaignList.length) {
            this.getListCampaigns(this.selectedDealerId);
        }

        // Auto refresh result info
        this.autoRefreshResultInfoInterval = setInterval(() => {
            const { position: scrollPosition } = this.$_generalMixin_getSimpleBarTabContentScrollPosition();

            if (['all', 'chat', 'messages'].includes(this.selectedTabId) && scrollPosition === 'bottom') {
                this.callUpdateResultInfoMethod();
            }
        }, 15000);
    },

    beforeDestroy() {
        // Clear autoRefreshResultInfoInterval
        clearInterval(this.autoRefreshResultInfoInterval);
    },

    methods: {
        /**
         * Vuex global actions
         */
        ...mapActions('global', {
            markSMSNotificationsRead: 'markSMSNotificationsRead',
        }),

        /**
         * Vuex services actions
         */
        ...mapActions('services', {
            getListCampaigns: 'getListCampaigns',
        }),

        /**
         * Imported methods
         */
        truncate,

        /**
         * Add Activity
         */
        async $_servicesInfoMixin_addActivity(parentType, parentId, activity, status = 'Completed') {
            const params = {
                activity: activity,
                parentType: parentType,
                status: status,
                parentId: parentId,
            };

            this.servicesInfoMixin_isLoading.addActivity = true;

            await axios
                .post(route('api.unotifi.AddActivity', { selectedDealer: this.selectedDealerId }), params)
                .then((response) => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Activity logged successfully',
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `There was an error logging the activity:
                            ${activity}`,
                    });
                })
                .finally(() => {
                    this.servicesInfoMixin_isLoading.addActivity = false;
                });
        },

        /**
         * Find and mark notifications as read
         */
        $_servicesInfoMixin_findAndMarkSMSNotificationsRead(smsMessages, selectedCustomerId) {
            const incomingSMSMessages = smsMessages.reduce((messages, smsMessage) => {
                if (smsMessage.direction !== 'Incoming') {
                    return messages;
                }

                messages.push(smsMessage.text);
                return messages;
            }, []);

            const smsNotificationIds = this.smsNotifications.notifications.reduce(
                (smsNotificationIds, smsNotification) => {
                    if (smsNotification.read_at) {
                        return smsNotificationIds;
                    }

                    if (smsNotification.customer_id !== selectedCustomerId) {
                        return smsNotificationIds;
                    }

                    if (!incomingSMSMessages.includes(smsNotification.text)) {
                        return [smsNotification.id];
                    }

                    smsNotificationIds.push(smsNotification.id);

                    return smsNotificationIds;
                },
                []
            );

            if (smsNotificationIds.length) {
                this.markSMSNotificationsRead({ ids: smsNotificationIds, selectedDealerId: this.selectedDealerId });
            }
        },

        /**
         * Generate a description when creating a case for payments
         */
        $_servicesInfoMixin_generatePaymentCaseDescription(data) {
            const description = ['\n---'];

            description.push(`\nCredit Card: ${data.credit_card ? data.credit_card : 'N/A'}`);
            description.push(`\nRepair Order #: ${data.repair_order_number ? data.repair_order_number : 'N/A'}`);
            description.push(`\nInvoice #: ${data.invoice_number ? data.invoice_number : 'N/A'}`);
            description.push(`\nTransaction ID: ${data.transaction_id ? data.transaction_id : 'N/A'}`);
            description.push(`\nAmount: ${data.amount ? data.amount : 'N/A'}`);

            return description.join('');
        },

        /**
         * Change the payment amount for a text to pay payment request
         */
        $_servicesInfoMixin_updatePayment(paymentInfo) {
            this.$prompt('Enter new payment amount', 'Change Payment Amount', {
                cancelButtonClass: 'btn btn-alpha_secondary',
                cancelButtonText: 'Cancel',
                confirmButtonClass: 'btn btn-alpha_primary',
                confirmButtonText: 'Update',
                inputValue: paymentInfo.amount,
                inputValidator: (value) => {
                    // Check if input value is not a number
                    if (isNaN(value)) {
                        return 'Please enter a valid numeric amount.';
                    }

                    // Check if input value is negative
                    if (!(value >= 0)) {
                        return 'Amount must be greater than or equal to 0.';
                    }

                    // Check if input value is an invalid dollar amount not in the format of 0.00
                    var regex = /^[0-9]\d*(?:\.\d{0,2})?$/;
                    if (!regex.test(value)) {
                        return 'Please enter a dollar amount in the format of 0.00.';
                    }

                    return true;
                },
            })
                .then(({ value }) => {
                    this.servicesInfoMixin_isLoading.updatePayment = true;

                    const params = {
                        amount: value,
                        paymentId: paymentInfo.paymentId,
                    };

                    axios
                        .post(route('api.unotifi.UpdatePayment', { selectedDealer: this.selectedDealerId }), params)
                        .then((response) => {
                            if (response.data.success) {
                                this.$message({
                                    type: 'success',
                                    message: 'Payment updated successfully',
                                });

                                this.callUpdateResultInfoMethod();
                            } else {
                                throw new Error(
                                    'The update payment response returned 200, but the payment may or may not have updated successfully.'
                                );
                            }
                        })
                        .catch((error) => {
                            if (error.response?.data?.errors) {
                                console.error(error.response.data.errors);
                            } else {
                                console.error(error);
                            }

                            this.$message({
                                type: 'error',
                                message: 'There was an error updating the payment',
                            });
                        })
                        .finally(() => {
                            this.servicesInfoMixin_isLoading.updatePayment = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Input canceled',
                    });
                });
        },

        /**
         * Default call update result info method
         */
        callUpdateResultInfoMethod() {
            console.error('The method "callUpdateResultInfoMethod" has not been specified.');
        },
    },
};
