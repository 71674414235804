<template>
    <div
        class="communication communication--customer-payments"
    >
        <div class="communication__wrapper">
            <!-- Icon -->
            <div class="communication__icon">
                <i
                    class="icon"
                    :class="getCreditCardIconClass(
                        payment.card_type ? payment.card_type : ''
                    )"
                ></i>
            </div>

            <!-- Content -->
            <div class="communication__content">
                <div class="communication__header">
                    <strong>Name</strong>
                    <p>{{ payment.name }}</p>
                </div>
                <div class="communication__item">
                    <strong>Credit Card</strong>
                    <p>{{ payment.credit_card }}</p>
                    <span v-if="payment.card_type">
                        {{ payment.card_type }}
                    </span>
                </div>
                <div class="communication__item">
                    <strong>Repair Order Number</strong>
                    <p>{{ payment.repair_order_number }}</p>
                </div>
                <div class="communication__item">
                    <strong>Transaction Id</strong>
                    <p>{{ payment.transaction_id }}</p>
                </div>
                <div class="communication__item">
                    <strong>Amount</strong>
                    <p>{{ payment.amount }}</p>
                </div>
                <div class="communication__item">
                    <small class="white-space-nowrap">
                        {{
                            $moment(payment.DateTime).format('MM/DD/YY @h:mm A')
                        }}
                    </small>
                </div>
            </div>

            <!-- Actions -->
            <div class="dropdown">
                <button
                    class="btn btn-unstyled px-3"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button
                        @click.stop="$emit('add-case')"
                        type="button"
                        class="dropdown-item"
                    >Add Case</button>
                    <button
                        @click.stop="$emit('update-payment')"
                        type="button"
                        class="dropdown-item"
                    >Change Payment Amount</button>
                    <button
                        @click.stop="printPayment"
                        type="button"
                        class="dropdown-item"
                    >Print Payment</button>
                </div>
            </div>
        </div>

        <!-- Payment Print Div -->
        <div class="d-none" :id="paymentElementId">
            <div class="payment-receipt">
                <table>
                    <caption>
                        {{ dealership.dealer_name }}
                        ({{ dealership.dealer_number }})<br>
                        <template v-if="dealership.dealer_phone">
                            Phone: {{ dealership.dealer_phone }}
                        </template>
                    </caption>
                    <tr>
                        <th scope="col" colspan="2">Payment</th>
                    </tr>
                    <tr v-if="payment.DateTime">
                        <th scope="row">Date</th>
                        <td>
                            {{
                                $moment(payment.DateTime)
                                    .format('MM/DD/YYYY h:mm A')
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Status:</th>
                        <td>{{ payment.status }}</td>
                    </tr>
                    <tr v-if="payment.invoice_number">
                        <th scope="row">Invoice No.:</th>
                        <td>{{ payment.invoice_number }}</td>
                    </tr>
                    <tr v-if="payment.repair_order_number">
                        <th scope="row">Repair Order No.:</th>
                        <td>{{ payment.repair_order_number }}</td>
                    </tr>
                    <tr v-if="payment.amount">
                        <th scope="row">Amount:</th>
                        <td>{{ payment.amount }}</td>
                    </tr>
                    <tr v-if="payment.billing_name">
                        <th scope="row">Billing Name:</th>
                        <td>{{ payment.billing_name }}</td>
                    </tr>
                    <tr v-if="payment.name">
                        <th scope="row">Description:</th>
                        <td>{{ payment.name }}</td>
                    </tr>
                    <tr v-if="payment.billing_phone">
                        <th scope="row">Billing Phone No.:</th>
                        <td>{{ payment.billing_phone }}</td>
                    </tr>
                    <tr v-if="payment.billing_email">
                        <th scope="row">Billing Email:</th>
                        <td>{{ payment.billing_email }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { generalMixin } from '@/mixins/generalMixin';

export default {
    mixins: [generalMixin],

    props: {
        dealership: {
            type: Object,
            required: true,
        },

        payment: {
            type: Object,
            required: true,
        },
    },

    computed: {
        paymentElementId() {
            return `payment_${this.payment.id}`;
        },
    },

    methods: {
        getCreditCardIconClass(cardType) {
            cardType = cardType.toLowerCase();

            if (/visa/i.test(cardType)) {
                return 'fa-brands fa-cc-visa';
            }

            if (/master/i.test(cardType)) {
                return 'fa-brands fa-cc-mastercard';
            }

            if (/amex|american/i.test(cardType)) {
                return 'fa-brands fa-cc-amex';
            }

            if (/discover/i.test(cardType)) {
                return 'fa-brands fa-cc-discover';
            }

            return 'fa-solid fa-credit-card';
        },

        async printPayment() {
            await this.$htmlToPaper(this.paymentElementId);
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
