import '~/app.scss';
// Todo: Figure out how to compile this separately
// import '~/print.scss';

import 'bootstrap';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

import $ from 'jquery';
import Popper from 'popper.js';

try {
    window.Popper = Popper;
    window.$ = window.jQuery = $;
} catch (e) {}

import axios from 'axios';
window.axios = axios;

const user_token = document.querySelector('meta[name="user-token"]')?.content;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Authorization'] = `Bearer ${user_token}`;

/**
 * Create interceptor to listen for expired session tokens
 *
 * The Laravel API routes use the middleware CheckSession to ensure the session
 * hasn't expired. Otherwise it throws a 403 error. We then intercept the 403
 * and send the user to the login page.
 */
window.axios.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    (response) => {
        return response;
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    (error) => {
        // Logout if session expired
        if (
            error.response?.status === 403 &&
            error.response?.data?.errors?.length &&
            error.response.data.errors[0] === 'Session expired.'
        ) {
            console.info('Session expired. Attempting to log out and redirect to login page.');

            axios
                .post('/logout')
                .then(() => {})
                .catch((error) => {
                    if (error.response?.status === 419) {
                        console.error(error.response.data.message);
                        console.info('User likely already logged out.');
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    console.log('Redirecting to login page now.');
                    window.location.href = '/login';
                });
        }

        // Update dealership session token if session invalid
        if (
            [400, 500].includes(error.response?.status) &&
            error.response?.data?.errors?.length &&
            (error.response.data.errors.includes('Invalid Session ID') ||
                error.response.data.errors.includes('The session ID is invalid'))
        ) {
            const selectedDealershipId = document.querySelector('meta[name="selected-dealer-id"]')?.content;

            if (selectedDealershipId) {
                console.info('Attempting to update dealership session token');
                axios
                    .post(route('api.unotifi.updateDealershipSessionToken', { selectedDealer: selectedDealerId }))
                    .then((response) => {
                        console.info('Dealership session token updated successfully');
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {});
            }
        }

        // For all other statuses, continue as normal
        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
