<template>
<div>
    <!-- Bulk Message Composition Modal -->
    <div class="modal fade" id="compose_group_message_modal" tabindex="-1" role="dialog" aria-labelledby="compose_group_message_title" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="compose_group_message_title">Compose Group Message</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="Type message"
                        v-model="smsMessageInput"
                        :disabled="isLoading.sendBulkSMSMessage"
                    ></el-input>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">
                        {{ isLoading.sendBulkSMSMessage ? 'Close' : 'Cancel' }}
                    </button>
                    <button @click="sendBulkSMSMessage" :disabled="!smsMessageInput.length || isLoading.sendBulkSMSMessage" type="button" class="btn btn-alpha_primary">
                        <template v-if="isLoading.sendBulkSMSMessage">
                            <i style="height: 11px" class="fas fa-cog fa-spin"></i> Sending
                            <span class="sr-only">Loading...</span>
                        </template>
                        <template v-else>Send to ({{ customerPhoneNumbers.length }}) Customers</template>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Errors Modal -->
    <div class="modal fade" id="group_message_errors_modal" tabindex="-1" role="dialog" aria-labelledby="group_message_errors_title" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="group_message_errors_title">Unable to Send Message to the Following Customer/s</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <table class="table table-sm table-bordered mb-0">
                        <tr>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Error Message</th>
                        </tr>
                        <tr v-for="(error, index) in errors" :key="index">
                            <td>{{ error.customerName }}</td>
                            <td>{{ error.phoneNumber }}</td>
                            <td>{{ error.message }}</td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <modal-compose-group-message ref="modalComposeGroupMessage"></modal-compose-group-message>
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalComposeGroupMessage.showModal()">Compose Group Message</button>
 */
export default {
    props: {
        customerPhoneNumbers: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            errors: [],

            isLoading: {
                sendBulkSMSMessage: false,
            },

            smsMessageInput: '',
        }
    },

    methods : {
        showModal() {
            $('#compose_group_message_modal').modal('show');
        },

        sendBulkSMSMessage() {
            // Confirm sending message
            this.$confirm(`You're about to send a group message to (${this.customerPhoneNumbers.length}) customers, are you sure you want to continue?`, 'Warning', {
                confirmButtonText: 'Send',
                cancelButtonText: 'Cancel',
            }).then(() => {
                this.errors = [];

                this.isLoading.sendBulkSMSMessage = true;

                const params = {
                    smsText: this.smsMessageInput,
                    destinations: this.customerPhoneNumbers,
                };

                axios
                    .post(route('api.unotifi.sendBulkSMSMessage', { selectedDealer: this.selectedDealerId }), params)
                    .then(response => {
                        this.smsMessageInput = '';
                        this.errors = response.data.errors;

                        const numberOfMessagesAttempted = response.data.results.numberOfMessagesAttempted;
                        const numberOfMessagesSuccessfullySent = response.data.results.numberOfMessagesSuccessfullySent;

                        const notificationType = !numberOfMessagesSuccessfullySent ?
                            'error' : numberOfMessagesAttempted !== numberOfMessagesSuccessfullySent ?
                            'warning' : 'success';

                        const errorModalButton = this.errors.length ?
                            '<button type="button" class="btn btn-link" data-toggle="modal" data-target="#group_message_errors_modal">View Errors</button>' : '';

                        const notificationMessage = `${numberOfMessagesSuccessfullySent} of ${numberOfMessagesAttempted} messages sent successfully.${errorModalButton}`;

                        this.$message({
                            dangerouslyUseHTMLString: true,
                            type: notificationType,
                            message: notificationMessage
                        });

                        $('#compose_group_message_modal').modal('hide');
                    })
                    .catch(error => {
                        if(error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }

                        this.$message({
                            type: 'error',
                            message: 'There was an error sending the message'
                        });
                    })
                    .finally(() => {
                        this.isLoading.sendBulkSMSMessage = false;
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Cancelled sending group message'
                });
            });
        },
    },
}
</script>
