<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div class="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <button
                            @click="clearGetResultsInterval"
                            class="btn btn-link p-0 mr-2 font-12"
                            type="button"
                            :title="`Results auto-refresh is ${getResultsInterval ? 'enabled' : 'disabled'}.`"
                            :class="getResultsInterval ? 'text-alpha_success' : 'text-alpha_neutral'"
                        ><i class="fas fa-sync-alt"></i></button>

                        <a
                            href="#filters-results"
                            class="font-20"
                            data-toggle="collapse"
                            aria-expanded="true"
                            @click="servicesResultSize"
                        >Appointments</a>

                        <ServicesFavoriteButton
                            servicePageName="services-appointments"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="
                            filterResultsFormCurrentPage = 1;
                            $_generalMixin_scrollSimpleBarResultsList();
                            submitFilterResultsForm({clearResults: true})
                        ">
                            <!-- Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Name</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Customer Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Customer No.</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormCustomerNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormVINNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Appointment Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Appt. No.</span>
                                    </div>
                                    <input
                                        v-model="filterResultsFormAppointmentNumber"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter"
                                    >
                                </div>
                            </div>

                            <!-- Appointment Date -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsFormAppointmentDate"
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>

                            <!-- Opened By -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Opened By</span>
                                    </div>
                                    <el-select
                                        class="select-blue hide-overflow-tags w-100"
                                        v-model="filterResultsFormOpenedByASMIds"
                                        size="mini"
                                        multiple
                                        collapse-tags
                                        placeholder="Select"
                                        :disabled="! asms.length"
                                    >
                                        <el-option
                                            v-for="(asm, index) in [
                                                {asmName: 'Select Options', asmId: 'select-options'},
                                                ...asms
                                            ]"
                                            :key="index"
                                            :label="`${asm.asmName} (${asm.asmNumber})`"
                                            :value="asm.asmNumber"
                                            :disabled="asm.asmId === 'select-options'"
                                            :hidden="asm.asmId === 'select-options' && asms.length < 10"
                                        >
                                            <div
                                                v-if="asm.asmId === 'select-options'"
                                                class="el-select-dropdown__buttons"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByASMIds = asms.map(
                                                        item => item.asmNumber
                                                    )"
                                                >Select All</button>
                                                <button
                                                    type="button"
                                                    class="btn btn-link"
                                                    @click.stop="filterResultsFormOpenedByASMIds = []"
                                                >Select None</button>
                                            </div>
                                            <span v-else>
                                                {{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                            <!-- Arrival Status -->
                            <div class="text-center">
                                <el-checkbox-group
                                    v-model="filterResultsFormArrivalStatus"
                                    :min="1"
                                    :max="2"
                                >
                                    <el-checkbox
                                        v-for="(arrivalStatus, index) in ['Arrived', 'Not Arrived']"
                                        :label="arrivalStatus"
                                        :key="index"
                                    >{{ arrivalStatus }}</el-checkbox>
                                </el-checkbox-group>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button
                                        type="submit"
                                        :disabled="isLoadingResults.getResults"
                                        class="btn btn-alpha_primary btn-block btn-lg"
                                    >
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button
                                        @click="
                                            resetFilterResultsForm();
                                            $_generalMixin_scrollSimpleBarResultsList();
                                            submitFilterResultsForm({clearResults: true})
                                        "
                                        type="button"
                                        class="btn btn-alpha_secondary btn-block btn-lg"
                                    >
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <!-- Results -->
                <simplebar
                    ref="resultsList"
                    id="results_list"
                    class="overflow-auto clients-list-wrapper flex-grow-1 pt-3"
                    :style="servicesResultCss"
                >
                    <template v-if="results.resultsList">
                        <!-- Compose Group Message -->
                        <div
                            v-if="results.allCustomerPhoneNumbers && results.allCustomerPhoneNumbers.length"
                            class="text-center"
                        >
                            <button
                                type="button"
                                class="btn btn-link font-weight-bold mt-1 text-alpha_primary"
                                @click="$refs.modalComposeGroupMessage.showModal()"
                            >Compose Group Message</button>
                        </div>
                        <ul class="list-unstyled clients-list">
                            <li
                                v-for="(result, index) in results.resultsList"
                                @click="getResultInfo({ result: result, selectedDealerId: selectedDealerId })"
                                :class="{
                                    'active': isResultSelected(result.customer_id),
                                    'unread': $_generalMixin_getMessageStatusByCustomerId(
                                        result.customer_id, notifications.notifications
                                    ) === 'unread'
                                }"
                                :key="index"
                            >
                                <!-- Customer/Vehicle -->
                                <div class="clients-list__name flex-grow-1">
                                    <p class="font-14">{{ result.cust }}</p>
                                    {{ $moment(result.app_date_time).format('MM/DD/YY @h:mm A') }}
                                </div>

                                <!-- Customer Info -->
                                <div class="clients-list__info">
                                    <!-- Sentiment -->
                                    <div
                                        v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                        class="mood mood-sm mx-1"
                                        :class="`mood-${$_generalMixin_getCustomerMoodSettings(
                                            result.customer_id,
                                            customersSentiments
                                        ).color}`"
                                    >
                                        <i
                                            :class="$_generalMixin_getCustomerMoodSettings(
                                                result.customer_id, customersSentiments
                                            ).icon"
                                            title="Customer's mood guessed from their recent correspondence"
                                        ></i>
                                    </div>
                                    <div v-else class="mood mood-sm mx-1">
                                        <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                    </div>
                                    <!-- Notifications -->
                                    <div class="clients-list__notification">
                                        <div
                                            v-if="$_generalMixin_getMessageStatusByCustomerId(
                                                result.customer_id, notifications.notifications
                                            ) === 'unread'"
                                            class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1 badge-notify"
                                        >
                                            <i class="icon">textsms</i>
                                        </div>
                                    </div>
                                    <!-- Arrival Status -->
                                    <div class="clients-list__arrival-status mx-1 text-right">
                                        <i
                                            v-if="result.rostatus == 1 || recentlyMarkedArrived.includes(result.appointment_id)"
                                            class="far fa-2x fa-calendar-check text-success"
                                        ></i>
                                        <button
                                            v-else
                                            @click.prevent.stop="setAppointmentAsArrived(result.appointment_id)"
                                            type="button"
                                            class="btn p-0"
                                        >
                                            <i
                                                v-if="result.rostatus == 0 && isDatePastNow(result.app_date_time)"
                                                class="far fa-2x fa-calendar-times text-danger"
                                            ></i>
                                            <i v-else class="far fa-2x fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {
                        filterResultsFormCurrentPage = currentPage;
                        $_generalMixin_scrollSimpleBarResultsList();
                        submitFilterResultsForm({clearResults: true})
                    }"
                />
            </div>

        </div>

        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Repair Order Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <AppointmentInfo />
            </template>
        </div>

        <!-- Compose Group Message Modal -->
        <template v-if="results.allCustomerPhoneNumbers && results.allCustomerPhoneNumbers.length">
            <ModalComposeGroupMessage
                ref="modalComposeGroupMessage"
                :customer-phone-numbers="results.allCustomerPhoneNumbers"
            />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import { mapPreferences } from 'vue-preferences';
import AppointmentInfo from '@/components/services/AppointmentInfo.vue';
import ModalComposeGroupMessage from '@/components/services/child-components/ModalComposeGroupMessage.vue';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        AppointmentInfo,
        ModalComposeGroupMessage,
        ResultsPagination,
        ServicesFavoriteButton,
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filters to pass along with getResults function
            filterResultsParams: {},

             // arial-expanded is active or not
            filerResultActive: true,

            // Variable for results auto refresh timer
            getResultsInterval: null,

            // css Class for services result
            servicesResultCss:'max-height: 50vh',
        }
    },

    computed: {
        ...mapState('services/appointments', {
            filterResultsForm: 'filterResultsForm',
            recentlyMarkedArrived: 'recentlyMarkedArrived',
            results: 'results',
            isLoadingResults: 'isLoading',
        }),

        ...mapState('services/appointmentInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('services/appointmentInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapPreferences({
            appointments_searchForm_openedBy: {
                defaultValue: null
            },
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormAppointmentDate: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.appointmentDate;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', {
                    attribute: 'appointmentDate',
                    value: value
                });
            }
        },

        filterResultsFormAppointmentNumber: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.appointmentNumber;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', {
                    attribute: 'appointmentNumber',
                    value: value
                });
            }
        },

        filterResultsFormArrivalStatus: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.arrivalStatus;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'arrivalStatus', value: value });
            }
        },

        filterResultsFormOpenedByASMIds: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.openedByASMIds;
            },
            set(value) {
                this.appointments_searchForm_openedBy = Array.isArray(value) && value.length === 1 ? value[0] : null;

                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'openedByASMIds', value: value });
            }
        },

        filterResultsFormCustomerNumber: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.customerNumber;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'customerNumber', value: value });
            }
        },

        filterResultsFormName: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.name;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'name', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.services.appointments.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('services/appointments/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },
    },

    created() {
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        if (!this.results.resultsList) {
            this.submitFilterResultsForm(this.filterResultsParams);
        }

        // Console log the timezone for testing
        console.log('Timezone from Moment', this.$moment.tz.guess());
    },

    beforeDestroy() {
        clearTimeout(this.getResultsInterval);
    },

    methods: {
        // Vuex appointments actions
        ...mapActions('services/appointments', {
            getResults: 'getResults',
        }),

        // Vuex appointmentInfo actions
        ...mapActions('services/appointmentInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex appointments mutations
        ...mapMutations('services/appointments', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Submit results filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS([]);
            }

            // Clear previously set timeout for results auto refresh
            this.clearGetResultsInterval();

            // Get repair orders
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.resultsList.forEach((result, i) => {
                        if (result.customer_id && ! foundCustomerIds.includes(result.customer_id)) {
                            foundCustomerIds.push(result.customer_id);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({
                        selectedDealerId: this.selectedDealerId,
                        customerIds: foundCustomerIds
                    });

                    // Set timeout for results auto refresh
                    this.setGetResultsInterval();
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        /**
         * Set the auto refresh interval for fetching results
         */
        setGetResultsInterval() {
            this.clearGetResultsInterval();
            const autoRefreshInterval = this.userSession
                ? this.userSession.settings.dealership['results-auto-refresh-interval'] * 1000
                : 60000;

            this.getResultsInterval = setTimeout(() => {
                this.submitFilterResultsForm();
            }, autoRefreshInterval);
        },

        /**
         * Clear the auto refresh interval for fetching results
         */
        clearGetResultsInterval() {
            clearTimeout(this.getResultsInterval);
            this.getResultsInterval = null;
        },

        /**
         * Reset the results filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/appointments/RESET_FILTERS');

            this.$store.commit('services/appointments/SET_RESULTS', {});
        },

        /**
         * Check if the result info is selected
         */
        isResultSelected(customerId) {
            return this.resultInfo &&
                this.resultInfo.Customer.ID === customerId;
        },

        /**
         * Check to see if date is past
         */
        isDatePastNow(date) {
            const now = this.$moment();
            const dateToCompare = this.$moment(date)

            if (now > dateToCompare) {
                return true;
            }

            return false;
        },

        /**
         * Set appointment as arrived
         */
        setAppointmentAsArrived(appointmentId) {
            this.$confirm(
                `Are you sure you want to update the appointment status to Arrived?`,
                'Appointment Status Update',
                { confirmButtonText: 'OK', cancelButtonText: 'Cancel', }
            ).then(() => {
                axios
                    .post(route('api.unotifi.SetAppAsArrived', {
                        selectedDealer: this.selectedDealerId,
                        appointmentId: appointmentId
                    }))
                    .then(response => {
                        // Add the appointment Id to the recently marked appointments
                        this.$store.commit(
                            'services/appointments/PUSH_RECENTLY_MARKED_APPOINTMENT',
                            appointmentId
                        );
                    })
                    .catch(error => {
                        this.$message({
                            type: 'error',
                            message: 'Unable to mark the appointment as arrived.',
                        });

                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }
                    });
            });
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
            this.servicesResultCss = this.filerResultActive ? 'max-height: 80vh' : 'max-height: 55vh';
            this.filerResultActive = !this.filerResultActive;
        },
    },
}
</script>
