<template>
    <div class="row col-12 col-sm-6 col-lg-6 col-xl-6 pr-0">

        <div
            class="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 actions-group no-gutters pr-2"
            role="group"
        >
            <div v-if="true" class="dropdown">
                <button
                    class="d-lg-none btn btn-alpha_primary btn-lg w-100 dropdown-toggle"
                    type="button"
                    id="dropdownMenuAction1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >Actions</button>
                <button
                    class="d-none d-lg-inline-block btn btn-alpha_primary btn-xs w-100 dropdown-toggle"
                    type="button"
                    id="dropdownMenuAction1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >Actions</button>
                <ul class="dropdown-menu" role="menu">
                    <li v-if="parentType && parentType === 'Opportunities'">
                        <a class="dropdown-item" href="#">Log Activity</a>
                        <ul class="sub-menu dropdown-menu">
                            <li>
                                <a
                                    type="button"
                                    @click="addActivity('Call')"
                                    class="dropdown-item"
                                >Call</a>
                            </li>
                            <li>
                                <a
                                    type="button"
                                    @click="addActivity('Email')"
                                    class="dropdown-item"
                                >Email</a>
                            </li>
                            <li>
                                <a
                                    type="button"
                                    @click="addActivity('Text')"
                                    class="dropdown-item"
                                >Text</a>
                            </li>
                            <li>
                                <a
                                    type="button"
                                    @click="addActivity('Other')"
                                    class="dropdown-item"
                                >Other</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="dropdown-item"
                           target="_blank"
                           rel="noopener noreferrer"
                           :href="hasUpdateMileageUrl ? `${userSession.urls.dealership}pic_update_mileage.php?vid=${selectedVehicle.id}` : ''"
                           :class="{'disabled': !hasUpdateMileageUrl}"
                        >Update Mileage</a>
                    </li>
                    <li>
                        <a class="dropdown-item"
                           :href="getAppointmentUrl()"
                           rel="noopener noreferrer"
                           target="_blank"
                        >Appointments</a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="addVehicleNote(selectedVehicle)"
                        >Add Vehicle Note</a>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dropdown-item"
                            @click="$refs.modalCreateNewChat.showModal()"
                        >+ Chat</button>
                    </li>
                    <li v-if="hasAutoMerchantAccount">
                        <button
                            type="button"
                            class="dropdown-item"
                            @click="$refs.modalAddManualPayment.showModal()"
                        >+ Payment Request</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 p-0" >
            <button
                type="button"
                class="d-lg-none btn btn-alpha_primary btn-lg w-100"
                @click="$refs.modalViewServiceHistory.showModal()"
            >Service History</button>
            <button
                type="button"
                class="d-none d-lg-inline-block btn btn-alpha_primary btn-xs w-100"
                @click="$refs.modalViewServiceHistory.showModal()"
            >Service History</button>
        </div>

        <!-- View Service History Modal -->
        <ModalViewServiceHistory
            ref="modalViewServiceHistory"
            :vehicle="selectedVehicle"
        />

        <!-- New Chat -->
        <ModalCreateNewChat
            ref="modalCreateNewChat"
            :selected-vehicle-id="selectedVehicle.id"
            :selected-customer-id="selectedCustomer.ID"
        />

        <!-- Add Payment Modal -->
        <ModalAddManualPayment
            v-if="hasAutoMerchantAccount"
            ref="modalAddManualPayment"
            :customer-phone-numbers="customerPhoneNumbers"
            :selected-vehicle="selectedVehicle"
        />
    </div>

</template>

<script>
import { mapState } from "vuex";
import ModalAddManualPayment from '@/components/modals/ModalAddManualPayment.vue';
import ModalCreateNewChat from '@/components/modals/ModalCreateNewChat.vue';
import ModalViewServiceHistory from '@/components/modals/ModalViewServiceHistory.vue';

export default {
    components: {
        ModalAddManualPayment,
        ModalCreateNewChat,
        ModalViewServiceHistory,
    },

    props: {
        parentId: {
            default: null,
            type: String,
        },
        parentType: {
            default: null,
            validator(value) {
                // The value must match one of these strings
                return ['Opportunities'].indexOf(value) !== -1
            },
        },
        customerPhoneNumbers: {
            type: Array,
            default: () => [],
        },
        selectedCustomer: {
            type: Object,
        },
        selectedVehicle: {
            type: Object,
        }
    },

    data() {
        return {
            isLoading: {
                addActivity: false,
                addVehicleNote: false,
            },
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        /**
         * Checks if vehicle dealer's URL is present
         */
        hasUpdateMileageUrl() {
            return this.userSession?.urls?.dealership && this.selectedVehicle?.id;
        },

        hasAutoMerchantAccount() {
            return Boolean(this.userSession?.hasAutoMerchantAccount);
        },
    },

    methods: {
        /**
         * Get the customer/vehicle appointment URL
         */
        getAppointmentUrl() {
            let url = 'https://timehighway.com/';

            if (this.userSession?.urls?.appointments) {
                url = this.userSession.urls.appointments;
                url = url.replace('{customer_first_name}', this.selectedCustomer.firstName ?? '');
                url = url.replace('{customer_last_name}', this.selectedCustomer.lastName ?? '');
                url = url.replace('{vehicle_vin}', this.selectedVehicle.VIN ?? '');
            }

            // Make sure http is prepended to URL
            if (!/^https?:\/\//i.test(url)) {
                url = 'http://' + url;
            }

            return url;
        },

        /**
         * Add Activity
         */
        addActivity(activity) {
            const params = {
                activity: activity,
                parentType: this.parentType,
                status: 'Completed',
                parentId: this.parentId,
            }

            this.isLoading.addActivity = true;

            axios
                .post(
                    route(
                        'api.unotifi.AddActivity',
                        { selectedDealer: this.selectedDealerId }
                    ),
                    params
                )
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Activity logged successfully'
                        });
                        this.$emit('update-activity');
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `There was an error logging the activity:
                            ${activity}`
                    });
                })
                .finally(() => {
                    this.isLoading.addActivity = false;
                });
        },

        /**
         * Add vehicle note prompt
         */
        addVehicleNote(vehicle) {
            this.$prompt(`${vehicle.year} ${vehicle.v_make} ${vehicle.v_model}`, 'Add Vehicle Notes', {
                    cancelButtonClass: 'btn btn-alpha_secondary',
                    cancelButtonText: 'Cancel',
                    confirmButtonClass: 'btn btn-alpha_primary',
                    confirmButtonText: 'OK',
                })
                .then(({ value }) => {
                    this.isLoading.addVehicleNote = true;

                    const params = {
                        note: value,
                    }

                    axios
                        .post(
                            route(
                                'api.unotifi.AddVehicleNote',
                                {
                                    selectedDealer: this.selectedDealerId,
                                    vehicleId: vehicle.id
                                }
                            ),
                            params
                        )
                        .then(response => {
                            if (response.data.success) {
                                this.$message({
                                    type: 'success',
                                    message: 'Note added successfully'
                                });
                                this.$emit('update-vehicle-notes');
                            }
                        })
                        .catch(error => {
                            if (error.response?.data?.errors) {
                                console.error(error.response.data.errors);
                            } else {
                                console.error(error);
                            }
                        })
                        .finally(() => {
                            this.isLoading.addVehicleNote = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Input canceled'
                    });
                });
        },

    },

    mounted() {
        $(document).ready(function(){
            $('.dropdown-submenu a.test').on("ready", function(e){
                $(this).next('ul').toggle();
                e.stopPropagation();
                e.preventDefault();
            });
        });
    },

}

</script>

<style lang="scss" scoped>
    .dropdown-menu {
        .sub-menu {
            left: 100%;
            margin-top: -1px;
            position: fixed;
            top: 0;
            visibility: hidden;
        }

        li {
            &:hover > .sub-menu {
                visibility: visible;
                display: block;
            }
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        .right-100 {
            right:0;
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        .right-100 {
            right:10px;
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        .right-100 {
            right:50px;
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1800px) {
        .right-menu-lg {
            right:170px;
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 2000px) {
        .right-menu-xl {
            right:80px;
        }
    }

</style>
