<template>
    <div class="container-fluid p-sm-2">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-5">
                <span class="font-20 font-weight-bolder">Business Development</span>
            </div>

            <!-- Filter -->
            <div class="col-12 col-md-8 col-lg-7">
                <form @submit.prevent="getCharts()">
                    <div class="row mb-4 mb-lg-0">
                        <!-- Date -->
                        <div class="col-12 col-sm-6 col-md-4 mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Date Range</span>
                                </div>
                                <v-date-picker
                                    v-model="filterResultsForm.dateFromTo"
                                    :max-date='new Date()'
                                    :masks="{
                                        input: ['M/D/YY'],
                                    }"
                                    :attributes="[{
                                        key: 'today',
                                        dot: 'red',
                                        dates: new Date(),
                                    }]"
                                    class="w-100"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                                        <input
                                            readonly
                                            class="v-calendar-select-blue w-100"
                                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                        <!-- Opened By -->
                        <div class="col-12 col-sm-6 col-md-4 mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Opened By</span>
                                </div>
                                <el-select
                                    class="select-blue hide-overflow-tags w-100"
                                    v-model="filterResultsForm.asmId"
                                    size="mini"
                                    placeholder="Select"
                                    :disabled="! asms.length"
                                >
                                    <el-option
                                        v-for="(asm, index) in asms"
                                        :key="index"
                                        :label="`${asm.asmName} (${asm.asmNumber})`"
                                        :value="asm.asmId">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <button :disabled="! filterResultsForm.asmId || isLoading.getCharts" type="submit" class="btn btn-alpha_primary btn-xs">
                                <template v-if="isLoading.getCharts">
                                    <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                    <span class="sr-only">Loading...</span>
                                </template>
                                <template v-else>Filter</template>
                            </button>
                            <button @click="printPage()" :disabled="! chartData" type="button" class="btn btn-alpha_neutral--shift btn-xs"><i class="icon">print</i> Print</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Charts -->
        <transition name="fade">
            <div v-if="chartData" v-loading="isLoading.getCharts" id="charts_to_print" class="row bg-white">
                <!-- Opportunity Count Chart -->
                <div v-if="opportunityCountChart.data" class="col-12 col-md-6">
                    <div class="row p-2">
                        <div class="col-12">
                            <horizontal-bar-chart
                                class="chart-wrapper"
                                label="User Opportunities"
                                :chart-data="opportunityCountChart.data"
                                :options="opportunityCountChart.options"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row p-2">
                        <div class="col-12">
                            <div class="row">
                                <!-- BDR Win/Loss Chart -->
                                <div v-if="bdrWinLossChart.data" class="col-12 col-sm-6">
                                    <doughnut-chart
                                        class="chart-wrapper"
                                        label="BDR Win/Loss"
                                        :chart-data="bdrWinLossChart.data"
                                        :options="bdrWinLossChart.options"
                                        :plugins="bdrWinLossChart.plugins"
                                    />
                                </div>
                                <!-- Team Win/Loss Chart -->
                                <div v-if="teamWinLossChart.data" class="col-12 col-sm-6">
                                    <doughnut-chart
                                        class="chart-wrapper"
                                        label="Team Win/Loss"
                                        :chart-data="teamWinLossChart.data"
                                        :options="teamWinLossChart.options"
                                        :plugins="teamWinLossChart.plugins"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BDR Opportunity Count By Resolution Chart -->
                <div v-if="bdrOpportunityCountByResolutionChart.data" class="col-12 col-md-6">
                    <div class="row p-2">
                        <div class="col-12">
                            <horizontal-bar-chart
                                class="chart-wrapper"
                                label="BRD Opportunity Count By Resolution"
                                :chart-data="bdrOpportunityCountByResolutionChart.data"
                                :options="bdrOpportunityCountByResolutionChart.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- BDR Opportunity Count By Campaign -->
                <div v-if="bdrOpportunityCountByCampaignChart.data" class="col-12 col-md-6">
                    <div class="row p-2">
                        <div class="col-12">
                            <horizontal-bar-chart
                                class="chart-wrapper"
                                label="BRD Opportunity Count By Campaign"
                                :chart-data="bdrOpportunityCountByCampaignChart.data"
                                :options="bdrOpportunityCountByCampaignChart.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- BDR Share of Total -->
                <div v-if="bdrShareOfTotalChart.data" class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                    <div class="row p-2">
                        <div class="col-12">
                            <pie-chart
                                class="chart-wrapper"
                                label="BDR Share of Total"
                                :chart-data="bdrShareOfTotalChart.data"
                                :options="bdrShareOfTotalChart.options"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import DoughnutChart from '@/components/analytics/charts/DoughnutChart.vue';
import { centerTextPlugin } from '@/components/analytics/charts/ChartJsPlugins';
import HorizontalBarChart from '@/components/analytics/charts/HorizontalBarChart.vue';
import PieChart from '@/components/analytics/charts/PieChart.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },

    data() {
        return {
            centerTextPlugin: centerTextPlugin,

            chartColors: {
                color1: 'rgb(255, 174, 201)',
                color1Border: 'rgb(255, 118, 149)',
                color2: 'rgb(154, 208, 245)',
                color2Border: 'rgb(88, 178, 239)',
            },

            chartData: null,

            filterResultsForm: {
                asmId: '',
                dateFromTo: {
                    start: new Date(),
                    end: new Date(),
                },
            },

            isLoading: {
                getCharts: false,
            },
        }
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        opportunityCountChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: userData } = this.chartData.find(result => result.name == "User Opportunities");
                const { items: teamData } = this.chartData.find(result => result.name == "Team Average");

                if (userData.length || teamData.length) {
                    chart.data = {
                        labels: ['Call Back', 'In Progress', 'Won', 'Closed'],
                        datasets: [{
                            label: 'BDR',
                            backgroundColor: this.chartColors.color1,
                            borderColor: this.chartColors.color1Border,
                            borderWidth: 1,
                            minBarLength: 2,
                            data: [
                                Math.max(Math.round(userData.find(o => o.name == 'Call Back').value)),
                                Math.max(Math.round(userData.find(o => o.name == 'In Progress').value)),
                                Math.max(Math.round(userData.find(o => o.name == 'Win').value)),
                                Math.max(Math.round(userData.find(o => o.name == 'Closed').value)),
                            ]
                        }, {
                            label: 'Team',
                            backgroundColor: this.chartColors.color2,
                            borderColor: this.chartColors.color2Border,
                            borderWidth: 1,
                            minBarLength: 2,
                            data: [
                                Math.max(Math.round(teamData.find(o => o.name == 'Call Back Team').value)),
                                Math.max(Math.round(teamData.find(o => o.name == 'In Progress Team').value)),
                                Math.max(Math.round(teamData.find(o => o.name == 'Win Team').value)),
                                Math.max(Math.round(teamData.find(o => o.name == 'Closed Team').value)),
                            ]
                        }],
                    };

                    chart.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { position: 'bottom' },
                        title: {
                            display: true,
                            text: 'Opportunity Count',
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                    var label = data.datasets[tooltipItem.datasetIndex].label;

                                    if (value < 1) {
                                        value = 0;
                                    }

                                    return label + ': ' + value;
                                }
                            }
                        },
                        scales: {
                            xAxes: [{ ticks: { beginAtZero: true, precision: 0, } }],
                            yAxes: [{ ticks: { beginAtZero: true, precision: 0, } }],
                        },
                    };
                }
            }

            return chart;
        },

        bdrWinLossChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: winLossData } = this.chartData.find(o => o.name == "Win\/Loss Ratios");

                if (winLossData.length) {
                    const winValUser = winLossData.find(o => o.name.startsWith('Win User')).value;
                    const closedValUser = winLossData.find(o => o.name.startsWith('Closed User')).value;
                    const winPercent = winValUser + closedValUser ? Math.round(winValUser / (winValUser + closedValUser) * 100) : 0;

                    chart.data = {
                        labels: [
                            "Won",
                            "Lost"
                        ],
                        datasets: [{
                            data: [winPercent, 100 - winPercent],
                            backgroundColor: [
                                "rgb(255,99,131)",
                                "rgb(54, 162, 235)",
                            ],
                            hoverBackgroundColor: [
                                "rgb(255,99,131)",
                                "rgb(54, 162, 235)",
                            ]
                        }]
                    };

                    chart.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: 'bottom',
                        },
                        elements: {
                            center: {
                                text: winPercent < 1 ? '0%' : winPercent + "%",
                                color: 'rgb(102, 102, 102)', // Default is #000000
                                fontStyle: 'Arial', // Default is Arial
                                sidePadding: 20, // Default is 20 (as a percentage)
                                minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                                lineHeight: 25 // Default is 25 (in px), used for when text wraps
                            }
                        },
                        title: {
                            display: true,
                            text: 'BDR Win/Loss'
                        },
                    };

                    chart.plugins = this.centerTextPlugin;
                }
            }

            return chart;
        },

        teamWinLossChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: winLossData } = this.chartData.find(o => o.name == "Win\/Loss Ratios");

                if (winLossData.length) {
                    const winValTeam = winLossData.find(o => o.name.startsWith('Win Team')).value;
                    const closedValTeam = winLossData.find(o => o.name.startsWith('Closed Team')).value;
                    const winPercent = winValTeam + closedValTeam ? Math.round(winValTeam / (winValTeam + closedValTeam) * 100) : 0;

                    chart.data = {
                        labels: [
                            "Won",
                            "Lost"
                        ],
                        datasets: [{
                            data: [winPercent, 100 - winPercent],
                            backgroundColor: [
                                "rgb(255,99,131)",
                                "rgb(54, 162, 235)",
                            ],
                            hoverBackgroundColor: [
                                "rgb(255,99,131)",
                                "rgb(54, 162, 235)",
                            ]
                        }]
                    };

                    chart.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: 'bottom',
                        },
                        elements: {
                            center: {
                                text: winPercent < 1 ? '0%' : winPercent + "%",
                                color: 'rgb(102, 102, 102)', // Default is #000000
                                fontStyle: 'Arial', // Default is Arial
                                sidePadding: 20, // Default is 20 (as a percentage)
                                minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                                lineHeight: 25 // Default is 25 (in px), used for when text wraps
                            }
                        },
                        title: {
                            display: true,
                            text: 'Team Win/Loss'
                        },
                    };

                    chart.plugins = this.centerTextPlugin;
                }
            }

            return chart;
        },

        bdrOpportunityCountByResolutionChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: oppByStatusData } = this.chartData.find(o => o.name == "Opportunities by Status");

                if (oppByStatusData.length) {
                    chart.data = {
                        labels: oppByStatusData.map(o => o.name),
                        datasets: [{
                            backgroundColor: this.chartColors.color1,
                            borderColor: this.chartColors.color1Border,
                            borderWidth: 1,
                            minBarLength: 2,
                            data: oppByStatusData.map(o => o.value),
                        }]
                    };

                    chart.options = {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                    if (value < 1) {
                                        value = 0;
                                    }

                                    return value;
                                }
                            }
                        },
                        scales: {
                            xAxes: [{ ticks: { beginAtZero: true } }],
                            yAxes: [{ ticks: { beginAtZero: true } }],
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { display: false },
                        title: {
                            display: true,
                            text: 'BDR Opportunity Count By Resolution'
                        }
                    };
                }
            }

            return chart;
        },

        bdrOpportunityCountByCampaignChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: oppByCampaignData } = this.chartData.find(o => o.name == "Opportunities by Campaign");

                if (oppByCampaignData.length) {
                    chart.data = {
                        labels: oppByCampaignData.map(o => o.name),
                        datasets: [{
                            backgroundColor: this.chartColors.color2,
                            borderColor: this.chartColors.color2Border,
                            borderWidth: 1,
                            minBarLength: 2,
                            data: oppByCampaignData.map(o => o.value),
                        }]
                    };

                    chart.options = {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                    if (value < 1) {
                                        value = 0;
                                    }

                                    return value;
                                }
                            }
                        },
                        scales: {
                            xAxes: [{ ticks: { beginAtZero: true } }],
                            yAxes: [{ ticks: { beginAtZero: true } }],
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { display: false },
                        title: {
                            display: true,
                            text: 'BDR Opportunity Count by Campaign'
                        }
                    };
                }
            }

            return chart;
        },

        bdrShareOfTotalChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (this.chartData) {
                const { items: resultsData} = this.chartData.find(o => o.name == "Results");

                if (resultsData.length) {
                    const totalOppsCnt = resultsData.find(o => o.name == 'Total eligible opportunities' && o.serie == 'All').value;
                    const userCallbackCnt = resultsData.find(o => o.name == 'User Progress' && o.serie == 'CallBack').value;
                    const userInProgressCnt = resultsData.find(o => o.name == 'User Progress' && o.serie == 'In Progress').value;
                    const userWonCnt = resultsData.find(o => o.name == 'User Progress' && o.serie == 'Won').value;
                    const userClosedCnt = resultsData.find(o => o.name == 'User Progress' && o.serie == 'Closed').value;
                    const remainingOpps = totalOppsCnt - userCallbackCnt - userInProgressCnt - userWonCnt - userClosedCnt;

                    chart.data = {
                        labels: [
                            "Other Opportunities",
                            "Callback",
                            "In Progress",
                            "Won",
                            "Closed",
                        ],
                        datasets: [{
                            data: [remainingOpps, userCallbackCnt, userInProgressCnt, userWonCnt, userClosedCnt],
                            backgroundColor: [
                                "rgb(54, 162, 235)",
                                "rgb(255, 159, 64)",
                                "rgb(255,99,131)",
                                "rgb(75, 192, 192)",
                                "rgb(255, 205, 86)",
                            ],
                            hoverBackgroundColor: [
                                "rgb(54, 162, 235)",
                                "rgb(255, 159, 64)",
                                "rgb(255, 99, 131)",
                                "rgb(75, 192, 192)",
                                "rgb(255, 205, 86)",
                            ]
                        }]
                    };

                    chart.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: 'bottom',
                        },
                        title: {
                            display: true,
                            text: 'BDR Share of Total'
                        }
                    }
                }
            }

            return chart;
        },
    },

    created() {
        this.setMixPanel();
        // Get ASM list if empty
        if (! this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }
    },

    methods: {
        // Vuex services actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),

        async getCharts() {
            this.isLoading.getCharts = true;

            const tab = 'Business Development';
            const params = this.filterResultsForm;

            await axios
                .get(route('api.unotifi.GetCharts', { selectedDealer: this.selectedDealerId, tab: tab }), { params: params })
                .then(response => {
                    if (response.data.success) {
                        this.chartData = response.data.results;
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.getCharts = false;
                });
        },

        printPage() {
            window.print();
        },

        setMixPanel(){
            this.$mixpanel.identify(this.userSession?.externalUserId);
            this.$mixpanel.track('business-development',{
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
                asm_id: this.selectedASM?.asmId,
                asm_name: this.selectedASM?.asmName,
                asm_dealername: this.selectedASM?.dealerName,
                asm_location_code: this.selectedASM?.locationCode,
                asm_location_id: this.selectedASM?.locationId,
                asm_location_name: this.selectedASM?.locationName,
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
    max-height: 90vh;
    position: relative;
}

// Vue transition classes
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
