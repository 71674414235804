<template>
<div class="communication communication__header communication__header--full-width">
    <div class="communication__icon">
        <div class="icon">report_problem</div>
    </div>
    <div class="communication__content">
        <div class="communication__header communication__header--full-width">
            <strong>Recall Notice</strong>

            <div class="communication__date">
                {{ $moment(recall.recall_date).format('ll') }}
            </div>
        </div>
        <div class="communication__item w-100">
            <h5>Defect Description</h5>
            <p class="card-text" v-html="recall.desc_defect"></p>
            <h5>Possible Consequence</h5>
            <p class="card-text" v-html="recall.conequence_defect"></p>
            <h5>Corrective Action</h5>
            <p class="card-text" v-html="recall.corrective_action"></p>
            <h5>Notes</h5>
            <p class="card-text" v-html="recall.notes"></p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        recall: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
