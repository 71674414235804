<template>
    <div
        id="password-update_modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="password-update_modal_title"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-sm modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Password</h5>

                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="updatePassword">
                        <!-- Current Password -->
                        <div class="form-group">
                            <label for="current_password">
                                Current Password
                            </label>

                            <input
                                v-model="currentPassword"
                                id="current_password"
                                type="password"
                                class="form-control"
                                name="current_password"
                                required
                            >
                        </div>

                        <!-- New Password -->
                        <div class="form-group">
                            <label for="password">New Password</label>

                            <input
                                v-model="password"
                                id="password"
                                type="password"
                                class="form-control"
                                name="password"
                                required
                            >
                        </div>

                        <!-- Confirm Password -->
                        <div class="form-group">
                            <label for="password_confirmation">
                                Confirm Password
                            </label>

                            <input
                                v-model="passwordConfirm"
                                id="password_confirmation"
                                type="password"
                                class="form-control"
                                name="password"
                                required
                            >
                        </div>

                        <button
                            class="btn btn-sm btn-alpha_primary"
                            type="submit"
                            :disabled="isLoading.updatePassword"
                        >
                            Save
                            <i
                                v-if="isLoading.updatePassword"
                                class="fas fa-spinner fa-spin"
                            ></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: {
                updatePassword: false,
            },

            currentPassword: '',
            password: '',
            passwordConfirm: '',
        }
    },

    methods: {
        showModal() {
            this.resetForm();
            $('#password-update_modal').modal('show');
        },

        hideModal() {
            $('#password-update_modal').modal('hide');
            this.resetForm();
        },

        resetForm() {
            this.currentPassword = '';
            this.password = '';
            this.passwordConfirm = '';
        },

        updatePassword() {
            const params = {
                current_password: this.currentPassword,
                password: this.password,
                password_confirmation: this.passwordConfirm,
            }

            this.isLoading.updatePassword = true;

            axios
                .put(route('user-password.update',), params)
                .then(() => {
                    this.hideModal();

                    this.$message({
                        type: 'success',
                        message: 'Password updated.'
                    });
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: error.response.data.message
                            ? error.response.data.message
                            : 'An error occurred. Please try again.'
                    });
                })
                .finally(() => {
                    this.isLoading.updatePassword = false;
                });
        },
    }
}
</script>
