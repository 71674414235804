<template>
    <div class="customer-info h-100 pt-3 py-md-4 px-2 px-md-3">
        <!-- Selected Lead Info -->
        <div class="row row-cols-1 d-md-block mb-md-3">
            <div class="col d-flex align-items-center d-md-block order-1">
                <div class="text-md-center">
                    <strong class="font-14">{{ customerInfo.first_name }} {{ customerInfo.last_name }}</strong>
                    <button
                        @click="$_copyMethods_copyText(`${customerInfo.first_name} ${customerInfo.last_name}`)"
                        class="btn badge badge-alpha_neutral--shift"
                        type="button"
                        title="Copy customer name"
                    ><i class="icon">file_copy</i></button>
                    <div class="mb-2">
                        {{ customerVehicle.year }}
                        {{ customerVehicle.make }}
                        {{ customerVehicle.model }}
                        {{ !customerVehicle.year && !customerVehicle.make && !customerVehicle.model ? 'N/A' : '' }}
                    </div>
                </div>
            </div>
            <div class="col order-3 order-lg-2 my-2">
                <div class="row row-cols-2 row-cols-md-1 sm-gutters">
                    <div class="col">
                        <button
                            type="button"
                            data-toggle="modal"
                            data-target="#customer_contact_info_modal"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >Contact Info</button>
                    </div>
                    <div v-if="customerPhoneNumbers.length" class="col">
                        <div class="btn-group w-100">
                            <button
                                class="btn btn-alpha_primary btn-sm btn-block mb-2 dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                :disabled="customerInfo.do_not_call == 1"
                                :title="customerInfo.do_not_call == 1 ? 'Customer is opted out from receiving calls' : ''"
                            >Call</button>
                            <div class="dropdown-menu">
                                <a
                                    v-for="(phoneNumber, index) in customerPhoneNumbers"
                                    :key="index"
                                    :href="`tel:${phoneNumber.number}`"
                                    class="dropdown-item"
                                >
                                    {{ phoneNumber.label }}: {{ formatPhoneNumber(phoneNumber.number) }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="btn-group w-100">
                            <button
                                class="btn btn-alpha_primary btn-sm btn-block mb-2 dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >Log Activity</button>
                            <div class="dropdown-menu">
                                <button v-for="(activity, index) in ['Call', 'Email', 'Text', 'Other']" :key="index" @click="addActivity(activity)" type="button" class="dropdown-item">
                                    Log {{ activity}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="customerInfo.email" class="col">
                        <a
                            v-if="customerInfo.do_not_email != 1"
                            :href="`mailto:${customerInfo.email}`"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >Email</a>
                        <button
                            v-else
                            title="Customer is opted out from receiving emails"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                            type="button"
                            disabled="true"
                        >Email</button>
                    </div>
                    <div class="col">
                        <button
                            @click="addCustomerNote"
                            type="button"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >+ Note</button>
                    </div>
                </div>
            </div>
            <div class="col order-2 order-lg-3 mt-2">
                <div class="row row-cols-3 row-cols-md-1 px-1 font-weight-bolder">
                    <div class="col mb-2 order-2">
                        <p class="text-uppercase">ASM</p>
                        <span class="text-alpha_text" v-if="customerASM">
                            {{ customerASM.first_name }} {{ customerASM.last_name }} – {{ customerASM.asmNumber }}
                        </span>
                        <span v-else>N/A</span>
                    </div>
                    <div class="col-12 mb-2 order-4 order-md-3">
                        <p class="text-uppercase">
                            Email
                            <button
                                v-if="customerInfo.email"
                                @click="$_copyMethods_copyText(customerInfo.email)"
                                class="btn badge badge-alpha_neutral--shift"
                                type="button"
                                title="Copy customer email"
                            ><i class="icon">file_copy</i></button>
                        </p>
                        <a v-if="customerInfo.email && customerInfo.do_not_email != 1" :href="`mailto:${customerInfo.email}`" class="text-alpha_text">{{ customerInfo.email }}</a>
                        <span v-else-if="customerInfo.email" class="text-alpha_text">{{ customerInfo.email }}</span>
                        <span v-else>N/A</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact Info Modal -->
        <modal-lead-contact-info
            @update-result-info="$emit('update-result-info')"
        />

        <!-- Add Case Modal -->
        <!-- <modal-add-a-case
            ref="modalAddACase"
            :asms='asms'
            :customer="customer"
            :filtered-cars="filteredCars"
            :selected-a-s-m="selectedASM"
            :selected-vehicle-id="selectedVehicleId"
        /> -->
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { copyMethods } from '@/mixins/helperMixins';
import ModalAddACase from '@/components/services/Customer/ModalAddACase.vue';
import ModalLeadContactInfo from '@/components/services/Customer/ModalLeadContactInfo.vue';

export default {
    components: {
        'modal-add-a-case': ModalAddACase,
        'modal-lead-contact-info': ModalLeadContactInfo,
    },

    mixins: [copyMethods],

    props: {
        //
    },

    data() {
        return {
            isLoading: {
                addActivity: false,
                addCustomerNote: false,
            },
        }
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', [
            'userSession',
        ]),

        ...mapState('services/leadInfo', {
            resultInfo: 'resultInfo',
        }),

        ...mapGetters('services/leadInfo', {
            customerASM: 'customerASM',
            customerInfo: 'customerInfo',
            customerPhoneNumbers: 'customerPhoneNumbers',
            customerVehicle: 'customerVehicle',
        }),
    },

    created() {
        this.setMixPanel();
    },

    mounted() {
        //
    },

    methods : {
        /**
         * Vuex leadInfo actions
         */
        ...mapActions('services/leadInfo', {
            updateResultInfo: 'updateResultInfo',
        }),

        formatPhoneNumber,

        /**
         * Add customer note prompt
         */
        addCustomerNote() {
            this.$prompt(`Add a Note About ${this.customerInfo.first_name} ${this.customerInfo.last_name }`, 'Add Customer Note', {
                    cancelButtonClass: 'btn btn-alpha_secondary',
                    cancelButtonText: 'Cancel',
                    confirmButtonClass: 'btn btn-alpha_primary',
                    confirmButtonText: 'OK',
                })
                .then(({ value }) => {
                    this.isLoading.addCustomerNote = true;

                    const params = {
                        note: value,
                    }

                    axios
                        .post(route('api.unotifi.AddCustomerNote', { selectedDealer: this.selectedDealerId, customerId: this.resultInfo.info.id }), params)
                        .then(response => {
                            if (response.data.success) {
                                this.$emit('success');
                                this.$message({
                                    type: 'success',
                                    message: 'Note added successfully'
                                });
                                this.$emit('update-customer-notes');
                            }
                        })
                        .catch(error => {
                            if (error.response?.data?.errors) {
                                console.error(error.response.data.errors);
                            } else {
                                console.error(error);
                            }
                        })
                        .finally(() => {
                            this.isLoading.addCustomerNote = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Input canceled'
                    });
                });
        },

        /**
         * Add Activity
         */
        addActivity(activity) {
            const params = {
                activity: activity,
                parentType: 'Leads',
                status: 'Completed',
                parentId: this.resultInfo.info.id,
            }

            this.isLoading.addActivity = true;

            axios
                .post(
                    route(
                        'api.unotifi.AddActivity',
                        { selectedDealer: this.selectedDealerId }
                    ),
                    params
                )
                .then(response => {
                    if (response.data.success) {
                        this.$message({
                            type: 'success',
                            message: 'Activity logged successfully'
                        });

                        this.updateResultInfo({
                            selectedDealerId: this.selectedDealerId,
                            leadId: this.resultInfo.info.id
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `There was an error logging the activity:
                            ${activity}`
                    });
                })
                .finally(() => {
                    this.isLoading.addActivity = false;
                });
        },

        setMixPanel(){
            this.$mixpanel.identify(this.userSession?.externalUserId);
            this.$mixpanel.track('customer-info',{
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
                asm_id: this.selectedASM?.asmId,
                asm_name: this.selectedASM?.asmName,
                asm_dealername: this.selectedASM?.dealerName,
                asm_location_code: this.selectedASM?.locationCode,
                asm_location_id: this.selectedASM?.locationId,
                asm_location_name: this.selectedASM?.locationName,
            });
        }
    },
}

</script>
