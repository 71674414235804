import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

const tracker = new Tracker({
  projectKey: "yv4eDgMmMkafCfnzMaxp",  
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  obscureInputEmails: false,
  obscureInputDates: false,
  network: {
      sessionTokenHeader: false,
      failuresOnly: false,
      ignoreHeaders: false,
      capturePayload: true,
      captureInIframes: true,
  },
});

tracker.use(trackerAssist({}));
tracker.start();

const userSession = document.querySelector('meta[name="user-session"]')?.content;

if (userSession) {
    const userInfo = JSON.parse(userSession)?.userInfo;

    if(userInfo) {

        const userSessionId = [userInfo.id, userInfo.name, userInfo.email]
            .filter(Boolean)
            .join(' - ');

        tracker.setUserID(userSessionId);
    }
}