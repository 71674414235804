<template>
    <div class="container-fluid p-sm-2">
        <div class="row">
            <div class="col-12 d-flex justify-content-between mb-4">
                <span class="font-20 font-weight-bolder">Campaign Reports</span>
                <button type="button" class="btn btn-alpha_primary" @click="$refs.modalCreateNewReport.showModal()">
                    New Report
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <reports-list ref="reportsList" />
            </div>
        </div>

        <!-- Create New Report Modal -->
        <modal-create-new-report ref="modalCreateNewReport"
            @reload-reports="$refs.reportsList.getReports()"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ModalCreateNewReport from "@/components/modals/ModalCreateNewReport.vue";
import ReportsList from "@/components/reports/ReportsList.vue";

export default {
    components: {
        'modal-create-new-report': ModalCreateNewReport,
        'reports-list': ReportsList,
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),
    },

    created() {
        // Get ASM list if empty
        if (! this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }
    },

    methods: {
        // Vuex services actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),
    },
}
</script>
