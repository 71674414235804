<template>
<div v-if="lastPage && lastPage > 1" class="column-module-button">
    <nav aria-label="Repair Orders pagination">
        <ul class="pagination pagination-sm justify-content-center">
            <li class="page-item" :class="{'disabled': currentPage == 1}">
                <button @click="$emit('update-current-page', 1)" type="button" class="page-link" aria-label="First" :disabled="currentPage == 1">
                    <i aria-hidden="true" class="fas fa-angle-double-left"></i>
                    <span class="sr-only">First</span>
                </button>
            </li>
            <li class="page-item" :class="{'disabled': currentPage == 1}">
                <button @click="$emit('update-current-page', currentPage - 1)" type="button" class="page-link" aria-label="Previous" :disabled="currentPage == 1">
                    <i aria-hidden="true" class="fas fa-angle-left"></i>
                    <span class="sr-only">Previous</span>
                </button>
            </li>
            <li class="page-item" :title="`Page ${currentPage} of ${lastPage} (${totalResults} results)`">
                <span class="page-link">
                    {{ currentPage }} of {{ lastPage }} ({{ totalResults }})
                </span>
            </li>
            <li class="page-item" :class="{'disabled': currentPage == lastPage}">
                <button @click="$emit('update-current-page', currentPage + 1)" type="button" class="page-link" aria-label="Next" :disabled="currentPage == lastPage">
                    <i aria-hidden="true" class="fas fa-angle-right"></i>
                    <span class="sr-only">Next</span>
                </button>
            </li>
            <li class="page-item" :class="{'disabled': currentPage == lastPage}">
                <button @click="$emit('update-current-page', lastPage)" type="button" class="page-link" aria-label="Last" :disabled="currentPage == lastPage">
                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                    <span class="sr-only">Last</span>
                </button>
            </li>
        </ul>
    </nav>
</div>
<!-- Elseif display total results -->
<div v-else-if="totalResults" class="column-module-button">
    <span class="font-weight-bold">
        {{ totalResults }} result{{ totalResults > 1 ? 's' : '' }}
    </span>
</div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: [Number, null],
            require: true,
        },
        lastPage: {
            type: [Number, null],
            require: true,
        },
        totalResults: {
            type: [Number, null],
            require: true,
        },
    },
}
</script>
