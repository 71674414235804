<template>
    <div class="row">
        <!-- Close Report View -->
        <div class="col-12 text-right">
            <button class="btn btn-alpha_danger" @click="$emit('close-report-view')">
                Close
            </button>
        </div>

        <template v-if="recallChartData && recallChartData.length && communicationsChart.data">
            <div class="col-12">
                <h2>Recall ROI Report</h2>
                <p>
                    <strong>Date Range:</strong>
                    {{ $moment(report.payload.startDate).format('MM/DD/YY') }}
                    - {{ $moment(report.payload.endDate).format('MM/DD/YY') }}
                </p>
                <p><strong>Dealerships:</strong></p>
                <ul>
                    <li v-for="(dealershipIntegralinkCode, index) in report.payload.dealershipIntegralinkCodes"
                        :key="index"
                    >{{ dealershipIntegralinkCode }}</li>
                </ul>
            </div>

            <!-- Appointment Arrival Rate -->
            <div class="col-12 col-sm-5">
                <bar-chart
                    class="chart-wrapper"
                    label="Appointment Arrival Rate"
                    :chart-data="communicationsChart.data"
                    :options="communicationsChart.options"
                />
            </div>

            <!-- Revenue by Campaign -->
            <div class="col-12 col-sm-7">
                <horizontal-bar-chart
                    class="chart-wrapper"
                    label="Campaign Revenue"
                    :chart-data="revenueChart.data"
                    :options="revenueChart.options"
                    :style="`height: ${(revenueChart.data.datasets[0].data.length * 20) + 94}px`"
                />
            </div>
        </template>
        <template v-else-if="isLoading.getReportData">
            <div class="col-12">
                <p>Loading...</p>
            </div>
        </template>
        <template v-else>
            <div class="col-12">
                <p>The selected report does not contain any recall campaigns.</p>
            </div>
        </template>
    </div>
</template>

<script>
import BarChart from '@/components/analytics/charts/BarChart.vue';
import HorizontalBarChart from '@/components/analytics/charts/HorizontalBarChart.vue';
import { reportViewMixin } from '@/components/reports/mixins/reportViewMixin';

export default {
    mixins: [
        reportViewMixin,
    ],

    components: {
        BarChart,
        HorizontalBarChart,
    },

    props: {
        report: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            chartColors: {
                color1: 'rgb(255, 174, 201)',
                color1Border: 'rgb(255, 118, 149)',
                color2: 'rgb(154, 208, 245)',
                color2Border: 'rgb(88, 178, 239)',
            },
        }
    },

    computed: {
        /**
         * Filter chart data by recall campaigns
         */
        recallChartData() {
            // Filter data by campaigns that contain 'recall'
            return !this.chartData ? null
                : this.chartData.filter((dataPoint) => {
                    return dataPoint['Campaign Name'].toLowerCase().includes('recall');
                });
        },

        communicationsChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (!this.recallChartData) {
                return chart;
            }

            const noOfEmailsSent = this.recallChartData.reduce((acc, curr) => {
                return acc + Number(curr['No. of Emails']);
            }, 0);

            const noOfTextsSent = this.recallChartData.reduce((acc, curr) => {
                return acc + Number(curr['No. of Texts']);
            }, 0);

            chart.data = {
                labels: ['No. of Emails Sent', 'No. of Texts Sent'],
                datasets: [{
                    label: 'Total Communications Sent',
                    backgroundColor: this.chartColors.color1,
                    borderColor: this.chartColors.color1Border,
                    borderWidth: 1,
                    minBarLength: 2,
                    data: [
                        noOfEmailsSent,
                        noOfTextsSent,
                    ]
                }],
            };

            chart.options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: { position: 'bottom' },
                title: {
                    display: true,
                    text: 'Communications Analysis',
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            const label = data.datasets[tooltipItem.datasetIndex].label;

                            return `${label}: ${value < 1 ? 0 : value}`;
                        }
                    }
                },
                scales: {
                    xAxes: [{ ticks: { beginAtZero: true, precision: 0, } }],
                    yAxes: [{ ticks: { beginAtZero: true, precision: 0, } }],
                },
            };

            return chart;
        },

        revenueChart() {
            const chart = {
                data: null,
                options: {},
            };

            if (!this.recallChartData) {
                return chart;
            }

            const campaigns = [];
            let totalReveue = 0;

            this.recallChartData.forEach((dataPoint) => {
                const campaignIndex = campaigns.findIndex((campaign) => {
                    return campaign.campaignName === dataPoint['Campaign Name'];
                });

                totalReveue += Number(dataPoint['Amount']);

                if (campaignIndex === -1) {
                    campaigns.push({
                        campaignName: dataPoint['Campaign Name'],
                        campaignRevenue: dataPoint['Amount'],
                    });

                    return;
                }

                campaigns[campaignIndex].campaignRevenue += dataPoint['Amount'];
            });

            chart.data = {
                labels: campaigns.map(campaign => campaign.campaignName),
                datasets: [{
                    backgroundColor: this.chartColors.color2,
                    borderColor: this.chartColors.color2Border,
                    borderWidth: 1,
                    minBarLength: 2,
                    data: campaigns.map(campaign => parseFloat(campaign.campaignRevenue).toFixed(2)),
                }]
            };

            chart.options = {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                            return `${value < 1 ? 0 : value}`;
                        }
                    }
                },
                scales: {
                    xAxes: [{ ticks: { beginAtZero: true } }],
                    yAxes: [{ ticks: { beginAtZero: true } }],
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: { display: false },
                title: {
                    display: true,
                    text: ['Revenue by Campaign', `Total Revenue: $${parseFloat(totalReveue).toFixed(2)}`],
                }
            };

            return chart;
        },
    },

    created() {
        // Get report data
        this.getReportData();
    },
}
</script>
