<template>
    <div
        id="password-confirmation_modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="password-confirmation_modal_title"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-sm modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Confirm Password to Continue
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="confirmPassword">
                        <div class="form-group">
                            <label
                                for="password_confirmation"
                            >
                                Password
                            </label>

                            <input
                                v-model="password"
                                id="password_confirmation"
                                type="password"
                                class="form-control"
                                name="password"
                                required
                            >
                        </div>

                        <button
                            class="btn btn-sm btn-alpha_primary"
                            type="submit"
                            :disabled="isLoading.confirmPassword"
                        >
                            Confirm
                            <i
                                v-if="isLoading.confirmPassword"
                                class="fas fa-spinner fa-spin"
                            ></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: {
                confirmPassword: false,
            },

            password: '',
        }
    },

    methods: {
        showModal() {
            $('#password-confirmation_modal').modal('show');
        },

        hideModal() {
            $('#password-confirmation_modal').modal('hide');
        },

        confirmPassword() {
            this.isLoading.confirmPassword = true;

            axios
                .post(route('password.confirm',), { password: this.password})
                .then(() => {
                    this.hideModal();

                    this.$message({
                        type: 'success',
                        message: 'Password confirmed.'
                    });
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: error.response.data.message
                            ? error.response.data.message
                            : 'An error occurred. Please try again.'
                    });
                })
                .finally(() => {
                    this.isLoading.confirmPassword = false;
                });
        },
    }
}
</script>
