export default {
    namespaced: true,

    state: {
        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        //
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {
                key:'getResults',
                value: true
            });

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.GetListOfCustomersWithOpportunitiesPaginated',
                        { selectedDealer: selectedDealerId }
                    ),
                    { params: parameters }
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            state.results = Object.freeze(results);
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state, userId = null) {
            state.filterResultsForm = {
                currentPage: 1,
                fromDate: new Date(),
                toDate: new Date(),
                sortBy: 'date_entered',
                filterByActivities: [],
                filterByAssigned: ['Unassigned'],
                filterByCampaignIds: [],
                filterByCustomerNumber: '',
                filterByFirstName: '',
                filterByLastName: '',
                filterByOpenedByAsmIds: [],
                filterByStatuses: [],
                filterByPhoneNumber: '',
                filterByStages: [],
                filterByStatuses: [],
                filterByVin: '',
            };

            if (userId) {
                state.filterResultsForm.filterByAssigned.push(userId);
            }
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
