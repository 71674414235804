<template>
<div class="bg-alpha_bg--shift py-4 flex-grow-1 h-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-7 col-lg-6 col-xl-4 px-xl-4">
                <div class="bg-alpha_bg p-4 p-sm-5">
                    <div class="font-23 text-center mb-4">
                        User Login
                    </div>

                    <div>
                        <div
                            v-if="csrfTokenMismatchError"
                            class="alert alert-alpha_danger"
                            role="alert"
                        >{{ csrfTokenMismatchError }}</div>
                        <div
                            v-if="serverError"
                            class="alert alert-alpha_danger"
                            role="alert"
                        >
                            {{ serverError }}<br>
                            If this problem persists, please contact support
                        </div>

                        <form :action="route('login')" method="POST">
                            <CSRFTokenInput />

                            <!-- Username Input -->
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Username
                                    </span>
                                </div>
                                <input
                                    v-model="loginForm.username"
                                    id="username"
                                    type="text"
                                    class="form-control bg-alpha_bg--shift"
                                    :class="{ 'is-invalid': usernameError }"
                                    name="username"
                                    required
                                    autocomplete="username"
                                    placeholder="Enter Username"
                                >
                            </div>

                            <!-- Password Input -->
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Password
                                    </span>
                                </div>
                                <input
                                    v-model="loginForm.password"
                                    id="password"
                                    type="password"
                                    class="form-control bg-alpha_bg--shift"
                                    :class="{ 'is-invalid': passwordError }"
                                    name="password"
                                    required
                                    autocomplete="current-password"
                                    placeholder="Enter Password"
                                >
                            </div>

                            <!-- Remember Me Checkbox -->
                            <div class="form-check form-check-inline mb-2">
                                <input
                                    v-model="shouldRememberUsernameCheckbox"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="remember_me_checkbox"
                                >
                                <label
                                    class="form-check-label"
                                    for="remember_me_checkbox"
                                >
                                    Remember Me
                                </label>
                            </div>

                            <!-- Submit -->
                            <button @click="preFormSubmit"
                                type="submit"
                                class="btn btn-alpha_primary btn-lg btn-block
                                    spin-on-click"
                            >
                                Login
                                <i
                                    class="fas fa-spinner-third fa-spin d-none"
                                ></i>
                            </button>

                            <!-- Password Reset -->
                            <div class="my-2 d-flex">
                                <a href="/forgot-password" class="m-auto">
                                    Forgot Password
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";
import { preference } from 'vue-preferences';

export default {
    components: {
        CSRFTokenInput,
    },

    props: {
        csrfTokenMismatchError: {
            type: String,
            default: '',
        },
        oldUsername: {
            type: String,
            default: '',
        },
        passwordError: {
            type: String,
            default: '',
        },
        serverError: {
            type: String,
            default: '',
        },
        usernameError: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            loginForm: {
                password: '',
                username: '',
            },

            shouldRememberUsernameCheckbox: false,
        };
    },

    computed: {
        rememberedUsername: preference('rememberedUsername'),
    },

    watch: {
        shouldRememberUsernameCheckbox(value) {
            if (!value) {
                this.rememberedUsername = '';
            }
        },
    },

    created() {
        this.shouldRememberUsernameCheckbox = Boolean(this.rememberedUsername);

        if (this.oldUsername !== '') {
            this.loginForm.username = this.oldUsername;
        } else if (this.rememberedUsername !== '') {
            this.loginForm.username = this.rememberedUsername;
        }
    },

    methods: {
        preFormSubmit() {
            this.rememberedUsername = this.shouldRememberUsernameCheckbox
                ? this.loginForm.username
                : '';
        },
    }
}
</script>
