<template />

<script>
export default {
    name: 'MessageFlasher',

    props: {
        closable: {
            type: Boolean,
            default: false
        },

        duration: {
            type: Number,
            default: 3000,
        },

        message: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            default: 'info',
        },
    },

    mounted() {
        this.$message({
            type: 'error',
            message: this.message,
            duration: this.duration,
            showClose: this.closable,
        });
    },
}
</script>
