<template>
    <div class="flex-grow-1">
        <form @submit.prevent="addConversationMessage">
            <div class="bg-alpha_bg border rounded p-2">
                <div class="row justify-content-between mb-3">
                    <div class="col-12 col-sm-6 col-xl-4">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Participants
                                </span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="selectedParticipants"
                                size="mini"
                                collapse-tags
                                :multiple="true"
                                :multiple-limit="40"
                            >
                                <el-option
                                    v-for="(asm, index) in [{asmName: 'Select Options', asmId: 'select-options'}, ...selectableParticipants]"
                                    :key="index"
                                    :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                    :value="asm.asmId"
                                    :disabled="asm.asmId === 'select-options'"
                                >
                                    <!-- Select Options Buttons -->
                                    <div v-if="asm.asmId === 'select-options'" class="el-select-dropdown__buttons">
                                        <button
                                            type="button"
                                            class="btn btn-link"
                                            @click.stop="selectedParticipants = selectableParticipants.map(item => item.asmId)"
                                        >Select All</button>
                                        <button
                                            type="button"
                                            class="btn btn-link"
                                            @click.stop="selectedParticipants = []"
                                        >Select None</button>
                                    </div>
                                    <!-- Label -->
                                    <span v-else>{{ `${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}` }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Type message"
                    v-model="smsMessageInput"
                ></el-input>
                <div class="row justify-content-between pt-2">
                    <div class="col-sm-8 col-md-6 col-lg-8 col-xl-4 d-flex flex-wrap mb-2 mb-sm-0">
                        <small>{{ smsMessageInput.length }} Characters used</small>
                    </div>
                    <div class="col-sm-4 text-right">
                        <button
                            type="submit"
                            class="btn btn-alpha_secondary"
                            :disabled="!selectedParticipants.length || !smsMessageInput"
                        >Send</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        conversationId: {
            type: String,
            default: '',
        },
        participants: {
            type: Array,
            default: () => [],
        },
        smsMessage: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: {
                addConversationMessage: false,
            },
        }
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        selectableParticipants() {
            return this.asms.filter(asm => asm.asmId !== this.userSession.externalUserId);
        },

        participantsSansLoggedInASM() {
            // All participants excluding the currently logged in user/ASM
            return this.participants.filter(participant => participant !== this.userSession.externalUserId);
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        selectedParticipants: {
            get() {
                return this.participantsSansLoggedInASM;
            },
            set(asmIds) {
                this.$emit('updateParticipants', asmIds);
            }
        },

        smsMessageInput: {
            get() {
                return this.smsMessage;
            },
            set(message) {
                this.$emit('updateSMSMessage', message);
            }
        },
    },

    watch: {
        //
    },

    created() {
        //
    },

    methods: {
        // Add to Conversation
        addConversationMessage() {
            this.isLoading.addConversationMessage = true;

            const params = {
                conversationId: this.conversationId,
                participantASMIds: this.selectedParticipants,
                message: this.smsMessageInput,
            };

            axios
                .post(route('api.unotifi.AddConversationMessage', { selectedDealer: this.selectedDealerId }), params)
                .then(response => {
                    if (response.data.success) {
                        this.$emit('success');
                        this.$message({
                            type: 'success',
                            message: 'Message sent successfully'
                        });
                    }
                })
                .catch(error => {
                    if(error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error sending the message'
                    });
                })
                .finally(() => {
                    this.isLoading.addConversationMessage = false;
                });
        },
    },

    beforeDestroy() {
        //
    },
}
</script>

<style lang="scss" scoped>
    //
</style>
