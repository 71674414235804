<template>
<div class="tab-pane fade py-2 px-0" id="phone-calls" role="tabpanel" aria-labelledby="phone-calls-tab">
    <div class="container-fluid">
        <div class="row mb-3">
            <!-- Phone Number -->
            <div class="col-12 col-sm-6 col-xl-4 mb-2 mb-sm-0">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Phone No.
                        </span>
                    </div>
                    <el-select
                        v-model="phoneCallLogFiltersPhoneNumbers"
                        @change="getPhoneCallLogs()"
                        class="select-blue hide-overflow-tags w-100"
                        size="mini"
                        value-key="uniqueIdentifier"
                        collapse-tags
                        multiple
                    >
                        <el-option
                            v-for="(phoneNumber, index) in customerPhoneNumbers"
                            :key="index"
                            :label="`${phoneNumber.label}: ${formatPhoneNumber(phoneNumber.number)}`"
                            :value="phoneNumber"
                        ></el-option>
                    </el-select>
                </div>
            </div>

            <!-- Date Range -->
            <div class="col-12 col-sm-6 col-xl-4">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text">From Date</span>
                    </div>
                    <v-date-picker
                        v-model="phoneCallLogFiltersDateFrom"
                        @input="getPhoneCallLogs()"
                        :max-date='new Date()'
                        :masks="{
                            input: ['MM/DD/YY'],
                        }"
                        :attributes="[{
                            key: 'today',
                            dot: 'red',
                            dates: new Date(),
                        }]"
                        class="w-100"
                    >
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <input
                                readonly
                                class="v-calendar-select-blue w-100"
                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </v-date-picker>
                </div>
            </div>
        </div>

        <!-- Results -->
        <template v-if="phoneCallLogs.length">
            <phone-call-log v-for="(phoneCallLog, index) in phoneCallLogs" :key="index"
                :phone-call="phoneCallLog"
            />
        </template>
        <template v-else-if="isLoadingGetPhoneCallLogs">
            Loading...
        </template>
        <template v-else>
            No results
        </template>
    </div>
</div>
</template>

<script>
import PhoneCallLog from '@/components/services/child-components/PhoneCallLog.vue';
import { formatPhoneNumber } from '@/filters/phoneNumbers';

export default {
    components: {
        'phone-call-log': PhoneCallLog,
    },

    props: {
        customerPhoneNumbers: {
            type: Array,
            required: true
        },

        dateFrom: {
            // type: Object,
        },

        isLoadingGetPhoneCallLogs: {
            type: Boolean,
            default: false
        },

        phoneCallLogs: {
            type: Array,
            default: () => []
        },

        phoneNumbers: {
            type: Array,
            default: () => []
        },
    },

    computed: {
        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        phoneCallLogFiltersDateFrom: {
            get() {
                return this.dateFrom;
            },
            set(value) {
                this.$emit('updateDateFrom', value);
            }
        },

        phoneCallLogFiltersPhoneNumbers: {
            get() {
                return this.phoneNumbers;
            },
            set(value) {
                this.$emit('updatePhoneNumbers', value);
            }
        },
    },

    methods: {
        /**
         * Imported methods
         */
        formatPhoneNumber,

        getPhoneCallLogs() {
            this.$emit('getPhoneCallLogs');
        },
    },
}
</script>