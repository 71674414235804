<template>
    <div class="col p-3 d-flex flex-column flex-grow-1 flex-shrink-1 h-100 overflow-auto">
        <div class="row mb-4">
            <div class="col-12 col-md-4">
                <span class="font-20 font-weight-bolder">User Account Settings</span>
            </div>
        </div>

        <template>
            <div class="row mb-4">
                <div class="col col-md-6">
                    <form @submit.prevent="saveUserInfo">
                        <div class="card">
                            <h5 class="card-header">User Information</h5>
                            <div class="card-body p-0">
                                <!-- Email -->
                                <div class="form-setting">
                                    <div class="d-flex flex-column justify-content-center">
                                        <p class="font-18">Email</p>
                                    </div>
                                    <div class="form-setting__input">
                                        <el-input placeholder="yourname@domain.com" type="email" name="email" v-model="userInfo.email" :disabled="isLoading.updateUserInfo" required></el-input>
                                    </div>
                                </div>
                                <div v-if="showSendVerificationEmailButton" class="form-setting">
                                    <div class="d-flex flex-column justify-content-center">
                                        <p class="font-18">Confirm Email</p>
                                        <p>Please check your inbox for a verification email.</p>
                                    </div>
                                    <div class="form-setting__input">
                                        <button
                                            @click="sendEmailVerificationLink"
                                            class="btn btn-lg btn-alpha_primary btn-block mr-1"
                                            type="button"
                                            :disabled="isLoading.sendEmailVerificationLink"
                                        >Resend Verification Email</button>
                                        <button
                                            title="Check for verification"
                                            @click="getUserSession(selectedDealerId)"
                                            class="btn btn-lg btn-alpha_secondary"
                                            type="button"
                                        >
                                            <i class="fas fa-redo-alt" :class="{'fa-spin': isLoadingUserSession.getUserSession}"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer bg-alpha_bg">
                                <div class="d-flex d-flex float-right">
                                    <button type="submit" class="btn btn-alpha_primary">
                                        <template v-if="isLoading.updateUserInfo">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Save</template>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>

        <!-- User Settings -->
        <div class="row mb-4">
            <!-- If Settings Loaded -->
            <template v-if="!(isLoading.getConfiguredUserSettings || isLoading.getAvailableSettingsList) && settings.length">
                <div class="col col-md-6">
                    <div class="card">
                        <h5 class="card-header">User Settings</h5>

                        <div class="card-body p-0">
                            <!-- Enable Technician Mode -->
                            <template v-if="enableTechnicianMode">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Enable Technician Mode</strong>
                                        <p>{{ enableTechnicianMode.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="userSettingsConfig['enable-technician-mode']"
                                            @change="updateConfiguredUserSettings('enable-technician-mode')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Else if settings not loaded -->
            <template v-else-if="isLoading.getConfiguredUserSettings || isLoading.getAvailableSettingsList || isLoadingGlobal.getASMList">
                Loading Settings...
            </template>

            <!-- Else -->
            <template v-else>
                Unable to load settings
            </template>
        </div>

        <!-- Two-Factor Authentication -->
        <div class="row mb-4">
            <div class="col col-md-6">
                <div class="card">
                    <h5 class="card-header">
                        <!-- Enabled -->
                        <template v-if="twoFactorAuthStatus === 'enabled'">
                            <i class="fas fa-shield-check text-alpha_success">
                            </i>
                            Two-Factor Authentication (Enabled)
                        </template>

                        <!-- Pending Confirmation -->
                        <template
                            v-else-if="
                                twoFactorAuthStatus === 'pending-confirmation'
                            "
                        >
                            <i
                                class="fas fa-shield-exclamation
                                    text-alpha_warning"
                            ></i>
                            Two-Factor Authentication (Pending Confirmation)
                        </template>

                        <!-- Disabled -->
                        <template v-else>
                            <i
                                class="fas fa-shield-exclamation
                                    text-alpha_danger"
                            ></i>
                            Two-Factor Authentication (Disabled)
                        </template>
                    </h5>

                    <div class="card-body p-0">
                        <div class="setting">
                            <div class="setting__name">
                                <strong>
                                    Manage Two-Factor Authentication
                                </strong>
                                <p>
                                    Two-Factor Authentication adds an extra
                                    layer of security to your account.
                                </p>
                            </div>
                            <div class="setting__input">
                                <a
                                    :href="route('two-factor.management')"
                                    class="btn btn-alpha_primary"
                                >
                                    Manage Two-Factor Authentication
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Change Password -->
        <div class="row mb-4">
            <div class="col col-md-6">
                <div class="card">
                    <h5 class="card-header">
                        Password Management
                    </h5>

                    <div class="card-body p-0">
                        <div class="setting">
                            <div class="setting__name">
                                <strong>
                                    Change Password
                                </strong>
                                <p>
                                    Manage your account password.
                                </p>
                            </div>
                            <div class="setting__input">
                                <button
                                    @click="
                                        $refs.modalPasswordUpdate.showModal()
                                    "
                                    class="btn btn-alpha_primary"
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Password Update Modal -->
        <ModalPasswordUpdate ref="modalPasswordUpdate" />
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ModalPasswordUpdate from '@/components/modals/ModalPasswordUpdate.vue';

export default {
    name: "account-settings",

    components: {
        ModalPasswordUpdate,
    },

    data() {
        return {
            isLoading: {
                getAvailableSettingsList: false,
                getConfiguredUserSettings: false,
                sendEmailVerificationLink: false,
                updateConfiguredUserSettings: false,
                updateUserInfo: false,
            },

            // Settings to pass when updating the user' settings
            userSettingsConfig: {
                'enable-technician-mode': null,
            },

            // Available settings to choose from
            settings: [],

            // Settings to update
            settingsToBeUpdated: [],

            // Variable to hold timeout ID for preventing settings update spam
            updateConfiguredUserSettingsTimeout: null,

            userInfo: {
                name: null,
                email: null,
            },
       }
    },

    created() {
        this.userInfo.name = this.userSession.userInfo.name;
        this.userInfo.email = this.userSession.userInfo.email;

        // Get Settings
        this.getConfiguredUserSettings();
        this.getAvailableSettingsList();
    },

    computed: {
        ...mapState('global', {
            isLoadingGlobal: 'isLoading',
        }),

        ...mapState('userSession', {
            isLoadingUserSession: 'isLoading',
            userSession: 'userSession',
        }),

        showSendVerificationEmailButton() {
            return this.userSession.userInfo.email && !this.userSession.hasVerifiedEmail;
        },

        twoFactorAuthStatus() {
            return this.userSession.twoFactorAuthenticationStatus;
        },

        enableTechnicianMode() {
            return this.settings.find(setting => setting.name === 'enable-technician-mode');
        },
    },

    methods: {
        ...mapActions('userSession', {
            getUserSession: 'getUserSession',
        }),

        saveUserInfo() {
            this.isLoading.updateUserInfo = true;

            const params = {
                'email': this.userInfo.email,
            };

            axios
                .post(route('api.user-info.updateUserInfo', {selectedDealer: this.selectedDealerId}), params)
                .then(response => {
                    this.$message({
                        type: 'success',
                        message: response.data,
                        duration: 0,
                        showClose: true,
                    });

                    this.getUserSession(this.selectedDealerId);
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: error.response?.data?.message ? error.response.data.message : 'There was an error updating your user information.',
                    });
                })
                .finally(() => {
                    this.isLoading.updateUserInfo = false;
                });
        },

        sendEmailVerificationLink() {
            this.isLoading.sendEmailVerificationLink = true;

            axios
                .post(route('api.user-info.sendEmailVerificationLink', {selectedDealer: this.selectedDealerId}))
                .then(response => {
                    this.$message({
                        type: 'success',
                        message: 'Please check your email for a verification link.',
                    });
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error sending the verification email.',
                    });
                })
                .finally(() => {
                    this.isLoading.sendEmailVerificationLink = false;
                });
        },

        /**
         * Get Available Settings List
         */
        getAvailableSettingsList() {
            this.isLoading.getAvailableSettingsList = true;

            axios
                .get(route('api.user-settings.getSettingsList', { selectedDealer: this.selectedDealerId }))
                .then(response => {
                    this.settings = response.data;

                    this.configureSettingsDefaults();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.getAvailableSettingsList = false;
                });
        },

        /**
         * Set the default settings
         */
        configureSettingsDefaults() {
            if (this.userSettingsConfig['enable-technician-mode'] === null) {
                let setting = this.settings.find(setting => setting.name === 'enable-technician-mode');
                let defaultValue = setting?.default_value ? true : false;
                this.userSettingsConfig['enable-technician-mode'] = defaultValue;
            }
        },

        /**
         * Get Configured User Settings
         */
        getConfiguredUserSettings() {
            this.isLoading.getConfiguredUserSettings = true;

            axios
                .get(route('api.user-settings.getConfiguredSettings', { selectedDealer: this.selectedDealerId }))
                .then(response => {
                    this.userSettings = response.data;

                    this.updateUserInterfaceSettings();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.getConfiguredUserSettings = false;
                });
        },

        /**
         * Update Configured User Settings
         */
        updateConfiguredUserSettings(settingToBeUpdated) {
            // Cancel previous timeout
            clearTimeout(this.updateConfiguredUserSettingsTimeout);

            // Add setting to be updated
            if (settingToBeUpdated && !this.settingsToBeUpdated.find(setting => setting === settingToBeUpdated)) {
                this.settingsToBeUpdated.push(settingToBeUpdated);
            }

            // Set timeout to prevent rapid settings updates
            this.updateConfiguredUserSettingsTimeout = setTimeout(() => {
                this.isLoading.updateConfiguredUserSettings = true;

                // Settings to update
                const userSettings = {};
                this.settingsToBeUpdated.forEach(setting => {
                    userSettings[setting] = this.userSettingsConfig[setting];
                });

                axios
                    .post(route('api.user-settings.updateConfiguredSettings', { selectedDealer: this.selectedDealerId }), userSettings)
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: 'Settings Updated'
                        });

                        this.getUserSession(this.selectedDealerId);
                    })
                    .catch(error => {
                        this.$message({
                            type: 'error',
                            message: 'One or more settings were not updated'
                        });

                        if (error.response?.data?.userSettings) {
                            this.userSettings = error.response.data.userSettings;

                            this.updateUserInterfaceSettings();
                        }

                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }
                    })
                    .finally(() => {
                        this.settingsToBeUpdated = [];
                        this.isLoading.updateConfiguredUserSettings = false;
                    });
            }, 2500);
        },

        /**
         * Update the User Interface settings
         */
        updateUserInterfaceSettings() {
            const enableTechnicianMode = this.userSettings.find(setting => setting.setting.name === 'enable-technician-mode');

            if (enableTechnicianMode) {
                this.userSettingsConfig['enable-technician-mode'] = enableTechnicianMode.boolean_value ? true : false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form-setting {
    border: solid grey;
    border-width: 0 0 1px;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem;

    &:last-child {
        border-width: 0;
    }

    .form-setting__input {
        align-self: center;
        display: flex;
        flex-basis: 70%;
        justify-content: flex-end;
    }
}

.setting {
    border: solid grey;
    border-width: 0 0 1px;
    display: flex;
    justify-content: space-between;

    &:last-child {
        border-width: 0;
    }

    .setting__name, .setting__input {
        padding: 1.25rem;
    }

    .setting__input {
        align-self: center;
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;
    }
}
</style>
