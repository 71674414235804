<template>
<div>
    <!-- Add Instant Customer Modal -->
    <div
        ref="modal_add_instant_customer"
        class="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="add_instant_customer_title"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-sm modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title mr-2"
                        id="add_instant_customer_title"
                    >Create New Customer</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <!-- Add Customer Form -->
                    <form @submit.prevent="addInstantCustomer" id="add_instant_customer_form">
                        <!-- First Name -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    First Name
                                </span>
                            </div>
                            <input
                                v-model="customerInfo.firstName"
                                type="text"
                                name="first_name"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Last Name -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Last Name
                                </span>
                            </div>
                            <input
                                v-model="customerInfo.lastName"
                                type="text"
                                name="last_name"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Cell Phone -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Cell Phone
                                    </span>
                                </div>
                                <input
                                    v-model="customerInfo.cellPhone"
                                    type="tel"
                                    name="cellPhone"
                                    class="form-control"
                                    v-mask="'(###) ###-####'"
                                    placeholder="(555) 555-5555"
                                    pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                                    required
                                >
                            </div>
                        </div>

                        <!-- Home Phone -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Home Phone
                                    </span>
                                </div>
                                <input
                                    v-model="customerInfo.homePhone"
                                    type="tel"
                                    name="homePhone"
                                    class="form-control"
                                    v-mask="'(###) ###-####'"
                                    placeholder="(Optional)"
                                    pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                                >
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Email Address
                                    </span>
                                </div>
                                <input
                                    v-model="customerInfo.email"
                                    type="email"
                                    name="email"
                                    class="form-control"
                                    placeholder="(Optional)"
                                />
                            </div>
                        </div>

                        <!-- VIN -->
                        <div
                            class="input-group input-group-sm"
                            :class="{'mb-2': !nhtsaVehicleInfo}"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    VIN
                                </span>
                            </div>
                            <input
                                v-model="customerInfo.vin"
                                v-mask="'XXXXXXXXXXXXXXXXX'"
                                type="text"
                                name="vin"
                                class="form-control"
                                pattern="[0-9A-Za-z]{17}"
                                placeholder="(Recommended)"
                            >
                        </div>

                        <div v-if="nhtsaVehicleInfo" class="mt-1 mb-2 border-top text-right">
                            <small class="form-text text-muted">
                                {{ nhtsaVehicleInfo }}
                            </small>
                        </div>

                        <!-- Advisor -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Advisor</span>
                                </div>
                                <el-select
                                    class="select-blue hide-overflow-tags w-100"
                                    v-model="customerInfo.serviceAdvisorId"
                                    size="mini"
                                    placeholder="(Optional)"
                                    clearable
                                    :disabled="! asms.length"
                                >
                                    <el-option
                                        v-for="(asm, index) in asms"
                                        :key="index"
                                        :label="`${asm.asmName} ${asm.asmNumber ? `(${asm.asmNumber})` : ''}`"
                                        :value="asm.asmNumber"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-danger ml-0"
                        data-dismiss="modal"
                        :disabled="isLoading.AddInstantCustomer"
                    >Cancel</button>
                    <button
                        form="add_instant_customer_form"
                        class="btn btn-alpha_primary"
                        :disabled="isLoading.AddInstantCustomer"
                    >
                        <i
                            v-if="isLoading.AddInstantCustomer"
                            class="fas fa-circle-notch fa-spin"
                        ></i>
                        <template v-else>Create</template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <ModalAddInstantCustomer ref="modalAddInstantCustomer" />
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalAddInstantCustomer.showModal()">Bulk Assign Opportunities</button>
 */

import { generalMixin } from '@/mixins/generalMixin';
import { mapState } from 'vuex';
import nhtsa from '@joshmakar/nhtsa';

export default {
    data() {
        return {
            customerInfo: {},

            isLoading: {
                AddInstantCustomer: false,
            },

            nhtsaVehicleInfo: '',
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),
    },

    mixins: [generalMixin],

    watch: {
        'customerInfo.vin': function(vin) {
            this.nhtsaVehicleInfo = '';

            if (vin?.length === 17) {
                this.getVehicleInfo(vin);
            }
        }
    },

    created() {
        this.resetCustomerInfo();
    },

    methods : {
        showModal() {
            $(this.$refs.modal_add_instant_customer).modal('show');
        },

        hideModal() {
            $(this.$refs.modal_add_instant_customer).modal('hide');
        },

        getVehicleInfo(vin) {
            nhtsa
                .decodeVinFlatFormat(vin)
                .then(response => {
                    const vehicleInfo = response.data.Results[0] ?? null;
                    if (vehicleInfo) {
                        this.nhtsaVehicleInfo = `
                            ${vehicleInfo.ModelYear} ${vehicleInfo.Make} ${vehicleInfo.Model}
                        `;
                    }
                }
            );
        },

        resetCustomerInfo() {
            this.customerInfo = {
                cellPhone: '',
                email: '',
                firstName: '',
                homePhone: '',
                lastName: '',
                serviceAdvisorId: '',
                vin: '',
            };
        },

        async addInstantCustomer() {
            let parameters = {
                cellPhone: this.customerInfo.cellPhone.replace(/\D/g,''),
                firstName: this.customerInfo.firstName,
                lastName: this.customerInfo.lastName,
            };
            let shouldContinue = true;

            if (this.customerInfo.email) {
                parameters['email'] = this.customerInfo.email;
            }

            if (this.customerInfo.homePhone) {
                parameters['homePhone'] = this.customerInfo.homePhone.replace(/\D/g,'');
            }

            if (this.customerInfo.serviceAdvisorId) {
                parameters['serviceAdvisorId'] = this.customerInfo.serviceAdvisorId;
            }

            if (this.customerInfo.vin) {
                parameters['vin'] = this.customerInfo.vin;
            }

            if (!this.customerInfo.vin) {
                const warningMessage = `
                    You're about to create a customer without a VIN. Are you
                    sure you want to continue?
                `;

                await this.$confirm(warningMessage, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).catch(() => {
                    shouldContinue = false;
                });
            }

            if (!shouldContinue) {
                return;
            }

            this.isLoading.AddInstantCustomer = true;

            axios
                .post(route('api.unotifi.AddInstantCustomer', { selectedDealer: this.selectedDealerId }), parameters)
                .then(() => {
                    this.hideModal();

                    this.resetCustomerInfo();

                    this.$message({
                        type: 'success',
                        message: 'Request to create customer was successful.',
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to create customer. If this problem
                            persists, please contact support.`,
                    });
                })
                .finally(() => {
                    this.isLoading.AddInstantCustomer = false;
                });
        },
    },
}
</script>
