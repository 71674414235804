<template>
    <div class="modal fade" id="away_message_modal" tabindex="-1" role="dialog" aria-labelledby="away_message_modal_title" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="away_message_modal_title">Set Away Message</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form id="set_away_settings" @submit.prevent="setAwaySettings">
                        <!-- Datetime -->
                        <label for="away_message_datetime">Date Range</label>
                        <v-date-picker
                            v-model="awaySettings.dateRange"
                            mode="dateTime"
                            :masks="{ input: ['M/D/YY h:mm A'] }"
                            :attributes="[{
                                key: 'today',
                                dot: 'red',
                                dates: new Date(),
                            }]"
                            is-range
                            :minute-increment="15"
                        >
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <div class="d-flex align-items-center mb-2">
                                <div class="">
                                    <input
                                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                        required
                                    />
                                </div>
                                <span class="p-1">
                                    &rarr;
                                </span>
                                <div class="">
                                    <input
                                        id="away_message_datetime"
                                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                        required
                                    />
                                </div>
                            </div>
                            </template>
                        </v-date-picker>

                        <!-- Away Message -->
                        <label for="away_message_message">Away Message</label>
                        <el-input
                            v-model="awaySettings.message"
                            id="away_message_message"
                            class="mb-2"
                            type="textarea"
                            maxlength="160"
                            :autosize="{ minRows: 2, maxRows: 4}"
                            placeholder="Type you away message"
                            required="true"
                        ></el-input>
                        <small>{{ awaySettings.message.length }}/160 Characters used</small>
                    </form>
                </div>

                <div class="modal-footer">
                    <button :disabled="anyLoading" type="submit" class="btn btn-alpha_primary" form="set_away_settings">Update</button>
                    <button :disabled="anyLoading" type="button" class="btn btn-alpha_secondary ml-0" @click="setAwaySettings({ clearAwaySettings: true })">Clear</button>
                    <button type="button" class="btn btn-danger ml-0" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ModalAwayMessage",

    data() {
        return {
            awaySettings: {
                dateRange: {
                    end: null,
                    start: null,
                },
                message: '',
            },

            isLoading: {
                getUserSettings: false,
                setAwaySettings: false,
            },

            userSettings: null,
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        /**
         * Check to see if anything is loading
         */
        anyLoading() {
            return Object.values(this.isLoading).includes(true);
        },
    },

    watch: {
        userSettings() {
            if (this.userSettings) {
                this.awaySettings.dateRange = {
                    start: this.userSettings.away_start_date ? new Date(this.userSettings.away_start_date) : null,
                    end: this.userSettings.away_end_date ? new Date(this.userSettings.away_end_date) : null,
                };
                this.awaySettings.message = this.userSettings.away_message ?? '';
            }
        },
    },

    created() {
        // 
    },

    methods: {
        showModal() {
            $('#away_message_modal').modal('show');

            if (!this.userSettings) {
                this.getUserSettings();
            }
        },

        hideModal() {
            $('#away_message_modal').modal('hide');
        },

        /**
         * Get User Settings
         */
        getUserSettings() {
            this.isLoading.getUserSettings = true;

            axios
                .get(route('api.unotifi.GetUserSettings', { selectedDealer: this.selectedDealerId }))
                .then(response => {
                    this.userSettings = response.data.settings;
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error retrieving your away settings.'
                    });
                })
                .finally(() => {
                    this.isLoading.getUserSettings = false;
                });
        },

        /**
         * Set away settings
         */
        setAwaySettings(options = { clearAwaySettings: false }) {
            this.isLoading.setAwaySettings = true;

            const params = {};

            // Update params if not clearing away settings
            if (!options.clearAwaySettings) {
                params.away_start_date = this.generateLocalDateTime(this.awaySettings.dateRange.start);
                params.away_end_date = this.generateLocalDateTime(this.awaySettings.dateRange.end);
                params.away_message = this.awaySettings.message;
            }

            axios
                .post(route('api.unotifi.SetAwaySettings', { selectedDealer: this.selectedDealerId }), params)
                .then(response => {
                    this.$message({
                        type: 'success',
                        message: 'Away settings updated successfully.'
                    });

                    this.getUserSettings();
                })
                .catch(error => {
                    if(error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error updating your away settings.'
                    });
                })
                .finally(() => {
                    this.isLoading.setAwaySettings = false;
                });
        },

        /**
         * Generate the datetime to local Y-m-d H:i:s format
         */
        generateLocalDateTime(date) {
            const p = new Intl.DateTimeFormat('en', {
                year:'numeric',
                month:'2-digit',
                day:'2-digit',
                hour:'2-digit',
                minute:'2-digit',
                second:'2-digit',
                hourCycle: 'h23',
            }).formatToParts(date).reduce((acc, part) => {
                acc[part.type] = part.value;
                return acc;
            }, {});

            return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
        },
    }

}
</script>

<style lang="scss" scoped>
// 
</style>
