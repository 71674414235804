<template>
    <div class="customer-info h-100 pt-3 py-md-4 px-2 px-md-3">
        <!-- Selected Repair Order Info -->
        <div class="row row-cols-1 d-md-block mb-md-3">
            <div class="col d-flex align-items-center d-md-block order-1">
                <!-- Sentiment -->
                <div v-if="!isLoadingSentiment">
                    <div
                        v-if="getCustomerMoodSettings().show"
                        class="mood mr-3 mx-md-auto mb-2"
                        :class="`mood-${getCustomerMoodSettings().color}`"
                    >
                        <i
                            :class="getCustomerMoodSettings().icon"
                            style="height: 46px"
                            title="Customer's mood guessed from their recent correspondence"
                        ></i>
                    </div>
                </div>
                <div
                    v-else
                    class="mood mood-default mr-3 mx-md-auto mb-2"
                >
                    <i
                        class="fas fa-circle-notch fa-spin"
                        style="height: 46px"
                    ></i>
                </div>

                <!-- Customer Name -->
                <div class="text-md-center">
                    <strong class="font-14">
                        {{ customer.firstName }} {{ customer.lastName }}
                    </strong>
                    <button
                        @click="$_copyMethods_copyText(`${customer.firstName} ${customer.lastName}`)"
                        class="btn badge badge-alpha_neutral--shift"
                        type="button"
                        title="Copy customer name"
                    ><i class="icon">file_copy</i></button>
                    <div class="mb-2">
                        <template v-if="selectedVehicle">
                            {{ Number(selectedVehicle.year)
                                ? selectedVehicle.year
                                : 'YEAR' }}
                            {{ selectedVehicle.v_make
                                ? selectedVehicle.v_make
                                : 'MAKE' }}
                            {{ selectedVehicle.v_model
                                ? selectedVehicle.v_model
                                : 'MODEL' }}
                        </template>
                        <template v-else>N/A</template>
                    </div>
                </div>

                <!-- Customer Score -->
                <div class="ml-auto ml-sm-3 mx-md-0 text-md-center mb-3">
                    <div class="badge badge-alpha_neutral--shift">
                        <i class="icon">star</i> {{ customer.score }}
                    </div>
                </div>
            </div>

            <!-- Action Buttons -->
            <div class="col order-3 order-lg-2 my-2">
                <div class="row row-cols-2 row-cols-md-1 sm-gutters">
                    <!-- Contact Info -->
                    <div class="col">
                        <button
                            type="button"
                            data-toggle="modal"
                            data-target="#customer_contact_info_modal"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >Contact Info</button>
                    </div>

                    <!-- Call -->
                    <div v-if="phoneNumbers.length" class="col">
                        <div class="btn-group w-100">
                            <button
                                class="btn btn-alpha_primary btn-sm btn-block mb-2 dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >Call</button>
                            <div class="dropdown-menu">
                                <!-- Click to Call -->
                                <template v-if="userSession && userSession.hasCallTrackingIntegration">
                                    <button v-for="(phoneNumber, index) in customerPhoneNumbers" :key="index"
                                        @click="$refs.modalClickToCall.showModal(phoneNumber.number)"
                                        class="dropdown-item"
                                    >
                                        {{ phoneNumber.label }}: {{ formatPhoneNumber(phoneNumber.number) }}
                                        <span v-if="phoneNumber.isValid == 1">
                                            <i
                                                title="Valid"
                                                class="fas fa-check-circle text-success"
                                            ></i>
                                        </span>
                                        <span v-else>
                                            <i
                                                title="Invalid"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                        </span>
                                    </button>
                                </template>
                                <!-- Or Tel:link -->
                                <template v-else>
                                    <a
                                        v-for="(phoneNumber, index) in customerPhoneNumbers"
                                        :key="index"
                                        :href="`tel:${phoneNumber.number}`"
                                        class="dropdown-item"
                                    >
                                        {{ phoneNumber.label }}: {{ formatPhoneNumber(phoneNumber.number) }}
                                        <span v-if="phoneNumber.isValid == 1">
                                            <i
                                                title="Valid"
                                                class="fas fa-check-circle text-success"
                                            ></i>
                                        </span>
                                        <span v-else-if="phoneNumber.isValid == 0">
                                            <i
                                                title="Invalid"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                        </span>
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div v-if="customer.email" class="col">
                        <a
                            :href="`mailto:${customer.email}`"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >Email</a>
                    </div>

                    <!-- Add a Case -->
                    <div v-if="filteredCars && filteredCars.length" class="col">
                        <button
                            @click="$refs.modalAddACase.showModal()"
                            type="button"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >+ Case</button>
                    </div>

                    <!-- Add a Note -->
                    <div v-if="customer && customer.ID" class="col">
                        <button
                            @click="addCustomerNote"
                            type="button"
                            class="btn btn-alpha_primary btn-sm btn-block mb-2"
                        >+ Note</button>
                    </div>
                </div>
            </div>

            <!-- Customer Info -->
            <div class="col order-2 order-lg-3 mt-2">
                <div class="row row-cols-3 row-cols-md-1 px-1 font-weight-bolder">
                    <!-- Customer Number -->
                    <div class="col mb-2 order-1">
                        <p class="text-uppercase">Customer</p>
                        <span>{{ customer.number }}</span>
                    </div>

                    <!-- ASM -->
                    <div class="col mb-2 order-2">
                        <p class="text-uppercase">ASM</p>
                        <span v-if="selectedASM">
                            {{ selectedASM.first_name }}
                            {{ selectedASM.last_name }}
                            – {{ selectedASM.asmNumber }}
                        </span>
                        <span v-else>N/A</span>
                    </div>

                    <!-- Email -->
                    <div class="col-12 mb-2 order-4 order-md-3">
                        <p class="text-uppercase">
                            Email
                            <button
                                v-if="customer.email"
                                @click="$_copyMethods_copyText(customer.email)"
                                class="btn badge badge-alpha_neutral--shift"
                                type="button"
                                title="Copy customer email"
                            ><i class="icon">file_copy</i></button>
                        </p>
                        <a
                            v-if="customer.email"
                            :href="`mailto:${customer.email}`"
                        >{{ customer.email }}</a>
                        <span v-else>N/A</span>
                    </div>

                    <!-- Rewards -->
                    <div v-if="userSession" class="col mb-2 order-3 order-md-4">
                        <p class="text-uppercase">
                            Rewards
                            <button
                                @click="getAndCopyRewardsTinyUrl(
                                    `${userSession.urls.dealership}pic_points_certificates_no_login.php`
                                    + `?cid=${customer.ID}&did=${userSession.dealership.dealer_id}`
                                )"
                                class="btn badge badge-alpha_neutral--shift"
                                type="button"
                                title="Copy rewards short link"
                            ><i class="icon">file_copy</i></button>
                        </p>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View rewards certificate"
                            :href="
                                `${userSession.urls.dealership}pic_points_certificates_no_login.php`
                                + `?cid=${customer.ID}&did=${userSession.dealership.dealer_id}`
                            "
                        >
                            <span>${{ customer.totalPoints.toFixed(2) }}</span>
                        </a>
                    </div>
                </div>

                <!-- Opted-in Settings -->
                <div class="row px-1">
                    <div class="col-12 mb-2">
                        <p class="text-uppercase font-weight-bolder">
                            Contact Preferences
                        </p>
                    </div>
                    <div class="col mb-2">
                        <div class="opt-in-settings">
                            <!-- Text -->
                            <div class="opt-in-setting">
                                <i
                                    class="fa-solid"
                                    :class="
                                        customer.optOutTextMessage == 0
                                            ? 'fa-circle-check text-alpha_success'
                                            : 'fa-circle-x text-alpha_danger'
                                    "
                                ></i>
                                Text
                            </div>

                            <!-- Email -->
                            <div class="opt-in-setting">
                                <i
                                    class="fa-solid"
                                    :class="
                                        customer.do_not_email_flag == 0
                                            ? 'fa-circle-check text-alpha_success'
                                            : 'fa-circle-x text-alpha_danger'
                                    "
                                ></i>
                                Email
                            </div>

                            <!-- Call -->
                            <div class="opt-in-setting">
                                <i
                                    class="fa-solid"
                                    :class="
                                        customer.do_not_call_flag == 0
                                            ? 'fa-circle-check text-alpha_success'
                                            : 'fa-circle-x text-alpha_danger'
                                    "
                                ></i>
                                Call
                            </div>

                            <!-- Print/Mail -->
                            <div class="opt-in-setting">
                                <i
                                    class="fa-solid"
                                    :class="
                                        customer.do_not_mail_flag == 0
                                            ? 'fa-circle-check text-alpha_success'
                                            : 'fa-circle-x text-alpha_danger'
                                    "
                                ></i>
                                Print/Mail
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact Info Modal -->
        <ModalCustomerContactInfo
            :are-phone-labels-available="arePhoneLabelsAvailable"
            :customer="customer"
            :custom-phone-numbers="customPhoneNumbers"
            :cars="cars"
            @update-result-info="$emit('update-result-info')"
        />

        <!-- Add Case Modal -->
        <ModalAddACase
            ref="modalAddACase"
            :asms='asms'
            :customer="customer"
            :filtered-cars="filteredCars"
            :selected-a-s-m="selectedASM"
            :selected-vehicle-id="selectedVehicleId"
        />

        <!-- Click to Call Modal -->
        <ModalClickToCall
            ref="modalClickToCall"
            :can-use-confirmation-prompt="userSession.callTrackingProvider
                === 'InteractivTel'"
        />
    </div>
</template>

<script>
import ModalAddACase from './ModalAddACase.vue';
import ModalClickToCall from '@/components/modals/ModalClickToCall.vue';
import ModalCustomerContactInfo from './ModalCustomerContactInfo.vue';
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { copyMethods } from '@/mixins/helperMixins';
import { mapState } from 'vuex';

export default {
    mixins: [copyMethods],

    components: {
        ModalAddACase,
        ModalClickToCall,
        ModalCustomerContactInfo,
    },

    props: {
        arePhoneLabelsAvailable: {
            type: Boolean,
            default: false,
        },

        asms: {
            type: Array,
            default: [],
        },

        cars: {
            type: Array,
            required: true,
        },

        customer: {
            type: Object,
            required: true,
        },

        customPhoneNumbers: {
            type: Array,
        },

        filteredCars: {
            type: Array,
            default: [],
        },

        isLoadingSentiment: {
            type: Boolean,
            default: false,
        },

        lastMessageSentiment: {
            type: String,
            default: 'none',
        },

        phoneNumbers: {
            type: Array,
            required: true,
        },

        rewardsLinkTinyUrl: {
            type: String,
            default: null,
        },

        selectedASM: {
            type: Object | null,
            default: null,
        },

        selectedVehicle: {
            type: Object,
            default: null,
        },

        selectedVehicleId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isLoading: {
                addCustomerNote: false,
            },
        }
    },

    computed: {
        ...mapState('userSession', [
            'userSession',
        ]),

        customerPhoneNumbers() {
            return this.phoneNumbers.filter(phones => {
                return phones.type != 'asm';
            });
        }
    },

    created() {
        this.setMixPanel();
    },

    methods : {
        formatPhoneNumber,

        getCustomerMoodSettings() {
            // Mood: none
            const moodSettings = {
                color: 'default',
                icon: '',
                show: false,
            };

            // Mood: angry
            if (this.lastMessageSentiment === 'angry') {
                moodSettings.color = 'red';
                moodSettings.icon = 'far fa-frown',
                moodSettings.show = true
            }

            // Mood: indifferent
            if (this.lastMessageSentiment === 'indifferent') {
                moodSettings.color = 'blue';
                moodSettings.icon = 'far fa-meh',
                moodSettings.show = true
            }

            // Mood: happy
            if (this.lastMessageSentiment === 'happy') {
                moodSettings.color = 'green';
                moodSettings.icon = 'far fa-smile',
                moodSettings.show = true
            }

            return moodSettings;
        },

        /**
         * Add customer note prompt
         */
        addCustomerNote() {
            this
                .$prompt(
                    `Add a Note About ${this.customer.firstName} ${this.customer.lastName }`,
                    'Add Customer Note',
                    {
                        cancelButtonClass: 'btn btn-alpha_secondary',
                        cancelButtonText: 'Cancel',
                        confirmButtonClass: 'btn btn-alpha_primary',
                        confirmButtonText: 'OK',
                    }
                )
                .then(({ value }) => {
                    this.isLoading.addCustomerNote = true;

                    const params = {
                        note: value,
                    }

                    axios
                        .post(
                            route('api.unotifi.AddCustomerNote',
                            { selectedDealer: this.selectedDealerId, customerId: this.customer.ID }),
                            params
                        )
                        .then(response => {
                            if (response.data.success) {
                                this.$emit('success');
                                this.$message({
                                    type: 'success',
                                    message: 'Note added successfully'
                                });
                                this.$emit('update-customer-notes');
                            }
                        })
                        .catch(error => {
                            if (error.response?.data?.errors) {
                                console.error(error.response.data.errors);
                            } else {
                                console.error(error);
                            }
                        })
                        .finally(() => {
                            this.isLoading.addCustomerNote = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Input canceled'
                    });
                });
        },

        async getAndCopyRewardsTinyUrl(url) {
            if (!this.rewardsLinkTinyUrl) {
                this.$message('Creating the TinyURL');

                await axios
                    .get(
                        route(
                            'api.unotifi.createTinyURL',
                            {
                                selectedDealer: this.selectedDealerId,
                                url: url
                            }
                        )
                    )
                    .then(response => {
                        if (response.data.success) {
                            this.$emit(
                                'update:rewardsLinkTinyUrl',
                                response.data.tinyURL
                            );
                        }
                    })
                    .catch(error => {
                        this.$message.error(
                            'Unable to create a rewards link tiny URL.'
                        );

                        // Copy the full URL
                        this.$copyText(url)
                            .then(() => {
                                this.$message.success(
                                    `Full URL Copied: ${url}`
                                );
                            })
                            .catch(error => {
                                console.error(error);
                            });

                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }

                        return;
                    });
            }

            // Copy the URL
            this.$copyText(this.rewardsLinkTinyUrl)
                .then(() => {
                    this.$message.success(
                        `URL Copied: ${this.rewardsLinkTinyUrl}`
                    );
                })
                .catch(error => {
                    console.error(error);
                });
        },

        setMixPanel(){
            this.$mixpanel.identify(this.userSession?.externalUserId);
            this.$mixpanel.track('customer-info',{
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
                asm_id: this.selectedASM?.asmId,
                asm_name: this.selectedASM?.asmName,
                asm_dealername: this.selectedASM?.dealerName,
                asm_location_code: this.selectedASM?.locationCode,
                asm_location_id: this.selectedASM?.locationId,
                asm_location_name: this.selectedASM?.locationName,
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.opt-in-settings {
    display: flex;
    flex-wrap: wrap;

    .opt-in-setting {
        flex-basis: 50%;
        margin-bottom: 5px;
        margin-right: 0;
    }
}
</style>
