<template>
    <div class="phone-call-log">
        <div class="phone-call-log__indicator" :class="phoneCall.answered ? 'text-success' : 'text-danger'">
            <div v-if="phoneCall.direction === 'outbound'" class="icon">north_east</div>
            <div v-else class="icon">south_west</div>
        </div>
        <div class="phone-call-log__call-info-wrapper">
            <div class="phone-call-log__agent-customer">
                <div class="phone-call-log__agent-info">
                    <strong>{{ asmName }}</strong><br>
                    <small v-if="phoneCall.direction === 'outbound'">{{ formatPhoneNumber(phoneCall.source_number) }}</small>
                    <small v-else>{{ formatPhoneNumber(phoneCall.destination_number) }}</small>
                </div>
                <div class="phone-call-log__direction-indicator">
                    <i v-if="phoneCall.direction === 'outbound'" class="fas fa-long-arrow-alt-right"></i>
                    <i v-else class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="phone-call-log__customer-info">
                    <strong>{{ phoneCall.caller_name ? phoneCall.caller_name : 'Caller'}}</strong><br>
                    <small v-if="phoneCall.direction === 'outbound'">{{ formatPhoneNumber(phoneCall.destination_number) }}</small>
                    <small v-else>{{ formatPhoneNumber(phoneCall.source_number) }}</small>
                </div>
            </div>
            <div class="phone-call-log__time">
                {{ $moment.utc(phoneCall.start_date_time).local().format('MM/DD/YY @h:mm A') }}
                <span title="Duration of call">({{ $moment.utc($moment(phoneCall.end_date_time).diff($moment(phoneCall.start_date_time))).format("mm:ss") }})</span>
            </div>
            <div class="phone-call-log__buttons">
                <button class="btn btn-alpha_primary" v-if="phoneCall.recordings.length" @click="showRecordingsHandler">
                    <i class="fas fa-voicemail"></i> <span>Recordings</span>
                </button>
            </div>
        </div>
        <transition name="expand">
            <div v-show="phoneCall.recordings.length && showRecordings" class="phone-call-log__recordings-wrapper">
                <div class="phone-call-log__recordings">
                    <vue-plyr ref="plyr" v-for="(recording, index) in phoneCall.recordings" :key="index">
                        <audio controls playsinline>
                            <source
                                :src="recording"
                                type="audio/mp3"
                            />
                        </audio>
                    </vue-plyr>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { generalMixin } from '@/mixins/generalMixin';

export default {
    mixins: [generalMixin],

    props: {
        phoneCall: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            showRecordings: false,
        }
    },

    computed: {
        asmName() {
            let asmName = 'Customer Service';

            if (this.phoneCall.reference_id && this.phoneCall.reference_id !== 'None') {
                const foundASM = this.$_generalMixin_getASMByIdOrNumber(this.phoneCall.reference_id);

                asmName = foundASM && (foundASM.first_name || foundASM.last_name) ? `${foundASM.first_name.trim()} ${foundASM.last_name.trim()}`
                    : foundASM.asmName ? foundASM.asmName
                    : asmName;
            }

            return asmName.trim();
        },
    },

    methods: {
        /**
         * Imported methods
         */
        formatPhoneNumber,

        showRecordingsHandler() {
            this.showRecordings = !this.showRecordings;

            if (this.showRecordings) {
                this.$refs.plyr[0].player.play();
            } else {
                this.$refs.plyr.forEach(plyr => {
                    plyr.player.pause();
                });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.phone-call-log {
    border: 1px solid var(--alpha_border);
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1rem;
    padding: 1rem;

    .phone-call-log__indicator {
        margin-right: 1rem;

        .icon {
            font-size: 2.5rem;

            @media (max-width: 1200px) {
                font-size: calc(1.375rem + 1.5vw);
            }
        }
    }

    .phone-call-log__call-info-wrapper {
        flex: 1;
        font-size: .875rem;

    }

    .phone-call-log__agent-customer, .phone-call-log__time {
        margin-bottom: .5rem;
    }

    .phone-call-log__agent-customer {
        display: inline-flex;
        justify-content: space-between;
        line-height: 1;

        small {
            font-size: .75rem;
        }
    }

    .phone-call-log__direction-indicator {
        margin: 0 .5rem;
    }

    .phone-call-log__time {
        flex: 1 0 100%;
        line-height: 1;
    }

    .phone-call-log__buttons {
        flex: 1 0 100%;
    }

    .phone-call-log__recordings-wrapper {
        flex: 1 0 100%;

    }

    .phone-call-log__recordings {
        border-top: 1px solid var(--alpha_border);
        margin-top: 1rem;
        padding-top: 1rem;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        .phone-call-log__call-info-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .phone-call-log__agent-customer {
            margin-right: 1rem;
        }

        .phone-call-log__buttons {
            flex: initial;
            margin-left: auto;

            button {
                height: 100%;
            }

            span {
                display: none;
            }
        }

        .phone-call-log__time {
            flex: initial;
        }
    }

    #all & {
        @media (min-width: 1200px) {
            margin-left: 3rem;
            margin-right: 3rem;
        }
    }
}

// Vue transition classes
.expand-enter-active, .expand-leave-active {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 1s;
}
.expand-enter, .expand-leave-to {
  max-height: 0;
}
</style>