export default {
    namespaced: true,

    state: {
        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        isFilterResultsFormEmpty: state => {
            if (
                ! state.filterResultsForm.dateFromTo
            ) {
                return true;
            }

            return false;
        },
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'getResults', value: true});

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.GetChats',
                        { selectedDealer: selectedDealerId }
                    ),
                    { params: parameters }
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results.conversations) {
                state.results = results.conversations;
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state, userId = null) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            const today = new Date();

            state.filterResultsForm = {
                asmIds: [],
                customerNumber: '',
                dateFromTo: {
                    start: yesterday,
                    end: today,
                },
                firstName: '',
                lastName: '',
                phoneNumber: '',
                VINNumber: '',
            };

            if (userId) {
                state.filterResultsForm.asmIds.push(userId);
            }
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
