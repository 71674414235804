<template>
    <div class="row">
        <div class="bg-alpha_bg col-md-3 col-xl-2 py-3 column-module d-flex flex-column scrolling-column px-0">

            <div id="services__results">
                <div class="px-2 px-md-4 pb-2">
                    <div class="d-flex align-items-center">
                        <a href="#filters-results" class="font-20" data-toggle="collapse" aria-expanded="true" @click="servicesResultSize">
                            Search
                        </a>

                        <ServicesFavoriteButton
                            servicePageName="services-search"
                        />
                    </div>

                    <!-- Filter Results -->
                    <div class="collapse show pt-2" id="filters-results">
                        <form @submit.prevent="filterResultsFormCurrentPage = 1; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})">
                            <!-- First Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="filterResultsFormFirstName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Last Name -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Last Name</span>
                                    </div>
                                    <input v-model="filterResultsFormLastName" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Customer Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Customer No.</span>
                                    </div>
                                    <input v-model="filterResultsFormCustomerNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- VIN Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">VIN</span>
                                    </div>
                                    <input v-model="filterResultsFormVINNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Phone Number -->
                            <div class="mb-2">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Phone No.</span>
                                    </div>
                                    <input v-model="filterResultsFormPhoneNumber" type="text" class="form-control" placeholder="Enter">
                                </div>
                            </div>

                            <!-- Submit/Reset Buttons -->
                            <div class="row mb-2">
                                <div class="col-9 pr-1">
                                    <button type="submit" :disabled="isLoadingResults.getResults || isFilterResultsFormEmpty" class="btn btn-alpha_primary btn-block btn-lg">
                                        <template v-if="isLoadingResults.getResults">
                                            <i style="height: 11px" class="fas fa-cog fa-spin"></i>
                                            <span class="sr-only">Loading...</span>
                                        </template>
                                        <template v-else>Search</template>
                                    </button>
                                </div>
                                <div class="col-3 pl-0">
                                    <button @click="resetFilterResultsForm(); $_generalMixin_scrollSimpleBarResultsList()" type="button" class="btn btn-alpha_secondary btn-block btn-lg">
                                        <i class="fas fa-redo-alt fa-flip-horizontal"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <simplebar ref="resultsList" id="results_list" class="overflow-auto clients-list-wrapper flex-grow-1" :style="servicesResultCss">
                    <!-- Create Customer Button -->
                    <div class="text-center">
                        <button
                            @click="$refs.modalAddInstantCustomer.showModal()"
                            type="button"
                            class="btn btn-link font-weight-bold text-alpha_primary"
                        >Create Customer</button>
                    </div>

                    <!-- Results -->
                    <ul v-if="results.resultsList" class="list-unstyled clients-list">
                        <li
                            v-for="(result, index) in results.resultsList"
                            @click="loadResultInfo(result, selectedDealerId)"
                            :class="{
                                'active': isResultSelected(result.cus_id),
                                'unread': $_generalMixin_getMessageStatusByCustomerId(
                                    result.cus_id, notifications.notifications
                                ) === 'unread'
                            }"
                            :key="index"
                        >
                            <!-- Customer/Vehicle -->
                            <div class="clients-list__name flex-grow-1">
                                <p class="font-14">{{ result.firstName }} {{ result.lastName }}</p>
                                <template v-if="result.vehicles.length">
                                    {{ Number(result.vehicles[0].vehicleYear)
                                        ? result.vehicles[0].vehicleYear
                                        : 'YEAR' }}
                                    {{ result.vehicles[0].vehicleModel
                                        ? result.vehicles[0].vehicleModel
                                        : 'MODEL' }}
                                    <span
                                        v-if="result.vehicles.length > 1"
                                        class="badge badge-pill badge-alpha_neutral--shift"
                                    >+{{ result.vehicles.length - 1 }} more</span>
                                </template>
                            </div>

                            <!-- Customer Info -->
                            <div class="clients-list__info">
                                <!-- Sentiment -->
                                <div
                                    v-if="! isLoadingServices.getCustomersLastMessageSentimentsByIds"
                                    class="mood mood-sm mx-1"
                                    :class="`mood-${$_generalMixin_getCustomerMoodSettings(result.cus_id, customersSentiments).color}`"
                                >
                                    <i
                                        :class="$_generalMixin_getCustomerMoodSettings(result.cus_id, customersSentiments).icon"
                                        title="Customer's mood guessed from their recent correspondence"
                                    ></i>
                                </div>
                                <div v-else class="mood mood-sm mx-1">
                                    <i class="fas fa-circle-notch fa-spin" style="height: 14px"></i>
                                </div>

                                <!-- Notifications -->
                                <div class="clients-list__notification">
                                    <div
                                        v-if="$_generalMixin_getMessageStatusByCustomerId(
                                            result.cus_id, notifications.notifications
                                        ) === 'unread'"
                                        class="badge badge-pill badge-alpha_neutral--shift text-alpha_primary mx-1 badge-notify"
                                    >
                                        <i class="icon">textsms</i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div v-else-if="isLoadingResults.getResults">
                        <p class="font-14 text-alpha_text text-center">Loading...</p>
                    </div>
                    <div v-else>
                        <p class="font-14 text-alpha_text text-center">No results to display.</p>
                    </div>
                </simplebar>

                <!-- Pagination -->
                <ResultsPagination
                    :current-page="filterResultsForm.currentPage ? parseInt(filterResultsForm.currentPage) : null"
                    :last-page="results.last_page ? parseInt(results.last_page) : null"
                    :total-results="results.total ? parseInt(results.total) : null"
                    @update-current-page="currentPage => {filterResultsFormCurrentPage = currentPage; $_generalMixin_scrollSimpleBarResultsList(); submitFilterResultsForm({clearResults: true})}"
                />
            </div>

        </div>
        <div class="col bg-alpha_bg--shift">
            <!-- Select a customer -->
            <template v-if="! isLoadingResultInfo.getResultInfo && ! resultInfo">
                <div class="row p-4">
                    <div class="col">
                        Select a customer
                    </div>
                </div>
            </template>

            <!-- Loading -->
            <template v-else-if="isLoadingResultInfo.getResultInfo">
                <div class="row p-4">
                    <div class="col">
                        Loading...
                    </div>
                </div>
            </template>

            <!-- Selected Result Info -->
            <template v-else-if="! isLoadingResultInfo.getResultInfo && resultInfo">
                <SearchResultInfo />
            </template>
        </div>

        <!-- Add Instant Customer Modal -->
        <ModalAddInstantCustomer
            ref="modalAddInstantCustomer"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { generalMixin } from '@/mixins/generalMixin';
import { servicesMixin } from '@/mixins/servicesMixin';
import ModalAddInstantCustomer from '@/components/services/child-components/ModalAddInstantCustomer.vue';
import ResultsPagination from '@/components/services/child-components/ResultsPagination.vue';
import SearchResultInfo from '@/components/services/SearchResultInfo.vue';
import ServicesFavoriteButton from '@/components/services/child-components/ServicesFavoriteButton.vue';

export default {
    components: {
        ModalAddInstantCustomer,
        ResultsPagination,
        SearchResultInfo,
        ServicesFavoriteButton
    },

    mixins: [generalMixin, servicesMixin],

    data() {
        return {
            // Filters to pass along with getResults function
            filterResultsParams: {},

            // arial-expanded is active or not
            filerResultActive: true,

            // css Class for services result
            servicesResultCss:'max-height: 60vh',
        }
    },

    computed: {
        ...mapState('services/searchResults', {
            filterResultsForm: 'filterResultsForm',
            isLoadingResults: 'isLoading',
            results: 'results',
        }),

        ...mapGetters('services/searchResults', {
            isFilterResultsFormEmpty: 'isFilterResultsFormEmpty',
        }),

        ...mapState('services/searchResultInfo', {
            resultInfo: 'resultInfo',
            isLoadingResultInfo: 'isLoading',
        }),

        ...mapGetters('services/searchResultInfo', {
            selectedVehicle: 'selectedVehicle',
        }),

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        filterResultsFormCurrentPage: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.currentPage;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'currentPage', value: value });
            }
        },

        filterResultsFormCustomerNumber: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.customerNumber;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'customerNumber', value: value });
            }
        },

        filterResultsFormFirstName: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.firstName;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'firstName', value: value });
            }
        },

        filterResultsFormLastName: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.lastName;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'lastName', value: value });
            }
        },

        filterResultsFormPhoneNumber: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.phoneNumber;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'phoneNumber', value: value });
            }
        },

        filterResultsFormVINNumber: {
            get() {
                return this.$store.state.services.searchResults.filterResultsForm.VINNumber;
            },
            set(value) {
                this.$store.commit('services/searchResults/SET_FILTERS', { attribute: 'VINNumber', value: value });
            }
        },
    },

    created() {
        if (Object.keys(this.results).length === 0 && this.results.constructor === Object) {
            this.resetFilterResultsForm();
        }
    },

    mounted() {
        //
    },

    methods: {
        // Vuex searchResults actions
        ...mapActions('services/searchResults', {
            getResults: 'getResults',
        }),

        // Vuex searchResultInfo actions
        ...mapActions('services/searchResultInfo', {
            getResultInfo: 'getResultInfo',
        }),

        // Vuex searchResults mutations
        ...mapMutations('services/searchResults', {
            SET_RESULTS: 'SET_RESULTS',
        }),

        /**
         * Submit result filter form
         */
        submitFilterResultsForm({clearResults = false} = {}) {
            // Create a copy of filters to prevent issues with infinite scrolling
            this.filterResultsParams = Object.assign({}, this.filterResultsForm);

            if (clearResults) {
                this.SET_RESULTS({});
            }

            // Get Results
            this.getResults({ selectedDealerId: this.selectedDealerId, parameters: this.filterResultsParams })
                .then(() => {
                    const foundCustomerIds = [];

                    this.results.resultsList.forEach(result => {
                        if (result.cus_id && ! foundCustomerIds.includes(result.cus_id)) {
                            foundCustomerIds.push(result.cus_id);
                        }
                    });

                    this.getCustomersLastMessageSentimentsByIds({ selectedDealerId: this.selectedDealerId, customerIds: foundCustomerIds });
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: `Unable to get results. Please try again. If
                            the problem persists, please contact support.`,
                        duration: 5000,
                    });
                });
        },

        /**
         * Reset the search result filter form
         */
        resetFilterResultsForm() {
            this.$store.commit('services/searchResults/RESET_FILTERS');

            this.$store.commit('services/searchResults/SET_RESULTS', {});
        },

        loadResultInfo(result, selectedDealerId) {
            this.getResultInfo({
                result: result,
                selectedDealerId: selectedDealerId
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: `Unable to load result info. Please try again. If
                        the problem persists, please contact support.`,
                    duration: 5000,
                });
            });
        },

        /**
         * Check if the search result info is selected
         */
        isResultSelected(customerId) {
            return this.resultInfo && customerId == this.resultInfo.Customer.ID;
        },

        /**
         * Set css Class for services result
         */
        servicesResultSize() {
        this.servicesResultCss = this.filerResultActive?'max-height: 80vh':'max-height: 60vh';
        this.filerResultActive=!this.filerResultActive;
        },
    },
}
</script>
