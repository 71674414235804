export default {
    namespaced: true,

    state: {
        filterResultsForm: {},

        results: {},

        isLoading: {
            getResults: false,
        },
    },

    getters: {
        // Filter selection options
        filterResultsFormOptions() {
            return {
                sortBy: [
                    'Date',
                    'Phone',
                    'Seller',
                ],
            }
        },
    },

    actions: {
        /**
         * Get results
         */
        async getResults({ commit }, { selectedDealerId, parameters }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {
                key:'getResults',
                value: true
            });

            try {
                const response = await axios.get(
                    route(
                        'api.unotifi.getLeads',
                        { selectedDealer: selectedDealerId }
                    ),
                    { params: parameters }
                );
                commit('SET_RESULTS', response.data);
            } catch {
                return Promise.reject(new Error('Unable to get results'));
            } finally {
                commit('CHANGE_IS_LOADING_SETTINGS', {
                    key:'getResults',
                    value: false
                });
            }
        },

        /**
         * Update Lead Info by Id
         */
        async updateLeadInfoById({ commit }, { selectedDealerId, keyValueInfoToUpdate, leadId }) {
            commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateLeadInfo', value: true});

            return await axios
                .post(route('api.unotifi.updateLead', { selectedDealer: selectedDealerId, leadId: leadId }), keyValueInfoToUpdate)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = 'There was an error updating the lead info.';

                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;

                        console.error(error.response.data.errors);

                        if (errors.email1) {
                            errorMessage = 'An invalid email address was provided.';
                        }

                        if (errors.phone_home || errors.phone_mobile || errors.phone_work) {
                            errorMessage = 'An invalid phone number was provided.';
                        }

                        if (errors.vehicle_estimated_miles) {
                            errorMessage = 'An invalid number was provided for the vehicle estimated mileage.'
                        }

                        if (errors.primary_address_postalcode) {
                            errorMessage = 'An invalid zip code was provided.'
                        }
                    } else {
                        console.error(error);
                    }

                    throw new Error(errorMessage);
                })
                .finally(() => {
                    commit('CHANGE_IS_LOADING_SETTINGS', {key:'updateLeadInfo', value: false});
                });
        },
    },

    mutations: {
        SET_RESULTS(state, results) {
            if (results.data) {
                state.results = Object.freeze(results.data);
            } else {
                state.results = {};
            }
        },

        SET_FILTERS(state, { attribute, value }) {
            state.filterResultsForm[attribute] = value;
        },

        RESET_FILTERS(state) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            state.filterResultsForm = {
                currentPage: 1,
                dateFromTo: {
                    start: yesterday,
                    end: new Date(),
                },
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                sortBy: 'Date',
                vehicleMake: '',
                vehicleModel: '',
                VINNumber: '',
            };
        },

        CHANGE_IS_LOADING_SETTINGS(state, {key, value}) {
            state.isLoading[key] = value;
        },
    },
};
