<template>
    <div class="flex-grow-1" :title="isOptOutTexts ? 'Customer is opted out of receiving texts' : !isCustomerMessagingEnabled ? 'Customer messaging is disabled' : ''">
        <form @submit.prevent="SendSMSToCustomer">
            <div class="bg-alpha_bg border rounded p-2">
                <div class="row justify-content-between mb-3">
                    <!-- Send To Select -->
                    <div class="col col-xl-4">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Send to
                                </span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="sendToInput"
                                size="mini"
                                value-key="uniqueIdentifier"
                                :disabled="!canMessageCustomer"
                            >
                                <template v-for="(option, index) in phoneNumbers">
                                    <el-option
                                        :key="index"
                                        :label="`${option.label}: ${formatPhoneNumber(option.number)}`"
                                        :value="option"
                                    >
                                        {{ option.label }}: {{ formatPhoneNumber(option.number) }}
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                    </div>

                    <div class="col-2 col-sm-4 text-right">
                        <button
                            type="button"
                            class="d-lg-none btn btn-lg btn-alpha_secondary"
                            data-toggle="modal"
                            data-target="#predefined_messages_modal"
                            :disabled="!predefinedSMSMessages.length || !canMessageCustomer"
                        >
                            <span class="d-none d-sm-inline">Predefined Messages</span>
                            <i class="d-sm-none far fa-comment-alt-lines"></i>
                        </button>
                        <button
                            type="button"
                            class="d-none d-lg-inline-block btn btn-xs btn-alpha_secondary"
                            data-toggle="modal"
                            data-target="#predefined_messages_modal"
                            :disabled="!predefinedSMSMessages.length || !canMessageCustomer"
                        >Predefined Messages</button>
                    </div>

                </div>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Type message"
                    v-model="smsMessageInput"
                    :disabled="!canMessageCustomer"
                ></el-input>
                <div class="row justify-content-between pt-2">
                    <div class="col-sm-8 col-md-6 col-lg-8 col-xl-4 d-flex flex-wrap align-items-center mb-2 mb-sm-0">
                        <template v-if="!file">
                            <label
                                class="add-attachment-button badge badge-alpha_neutral--shift mr-2 mb-0 p-1"
                                :class="{ disabled: !canMessageCustomer }"
                            >
                                <input
                                    @change="onFileChange"
                                    ref="file"
                                    type="file"
                                    name="file"
                                    accept="image/*, application/pdf"
                                    capture="environment"
                                    style="display: none"
                                    :disabled="!canMessageCustomer"
                                >
                                <i class="fas fa-image"></i> Attach Image/PDF
                            </label>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-danger mr-2 p-1" @click="removeFile">Remove File</button>
                        </template>
                        <small>{{ smsMessageInput.length }} Characters used</small>
                    </div>

                <div class="col-sm-4 text-right">
                    <button
                        type="submit"
                        class="d-lg-none btn btn-lg btn-alpha_primary"
                        :disabled="!canSendSmsMessage"
                    >Send to Customer</button>
                    <button
                        type="submit"
                        class="d-none d-lg-inline-block btn btn-xs btn-alpha_primary"
                        :disabled="!canSendSmsMessage"
                    >Send to Customer</button>
                </div>

                </div>
            </div>
        </form>

        <!-- Predefined Messaged Modal -->
        <div class="modal fade" id="predefined_messages_modal" tabindex="-1" role="dialog" aria-labelledby="predefined_message_title" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="predefined_message_title">Message Templates</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <template v-for="(category, categoryIndex) in predefinedSMSMessages">
                        <div v-if="category.SMS.length" class="list-group mb-3" :key="categoryIndex">
                            <h2 class="font-20">{{ category.NAME }}</h2>
                            <button
                                v-for="(sms, smsIndex) in category.SMS"
                                :key="smsIndex"
                                @click="smsMessageInput = renderPredefinedMessageTags(sms.PREDEFINED_SMS_MESSAGE)"
                                type="button"
                                class="list-group-item list-group-item-action flex-column align-items-start"
                                data-dismiss="modal"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ sms.NAME }}</h5>
                                </div>
                                <p class="mb-1">{{ renderPredefinedMessageTags(sms.PREDEFINED_SMS_MESSAGE) }}</p>
                            </button>
                        </div>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { smsCompositionMixin } from '@/mixins/smsCompositionMixin';

export default {
    mixins: [smsCompositionMixin],

    props: {
        leadId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            //
        }
    },

    computed: {
        isOptOutTexts() {
            return this.customer.do_not_text == '1';
        },

        canMessageCustomer() {
            return !this.isOptOutTexts && this.isCustomerMessagingEnabled
        },

        canSendSmsMessage() {
            return this.smsMessageInput
                && (this.sendToInput ? this.sendToInput.number : false)
                && this.canMessageCustomer
                && !this.isLoading.SendSMSToCustomer;
        },
    },

    created() {
        //
    },

    methods: {
        // Send SMS message to customer
        SendSMSToCustomer() {
            if (!this.phoneNumbers.length) {
                return this.$message({
                    type: 'error',
                    message: 'Unable to send a message if a customer has no phone numbers listed.'
                });
            }

            this.isLoading.SendSMSToCustomer = true;

            const formData = new FormData();

            formData.append('smsText', this.smsMessageInput);
            formData.append('componentName', this.componentName);
            formData.append('phoneNumber', this.sendToInput.number);

            if (this.fileInput) {
                formData.append('file', this.fileInput);
            }

            axios
                .post(
                    route(
                        'api.unotifi.SendSMSToLead',
                        {
                            selectedDealer: this.selectedDealerId,
                            leadId: this.leadId
                        }
                    ),
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(response => {
                    if (response.data.success) {
                        this.$emit('success');
                        this.$message({
                            type: 'success',
                            message: 'Message sent successfully'
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'error',
                        message: 'There was an error sending the message. Please try again.'
                    });
                })
                .finally(() => {
                    this.isLoading.SendSMSToCustomer = false;
                });
        },
    },
}
</script>
