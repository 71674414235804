import { mapActions, mapState } from 'vuex';

export const servicesMixin = {
    computed: {
        ...mapState('global', {
            asms: 'asms',
            notifications: 'smsNotifications',
        }),

        ...mapState('services', {
            customersSentiments: 'customersSentiments',
            isLoadingServices: 'isLoading',
        }),
    },

    created() {
        // Get ASMs list
        if (!this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }
    },

    beforeDestroy() {
        //
    },

    methods: {
        // Vuex global actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),

        // Vuex services actions
        ...mapActions('services', {
            getCustomersLastMessageSentimentsByIds: 'getCustomersLastMessageSentimentsByIds',
        }),
    },
};
