<template>
<div
    ref="modal_add_manual_payment"
    class="modal fade"
    id="add_manual_payment_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add_manual_payment_title"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" :class="{'modal-sm': foundContactInfo}" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-2" id="add_manual_payment_title">Create Payment Request</h5>
            </div>
            <div class="modal-body">
                <!-- Get or Add Contact Info Form -->
                <form v-if="!foundContactInfo" @submit.prevent="getContactInfoByPhone">
                    <!-- If customer phone numbers provided -->
                    <div v-if="customerPhoneNumbers.length">
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Phone Number</span>
                                </div>
                                <el-select
                                    class="select-blue hide-overflow-tags w-100"
                                    v-model="lookupPhoneNumber"
                                    size="mini"
                                    placeholder="Select"
                                >
                                    <el-option
                                        v-for="(phoneNumber, index) in customerPhoneNumbers"
                                        :key="index"
                                        :label="`${phoneNumber.label}: ${formatPhoneNumber(phoneNumber.number)}` + (phoneNumber.isValid == 0 ? ' (Invalid)' : '')"
                                        :value="phoneNumber.number">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <!-- Else -->
                    <div v-else class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Customer Phone Number</span>
                        </div>
                        <input
                            v-model="lookupPhoneNumber"
                            v-mask="'(###) ###-####'"
                            class="form-control"
                            placeholder="(555) 555-5555"
                            pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                            required
                        >
                    </div>

                    <div class="text-right">
                        <button
                            class="btn btn-sm btn-alpha_primary"
                            type="submit"
                            :disabled="isGetContactInfoByPhoneFormDisabled"
                        >
                            {{ customerPhoneNumbers.length ? 'Select Phone Number' : 'Search/Add Customer' }}
                        </button>
                    </div>
                </form>

                <template v-else>
                    <!-- Add Manual Payment Form -->
                    <form
                        v-if="foundContactInfo.type === 'leads'"
                        @submit.prevent="addManualPayment"
                    >
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Phone Number</span>
                            </div>
                            <input :value="lookupPhoneNumber" class="form-control" required disabled>
                        </div>

                        <!-- First Name -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    First Name
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.first_name"
                                type="text"
                                name="first_name"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Last Name -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Last Name
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.last_name"
                                type="text"
                                name="last_name"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Address -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Address
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.address1"
                                @change="!paymentInfo.address1 ? paymentInfo.address2 = '' : ''"
                                type="text"
                                name="address1"
                                class="form-control"
                            >
                        </div>

                        <!-- Address Cont -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Address Cont.
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.address2"
                                type="text"
                                name="address2"
                                class="form-control"
                                placeholder="Apartment, suite, building, etc."
                                :disabled="!paymentInfo.address1"
                            >
                        </div>

                        <!-- City -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    City
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.city"
                                type="text"
                                name="city"
                                class="form-control"
                            >
                        </div>

                        <!-- State -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    State
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.state"
                                v-mask="'AA'"
                                type="text"
                                name="state"
                                class="form-control"
                                pattern="[A-Za-z]{2}"
                            >
                        </div>

                        <!-- Zip Code -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Zip Code
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.zip"
                                v-mask="'#####-####'"
                                @change="paymentInfo.zip.slice(-1) === '-' ? paymentInfo.zip = paymentInfo.zip.slice(0, -1) : ''"
                                type="text"
                                name="zip"
                                class="form-control"
                                minlength="5"
                                maxlength="10"
                            >
                        </div>

                        <!-- Payment Amount -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Amount
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.amount"
                                type="number"
                                name="amount"
                                class="form-control hide-spin-buttons"
                                min=".01"
                                step="any"
                                required
                            >
                        </div>

                        <!-- Reference Number -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Reference No.
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.reference_no"
                                type="text"
                                name="reference_no"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Message -->
                        <div class="form-group">
                            <label for="message">Message</label>
                            <textarea
                                v-model="paymentInfo.message"
                                class="form-control p-1"
                                name="message"
                                rows="3"
                                required
                            ></textarea>
                        </div>

                        <!-- Buttons -->
                        <div class="text-right">
                            <button @click="reset" class="btn btn-sm btn-alpha_secondary" type="button">Go Back</button>
                            <button
                                class="btn btn-sm btn-alpha_primary"
                                type="submit"
                            >Create Payment Request</button>
                        </div>
                    </form>

                    <!-- Add Manual Payment Form -->
                    <form
                        v-else-if="foundContactInfo.type === 'auto_customer'"
                        @submit.prevent="addManualPayment"
                    >
                        <!-- Found Contact Info -->
                        <section>
                            <h2>{{ foundContactInfo.first_name }} {{ foundContactInfo.last_name }}</h2>
                            <h3 v-if="selectedVehicle">
                                {{ selectedVehicle.year }} {{ selectedVehicle.v_make }} {{ selectedVehicle.v_model }}
                            </h3>
                            <address>
                                <div class="phone-number-container">
                                    {{ formattedContactInfoPhoneNumber }}<br>
                                </div>
                                <div class="address-container">
                                    {{ foundContactInfo.address1 }}<br>
                                    <template v-if="foundContactInfo.address2">
                                        {{ foundContactInfo.address2 }}<br>
                                    </template>
                                    {{ foundContactInfo.city }},
                                    {{ foundContactInfo.state }}
                                    {{ foundContactInfo.zip }}
                                </div>
                            </address>
                        </section>

                        <!-- Payment Amount -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Amount
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.amount"
                                type="number"
                                name="amount"
                                class="form-control hide-spin-buttons"
                                min=".01"
                                step="any"
                                required
                            >
                        </div>

                        <!-- Reference Number -->
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Reference No.
                                </span>
                            </div>
                            <input
                                v-model="paymentInfo.reference_no"
                                type="text"
                                name="reference_no"
                                class="form-control"
                                required
                            >
                        </div>

                        <!-- Message -->
                        <div class="form-group">
                            <label for="message">Message</label>
                            <textarea
                                v-model="paymentInfo.message"
                                class="form-control p-1"
                                name="message"
                                rows="3"
                                required
                            ></textarea>
                        </div>

                        <!-- Buttons -->
                        <div class="text-right">
                            <button
                                @click="reset"
                                class="btn btn-sm btn-alpha_secondary"
                                type="button"
                            >Go Back</button>
                            <button
                                class="btn btn-sm btn-alpha_primary"
                                type="submit"
                            >Create Payment Request</button>
                        </div>
                    </form>
                </template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <ModalAddManualPayment ref="modalAddManualPayment" />
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalAddManualPayment.showModal()">
 *     Add Payment
 * </button>
 */

import { formatPhoneNumber } from '@/filters/phoneNumbers';

export default {
    props: {
        customerPhoneNumbers: {
            type: Array,
            required: false,
            default: () => []
        },
        selectedVehicle: {
            type: Object,
            required: false
        },
    },

    data() {
        return {
            foundContactInfo: null,

            isLoading: {
                addManualPayment: false,
                getContactInfoByPhone: false,
            },

            lookupPhoneNumber: '',

            paymentInfo: null,
        }
    },

    computed: {
        // Strip non-digits from lookupPhoneNumber
        formattedLookupPhoneNumber() {
            return this.lookupPhoneNumber.replace(/\D/g,'');
        },

        // Format found contact info phone number
        formattedContactInfoPhoneNumber() {
            if (!this.foundContactInfo) {
                return null;
            }

            const cleaned = ('' + this.foundContactInfo.phone).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }

            return this.foundContactInfo.phone;
        },

        isGetContactInfoByPhoneFormDisabled() {
            if (this.isLoading.getContactInfoByPhone) {
                return true;
            }

            if (this.customerPhoneNumbers.length && !this.lookupPhoneNumber) {
                return true;
            }

            return false;
        },
    },

    methods: {
        /**
         * Imported methods
         */
        formatPhoneNumber,

        /**
         * Show the modal
         */
        showModal() {
            $(this.$refs.modal_add_manual_payment).modal('show');
        },

        /**
         * Hide the modal
         */
        hideModal() {
            $(this.$refs.modal_add_manual_payment).modal('hide');
        },

        /**
         * Reset the modal
         */
        reset() {
            this.foundContactInfo = null;
            this.lookupPhoneNumber = '';
            this.paymentInfo = null;
        },

        /**
         * Get or create contact info by phone number
         */
        getContactInfoByPhone() {
            this.isLoading.getContactInfoByPhone = true;

            const params = {
                phone: this.formattedLookupPhoneNumber,
            };

            axios
                .get(
                    route('api.unotifi.GetContactInfoByPhone', { selectedDealer: this.selectedDealerId }),
                    { params }
                )
                .then(response => {
                    this.foundContactInfo = response.data.contactInfo;

                    this.setPaymentInfo();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'There was an error looking up the contact info or creating a new lead.'
                    });
                })
                .finally(() => {
                    this.isLoading.getContactInfoByPhone = false;
                });
        },

        /**
         * Set paymentInfo
         */
        setPaymentInfo() {
            const basePaymentInfo = {
                amount: '',
                phone: this.foundContactInfo.phone,
                message: '',
                reference_no: '',
                type: this.foundContactInfo.type,
            };

            let computedPaymentInfo = {};

            // If contact info type is "leads"
            if (this.foundContactInfo && this.foundContactInfo.type === 'leads') {
                computedPaymentInfo = {
                    lead_id: this.foundContactInfo.id,
                    first_name: this.foundContactInfo.first_name,
                    last_name: this.foundContactInfo.last_name,
                    address1: this.foundContactInfo.address1,
                    address2: this.foundContactInfo.address2,
                    city: this.foundContactInfo.city,
                    state: this.foundContactInfo.state,
                    zip: this.foundContactInfo.zip,
                };
            }
            // Else If contact info type is "auto_customer"
            else if (this.foundContactInfo && this.foundContactInfo.type === 'auto_customer') {
                computedPaymentInfo = {
                    customer_id: this.foundContactInfo.id,
                    vehicle_id: this.selectedVehicle?.id,
                };
            }

            // Merge basePaymentInfo and computerPaymentInfo objects
            this.paymentInfo = { ...basePaymentInfo, ...computedPaymentInfo };
        },

        /**
         * Add the manual payment request
         */
        addManualPayment() {
            let addManualPaymentRoute = null;

            if (this.paymentInfo && this.paymentInfo.type === 'leads') {
                addManualPaymentRoute = 'api.unotifi.AddLeadManualPayment';
            } else if (this.paymentInfo && this.paymentInfo.type === 'auto_customer') {
                addManualPaymentRoute = 'api.unotifi.AddCustomerManualPayment';
            }

            if (!addManualPaymentRoute) {
                this.$message({
                    type: 'error',
                    message: 'The payment request was not able to be created due to an unknown customer type.'
                });

                return;
            }

            this.isLoading.addManualPayment = true;

            axios
                .post(route(addManualPaymentRoute, { selectedDealer: this.selectedDealerId }), this.paymentInfo)
                .then(response => {
                    if (response.data.success) {
                        // Reset the modal
                        this.reset();

                        // Hide the modal
                        this.hideModal();

                        // Inform of success
                        this.$message({
                            type: 'success',
                            message: 'Payment request created successfully.'
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'The payment request was not able to be created.'
                    });
                })
                .finally(() => {
                    this.isLoading.addManualPayment = false;
                });
        },
    },
}
</script>
