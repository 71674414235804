<template>
<div class="modal fade" ref="add_a_case_modal" tabindex="-1" role="dialog" aria-labelledby="add_a_case_title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-2" id="add_a_case_title">Add a Case</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <template v-if="customer">
                <form @submit.prevent="addCase">
                    <div class="modal-body">
                        <!-- Vehicle Select -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Vehicle</span>
                                </div>
                                <select v-model="caseFormInputs.vehicleId" class="form-control" name="vehicle-id" required>
                                    <option v-for="(car, index) in filteredCars" :key="index" :value="car.id">
                                        {{ car.year }} {{ car.v_make }} {{ car.v_model }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Subject -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Subject</span>
                                </div>
                                <input v-model="caseFormInputs.subject" type="text" name="subject" class="form-control" required>
                            </div>
                        </div>
                        <!-- Description -->
                        <div class="form-group">
                            <label for="description">Description</label>
                            <textarea v-model="caseFormInputs.description" name="description" class="form-control p-1" rows="3"></textarea>
                        </div>
                        <!-- Assigned User -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Assigned User</span>
                                </div>
                                <select v-model="caseFormInputs.asmId" class="form-control" name="asm-id" required>
                                    <option
                                        v-for="(asm, index) in asms"
                                        :key="index"
                                        :value="asm.asmId"
                                    >{{ asm.asmName }} ({{ asm.asmNumber }})</option>
                                </select>
                            </div>
                        </div>
                        <!-- Type -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Type</span>
                                </div>
                                <select v-model="caseFormInputs.type" class="form-control" name="type" required>
                                    <option v-for="(type, index) in ['Service', 'Sale', 'Other']" :key="index" :value="type">
                                        {{ type }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Status -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Status</span>
                                </div>
                                <select v-model="caseFormInputs.status" class="form-control" name="status" required>
                                    <option v-for="(status, index) in ['Assigned', 'Pending', 'Closed', 'Rejected']" :key="index" :value="status">
                                        {{ status }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Priority -->
                        <div class="mb-2">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Priority</span>
                                </div>
                                <select v-model="caseFormInputs.priority" class="form-control" name="priority" required>
                                    <option v-for="(priority, index) in ['Low', 'Medium', 'High', 'Immediate']" :key="index" :value="priority">
                                        {{ priority }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-alpha_secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-alpha_primary" :disabled="isLoading.addCase">
                            <i v-if="isLoading.addCase" class="fas fa-circle-notch fa-spin"></i>
                            <template v-else>Save</template>
                        </button>
                    </div>
                </form>
            </template>
        </div>
    </div>
</div>
</template>

<script>
/**
 * To use this component, include it in your page:
 * <modal-add-a-case ref="modalAddACase"></modal-add-a-case>
 *
 * Then create a button, link, etc that fires the showModal() method:
 * <button type="button" @click="$refs.modalAddACase.showModal()">Add Case</button>
 */

import { mapState } from 'vuex';

export default {
    components: {
        //
    },

    props: {
        asms: {
            type: Array,
        },

        customer: {
            type: Object,
            required: true,
        },

        filteredCars: {
            type: Array,
            default: [],
        },

        selectedASM: {
            type: Object,
            default: null,
        },

        selectedVehicleId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            caseFormInputs: {
                vehicleId: '',
                subject: '',
                description: '',
                asmId: '',
                type: '',
                status: '',
                priority: '',
            },

            isLoading: {
                addCase: false,
            },
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),
    },

    watch: {
        selectedVehicleId(newValue, oldValue) {
            this.caseFormInputs.vehicleId = newValue;
        },
    },

    created() {
        this.resetForm();
    },

    mounted() {
        //
    },

    methods : {
        /**
         * Show the modal
         */
        showModal(data = {}) {
            if (data.description) this.caseFormInputs.description = data.description;
            if (data.subject) this.caseFormInputs.subject = data.subject;
            if (data.vehicleId) this.caseFormInputs.vehicleId = data.vehicleId;

            $(this.$refs.add_a_case_modal).modal('show');
        },

        /**
         * Hide the modal
         */
        hideModal() {
            $(this.$refs.add_a_case_modal).modal('hide');
        },

        /**
         * Reset the form
         */
        resetForm() {
            const loggedInAsm = this.asms.find(asm => asm.asmId === this.userSession.externalUserId);

            this.caseFormInputs.vehicleId = this.selectedVehicleId;
            this.caseFormInputs.subject = '';
            this.caseFormInputs.description = '';
            this.caseFormInputs.asmId = loggedInAsm ? loggedInAsm.asmId : '';
            this.caseFormInputs.type = 'Service';
            this.caseFormInputs.status = 'Assigned';
            this.caseFormInputs.priority = 'Low';
        },

        /**
         * Add a new case
         */
        addCase(submitEvent) {
            const vehicleId = submitEvent.target['vehicle-id'].value;
            const subject = submitEvent.target['subject'].value;
            const description = submitEvent.target['description'].value;
            const asmId = submitEvent.target['asm-id'].value;
            const type = submitEvent.target['type'].value;
            const status = submitEvent.target['status'].value;
            const priority = submitEvent.target['priority'].value;

            const parameters = {
                'subject': submitEvent.target['subject'].value,
                'description': submitEvent.target['description'].value,
                'assignedUserId': submitEvent.target['asm-id'].value,
                'type': submitEvent.target['type'].value,
                'status': submitEvent.target['status'].value,
                'priority': submitEvent.target['priority'].value,
            };

            this.isLoading.addCase = true;

            axios
                .post(route('api.unotifi.AddCase', { selectedDealer: this.selectedDealerId, customerId: this.customer.ID, vehicleId: vehicleId }), parameters)
                .then(response => {
                    if (response.data.success) {
                        // Reset the form
                        this.resetForm();

                        // Hide the modal
                        this.hideModal();

                        // Inform of success
                        this.$message({
                            type: 'success',
                            message: 'Case added successfully'
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.addCase = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
//
</style>