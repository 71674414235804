<template>
    <div class="confirmation-dialog">
        <div v-show="!showConfirmationDialog">
            <div class="btn-group d-inline">
                <button class="btn btn-sm btn-alpha_bg--shift btn-flat dropdown-toggle border" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="isLoading.updateOpportunityStatus">
                    {{ statusUpdate.status }}
                </button>
                <div class="dropdown-menu scrollable-menu">
                    <button v-for="(status, index) in statusListOpportunities.statusList" :key="index"
                        @click="handleInputChange(status)"
                        type="button"
                        class="dropdown-item"
                        :disabled="status === statusUpdate.status && status !== 'Call Back'"
                    >{{ status }}</button>
                </div>
            </div>

            <button v-if="canUndoStatus"
                @click="updateOpportunityStatus({rollbackStatusChange: true})"
                :disabled="isLoading.updateOpportunityStatus"
                :title="`Undo & revert to original status: ${statusUpdate.originalStatus}`"
                type="button"
                class="btn btn-sm px-1"
            ><i class="fas fa-undo-alt"></i></button>
        </div>

        <div v-show="showConfirmationDialog">
            <el-date-picker
                class="confirmation-dialog__date-select"
                v-model="callBackDate"
                type="date"
                size="mini"
                placeholder="Pick a day"
                format="MM-dd-yyyy"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :disabled="isLoading.updateOpportunityStatus"
            ></el-date-picker>
            <div class="confirmation-dialog__buttons">
                <button @click="cancelChange"
                    class="confirmation-dialog__cancel"
                    type="button"
                    :disabled="isLoading.updateOpportunityStatus"
                ><i class="fas fa-times"></i></button>
                <button @click="updateOpportunityStatus"
                    class="confirmation-dialog__confirm"
                    type="button"
                    :disabled="!callBackDate || isLoading.updateOpportunityStatus"
                ><i class="fas fa-check"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: {
        assignedUserId: {
            type: String,
        },

        opportunityId: {
            type: String,
            required: true,
        },

        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            callBackDate: this.$moment(new Date()).format('YYYY-MM-DD'),

            canUndoStatus: false,

            isLoading: {
                updateOpportunityStatus: false,
            },

            pickerOptions: {
                disabledDate(time) {
                    const date = new Date();
                    date.setDate(date.getDate() -1);

                    return time.getTime() < date;
                },
            },

            showConfirmationDialog: false,

            statusUpdate: {
                confirmedStatus: '',
                status: '',
                unconfirmedStatus: '',
                originalStatus: '',
                originalAssignedUserId: '',
            },
        }
    },

    computed: {
        ...mapState('services', {
            statusListOpportunities: 'statusListOpportunities',
        }),
    },

    watch: {
        value() {
            this.setStatus();
        }
    },

    created() {
        // Populate the status list array if it is empty
        if (! this.statusListOpportunities.statusList.length) {
            this.getStatusListOpportunities(this.selectedDealerId);
        }

        this.setStatus();
        this.setOriginalStatus();
    },

    methods: {
        /**
         * Vuex services actions
         */
        ...mapActions('services', {
            getStatusListOpportunities: 'getStatusListOpportunities',
        }),

        /**
         * Set the status to the currently selected status
         */
        setStatus() {
            this.statusUpdate.confirmedStatus = this.value;
            this.statusUpdate.status = this.value;
        },

        setOriginalStatus() {
            this.statusUpdate.originalStatus = this.value;
            this.statusUpdate.originalAssignedUserId = this.assignedUserId;
        },

        handleInputChange(status) {
            this.statusUpdate.unconfirmedStatus = status;

            if (this.statusUpdate.unconfirmedStatus === 'Call Back') {
                return this.showConfirmationDialog = true;
            }

            this.updateOpportunityStatus();
        },

        confirmChange() {
            this.statusUpdate.status = this.statusUpdate.unconfirmedStatus;
            this.statusUpdate.confirmedStatus = this.statusUpdate.status;
            this.statusUpdate.unconfirmedStatus = '',
            this.showConfirmationDialog = false;
        },

        confirmRollback() {
            this.statusUpdate.status = this.statusUpdate.originalStatus;
            this.statusUpdate.confirmedStatus = this.statusUpdate.status;
            this.statusUpdate.unconfirmedStatus = '',
            this.showConfirmationDialog = false;
        },

        cancelChange() {
            this.statusUpdate.status = this.statusUpdate.confirmedStatus;
            this.statusUpdate.unconfirmedStatus = '',
            this.showConfirmationDialog = false;
        },

        /**
         * Update the opportunity status
         */
        updateOpportunityStatus({ rollbackStatusChange = false } = {}) {
            // Cancel if rolling back to "Call Back" status
            if (
                rollbackStatusChange
                && this.statusUpdate.originalStatus === 'Call Back'
            ) {
                this.$message({
                    type: 'warning',
                    message: 'Unable to rollback to "Call Back" status. Please manually update the status.',
                });

                return;
            }

            const parameters = {
                status: rollbackStatusChange
                    ? this.statusUpdate.originalStatus
                    : this.statusUpdate.unconfirmedStatus,
                rollbackStatusChange: rollbackStatusChange,
            };

            if (rollbackStatusChange) {
                parameters.opportunityOwnerId = this.statusUpdate.originalAssignedUserId;
            }

            if (this.statusUpdate.unconfirmedStatus === 'Call Back') {
                parameters.dateCallBack = this.callBackDate;
            }

            this.isLoading.updateOpportunityStatus = true;

            axios
                .post(
                    route(
                        'api.unotifi.SetOportunityCompleted',
                        {
                            selectedDealer: this.selectedDealerId,
                            opportunityId: this.opportunityId
                        }
                    ),
                    parameters
                )
                .then(() => {
                    rollbackStatusChange
                        ? this.confirmRollback()
                        : this.confirmChange();

                    this.$message({
                        type: 'success',
                        message: 'Opportunity updated',
                    });

                    this.canUndoStatus = !rollbackStatusChange;

                    this.$emit('update-result-info');
                })
                .catch(() => {
                    this.cancelChange();

                    this.$message({
                        type: 'error',
                        message: 'Unable to update opportunity',
                    });
                })
                .finally(() => {
                    this.isLoading.updateOpportunityStatus = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
    .confirmation-dialog {
        --margin: 4px;

        align-items: flex-start;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0 calc(var(--margin) * -1) calc(var(--margin) * -1) 0;

        > * {
            margin: 0 var(--margin) var(--margin) 0;
        }

        .confirmation-dialog__buttons {
            display: inline-block;

            .confirmation-dialog__cancel, .confirmation-dialog__confirm {
                border: 0;
                border-radius: 5px;
                color: white;
                height: 28px;
                width: 36px;
            }

            .confirmation-dialog__cancel {
                background: var(--alpha_danger);
            }

            .confirmation-dialog__confirm {
                background: var(--alpha_primary);

                &[disabled] {
                    opacity: .5;
                }
            }
        }

        .form-control-sm {
            max-width: 160px;
            padding: 0.0rem 0.5rem;
        }
    }

    .scrollable-menu {
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
    }
</style>

<style scoped>
    .confirmation-dialog :deep(
        .el-date-editor.el-input,
        .confirmation-dialog :deep(
            .el-date-editor.el-input__inner
        )
    ) {
        width: 130px;
    }

    .confirmation-dialog :deep(.el-input__inner) {
        background: var(--alpha_bg--shift);
    }
</style>
