import { mapActions, mapState } from 'vuex';
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { decodeHtml } from '@/filters/stringFormats';

export const smsCompositionMixin = {
    props: {
        asm: {
            type: Object,
        },
        componentName: {
            type: String,
            required: true,
        },
        customer: {
            type: Object,
        },
        file: {
            type: Object | String,
        },
        phoneNumber: {
            type: Object,
        },
        phoneNumbers: {
            type: Array,
        },
        smsMessage: {
            type: String,
        },
        vehicle: {
            type: Object,
        },
    },

    data() {
        return {
            isLoading: {
                createRewardsTinyUrl: false,
                SendSMSToCustomer: false,
            },
        };
    },

    computed: {
        ...mapState('global', {
            asms: 'asms',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        ...mapState('services', {
            predefinedSMSMessages: 'predefinedSMSMessages',
        }),

        enableTechnicianMode() {
            return Boolean(this.userSession && this.userSession.settings.user['enable-technician-mode']);
        },

        isCustomerMessagingEnabled() {
            return Boolean(this.userSession && this.userSession.settings.dealership['enable-customer-messaging']);
        },

        isOptOutTexts() {
            return this.customer.optOutTextMessage === '1';
        },

        isSmsMessageSendable() {
            if (!this.smsMessageInput) {
                return false;
            }

            if (!this.customer.ID) {
                return false;
            }

            if (!this.selectedVehicleIdInput) {
                return false;
            }

            if (this.sendToInput ? !this.sendToInput.number : true) {
                return false;
            }

            if (this.isOptOutTexts) {
                return false;
            }

            if (!this.isCustomerMessagingEnabled) {
                return false;
            }

            if (this.isLoading.SendSMSToCustomer) {
                return false;
            }

            return true;
        },

        rewardsLink() {
            if (!this.userSession?.urls?.dealership || !this.customer.ID) {
                return null;
            }

            return `${this.userSession.urls.dealership}pic_points_certificates_no_login.php?cid=${this.customer.ID}&did=${this.userSession.externalUserId}`;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        sendToInput: {
            get() {
                if (!this.isCustomerMessagingEnabled && this.phoneNumber.optionType !== 'pending') {
                    return {};
                }
                return this.phoneNumber;
            },
            set(sendToInput) {
                this.$emit('updateSendToInput', sendToInput);
            },
        },

        smsMessageInput: {
            get() {
                return this.smsMessage;
            },
            set(message) {
                this.$emit('updateSMSMessage', message);
            },
        },

        // File input for images/pdfs
        fileInput: {
            get() {
                return this.file;
            },
            set(file) {
                const fileType = file['type'] ?? null;
                const fileSize = file.size ? file.size / 1024 / 1024 : null; // In MBs
                const maxFileSize = 10; // MB

                // Validate file type
                if (fileType && !(fileType.includes('image') || fileType.includes('application/pdf'))) {
                    this.$message({
                        type: 'error',
                        message: 'Invalid file provided',
                    });
                    return;
                }

                // Validate file size
                if (fileSize && fileSize > maxFileSize) {
                    this.$message({
                        type: 'error',
                        message: 'File exceeds max file size',
                    });
                    return;
                }

                this.$emit('updateFile', file);
            },
        },
    },

    created() {
        // Populate the predefined SMS message array if it is empty
        if (!this.predefinedSMSMessages.length) {
            this.getPredefinedSMSMessages(this.selectedDealerId);
        }

        // Give userSession time to load
        const maxAttempts = 5;
        let attempts = 0;
        const waitForUserSessionInterval = setInterval(() => {
            attempts++;
            if (this.userSession?.externalUserId) {
                // Start default message composition if smsMessage blank
                if (!this.smsMessage) {
                    const defaultMessage = this.userSession.settings.dealership['default-precomposed-message']
                        ? `${this.userSession.settings.dealership['default-precomposed-message']} `
                        : `${this.userSession.dealership.dealer_name}: `;
                    this.smsMessageInput = defaultMessage;
                }
                clearInterval(waitForUserSessionInterval);
            } else if (attempts >= maxAttempts) {
                clearInterval(waitForUserSessionInterval);
            }
        }, 500);
    },

    beforeDestroy() {
        //
    },

    methods: {
        /**
         * Vuex services actions
         */
        ...mapActions('services', ['getPredefinedSMSMessages']),

        /**
         * Imported methods
         */
        decodeHtml,
        formatPhoneNumber,

        // Handle file change for images
        onFileChange() {
            this.fileInput = this.$refs.file.files[0];
        },

        // Remove image file
        removeFile() {
            this.fileInput = '';
        },

        // Send SMS message to customer
        sendSMSToCustomer() {
            console.error('You must define the sendSMSToCustomer method');
        },

        // Check if the message contains a reward link tag
        hasRewardsLinkTagInPredefinedMessage(message) {
            return message.includes('%%Rewards Link%%');
        },

        /**
         * Render predefined message tags such as %%Customer First Name%%
         */
        renderPredefinedMessageTags(message) {
            let renderedMessage = message;

            const mappings = {
                // Dealership
                '%%Dealer Name%%': this.userSession?.dealership?.dealer_name
                    ? this.userSession.dealership.dealer_name
                    : '<<Dealer Name>>',
                '%%Dealer Service Phone%%': this.userSession?.dealership?.dealer_phone
                    ? this.formatPhoneNumber(this.userSession.dealership.dealer_phone)
                    : '<<Dealer Service Phone>>',

                // Customer
                '%%Customer Address%%': this.customer?.address1 ? this.customer.address1 : '<<Customer Address>>',
                '%%Customer City%%': this.customer?.city ? this.customer.city : '<<Customer City>>',
                '%%Customer First Name%%': this.customer?.firstName
                    ? this.customer.firstName
                    : '<<Customer First Name>>',
                '%%Customer Last Name%%': this.customer?.lastName ? this.customer.lastName : '<<Customer Last Name>>',
                '%%Customer Score%%': this.customer?.score ? this.customer.score : '<<Customer Score>>',
                '%%Customer State%%': this.customer?.state ? this.customer.state : '<<Customer State>>',
                '%%Customer Zip%%': this.customer?.zip ?? '<<Customer Zip>>',
                '%%Customer Total Points%%': this.customer?.totalPoints ?? '<<Customer Total Points>>',
                '%%Customer Total Points Dollar Value%%':
                    this.customer?.totalPoints ?? '<<Customer Total Points Dollar Value>>',
                '%%Customer Email%%': this.customer?.email ? this.customer.email : '<<Customer Email>>',

                // Rewards Link
                '%%Rewards Link%%': this.rewardsLinkTinyUrl
                    ? this.rewardsLinkTinyUrl
                    : this.rewardsLink
                    ? this.rewardsLink
                    : '<<Rewards Link>>',

                // Service Advisor
                '%%Service Advisor First Name%%': this.asm?.first_name
                    ? this.asm.first_name
                    : '<<Service Advisor First Name>>',
                '%%Service Advisor Last Name%%': this.asm?.last_name
                    ? this.asm.last_name
                    : '<<Service Advisor Last Name>>',

                // Vehicle
                '%%Vehicle Estimated Mileage%%': this.vehicle?.estimated_mileage
                    ? this.vehicle.estimated_mileage
                    : '<<Vehicle Estimated Mileage>>',
                '%%Vehicle Estimated Miles%%': this.vehicle?.estimated_miles_per_day
                    ? this.vehicle.estimated_miles_per_day
                    : '<<Vehicle Estimated Miles>>',
                '%%Vehicle Make%%': this.vehicle?.v_make ? this.vehicle.v_make : '<<Vehicle Make>>',
                '%%Vehicle Model%%': this.vehicle?.v_model ? this.vehicle.v_model : '<<Vehicle Model>>',
                '%%Vehicle Next Service Due%%': this.vehicle?.nextServiceDueDate
                    ? this.$moment(this.vehicle.nextServiceDueDate).format('MMMM Do')
                    : '<<Vehicle Next Service Due>>',
                '%%Vehicle Next Appointment Date%%': this.vehicle?.nextAppointment?.appointment_date
                    ? this.$moment(this.vehicle.nextAppointment.appointment_date).format('MMMM Do')
                    : '<<Vehicle Next Appointment Date>>',
                '%%Vehicle Next Appointment Time%%': this.vehicle?.nextAppointment?.time
                    ? this.vehicle.nextAppointment.time
                    : '<<Vehicle Next Appointment Time>>',
                '%%Vehicle Service Advisor ID%%': this.vehicle?.service_advisor_number
                    ? this.vehicle.service_advisor_number
                    : '<<Vehicle Service Advisor ID>>',
                '%%Vehicle Vin%%': this.vehicle?.VIN ? this.vehicle.VIN : '<<Vehicle Vin>>',
                '%%Vehicle VIN%%': this.vehicle?.VIN ? this.vehicle.VIN : '<<Vehicle VIN>>',
                '%%Vehicle Year%%': this.vehicle?.year ? this.vehicle.year : '<<Vehicle Year>>',
                '%%Vehicle Last Service Date%%': this.vehicle?.last_service_date
                    ? this.$moment(this.vehicle.last_service_date).format('MMMM Do')
                    : '<<Vehicle Last Service Date>>',
            };

            // Create regex expression to search for keys/tags in mappings
            const regex = new RegExp(Object.keys(mappings).join('|'), 'g');

            // Perform the replace operation
            renderedMessage = renderedMessage.replace(regex, (matched) => {
                if (!mappings[matched]) {
                    return matched;
                }

                return mappings[matched];
            });

            // Log any unhandled tags that still exist (ie %%Customer Favorite Planet%%)
            const unhandledTags = /%%[^%%]+%%/g.exec(renderedMessage);
            if (unhandledTags) {
                console.warn('Unhandled tags being used', unhandledTags);
            }

            return decodeHtml(renderedMessage);
        },

        async createRewardsTinyUrl() {
            this.$message('Creating a Tiny URL for the rewards link. Please wait.');
            let url = `${this.userSession.urls.dealership}pic_points_certificates_no_login.php?cid=${this.customer.ID}&did=${this.userSession.externalUserId}`;

            this.isLoading.createRewardsTinyUrl = true;

            await axios
                .get(route('api.unotifi.createTinyURL', { selectedDealer: this.selectedDealerId, url: url }))
                .then((response) => {
                    if (response.data.success) {
                        this.$emit('update:rewardsLinkTinyUrl', response.data.tinyURL);
                    }
                })
                .catch((error) => {
                    this.$message.error('Unable to create a rewards link tiny URL.');

                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    }
                })
                .finally(() => {
                    this.isLoading.createRewardsTinyUrl = false;
                });
        },
    },
};
