<template>
<form @submit.prevent="createReportRequest" id="form_report">
    <div class="row mb-2">
        <div class="col">
            <!-- Dealership Select -->
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">Dealerships</span>
                </div>
                <el-select
                    class="select-blue hide-overflow-tags w-100"
                    v-model="reportForm.dealershipIntegralinkCodes"
                    size="mini"
                    multiple
                    :multiple-limit="3"
                    collapse-tags
                    placeholder="Select"
                    :disabled="! reportFormOptions.dealerships.length"
                >
                    <el-option
                        v-for="(dealership, index) in reportFormOptions.dealerships"
                        :key="index"
                        :label="`${dealership.name} (${dealership.dealer_number})`"
                        :value="dealership.dealer_number"
                        :disabled="dealership.name === 'select-options'"
                        :hidden="dealership.name === 'select-options' && reportFormOptions.dealerships.length < 10"
                    >
                        <span>{{ `${dealership.name} ${dealership.dealer_number ? `(${dealership.dealer_number})` : ''}` }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <!-- Date Range -->
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">Date Range</span>
                </div>
                <v-date-picker
                    v-model="reportForm.dateRange"
                    :attributes="[{
                        key: 'today',
                        dot: 'red',
                        dates: new Date(),
                    }]"
                    is-range
                    :min-date='new Date().setFullYear( new Date().getFullYear() - 1 )'
                    :max-date='new Date()'
                >
                    <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex align-items-center">
                        <input
                            class="form-control form-control--with-prepend"
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            required
                        />
                        <span class="p-1">
                            &rarr;
                        </span>
                        <input
                            class="form-control"
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            required
                        />
                    </div>
                    </template>
                </v-date-picker>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col d-flex justify-content-end">
            <button form="form_report" class="btn btn-alpha_primary" :disabled="!isReportFormValid || isLoading.createReportRequest">Send</button>
        </div>
    </div>
</form>
</template>

<script>
import { formReportMixin } from './mixins/formReportMixin';

export default {
    name: 'FormRecallBdcReport',

    mixins: [ formReportMixin ],

    data() {
        return {
            isLoading: {
                createReportRequest: false,
            },

            report: {
                identifier: 'recall_bdc_report',
            },

            reportForm: {
                dealershipIntegralinkCodes: [],
                dateRange: {
                    start: null,
                    end: null,
                },
            },

            reportFormOptions: {
                dealerships: [],
            },
        };
    },

    computed: {
        isReportFormValid() {
            // Check if dealerships are selected
            if (!this.reportForm.dealershipIntegralinkCodes.length) {
                return false;
            }

            // Check if date range is selected
            if (!this.reportForm.dateRange.start || !this.reportForm.dateRange.end) {
                return false;
            }

            // Check if start date is less than end date
            if (!(this.reportForm.dateRange.start.setHours(0,0,0,0) < this.reportForm.dateRange.end.setHours(23,59,59,999))) {
                return false;
            }

            // Check if a maximum of 31 days are selected
            if (!(Math.round((this.reportForm.dateRange.end - this.reportForm.dateRange.start) / 86400000) <= 31)) {
                this.$message({
                    type: 'error',
                    message: 'Please select a date range of no more than 31 days.',
                });

                return false;
            }

            return true;
        },
    },

    created() {
        if (!this.reportFormOptions.dealerships.length) {
            this.$_formReportMixin_getListOfDealerships().then(dealerships => {
                this.reportFormOptions.dealerships = dealerships;
            });
        }
    },

    methods: {
        createReportRequest() {
            this.isLoading.createReportRequest = true;

            axios
                .post(route('api.reports.store', { selectedDealer: selectedDealerId }), {
                    identifier: this.report.identifier,
                    payload: {
                        dealershipIntegralinkCodes: this.reportForm.dealershipIntegralinkCodes,
                        startDate: this.reportForm.dateRange.start,
                        endDate: this.reportForm.dateRange.end,
                    },
                })
                .then(response => {
                    this.$message({
                        type: 'success',
                        message: 'Report request created.',
                    });

                    this.$emit('reportRequestCreated');
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ?? 'Unable to create report request.';

                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: errorMessage,
                    });
                })
                .finally(() => {
                    this.isLoading.createReportRequest = false;
                });
        },
    },
}
</script>
