<template>
    <div class="communication communication--activity">
        <div class="communication__icon">
            <div v-if="activityName === 'Call'" class="icon">phone</div>
            <div v-else-if="activityName === 'Email'" class="icon">alternate_email</div>
            <div v-else-if="activityName === 'Text'" class="icon">message</div>
            <div v-else class="icon">help_outline</div>
        </div>
        <div class="communication__content">
            <div class="communication__header">
                <strong>{{ activityName }} {{ activityName === 'Other' ? 'Activity Logged' : 'Logged' }}</strong>
                <p v-if="vehicle">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</p>
            </div>
            <div class="communication__item">
                {{ asm.firstName }} {{ asm.lastName }}
            </div>
            <div class="communication__item">
                <small class="white-space-nowrap">
                    {{ $moment(dateTime).format('MM/DD/YY @h:mm A') }}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activityName: {
            type: String,
            require: true,
        },

        asm: {
            type: Object,
            required: true,
        },

        dateTime: {
            type: String,
            required: true,
        },

        vehicle: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
